import { useIsMobile } from '@/hooks/useIsMobile';
import { InfoCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import styled from '@emotion/styled';
import { CodeCardProps } from './CodeCard.type';
import * as Styled from './CodeCard.styled';

export function CodeCard({ title, tooltip, onCopyClick, code }: CodeCardProps) {
  const { isMobile } = useIsMobile();

  const ClickableTooltipWrapper = styled.div`
    display: contents;
    div:first-child {
      display: inherit;
    }
  `;
  return (
    <Styled.Container>
      {isMobile ? (
        <>
          <Styled.CodeMobileLeft>
            <div>
              <Styled.Text>{title}</Styled.Text>
              <ClickableTooltipWrapper>
                <Popover content={tooltip}>
                  <span>
                    <Styled.CodeMobileInfoIcon />
                  </span>
                </Popover>
              </ClickableTooltipWrapper>
            </div>
            {code}
          </Styled.CodeMobileLeft>
          {onCopyClick && (
            <Styled.CodeIcons>
              <span>
                <Styled.CodeCopy onClick={onCopyClick} />
              </span>
            </Styled.CodeIcons>
          )}
        </>
      ) : (
        <>
          <div>
            <Styled.Text>{title}</Styled.Text> : {code}
          </div>
          <Styled.CodeIcons>
            <Popover title={title} content={tooltip} placement="top">
              <span>
                <InfoCircleFilled />
              </span>
            </Popover>
            {onCopyClick && (
              <span>
                <Styled.CodeCopy onClick={onCopyClick} />
              </span>
            )}
          </Styled.CodeIcons>
        </>
      )}
    </Styled.Container>
  );
}
