import styled from '@emotion/styled';
import {
  Accordion,
  accordionClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Popper as MuiPopper,
  Paper,
} from '@mui/material';

export * from '../../Filters.styled';

export const DPFilterContainer = styled(Paper)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const MapHideButton = styled.div`
  width: 200px;
  height: 100%;

  > div {
    height: 100%;
    border-radius: 0;
  }
`;

export const FiltersBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
  height: 100%;
  scroll-snap-type: x mandatory;
  padding: 0px;
`;

export const FilterButton = styled(Accordion)<{
  $theme: any;
}>`
  height: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 0 1px #eaeaeb;
  border-radius: 19px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  scroll-snap-align: center;
  user-select: none;
  border: 2px solid transparent;

  :first-of-type {
    margin-left: 10px;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
  }

  :last-of-type {
    margin-right: 0;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
  }

  ::before {
    display: none;
  }

  &.${accordionClasses.expanded} {
    margin: 0 10px 0 0;

    :first-of-type {
      margin-left: 10px;
    }
  }

  :hover {
    color: ${({ $theme }) => $theme.primaryColor};
    border: 2px solid ${({ $theme }) => $theme.primaryColor};

    .MuiAccordionSummary-expandIconWrapper {
      color: ${({ $theme }) => $theme.primaryColor};
    }
  }
`;

export const FilterButtonText = styled(AccordionSummary)`
  padding: 0px 6px 0 16px;
  &.${accordionSummaryClasses.expanded} {
    min-height: 48px;
  }
`;

export const Popper = styled(MuiPopper)``;

export const Dropdown = styled(Paper)`
  border-radius: 16px;
`;
