import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import { Price } from '../TransportCard/Price';
import { TransportInfo } from '../TransportCard/TransportInfo';
import * as Styled from './FlightCard.styled';
import { FlightCardProps } from './FlightCard.types';

export function FlightCard({
  outbound,
  inbound,
  highLight,
  price,
  enableCardButton,
  isFreeFlight,
  agencyFee,
  isSelectedFlight,
  disabledElevation,
  onFlightCardClick,
}: FlightCardProps) {
  const { t } = useTranslate();

  const buttonText = isSelectedFlight
    ? t('flight.maintain')
    : t('flight.select');

  const hasDivider = !!price || enableCardButton;

  return (
    <Styled.Container onClick={onFlightCardClick}>
      <Styled.HighLight $showHighlight={!!highLight}>
        {highLight && (
          <Styled.HighLightContainer>
            <Styled.HighlightedText>{highLight}</Styled.HighlightedText>
          </Styled.HighLightContainer>
        )}
        <Styled.FlightCardContainer disabledElevation={disabledElevation}>
          <Styled.Content hasDivider={hasDivider}>
            <Styled.Transport>
              <TransportInfo {...outbound} showPin={false} />
            </Styled.Transport>
            {inbound && (
              <Styled.Transport>
                <TransportInfo {...inbound} showPin={false} />
              </Styled.Transport>
            )}
          </Styled.Content>
          {!isFreeFlight && hasDivider && (
            <Styled.PriceContainer>
              {!!price && <Price {...price} agencyFee={agencyFee} />}
              {enableCardButton && (
                <Styled.CardButtonContainer>
                  <Styled.CardButton isSelectedFlight={isSelectedFlight}>
                    {buttonText}
                  </Styled.CardButton>
                </Styled.CardButtonContainer>
              )}
            </Styled.PriceContainer>
          )}
        </Styled.FlightCardContainer>
      </Styled.HighLight>
    </Styled.Container>
  );
}
