import CurrencyFormatter from '@/utils/currencyFormatter';

export const calculatePriceDiffFormatted = ({
  selectedFlight,
  flightPrice,
  language,
  currency,
}: {
  selectedFlight: number;
  flightPrice: number;
  language: string;
  currency: string;
}) => {
  const currencyFormatter = new CurrencyFormatter(language, currency, {
    minimumFractionDigits: 0,
  });

  const price = Math.abs(flightPrice - selectedFlight);

  const prefix = flightPrice > selectedFlight ? '+' : '-';

  return `${prefix}${currencyFormatter.formatNumberToFloor(price)}`;
};
