import { create } from 'zustand';

interface SubscriptionsStore {
  marketingConsent: boolean;
  contractConsent: boolean;
  setMarketingConsent: (value: boolean) => void;
  setContractConsent: (value: boolean) => void;
}

export const useSubscriptionsStore = create<SubscriptionsStore>((set) => ({
  marketingConsent: false,
  setMarketingConsent: (marketingConsent: boolean) => set({ marketingConsent }),
  contractConsent: false,
  setContractConsent: (contractConsent: boolean) => set({ contractConsent }),
}));
