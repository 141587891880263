import styled from '@emotion/styled';

export const Container = styled.div`
  font-size: 14px;
  color: #3c3c3c;
  display: flex;
  align-items: start;
`;

export const IconContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-right: 8px;
  padding-top: 4px;
`;

export const TextContainer = styled.div`
  p {
    margin-bottom: 4px;
  }
`;

export const ExpandButton = styled.p`
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  color: #3c3c3c;
`;

export const ExpandButtonIconContainer = styled.span`
  padding-left: 8px;
  padding-top: 2px;
`;
