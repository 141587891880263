import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '../Button';

export const FlightCardContainer = styled.div<{ disabledElevation: boolean }>`
  border-radius: 8px;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #fff;

  position: relative;
  z-index: 2;
  display: flex;

  transition: box-shadow 0.2s ease-in-out 0s;

  @media (max-width: 767px) {
    min-height: unset;
    display: flex;
    flex-direction: column;
  }

  &:hover {
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }

  ${(props) =>
    props.disabledElevation &&
    css`
      box-shadow: none;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      &:hover {
        box-shadow: none;
      }
    `}
`;

export const Content = styled.div<{ hasDivider: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 8px;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;

  @media (min-width: 768px) {
    ${(props) =>
      props.hasDivider &&
      css`
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          width: 16px;
          box-shadow:
            rgba(0, 0, 0, 0.04) -16px 0px 20px -16px inset,
            rgba(0, 0, 0, 0.04) -16px 0px 10px -16px inset,
            rgba(0, 0, 0, 0.02) -16px 0px 4px -16px inset;
        }
      `}
  }

  @media (max-width: 767px) {
    padding: 0px 16px;
  }
`;

export const PriceContainer = styled.div`
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Transport = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
`;

export const HighLight = styled.div<{ $showHighlight: boolean }>`
  ${({ $showHighlight }) =>
    $showHighlight
      ? css`
          padding: 4px;
          background-color: #fee6f2;
          border-radius: 10px;
          margin: 0px -4px;
        `
      : css``}
`;

export const HighLightContainer = styled.div`
  overflow: hidden;
  margin: 4px 16px 6px;
`;

export const HighlightedText = styled.div`
  display: flex;
  flex-wrap: wrap;

  color: #f2007d;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
`;

export const Container = styled.div`
  // TO DELETE
  letter-spacing: 0px;
  font-family: Open Sans;
  cursor: pointer;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ModalMessageContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  color: rgba(128, 128, 128, 1);
  background-color: #e3e3e3;
  height: 47px;
  padding: 16px;
  border-radius: 10px;
`;

export const ModalMessageLabel = styled.span`
  color: rgba(128, 128, 128, 1);
  margin-left: 8px;
  font-size: 14px;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
`;

export const ModalFooterPriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
`;

export const ModalFooterPrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #f2007d;
  line-height: 28px;
  display: flex;
`;

export const ModalFooterPriceUnity = styled.span`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #f2007d;
  line-height: 16px;
  align-self: center;
`;

export const ModalFooterExtraMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #74747c;
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
`;

export const ModalFooterExtraMessageLabel = styled.span`
  margin-right: 4;
  line-height: 16px;
`;

export const AgencyFee = styled.p`
  line-height: 18px;
  color: gray;
  text-align: right;
`;

export const AgencyFeeContainer = styled.p`
  line-height: 18px;
  margin-top: 2px;
  color: gray;
  text-align: right;
`;

export const CardButton = styled(Button)<{ isSelectedFlight: boolean }>`
  border: 1px solid #f2007d;
  padding: 4px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  line-height: 20px;
  width: 100%;

  ${({ isSelectedFlight }) =>
    isSelectedFlight
      ? css`
          background-color: #f2007d;
          color: #fff;
        `
      : css`
          color: #f2007d;
          background-color: #fff;
        `}
`;

export const CardButtonContainer = styled.div`
  padding: 0 16px 16px 16px;
`;
