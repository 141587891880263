import { HotelBedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useGetParam } from '@/hooks/useGetParam';
import { useHandleFilter } from '@/hooks/useHandleFilter';
import { useStore } from '@/store/useStore';
import { Box } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Styled from './ProductSelector.styled';

export function ProductSelector() {
  const getParam = useGetParam();

  const { addFilter } = useHandleFilter();

  const { t } = useTranslate();

  const brandConfig = useStore((s) => s.brandConfig);
  const { primaryColor } = brandConfig.theme;
  // const availableProducts = brandConfig.availableProducts;
  const availableProducts = ['F', 'DP', 'H'];

  const product = getParam('product');

  const products = [
    {
      label: t('general.product.dp'),
      icon: (
        <span>
          <PlaneNEIcon style={{ marginRight: '4px' }} />
          <HotelBedIcon />
        </span>
      ),
      id: 'DP',
    },
    { label: t('general.product.hotel'), icon: <HotelBedIcon />, id: 'H' },
    { label: t('general.product.flight'), icon: <PlaneNEIcon />, id: 'F' },
  ];

  return (
    <Box sx={{ borderBottom: 1, borderColor: '#eaeaeb', paddingTop: 1 }}>
      <Styled.Container>
        {products
          .filter((e) => availableProducts.includes(e.id))
          .map((el) => (
            <Styled.Item
              key={el.id}
              onClick={() => addFilter('product', el.id)}
              $selected={product === el.id}
              $color={primaryColor}
            >
              {el.icon}
              {el.label}
            </Styled.Item>
          ))}
      </Styled.Container>
    </Box>
  );
}
