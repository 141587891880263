import styled from '@emotion/styled';

export const HotelName = styled.h1`
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: 744px) {
    font-size: 28px;
    line-height: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
  }
`;

export const HotelDetailContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HotelDirection = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const ImageCarouselContainer = styled.div`
  position: relative;
  height: 67.5vw;
  display: flex;
  column-gap: 0.25rem;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  @media (min-width: 375px) {
    height: 69.3vw;
  }

  @media (min-width: 744px) {
    height: 264px;
    display: grid;
    gap: 0.5rem;
    z-index: 0;
    padding: 0px;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (min-width: 1280px) {
    height: 400px;
  }
`;

export const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;

  @media (min-width: 375px) {
    flex: 0 0 calc(-36px + 100vw);
  }
`;

export const MainImage = styled(CarouselImage)`
  grid-row: 1 / 3;
`;

export const LastImage = styled(CarouselImage)``;

export const Divider = styled.div`
  width: 1px;
  background-color: #eaeaeb;
  height: 20px;
  margin: 0px 8px;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
`;

export const AmenitiesContainer = styled.div`
  width: 100%;
`;
export const CheckInContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-right: 1px solid #eaeaeb;
`;

export const CheckInTitle = styled.span`
  min-width: 90px;
  font-weight: 700;
`;
export const CheckInContent = styled.span`
  display: flex;
`;

export const CheckOutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CheckInInfoContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #3c3c3c;
  display: flex;
  gap: 1rem;
  min-height: 80px;
  padding: 1rem 1.5rem;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 744px) {
    min-height: 76px;
  }

  @media (min-width: 1280px) {
    display: inline-flex;
  }
`;

export const AmenitiesList = styled.ul`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  height: auto;
  padding: 0px;
  gap: 4px;
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 744px) {
    display: flex;
    gap: 8px;
    padding: 0px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0px;
  }
`;

export const Amenity = styled.li`
  display: flex;
  gap: 4px;
  & svg {
    font-size: 20px;
    opacity: 0.75;
  }
`;

export const ViewAllAmenities = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: red;
  padding: 0px;
  border: unset;
  background: unset;
  width: fit-content;
  cursor: pointer;
  text-align: left;

  @media (max-width: 744px) {
    width: 100%;
  }
`;

export const AmenetiesTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
  margin-bottom: 8px;
`;

export const HotelDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 744px) {
    gap: 16px;
    flex-direction: column;
  }
`;
export const StayContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
