import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #eaeaeb;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 24px;
  margin-top: 0px;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
`;
