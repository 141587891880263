import { TransportLogo } from '../../FullTransportLogos/TransportLogo';
import { LuggageDetails } from '../../LuggageDetails';
import { MobileTransportInfoProps } from './MobileTransportInfo.types';
import * as Styled from './MobileTransportInfo.styled';

export function MobileTransportInfo({
  companies,
  baggageInfo,
}: MobileTransportInfoProps) {
  return (
    <Styled.Container>
      <Styled.TransportCompanyContainer>
        <Styled.LogosContainer>
          {companies.map((e, index) => (
            <TransportLogo
              key={`${index}_${e.code}_transport`}
              companyCode={e.code}
              logoSize={20}
              iconSize={20}
            />
          ))}
        </Styled.LogosContainer>
        {companies.length === 1 ? (
          <Styled.TransportCompany>{companies[0].name}</Styled.TransportCompany>
        ) : (
          <Styled.TransportCompany>{`${companies[0].name} + ${
            companies.length - 1
          }`}</Styled.TransportCompany>
        )}
      </Styled.TransportCompanyContainer>
      <LuggageDetails
        carryOnIncluded={baggageInfo.carryOnIncluded}
        checkedBaggageIncluded={baggageInfo.checkedBaggageIncluded}
      />
    </Styled.Container>
  );
}
