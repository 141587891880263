import { BackendFetch, DevTools, FormatSimple, Tolgee } from '@tolgee/react';
import { useStore } from '../../store/useStore';

export function useTolgeeConfig() {
  const brandConfig = useStore((state) => state.brandConfig);

  return Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .use(
      BackendFetch({
        prefix: window._env_.VITE_APP_TOLGEE_PROD_URL,
      })
    )
    .init({
      apiUrl: window._env_.VITE_APP_TOLGEE_API_URL,
      apiKey: window._env_.VITE_APP_TOLGEE_API_KEY,
      language: brandConfig?.language,
    });
}
