import { useTranslate } from '@tolgee/react';
import { Facilities } from '../Facilities/Facilities';
import { NightCounter } from '../NightsCounter';
import { Rating } from '../Rating';
import * as Styled from './HotelCardInfo.styled';
import { HotelCardInfoProps } from './HotelCardInfo.types';

export function HotelCardInfo({
  summary,
  hotelName,
  rating,
  stayConfig,
  facilities,
  flightRate,
}: HotelCardInfoProps) {
  const { t } = useTranslate();

  const summaryText = `${t(`hotel.property_type.${summary.accommodationType?.toLowerCase()}`)?.toUpperCase()} in ${summary.city?.label}, ${summary.country?.label}`;

  return (
    <Styled.Container>
      <Styled.Summary>{summaryText}</Styled.Summary>
      <Styled.HotelName>{hotelName}</Styled.HotelName>
      <Rating
        stars={rating.stars}
        value={rating?.value}
        opinionsCount={rating.opinionsCount}
      />
      <NightCounter stayConfig={stayConfig} />
      <Facilities facilities={facilities} flightRate={flightRate} />
    </Styled.Container>
  );
}
