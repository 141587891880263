import { JourneyContractSegment } from '../types/tripDetailsTypes/JourneyContract';

export function enrichWithTechnicalStops(
  segments: Array<JourneyContractSegment>
): Array<JourneyContractSegment> {
  const adaptedSegments: Array<JourneyContractSegment> = [];

  segments.forEach((segment) => {
    if (segment.technicalStops && segment.technicalStops.length) {
      const segmentDifferentArrival: JourneyContractSegment = {
        ...segment,
        arrival: {
          datetime: segment.technicalStops[0]?.arrivalTime,
          hub: segment.technicalStops[0]?.hub,
        },
      };
      adaptedSegments.push(segmentDifferentArrival);

      const segmentDifferentDeparture: JourneyContractSegment = {
        ...segment,
        departure: {
          datetime: segment.technicalStops[0]?.departureTime,
          hub: segment.technicalStops[0]?.hub,
        },
      };
      delete segmentDifferentDeparture.technicalStops;
      adaptedSegments.push(segmentDifferentDeparture);
    } else {
      adaptedSegments.push(segment);
    }
  });

  return adaptedSegments;
}
