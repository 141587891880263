export const getCombination = (additionalInfo: {
  numberOfPieces: number;
  weightForPiece: string;
  totalWeight: string;
  weightUnitOfMeasure: string;
}) =>
  additionalInfo &&
  `${additionalInfo.numberOfPieces} x 
  ${additionalInfo.weightForPiece || additionalInfo.totalWeight}
  ${additionalInfo.weightUnitOfMeasure}`;
