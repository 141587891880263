import styled from '@emotion/styled';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';

export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #eaeaeb;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;
export const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #3c3c3c;
`;
export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
`;
export const TotalPrice = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
`;

export const Accordion = styled(MuiAccordion)`
  & .MuiAccordionDetails-root {
    padding: 0px !important;
    padding-top: 8px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding-left: 0px;
  padding-right: 0px;
  min-height: unset !important;
  & .MuiAccordionSummary-content {
    margin: 0 !important;
  }
`;
