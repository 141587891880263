import {
  AdvancedSearchInput,
  ShoppingHdpServiceService,
} from '@/services/codegen-romulo';

import { createStore } from 'zustand';

const SORT_SELECTOR_DEFAULT = 'TRANSPORT-RECOMMENDED';

export type CreateHotelDetailConfigStore = ReturnType<
  typeof createHotelDetailConfigStore
>;

type SortSelectorProps = {
  value: string;
  type?: string;
  ascending: boolean;
};

export interface StoreHotelDetailStateProps {
  sortSelector: SortSelectorProps;
  retrievePricingProporsals: any;
  flightsAlternativeProposals: any;
  isFetchFlightsAlternativeProporsals: boolean;
  currentPageNumber: number;
  onChangePageNumber: (page: number) => void;
  onChangeSortSelector: (selectorData: SortSelectorProps) => void;
  pricingId: string;
  getRetrievePricingProporsals: (searchId: string, proposalId: string) => void;
  getFlightsAlternativeProposals: (
    pricingId: string,
    resultFilter?: any
  ) => void;
}

export const createHotelDetailConfigStore = () => {
  return createStore<StoreHotelDetailStateProps>()((set, get) => ({
    sortSelector: {
      value: SORT_SELECTOR_DEFAULT,
      ascending: true,
    },
    flightFilters: {},
    flightsAlternativeProposals: null,
    retrievePricingProporsals: null,
    pricingId: null,
    currentPageNumber: 1,
    isFetchFlightsAlternativeProporsals: false,
    onChangePageNumber: (page) => {
      set({
        currentPageNumber: page,
      });
    },
    getRetrievePricingProporsals: async (
      searchId,
      proposalId
    ): Promise<void> => {
      try {
        const responseCreatePricing =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreatePricing(
            { requestBody: { searchId, proposalId } }
          );

        const pricingId = responseCreatePricing?.id?.value;

        const retrievePricingProporsals =
          await ShoppingHdpServiceService.getApiServicesAppShoppingHdpServiceRetrievePricing(
            {
              pricingId,
            }
          );

        set({
          pricingId,
          retrievePricingProporsals,
        });
      } catch (e) {
        console.log('🚀 ~ getRetrievePricingProporsals ~ e:', e);
      }
    },

    onChangeSortSelector: (selectorData) =>
      set(() => ({ sortSelector: selectorData })),
    getFlightsAlternativeProposals: async (pricingId, newFilters = []) => {
      try {
        const filters = {};

        set(() => ({
          flightFilters: filters,
          isFetchFlightsAlternativeProporsals: true,
        }));

        const currentPageNumber = get().currentPageNumber;

        const alternativeTransportsRequest: AdvancedSearchInput = {
          facets: [],
          sort: {
            name: get().sortSelector.value,
            ascending: get().sortSelector.ascending,
          },
          page: {
            number: currentPageNumber,
            size: 20,
          },
          filtersMetadata: true,
        };

        const flightsAlternativeProposals =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceGetAlternativeTransports(
            {
              pricingId,
              requestBody: alternativeTransportsRequest,
            }
          );

        set(() => ({
          flightsAlternativeProposals,
          isFetchFlightsAlternativeProporsals: false,
        }));
      } catch (e) {
        set(() => ({
          isFetchFlightsAlternativeProporsals: false,
        }));

        console.log('Error - getFlightsAlternativeProposals', e);
      }
    },
  }));
};
