import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './AmenitiesFilters.styled';
import { Amenity } from './Amenity';
import { isEmpty } from '@/utils/isEmpty';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';

export function AmenitiesFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { amenities } = hdpStaticData;

  const { t } = useTranslate();

  const onAmenityHandle = (e) => {
    const propertyTypeUpdated = getCheckboxFilterUpdated(
      hdpFilters,
      'amenities',
      e.target
    );

    getHDPProposals(searchId, [{ amenities: propertyTypeUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Amenity
          data={amenities}
          onAmenityHandle={onAmenityHandle}
          disabled={isFecthRetrieveProporsals}
          amenityFilter={hdpFilters.amenities}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
