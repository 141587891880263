import { T } from '@tolgee/react';
import { Dayjs } from 'dayjs';
import * as AdressStyled from '../components/AddressFields.styled';
import BirthdayField from '../components/BirthdayField';
import CityField from '../components/CityField';
import DocumentNumberField from '../components/DocumentNumberField';
import DocumentTypeField from '../components/DocumentTypeField';
import EmailField from '../components/EmailField';
import EmissionCountryField from '../components/EmissionCountryField';
import EmissionDateField from '../components/EmissionDateField';
import ExpirationDateField from '../components/ExpirationDateField';
import FiscalCodeField from '../components/FiscalCodeField';
import GenderField from '../components/GenderField';
import NameField from '../components/NameField';
import NationalityField from '../components/NationalityField';
import * as PassportStyled from '../components/PassportFields.styled';
import PhoneField from '../components/PhoneField';
import PostalCodeField from '../components/PostalCodeField';
import StreetNameField from '../components/StreetNameField';
import StreetNumberField from '../components/StreetNumberField';
import SurnameField from '../components/SurnameField';
import { ContactDetailsValues } from '../hooks/useContactDetailsDefaultValues';
import { TravellerDetailsValues } from '../hooks/useTravellerDetailsDefaultValues';
import {
  dateRegex,
  emailRegex,
  phoneRegex,
  streetNumberRegex,
  textRegex,
  zipCodeRegex,
} from './regexDictionary';

export const getComponentByField = (props: {
  source: 'CONTACT' | 'TRAVELLER';
  fieldName: string;
  formData: {
    [K in keyof (ContactDetailsValues | TravellerDetailsValues)]:
      | string
      | number;
  };
  inputErrors: {
    [K in keyof (ContactDetailsValues | TravellerDetailsValues)]: boolean;
  };
  handleChange: (e: any) => void;
  handleValidate: (regex?: RegExp) => (e: any) => void;
  disabled?: boolean;
  passengerType?: 'INFANT' | 'CHILD' | 'ADULT';
  bookingDate?: Dayjs;
}): any => {
  const {
    source,
    fieldName,
    formData,
    inputErrors,
    handleChange,
    handleValidate,
    disabled,
    passengerType,
    bookingDate,
  } = props;
  switch (fieldName) {
    case 'NAME':
      return (
        <NameField
          value={formData?.name}
          handleChange={handleChange}
          handleBlur={handleValidate(textRegex)}
          hasError={inputErrors?.name}
          disabled={disabled}
        />
      );
    case 'SURNAME':
      return (
        <SurnameField
          value={formData?.surname}
          handleChange={handleChange}
          handleBlur={handleValidate(textRegex)}
          hasError={inputErrors?.surname}
          disabled={disabled}
        />
      );
    case 'EMAIL':
      return (
        <EmailField
          value={formData?.email}
          handleChange={handleChange}
          handleBlur={handleValidate(emailRegex)}
          hasError={inputErrors?.email}
          disabled={disabled}
        />
      );
    case 'ADDRESS':
      return (
        <div>
          <AdressStyled.Title>
            <T keyName={'form.contact.address'} />
          </AdressStyled.Title>
          <AdressStyled.Container>
            <AdressStyled.AddressContainer>
              <StreetNameField
                value={formData?.addressStreetName}
                handleChange={handleChange}
                handleBlur={handleValidate(textRegex)}
                hasError={inputErrors?.addressStreetName}
                disabled={disabled}
              />
              <StreetNumberField
                value={formData?.addressStreetNumber}
                handleChange={handleChange}
                handleBlur={handleValidate(streetNumberRegex)}
                hasError={inputErrors?.addressStreetNumber}
                disabled={disabled}
              />
            </AdressStyled.AddressContainer>
            <AdressStyled.CountryContainer>
              <PostalCodeField
                value={formData?.addressPostalCode}
                handleChange={handleChange}
                handleBlur={handleValidate(zipCodeRegex)}
                hasError={inputErrors?.addressPostalCode}
                disabled={disabled}
              />
              <CityField
                value={formData?.addressCity}
                handleChange={handleChange}
                handleBlur={handleValidate(textRegex)}
                hasError={inputErrors?.addressCity}
                disabled={disabled}
              />
            </AdressStyled.CountryContainer>
          </AdressStyled.Container>
        </div>
      );
    case 'STREET_NUMBER':
      return null;
    case 'POSTAL_CODE':
      return null;
    case 'CITY':
      return null;
    case 'PHONE':
      return (
        <PhoneField
          value={formData?.phoneNumber}
          countryValue={formData?.phoneIsoCode}
          handleChange={handleChange}
          handleChangeCountry={handleChange}
          handleBlur={handleValidate(phoneRegex)}
          hasError={inputErrors?.phoneNumber}
          disabled={disabled}
        />
      );
    case 'NOT_GUEST':
      // return (
      //   <NotGuestField
      //     value={formData?.isTraveller}
      //     handleChange={handleChange}
      //     handleBlur={handleValidate()}
      //     hasError={inputErrors?.isTraveller}
      //     disabled={disabled}
      //   />
      // );
      return null;
    case 'NATIONALITY':
      return (
        <NationalityField
          source={source}
          value={formData?.nationality}
          handleChange={handleChange}
          handleBlur={handleValidate()}
          hasError={inputErrors?.nationality}
          disabled={disabled}
        />
      );
    case 'BIRTH_DATE':
      return (
        <BirthdayField
          value={formData?.dateOfBirth}
          handleChange={handleChange}
          handleBlur={handleValidate(dateRegex)}
          hasError={inputErrors?.dateOfBirth}
          disabled={disabled}
          passengerType={passengerType}
          bookingDate={bookingDate}
        />
      );
    case 'GENDER':
      return (
        <GenderField
          value={formData?.gender}
          handleChange={handleChange}
          handleBlur={handleValidate(textRegex)}
          hasError={inputErrors?.gender}
          disabled={disabled}
        />
      );
    case 'FISCAL_CODE':
      return (
        <FiscalCodeField
          value={formData?.fiscalCode}
          handleChange={handleChange}
          // TODO TAKE FISCAL CODE REGEX FROM STRAPI
          handleBlur={handleValidate(textRegex)}
          hasError={inputErrors?.fiscalCode}
          disabled={disabled}
        />
      );
    case 'DOCUMENT_TYPE':
      return (
        <div>
          <AdressStyled.Title>
            <T keyName={'form.passenger.traveller_details.document.title'} />
          </AdressStyled.Title>
          <PassportStyled.Container>
            <PassportStyled.AddressContainer>
              <DocumentTypeField
                value={formData?.documentType}
                handleChange={handleChange}
                handleBlur={handleValidate(textRegex)}
                hasError={inputErrors?.documentType}
                disabled={disabled}
              />
              <DocumentNumberField
                value={formData?.documentNumber}
                handleChange={handleChange}
                handleBlur={handleValidate(textRegex)}
                hasError={inputErrors?.documentNumber}
                disabled={disabled}
              />
            </PassportStyled.AddressContainer>
            <EmissionCountryField
              value={formData?.documentEmissionCountry}
              handleChange={handleChange}
              handleBlur={handleValidate(textRegex)}
              hasError={inputErrors?.documentEmissionCountry}
              disabled={disabled}
            />
            <EmissionDateField
              value={formData?.documentEmissionDate}
              handleChange={handleChange}
              handleBlur={handleValidate(textRegex)}
              hasError={inputErrors?.documentEmissionDate}
              disabled={disabled}
            />
            <ExpirationDateField
              value={formData?.documentExpirationDate}
              handleChange={handleChange}
              handleBlur={handleValidate(textRegex)}
              hasError={inputErrors?.documentExpirationDate}
              disabled={disabled}
            />
          </PassportStyled.Container>
        </div>
      );
    case 'DOCUMENT_NUMBER':
      return null;
    case 'EMISSION_COUNTRY':
      return null;
    case 'EXPIRATION_DATE':
      return null;
    case 'EMISSION_DATE':
      return null;
    default:
      return null;
  }
};
