export type TripCardContractPrice = {
  amount: number;
  tooltip?: string;
};

export type JourneySharedContract = {
  outbound: JourneyContractWay;
  inbound?: JourneyContractWay;
  specialOffer?: JourneyContractSpecialOffer;
  locale: string;
};

export type JourneySummaryContract = JourneySharedContract & {
  /** used only in Full Summary, not in Slim Summary */
  openDetailsClicked?: () => void;
  featureToggles: JourneySummaryContractFeatureToggles;
  dateAsText?: JourneySummaryDateAsTextContract;
};

export type JourneyDetailContract = JourneySharedContract & {
  partnerHighlighted?: boolean;
  /** it does not display the message if the array is empty */
  seatSelectionMessage: string[];
  afterTransportComponent?: JSX.Element;
  slimIntro?: boolean;
  showNights?: boolean;
};

export type JourneySummaryWayDateAsTextContract = {
  icon?: JSX.Element;
  color?: string;
};

export type JourneySummaryDateAsTextContract = {
  /** Use "true/false" to show or hide the date text, default is hidden.
   * Use the full object if you want to show the date text and modify icon and/or color */
  outbound: JourneySummaryWayDateAsTextContract | boolean;
  inbound?: JourneySummaryWayDateAsTextContract | boolean;
};

export type JourneySummaryContractFeatureToggles = {
  /** To show the checkbox to select each way, eg when having a list of trips (CNCR, HDP change flight page) */
  showWayFilter: boolean;
};

export type JourneyContractLocations = {
  origin: string;
  destination: string;
};

export type JourneyContractWay = {
  segments: Array<JourneyContractSegment>;
  /** Optional info about price and taxes. [ OBE: false; CNCR: true except promo; ] */
  priceInformation?: JourneyContractWayPrice;
  seats?: number;
  transportCategory: JourneyContractWayTransportCategory;
  durationInMinutes?: number;
  selected: boolean;
  onSelected?: (checked: boolean) => void;
  baggage: JourneyContractBaggage;
  locations: JourneyContractLocations;
  /** Used only on details when search date different from trip date. Summaries components now rely on JourneySummaryContract.DateAsText */
  differentDepartureDate?: boolean;
};

export type JourneyContractBaggage = {
  hand?: boolean;
  hold?: boolean;
} | null;

export interface JourneyContractWayPrice {
  price: {
    perPassenger: TripCardContractPrice;
  };
  taxes?: {
    perPassenger: TripCardContractPrice;
  };
}

export type JourneyContractSegment = {
  /** A stop usually to refuel or change plane.
   * on the UI it adds a NEW segment with the tech stop in the middle
   */
  technicalStops?: Array<JourneyContractTechnicalStop>;
  transport: JourneyContractSegmentTransport;
  departure: JourneyContractSegmentLocation;
  arrival: JourneyContractSegmentLocation;
  duration?: number;
};

export interface JourneyContractTechnicalStop {
  arrivalTime: string;
  departureTime: string;
  hub: JourneyContractHub;
}

export type JourneyContractSegmentLocation = {
  hub: JourneyContractHub;
  datetime: string;
};

export type JourneyContractSegmentTransport = {
  class: string;
  /** ID of transport, eg: UX1234 */
  operativeId: string;
  type: JourneyContractSegmentTransportType;
  /** needed for: "Operated by Air France" */
  operateBy?: {
    /** if it matches companyCode, all the "operated by" node won't be displayed */
    code: string;
    name: string;
  };
  companyCode: string;
  companyName: string;
  PNR?: {
    title: string;
    pnr: string;
    tooltip: string;
    onCopyClick?: () => void;
  };
};

export type JourneyContractHub = {
  id: string;
  name: string;
  terminal?: string;
};

export enum JourneyContractSegmentTransportType {
  FLIGHT = 'FLIGHT',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
}

export type JourneyContractSpecialOffer = {
  type: SPECIAL_OFFER_TYPES;
  customText?: string;
};

export enum SPECIAL_OFFER_TYPES {
  CHARTER = 'CHARTER',
  PINKPEOPLE = 'PINKPEOPLE',
  CUSTOM = 'CUSTOM',
}

export enum JourneyContractWayTransportCategory {
  CHARTER = 'CHARTER',
  STANDARD = 'STANDARD',
}
