import styled from '@emotion/styled';

export const Fallback = styled.span<{ logoSize: number }>`
  font-size: ${(p) => `${p.logoSize}px`};
  text-align: left;

  &:only-of-type {
    font-size: ${(p) => `${p.logoSize}px`};
  }
`;

export const LogoInnerContainer = styled.span<{ logoSize: number }>`
  height: ${({ logoSize }) => logoSize}px;
  font-size: 0;

  img {
    width: ${({ logoSize }) => logoSize}px;
    height: ${({ logoSize }) => logoSize}px;
  }
`;
