import { BookingType } from '@/hooks/useBookingType';
import { Rate } from '@/services/codegen-romulo';
import CurrencyFormatter from '@/utils/currencyFormatter';

type GetFirstAccomodationProps = {
  rate: Rate;
  numberOfNights: number;
  numberPassengers: number;
  bookingType: BookingType;
  currencyFormatter: CurrencyFormatter;
};

const PRICE_PER_PERSON = 'general.price.per_person';

export function getPrice({
  currencyFormatter,
  rate,
  numberOfNights,
  numberPassengers,
  bookingType,
}: GetFirstAccomodationProps) {
  const customerPrice = rate.prices.std.customerPrice / 100;

  const totalAmountFormatted =
    currencyFormatter.formatNumberToFloor(customerPrice);

  const totalAmount = customerPrice;

  const rateFullPrice = rate.prices.std.fullPrice / 100;

  const excludedChargesAmount = rate.prices.std.excludedCharges?.amount;

  const isExcludedChargesInclude = rate.prices.std.excludedChargesIncluded;

  const extraFeeAmountFormatted = isExcludedChargesInclude
    ? null
    : currencyFormatter.formatNumber(excludedChargesAmount / 100);

  const isBookingFlightAndHotel = bookingType === BookingType.FlightAndHotel;

  const divisorAmount = isBookingFlightAndHotel
    ? numberPassengers
    : numberOfNights;

  const amountPerPerson = customerPrice / divisorAmount;

  const amountPerPersonFormatted =
    currencyFormatter.formatNumberToFloor(amountPerPerson);

  const fullPricePerPerson = currencyFormatter.formatNumberToFloor(
    rateFullPrice / divisorAmount
  );

  return {
    totalAmount,
    offerPrice:
      fullPricePerPerson !== amountPerPersonFormatted
        ? fullPricePerPerson
        : null,
    pricePerPerson: {
      article: PRICE_PER_PERSON,
    },
    priceFormatted: amountPerPersonFormatted,
    extraFeeAmountFormatted,
    totalAmountFormatted: totalAmountFormatted,
  };
}
