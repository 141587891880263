import CodeIcon from '@mui/icons-material/Code';
import { Dialog } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Fragment, useState } from 'react';
import { InvalidateStrapiCache } from './components/InvalidateStrapiCache/InvalidateStrapiCache';

export const DevTools = () => {
  const [open, setOpen] = useState(false);
  const domain = window.location.hostname;

  if (domain !== 'localhost') {
    return null;
  }

  return (
    <Fragment>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: '32px', right: '32px' }}
        onClick={() => setOpen(true)}
      >
        <CodeIcon />
      </Fab>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <div
          style={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <h2>DevTools</h2>
          <InvalidateStrapiCache />
        </div>
      </Dialog>
    </Fragment>
  );
};
