import { getFacilityIcon } from '@/utils/dictionaries/getFacilityIcon';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import * as Styled from './Facilities.styled';
const blocks = [
  'Internet',
  'Children',
  'Cleaning services',
  'Swimming pool',
  'Pets',
];

export const Facilities: FC = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslate();
  return (
    <Styled.ContentContainer>
      <Styled.SectionTitle>Facilities</Styled.SectionTitle>
      <Styled.Card>
        <div>
          <Styled.BlockTitle>Most popular</Styled.BlockTitle>
          <Styled.FacilitiesContainer>
            {blocks.map((e) => (
              <Styled.FacilityElement>
                {getFacilityIcon(e)} {e}
              </Styled.FacilityElement>
            ))}
          </Styled.FacilitiesContainer>
        </div>
        <Styled.Divider />
        <Styled.BlocksViewPart $expanded={!expanded}>
          <Styled.BlocksContainer>
            {blocks.map((e) => (
              <Styled.Block>
                <Styled.BlockTitle>
                  {getFacilityIcon(e)} {e}
                </Styled.BlockTitle>
                <Styled.FacilitiesList>
                  {blocks.map((e) => (
                    <Styled.FacilityListElement>
                      <CheckIcon /> {e}
                    </Styled.FacilityListElement>
                  ))}
                </Styled.FacilitiesList>
              </Styled.Block>
            ))}
          </Styled.BlocksContainer>
        </Styled.BlocksViewPart>

        <Styled.ExpandMoreButton onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show less' : 'Show more'}{' '}
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Styled.ExpandMoreButton>
      </Styled.Card>
    </Styled.ContentContainer>
  );
};
