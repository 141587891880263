import * as Styled from './ImagesCarouselMobile.styled';

export function ImagesCarouselMobile({ images }: { images: string[] }) {
  return (
    <Styled.Container>
      <Styled.InnerContainer>
        {images
          ?.slice(0, 5)
          .map((image, key) => (
            <Styled.ImagesMobile src={image} loading="lazy" key={key} />
          ))}
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
