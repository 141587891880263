import { useState } from 'react';

export function useHighlightHotel() {
  const [hoverHotel, setHoverHotel] = useState<string>();

  const setHotel = (hotelid: string) => {
    const elem = document.getElementById(`price_marker_${hotelid}`);

    if (elem) {
      elem.className = `${elem?.className} price-popup-focused`;
    }

    setHoverHotel(hotelid);
  };

  const clearHotel = () => {
    const elem = document.getElementById(`price_marker_${hoverHotel}`);

    if (elem) {
      elem.className = elem.className.replaceAll(`price-popup-focused`, '');
      setHoverHotel(undefined);
    }
  };

  return { setHotel, clearHotel };
}
