import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './DPFiltersHorizontalContainer.styled';
import { BudgetFilters } from '../BudgetFilters';
import { FreeCancellationFilter } from '../FreeCancellationFilter';
import { PropertyTypesFilters } from '../PropertyTypeFilters';
import { StarsFilters } from '../StarsFilters';
import { RatingFilters } from '../RatingFilters';
import { AmenitiesFilters } from '../AmenitiesFilters';
import { CharacteristicsFilters } from '../CharacteristicsFilters';
import { MealPlansFilters } from '../MealPlansFilters';
import { MapToggle } from '../../MapToggle';
import { useState } from 'react';
import { ClickAwayListener } from '@mui/material';

const allFilters = [
  {
    type: 'free-cancellation',
    title: 'filter.title.free_cancellation',
    content: <FreeCancellationFilter />,
  },
  {
    type: 'budget',
    title: 'filter.title.budget',
    content: <BudgetFilters />,
  },
  {
    type: 'property-type',
    title: 'filter.title.property_type',
    content: <PropertyTypesFilters />,
  },
  {
    type: 'stars',
    title: 'filter.title.stars',
    content: <StarsFilters />,
  },
  {
    type: 'meal-plan',
    title: 'filter.title.meal_plans',
    content: <MealPlansFilters />,
  },
  {
    type: 'rating',
    title: 'filter.title.rating',
    content: <RatingFilters />,
  },
  {
    type: 'aminities',
    title: 'filter.title.amenities',
    content: <AmenitiesFilters />,
  },
  {
    type: 'characteristics',
    title: 'filter.title.characteristics',
    content: <CharacteristicsFilters />,
  },
];

export function DPFiltersHorizontalContainer({ closeMap }) {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperId, setPopperId] = useState<number>(undefined);

  const handleClick = (event: React.MouseEvent<HTMLElement>, key: number) => {
    setAnchorEl(event.currentTarget === anchorEl ? null : event.currentTarget);
    setPopperId(key === popperId ? undefined : key);
  };

  return (
    <Styled.DPFilterContainer elevation={1} square>
      <Styled.MapHideButton>
        <MapToggle action="close" onToggle={closeMap} />
      </Styled.MapHideButton>
      <Styled.FiltersBox>
        {allFilters.map((filter, key) => {
          return (
            <Styled.FilterButton
              expanded={popperId === key}
              $theme={brandConfig.theme}
            >
              <Styled.FilterButtonText
                expandIcon={<Styled.ExpandIcon />}
                onClick={(e) => handleClick(e, key)}
                key={key}
              >
                {t(filter.title)}
              </Styled.FilterButtonText>
            </Styled.FilterButton>
          );
        })}
      </Styled.FiltersBox>
      {allFilters.map((filter, key) => {
        return (
          <Styled.Popper
            id="simple-popper"
            open={popperId === key}
            anchorEl={anchorEl}
            key={key}
          >
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null);
                setPopperId(undefined);
              }}
            >
              <Styled.Dropdown elevation={3}>{filter.content}</Styled.Dropdown>
            </ClickAwayListener>
          </Styled.Popper>
        );
      })}
    </Styled.DPFilterContainer>
  );
}
