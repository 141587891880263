import { useStore } from '@/store/useStore';
import { Pagination as PaginationMui, PaginationProps } from '@mui/material';

export function Pagination(props: PaginationProps) {
  const brandConfig = useStore((state) => state.brandConfig);
  const { primaryColor, primaryColorBg } = brandConfig.theme;
  
  return (
    <PaginationMui
      {...props}
      sx={{
        '& .Mui-selected': {
          backgroundColor: `${primaryColorBg} !important`,
          color: primaryColor,
        },
      }}
    />
  );
}
