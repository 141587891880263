import * as React from 'react';
import { Dayjs } from 'dayjs';
import {
  StaticData,
  Accommodation,
  City,
  Country,
} from '@/services/codegen-romulo';
import { getTimeInTheTimezone } from '@/utils/timeUtils';
import { CheckOutlined } from '@ant-design/icons';

const getLocation = (
  accommodation: Accommodation,
  cities: City[],
  countries: Country[]
) => {
  if (accommodation) {
    const cityId = accommodation.internalIdCity;
    const city = cities.find((it) => cityId === it.id);
    const country = countries.find((it) => city.countryId === it.id);
    const cityLabel = city.label;
    const countryLabel = country.label;

    return {
      direction: accommodation?.address
        ? `${accommodation?.address}, ${cityLabel}, ${countryLabel}`
        : `${cityLabel}, ${countryLabel}`,
      country: countryLabel,
    };
  }

  return null;
};

export const getHotelDetail = (
  staticData: StaticData,
  returnDate: Dayjs,
  outboundDate: Dayjs,
  t: (key: string, x?: any, replaces?: any) => string
) => {
  const { accommodation, cities, countries } = staticData;

  const lastminuteDeal = staticData.promotions.find(
    (e) => (e.type = 'FLASH_SALE')
  );

  const lastminuteDealInfo = !!lastminuteDeal && {
    disclaimer: `* ${t('promo.last_minute.disclaimer', '', {
      deadline: `<strong>${getTimeInTheTimezone(
        lastminuteDeal.bookDate.to
      ).format('DD MMMM YYYY')}</strong>`,
      start_date: `<strong>${getTimeInTheTimezone(
        lastminuteDeal.stayDate.from
      ).format('DD MMMM')}`,
      end_date: `${getTimeInTheTimezone(lastminuteDeal.stayDate.to).format(
        'DD MMMM YYYY'
      )}</strong>`,
    })}`,
    targetDate: lastminuteDeal.bookDate.to,
  };

  return {
    name: accommodation?.label,
    stars: accommodation?.stars,
    location: getLocation(accommodation, cities, countries),
    images: accommodation?.images,
    tags: [],
    facilities: accommodation?.amenities?.map((item) => ({
      id: item.id,
      label: item.label,
    })),
    checkIn: {
      date: outboundDate?.format('DD MMM'),
      hour: accommodation?.checkin?.from
        ? `, ${t('general.date.from').toLowerCase()} ${
            accommodation?.checkin?.from
          }`
        : undefined,
    },
    checkOut: {
      date: returnDate?.format('DD MMM'),
      hour: accommodation?.checkout?.to
        ? `, ${t('general.date.until').toLowerCase()} ${
            accommodation?.checkout?.to
          }`
        : undefined,
    },
    lastminuteDealInfo,
  };
};
