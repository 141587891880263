import { Counter } from '../Counter';
import { ImagesCarousel } from '../ImagesCarousel';
import { ImagesCarouselMobile } from '../ImagesCarouselMobile';
import { TopSecretWhiteMaskIcon } from '../LmnIcons/LmnIcons';
import * as Styled from './HotelCard.styled';
import { HotelCardProps } from './HotelCard.types';
import { HotelCardInfo } from './HotelCardInfo';
import { PriceInfo } from './PriceInfo';

export function HotelCard({
  isAvailable,
  hotelLocation,
  facilities,
  hotelName,
  stayConfig,
  stars,
  images,
  rating,
  connectionIncluded,
  price,
  isTopSecret,
  extraFeeInfo,
  lastminuteDeal,
  onMouseEnter,
  onMouseLeave,
  onCLickHotelCard,
}: HotelCardProps) {
  return (
    <Styled.Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onCLickHotelCard}
    >
      <Styled.ImagesCarouselContainer>
        <ImagesCarousel images={images} />
      </Styled.ImagesCarouselContainer>
      <Styled.ImagesCarouselMobileContainer>
        <ImagesCarouselMobile images={images} />
      </Styled.ImagesCarouselMobileContainer>
      {!!lastminuteDeal && (
        <Styled.CounterContainer>
          <Counter date={lastminuteDeal.bookDate.to} />
        </Styled.CounterContainer>
      )}
      {isTopSecret && (
        <Styled.IconContainer>
          <TopSecretWhiteMaskIcon size={111} />
        </Styled.IconContainer>
      )}
      <Styled.HotelLink>
        <HotelCardInfo
          summary={hotelLocation}
          hotelName={hotelName}
          stayConfig={stayConfig}
          rating={{
            stars: stars,
            value: rating?.value,
            opinionsCount: rating?.numberOfReviews,
          }}
          facilities={facilities}
          flightRate={connectionIncluded}
        />
        <PriceInfo
          isTopSecret={isTopSecret}
          offerPrice={price.offerPrice}
          originalPrice={price.priceFormatted}
          priceUnity={price.pricePerPerson.article}
          extraFeeInfo={extraFeeInfo}
          lastminuteDeal={lastminuteDeal}
        />
      </Styled.HotelLink>
    </Styled.Container>
  );
}
