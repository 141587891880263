import styled from '@emotion/styled';

const Pill = styled.div<{ $colour: string; $fontSize: number }>`
  background: ${({ $colour }) => $colour};
  border-radius: 4px;
  font-size: ${({ $fontSize }) => `${$fontSize}px` || '12px'};
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  height: ${({ $fontSize }) => `${$fontSize + 8}px` || '20px'};
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ $fontSize }) => `${$fontSize > 12 ? 8 : 4}px` || '4px'};
  letter-spacing: 0.01em;
`;

const ratings = {
  pleasant: {
    label: 'hotel.assessment.pleasant',
    colour: '#F8CE72',
    minRating: 60,
  },
  good: {
    label: 'hotel.assessment.good',
    colour: '#BFCC4E',
    minRating: 75,
  },
  very_good: {
    label: 'hotel.assessment.very_good',
    colour: '#A0CA3F',
    minRating: 81,
  },
  excellent: {
    label: 'hotel.assessment.excellent',
    colour: '#7CB342',
    minRating: 87,
  },
};

const getRatingByNumber = (number: number) => {
  if (number <= 74) return ratings.pleasant;

  if (number <= 80) return ratings.good;

  if (number <= 86) return ratings.very_good;

  return ratings.excellent;
};

const getRatingByCode = (code: string) => {
  const codeFormatted = code.toLowerCase();
  return ratings[codeFormatted];
};

export function Points({
  number,
  opinionsCount,
  size,
  ratingCode,
}: {
  number?: number;
  opinionsCount?: number;
  size?: number;
  ratingCode?: string;
}) {
  const rating = number
    ? getRatingByNumber(number)
    : getRatingByCode(ratingCode);

  return (
    <>
      <Pill $colour={rating.colour} $fontSize={size}>
        {number ? number : `${rating.minRating}+`}
      </Pill>
      <span>
        {rating.label} {opinionsCount && `(${opinionsCount})`}
      </span>
    </>
  );
}
