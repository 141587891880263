import styled from '@emotion/styled';
import { StepConnector, stepConnectorClasses } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const CheckoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
`;

export const StepperContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
    flex-direction: row;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
    flex-direction: row;
  }

  & .MuiStepLabel-label {
    margin-top: 8px;
  }

  & .MuiStepLabel-iconContainer {
    & .Mui-disabled {
      color: red;
      background-color: red;
    }
  }
`;

export const Connector = styled(StepConnector)<{ primarycolor: string }>`
  &.${stepConnectorClasses.active} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ primarycolor }) => primarycolor};
      border-width: 2px;
    }
  }
  &.${stepConnectorClasses.completed} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ primarycolor }) => primarycolor};
      border-width: 2px;
    }
  }
  &.${stepConnectorClasses.disabled} {
    & .${stepConnectorClasses.line} {
      border-color: #eaeaeb;
    }
  }
`;

export const ColorlibStepIconRoot = muiStyled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: '#eaeaeb',
  fontSize: '12px',
  zIndex: 1,
  color: '#babac0',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontWeight: 'bold',
        ['&:before']: {
          opacity: 0.3,
          content: '""',
          width: '30px',
          height: '30px',
          position: 'absolute',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
        },
        ['& span']: {
          zIndex: 1,
        },
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        ['& .MuiSvgIcon-root']: {
          width: '16px;',
          height: '16px;',
        },
      },
    },
  ],
}));

export const ColumnsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 16px;

  @media (min-width: 768px) {
    max-width: auto !important;
    flex-direction: row;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
    flex-direction: row;
  }
`;

export const MainContent = styled.div`
  order: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    max-width: 58.33333 !important;
    order: 0;
    padding-right: 16px;
  }

  @media (min-width: 996px) {
    max-width: 66.6667% !important;
  }
`;

export const SideContent = styled.div`
  order: 0;
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    max-width: 41.6667 !important;
    order: 1;
    padding-right: 8px;
  }

  @media (min-width: 996px) {
    max-width: 33.3333% !important;
  }
`;
