import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './RatingFilters.styled';
import { Rating } from './Rating';
import { isEmpty } from '@/utils/isEmpty';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';

export function RatingFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { rating } = hdpStaticData;

  const onRatingHandle = (e) => {
    const ratingUpdated = getCheckboxFilterUpdated(
      hdpFilters,
      'rating',
      e.target
    );

    getHDPProposals(searchId, [{ rating: ratingUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Rating
          data={rating}
          onRatingHandle={onRatingHandle}
          disabled={isFecthRetrieveProporsals}
          ratingFilter={hdpFilters.rating}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
