import { HubProps } from './Hub.types';
import * as Styled from './Hub.styled';

export function Hub({ hub, shouldHighlight, terminalText }: HubProps) {
  return (
    <>
      <Styled.Text>
        <Styled.HubIdContainer
          shouldHighlight={shouldHighlight}
        >{`(${hub.id})`}</Styled.HubIdContainer>{' '}
        {hub.name}
      </Styled.Text>
      {terminalText && (
        <Styled.TerminalText>{terminalText}</Styled.TerminalText>
      )}
    </>
  );
}
