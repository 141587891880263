import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';

const documentTypes = [
  {
    label: 'form.passenger.traveller_details.document.type.passport',
    value: 'PA',
  },
  {
    label: 'form.passenger.traveller_details.document.type.identity_card',
    value: 'ID',
  },
  {
    label: 'form.passenger.traveller_details.document.type.nie',
    value: 'NIE',
  },
  {
    label: 'form.passenger.traveller_details.document.type.dni',
    value: 'DNI',
  },
  {
    label: 'form.passenger.traveller_details.document.type.russian_passport',
    value: 'PR',
  },
];

interface DocumentTypeFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: (
    event: SelectChangeEvent<string | number>,
    child: React.ReactNode
  ) => void;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const DocumentTypeField: FC<DocumentTypeFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();

  return (
    <FormControl variant="filled">
      <InputLabel id="nationality-select-label">
        {t('form.passenger.traveller_details.document.type')}
      </InputLabel>
      <Select
        variant="filled"
        name={'documentType'}
        error={hasError}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        disabled={disabled}
      >
        {documentTypes.map((e) => (
          <MenuItem key={e.value} value={e.value}>
            {t(e.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DocumentTypeField;
