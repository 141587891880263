export const getCheckboxFilterUpdated = (
  allCurrentFilters: any,
  currentFilterKey: string,
  newValue: { value: any; checked: boolean }
) => {
  const currentFilter = allCurrentFilters[currentFilterKey] ?? [];

  const filterUpdated = newValue.checked
    ? [...currentFilter, newValue.value]
    : currentFilter.filter((item) => item !== newValue.value);

  return filterUpdated;
};
