import { useStore } from '@/store/useStore';

export const useIsRyanair = () => {
  const [cartDetail] = useStore((state) => [state.cartDetail, state.order]);

  const flightProduct = cartDetail?.products?.find((e) => e.type === 'FLIGHT');
  const flight = flightProduct?.detail?.itinerary;

  const isRyanair = !!flight?.inboundTransport
    ? flight?.outboundTransport[0].segments[0].company.id === 'FR' ||
      flight?.inboundTransport[0]?.segments[0].company.id === 'FR'
    : flight?.outboundTransport[0].segments[0].company.id === 'FR';

    return isRyanair
};
