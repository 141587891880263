import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import { AirlinesFilters } from '../AirlinesFilters';
import { AirportFilters } from '../AirportFilters';
import { BudgetFilters } from '../BudgetFilters';
import { DepartureTimeFilters } from '../DepartureTimeFilters';
import { StopFilters } from '../StopFilters';
import * as Styled from './FlightFiltersContainer.styled';
import { ResetFilters } from '../../ResetFilters';

export function FlightFiltersContainer() {
  const { t } = useTranslate();
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const [flightFilters] = useStore((state) => [
    state.flightFilters,
    state.isFetchCart,
    state.retrieveProporsals,
  ]);

  const { isFecthRetrieveProporsals } = useGetFiltersData();

  const disableResetButton =
    Object.keys(flightFilters).filter((item) => item !== 'undefined').length ===
      0 || isFecthRetrieveProporsals;

  const allFilters = [
    {
      type: 'budget',
      title: 'filter.title.budget',
      content: <BudgetFilters />,
    },
    { type: 'stops', title: 'flight.stops', content: <StopFilters /> },
    {
      type: 'departureTime',
      title: 'filter.time.departure',
      content: <DepartureTimeFilters />,
    },
    {
      type: 'airports',
      title: 'filter.title.airports',
      content: <AirportFilters />,
    },
    {
      type: 'airlines',
      title: 'filter.title.airlines',
      content: <AirlinesFilters />,
    },
  ];

  const freeflightFilters = allFilters.filter(
    (item) => item.type === 'airports' || item.type === 'departureTime'
  );

  return (
    <>
      {(isFreeFlight ? freeflightFilters : allFilters).map((filter, key) => {
        return (
          <Styled.AccordionContainer defaultExpanded elevation={0} key={key}>
            <Styled.AccordionSummary
              aria-controls={`panel${key}-${filter.type}`}
              id={`panel${key}-header`}
              expandIcon={<Styled.ExpandIcon />}
            >
              <Styled.SectionTitle>{t(filter.title)}</Styled.SectionTitle>
            </Styled.AccordionSummary>
            <Styled.AccordionDetails>{filter.content}</Styled.AccordionDetails>
          </Styled.AccordionContainer>
        );
      })}
      <ResetFilters disableResetButton={disableResetButton} />
    </>
  );
}
