import RefreshIcon from '@mui/icons-material/Refresh';
import * as Sentry from '@sentry/react';
import { FC, useEffect } from 'react';
import * as Styled from './ErrorScreen.styled';

interface ErrorScreenProps {
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
}

export const ErrorScreen: FC<ErrorScreenProps> = ({
  title,
  subtitle,
  ctaLabel,
}) => {
  useEffect(() => {
    Sentry.captureEvent({ message: 'viewed.app-error' });
  }, []);
  return (
    <Styled.Container>
      <Styled.Card>
        <Styled.ErrorImage />
        {title && <Styled.Title>{title}</Styled.Title>}
        {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
        <Styled.Button onClick={() => location.reload()}>
          <RefreshIcon /> {ctaLabel}
        </Styled.Button>
      </Styled.Card>
    </Styled.Container>
  );
};
