import { useGetParam } from '@/hooks/useGetParam';
import { useTranslate } from '@tolgee/react';
import * as Styled from './NotFound.styled';

const overFilter =
  'https://storage.googleapis.com/strapi-rw-data/over_filtered_a1c802cb49/over_filtered_a1c802cb49.svg';

export function NofFound() {
  const getParam = useGetParam();
  const product = getParam('product');
  const { t } = useTranslate();

  return (
    <Styled.Container>
      <Styled.IconContainer $url={overFilter} />
      <Styled.TitleContainer>
        <Styled.Title>
          {t(`filter.no_results.${product.toLowerCase()}`)}
        </Styled.Title>
        {t('filter.try_other_dates')}
      </Styled.TitleContainer>
      <Styled.Button
        variant="contained"
        onClick={() => window.location.reload()}
      >
        {t('filter.clear_all')}
      </Styled.Button>
    </Styled.Container>
  );
}
