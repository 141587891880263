import styled from '@emotion/styled';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';

export const Container = styled.div`
  gap: 8px;
  display: flex;
`;
export const TextContainer = styled.div`
  margin-left: 8px;
  gap: 8px;
  display: flex;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
  margin-right: 0px;
  align-items: flex-start;

  & .MuiCheckbox-root {
    margin-top: 4px;
    padding: 0px;
    min-width: 24px;
  }

  & .MuiTypography-root {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #3c3c3c;
  }
`;
