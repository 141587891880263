import { Skeleton as CardSkeleton } from '../HotelCard/Skeleton';
import * as Styled from './HotelList.styled';

export function Skeleton() {
  return (
    <Styled.Container>
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </Styled.Container>
  );
}
