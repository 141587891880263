import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { MapHotelCard } from '@/components/MapHotelCard';

export function createHotelMarker({
  hotelInfo,
  brandColor,
  map,
  translate,
  bookingType,
}): mapboxgl.Marker {
  const marker = document.createElement('div');

  marker.id = `price_marker_${hotelInfo.proposalId}`;

  marker.className = 'mapbox-marker mapboxgl-marker-anchor-bottom';

  const markerRoot = createRoot(marker);

  markerRoot.render(
    <span className="price-popup">{hotelInfo.price.priceFormatted}</span>
  );

  const hotelCard = document.createElement('div');

  const hotelCardRoot = createRoot(hotelCard);

  hotelCardRoot.render(
    <MapHotelCard
      images={hotelInfo.images}
      hotelName={hotelInfo.hotelName}
      rating={{
        stars: hotelInfo.stars,
        value: hotelInfo.rating?.value,
        opinionsCount: hotelInfo.rating?.numberOfReviews,
      }}
      translate={translate}
      bookingType={bookingType}
      price={hotelInfo.price}
      brandColor={brandColor}
    />
  );

  const result = new mapboxgl.Marker({
    element: marker,
  });

  result
    .setLngLat(hotelInfo.location as [number, number])
    .setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        className: 'hotel-detail-popup',
        offset: -30,
        maxWidth: '280px',
      }).setDOMContent(hotelCard)
    )
    .addTo(map);

  return result;
}
