import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';
import * as Styled from './EmailField.styled';

interface EmailFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const EmailField: FC<EmailFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  return (
    <Styled.Container>
      <Styled.Title>{t('form.contact.email_input_description')}</Styled.Title>
      <TextField
        variant="filled"
        label={t('form.contact.email')}
        name={'email'}
        error={hasError}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        helperText={hasError && t(isFreeFlight ? 'form.validation.email_free_flight' : 'form.validation.email')}
        disabled={disabled}
      />
    </Styled.Container>
  );
};

export default EmailField;
