import { BookingType } from '@/hooks/useBookingType';
import { ClickAwayListener, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import * as Styled from './PassengerSelector.styled';
import { PassengersSelectorProps } from './PassengerSelector.types';
import { Room } from './components/Room';

export const PassengersSelector: React.FC<PassengersSelectorProps> = ({
  rooms,
  searchType,
  setRooms,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslate();
  const peopleSum = rooms.reduce((acc, curr) => {
    acc = acc + curr.adults;
    acc = acc + curr.childrenAges.length;
    return acc;
  }, 0);

  const getResultString = (searchType: BookingType) => {
    switch (searchType) {
      case BookingType.Flight:
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.passengers')
          : t('general.passenger')
        ).toLowerCase()}`;
      case BookingType.FlightAndHotel:
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.travellers')
          : t('general.traveller')
        ).toLowerCase()}, ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()}`;
      case BookingType.Hotel:
        return `${peopleSum} ${(peopleSum > 1
          ? t('general.guests')
          : t('general.guest')
        ).toLowerCase()}, ${rooms.length} ${(rooms.length > 1
          ? t('search.rooms')
          : t('search.room')
        ).toLowerCase()}`;
      default:
        return '';
    }
  };

  const getLabel = (searchType: BookingType) => {
    switch (searchType) {
      case BookingType.Flight:
        return t('search.passengers');
      case BookingType.FlightAndHotel:
        return t('search.travellers');
      case BookingType.Hotel:
        return t('search.guests');
      default:
        return '';
    }
  };

  const handleAdult = (number: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber ? { ...room, adults: number } : room
    );

    setRooms(result);
  };

  const handleChildren = (age: number, roomNumber: number) => {
    const result = rooms.map((room, index) =>
      index === roomNumber
        ? { ...room, childrenAges: room.childrenAges.concat(age) }
        : room
    );

    setRooms(result);
  };

  const removeChild = (roomNumber: number, childIndex: number) => {
    const result = rooms.map((room, roomIndex) => {
      if (roomIndex === roomNumber) {
        const resultArray = room.childrenAges.filter(
          (_, index) => index !== childIndex
        );
        return { ...room, childrenAges: resultArray };
      }
      return room;
    });
    setRooms(result);
  };

  const addRoom = () => {
    setRooms(rooms.concat([{ adults: 1, childrenAges: [] }]));
  };

  const removeRoom = (roomNumber: number) => {
    const result = rooms.filter((_, index) => index !== roomNumber);
    setRooms(result);
  };

  const canAddPassengers =
    rooms.reduce((acc, curr) => {
      const adults = acc + curr.adults;
      const children = curr.childrenAges.length;
      acc = adults + children;
      return acc;
    }, 0) >= 6
      ? true
      : false;

  return (
    <Styled.PassengerSelector>
      <TextField
        value={getResultString(searchType)}
        InputLabelProps={{ shrink: true }}
        sx={{
          width: '100%',
          '& label': {
            fontSize: '18px',
          },
          '& input': {
            textOverflow: 'ellipsis',
          },
        }}
        onClick={() => setOpen(!open)}
        label={getLabel(searchType)}
      />
      {open && (
        <ClickAwayListener
          children={
            <Styled.PassengersSelectorContent>
              {rooms.map((_, roomNumber, arr) => (
                <Room
                  key={roomNumber}
                  searchType={searchType}
                  roomNumber={roomNumber}
                  adults={rooms[roomNumber].adults}
                  minors={rooms[roomNumber].childrenAges}
                  updateAdults={(number) => handleAdult(number, roomNumber)}
                  updateChild={(age) => handleChildren(age, roomNumber)}
                  removeChild={(childIndex: number) =>
                    removeChild(roomNumber, childIndex)
                  }
                  addRoom={() => addRoom()}
                  removeRoom={() => removeRoom(roomNumber)}
                  canAddPassengers={canAddPassengers}
                  roomsAmount={arr.length}
                />
              ))}
            </Styled.PassengersSelectorContent>
          }
          onClickAway={() => setOpen(false)}
        />
      )}
    </Styled.PassengerSelector>
  );
};
