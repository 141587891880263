import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { Button, Divider } from 'antd';
import * as React from 'react';
import { AirportFilters } from '../Filters/Flights/AirportFilters';
import { DepartureTimeFilters } from '../Filters/Flights/DepartureTimeFilters';
import { AllFiltersIcon, SortIcon } from '../LmnIcons/LmnIcons';
import { Modal } from '../Modal';
import * as Styled from './FlightsResponsiveFilters.styled';
import { filtersTabs } from './utils';

export function FlightsResponsiveFilters() {
  const [isOpenSortingModal, setIsOpenSortingModal] = React.useState(false);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const [isOpenFilterOptionsModal, setIsOpenFilterOptionsModal] =
    React.useState(false);

  const [sortSelector, isFecthRetrieveProporsals, onChangeSortSelector] =
    useStore((state) => [
      state.sortSelector,
      state.isFecthRetrieveProporsals,
      state.onChangeSortSelector,
    ]);

  const { t } = useTranslate();
  const track = useTrack();

  return (
    <>
      <Styled.Contanier>
        {!isFreeFlight && (
          <React.Fragment>
            <Styled.TabContainer onClick={() => setIsOpenSortingModal(true)}>
              <SortIcon size={24} />
              <Styled.Text>{t('filter.sort')}</Styled.Text>
            </Styled.TabContainer>
            <Divider type="vertical" />
          </React.Fragment>
        )}

        <Styled.TabContainer onClick={() => setIsOpenFilterOptionsModal(true)}>
          <AllFiltersIcon size={24} />
          <Styled.Text>{t('filter.all')}</Styled.Text>
        </Styled.TabContainer>
      </Styled.Contanier>
      <Modal
        title={t('filter.sort.by')}
        isModalOpen={isOpenSortingModal}
        onCloseModal={() => setIsOpenSortingModal(false)}
      >
        <Styled.ModalInnerContainer>
          {filtersTabs.map((filter, key) => {
            return (
              <Button
                size="large"
                type={sortSelector.value === filter.key ? 'primary' : 'text'}
                onClick={() => {
                  track({
                    eventName: 'clicked.sort',
                    logLevel: 'info',
                    eventProperties: {
                      value: filter.key,
                      ascending: true,
                    },
                  });
                  onChangeSortSelector({ value: filter.key, ascending: true });
                }}
                disabled={isFecthRetrieveProporsals}
                key={key}
              >
                {t(filter.label)}
              </Button>
            );
          })}
        </Styled.ModalInnerContainer>
      </Modal>
      <Modal
        title={t('filter.name.plural')}
        isModalOpen={isOpenFilterOptionsModal}
        onCloseModal={() => setIsOpenFilterOptionsModal(false)}
      >
        <Styled.ModalInnerContainer>
          <AirportFilters />
          <DepartureTimeFilters />
        </Styled.ModalInnerContainer>
      </Modal>
    </>
  );
}
