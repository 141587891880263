import { useTranslate } from '@tolgee/react';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import * as Styled from './FlightCard.styled';

export function AgencyFee({ fee }: { fee: string }) {
  const { t } = useTranslate();

  const AgencyFeeText = t('flight.price_detail.agency_fee', {
    agencyFee: fee,
  });

  const sanitizedData = DOMPurify.sanitize(AgencyFeeText);

  return (
    <Styled.AgencyFeeContainer>
      {ReactHtmlParser(sanitizedData)}
    </Styled.AgencyFeeContainer>
  );
}
