import { JourneyContractBaggage } from '../types/tripDetailsTypes/JourneyContract';
import { TripBaggageAdapted } from '../types/tripDetailsTypes/TripBaggageAdapted';

export const tripBaggageAdapter = {
  adapt: (baggage: JourneyContractBaggage): TripBaggageAdapted => {
    if (!baggage) return {};

    const holdIncluded = baggage.hold;
    const handIncluded = baggage.hand;

    return { holdIncluded, handIncluded };
  },
};
