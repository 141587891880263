import Checkbox from '@mui/material/Checkbox';
import * as Styled from './PropertyTypeFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { PropertyTypeProps } from './PropertyTypeFilters.types';
import { useTranslate } from '@tolgee/react';

export function PropertyType({
  data,
  onPropertyTypeHandle,
  disabled,
  propertyTypeFilter,
}: PropertyTypeProps) {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onPropertyTypeHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((property, index) => {
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${property.code}_${index}_airline`}
              control={
                <Checkbox
                  disabled={!property.active || disabled}
                  value={property.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={propertyTypeFilter?.includes(property.code) ?? false}
                />
              }
              labelPlacement="start"
              label={
                <Styled.LabelOption>
                  {t(
                    `hotel.property_type.${property.code.toLowerCase().replaceAll(' ', '_')}`
                  )}
                </Styled.LabelOption>
              }
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
