import {
  BaggageCheckedIcon,
  PassportIcon,
  PriorityIcon,
  ShieldIcon,
} from '@/components/LmnIcons/LmnIcons';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Extras.styled';

export const Extras: FC = () => {
  const { t } = useTranslate();
  const cartDetail = useStore((state) => state.cartDetail);

  if (!cartDetail) return null;

  const luggage =
    cartDetail.products.find((e) => e.type === 'LUGGAGE')?.cost.amount.value > 0
      ? true
      : false;

  const insurance = cartDetail.products.find((e) => e.type === 'INSURANCE')
    ?.detail.ancillary.label;

  const specialAssistance = cartDetail.specialRequests;

  const priority =
    cartDetail.products.find((e) => e.type === 'PRIORITY')?.detail.lmnId ===
    'PB_1';

  const wcinp = cartDetail.products.find((e) => e.type === 'WEBCHECKIN');

  if (!luggage && !insurance && !specialAssistance && !priority && !wcinp)
    return null;

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title>{t('checkout.summary.extras')}</Styled.Title>
      </Styled.TitleContainer>
      <Styled.ContentContainer>
        {insurance && (
          <Styled.Span>
            <ShieldIcon /> {insurance}
          </Styled.Span>
        )}
        {luggage && (
          <Styled.Span>
            <BaggageCheckedIcon /> {luggage ? t('flight.luggage') : undefined}
          </Styled.Span>
        )}
        {wcinp && (
          <Styled.Span>
            <PassportIcon /> {t('form.product.webcheckin.title')}
          </Styled.Span>
        )}
        {specialAssistance && (
          <Styled.Span>
            <PassportIcon />
            {specialAssistance && t('form.special_assistance.required')}
          </Styled.Span>
        )}
        {priority && (
          <Styled.Span>
            <PriorityIcon /> {t('form.product.priority')}
          </Styled.Span>
        )}
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
