import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 28px;
  margin-top: 0px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 996px) {
    max-width: 400px;
  }
`;
