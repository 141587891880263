import styled from '@emotion/styled';
import { Select as MuiSelect } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.span`
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
`;