import { Modal } from '@/components/Modal/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { AccordionSummary } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { Fragment, useEffect, useState } from 'react';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import * as Styled from './CheckedLuggage.styled';
import { ModalContent } from './components/ModalContent';
import { useGetLuggagesInfo } from './hooks/useGetLuggagesInfo';
import { LuggageOption, useLuggageStore } from './hooks/useLuggageStore';
import { getLuggageLabel } from './utils/getLuggageLabel';
import { getOptionPrice } from './utils/getOptionPrice';

const lmn_luggage_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1/DAM/SVG/Custom%20Icons/Luggage/Pink_Big%20Checked%20luggage.svg';

export const CheckedLuggage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const addProduct = useAddProduct();
  const [luggageSelected, setLuggageSelected] = useLuggageStore((state) => [
    state.luggageSelected,
    state.setLuggageSelected,
  ]);
  const brandConfig = useStore((state) => state.brandConfig);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const primaryColorBg = brandConfig.theme.primaryColorBg;
  const { t } = useTranslate();
  const currencyFormatter = useCurrencyFormatter();
  const luggageInfo = useGetLuggagesInfo();

  useEffect(() => {}, [luggageSelected]);

  if (!luggageInfo) {
    return <Skeleton />;
  }

  const { luggagesByTraveller, waysNumber, outboundDetail, inboundDetail } =
    luggageInfo;

  const handleSelectLuggageOption = async (
    luggageOption: LuggageOption,
    travellerId: number,
    direction: number
  ) => {
    setIsLoading(true);
    try {
      const luggageResult = Object.entries({
        ...luggageSelected,
        [travellerId]: {
          ...luggageSelected[travellerId],
          [direction]: luggageOption,
        },
      })
        .map(([travellerId, detail]) => {
          return Object.entries(detail).map(([_, luggageDetail]) => ({
            type: 'LUGGAGE',
            detail: {
              lmnId: luggageDetail.id,
              travellerId: Number(travellerId),
            },
            cost: {
              amount: luggageDetail.price.total,
            },
          }));
        })
        .flat();

      await addProduct('LUGGAGE', luggageResult)
        .then((e) => {
          setLuggageSelected(travellerId, { direction, luggageOption });
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <SectionCard>
        <Styled.Container>
          <div>
            <Styled.Title>{t('flight.luggage.checked_luggage')}</Styled.Title>
            <Styled.Subtitle></Styled.Subtitle>
          </div>

          <Styled.Alert
            onClick={() => setIsModalOpen(true)}
            severity="info"
            icon={<InfoIcon sx={{ fontSize: '24px' }} />}
          >
            {/* TODO UNIFY COPY */}
            {t('form.product.luggage.disclaimer_pt_1')}{' '}
            <Styled.Strong>
              {t('form.product.luggage.disclaimer_pt_2')}
            </Styled.Strong>{' '}
            {t('form.product.luggage.disclaimer_pt_3')}
          </Styled.Alert>
          {!isFreeFlight && (
            <Styled.Container>
              <div>
                {luggagesByTraveller.map((traveller, travellerIndex) => (
                  <Styled.Accordion
                    defaultExpanded={travellerIndex === 0}
                    key={`${travellerIndex}_luggage_passenger`}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Styled.PassengerContainer>
                        <Styled.PassengerName>
                          {traveller.travellerFullName}
                        </Styled.PassengerName>
                        <Styled.SelectedLuggageDetails>
                          {Object.entries(
                            luggageSelected?.[traveller.travellerId] || {}
                          )?.map(([direction, detail]) => (
                            <p>
                              {`${waysNumber !== 2 ? (direction === '0' ? `${t('general.trip.outbound')} - ` : `${t('general.trip.inbound')} -`) : ``} ${getLuggageLabel(detail, t)}`}
                            </p>
                          ))}
                        </Styled.SelectedLuggageDetails>
                      </Styled.PassengerContainer>
                    </AccordionSummary>
                    <Styled.AccordionDetails>
                      {traveller.luggages.map((luggage, luggageIndex) => (
                        <div key={`${luggageIndex}_traveller_luggage`}>
                          {traveller.luggages.length > 1 ? (
                            <>
                              <Styled.BoundContainer>
                                <Styled.BoundDirection>
                                  {luggageIndex === 0
                                    ? t('general.trip.outbound.flight')
                                    : t('general.trip.inbound.flight')}
                                </Styled.BoundDirection>
                                <Styled.DirectionDetails>
                                  {luggageIndex === 0
                                    ? outboundDetail
                                    : inboundDetail}
                                </Styled.DirectionDetails>
                              </Styled.BoundContainer>
                            </>
                          ) : (
                            <></>
                          )}
                          <Styled.OptionsContainer>
                            {luggage.luggageOptions
                              ?.filter(
                                (item) => item.additionalInfo.numberOfPieces < 2
                              )
                              .map((luggageOption, indexLuggage) => {
                                const isSelected =
                                  luggageSelected?.[traveller.travellerId]?.[
                                    luggageIndex
                                  ]?.id === luggageOption.id;

                                return (
                                  <Styled.OptionContainer
                                    key={`${indexLuggage}_luggage_option`}
                                    $selected={isSelected}
                                    primarycolorbg={primaryColorBg}
                                    onClick={() =>
                                      handleSelectLuggageOption(
                                        luggageOption,
                                        traveller.travellerId,
                                        luggageIndex
                                      )
                                    }
                                  >
                                    <Styled.Image src={lmn_luggage_url} />
                                    <Styled.RadioContainer>
                                      <Styled.LuggageNameContainer>
                                        <Styled.LuggageName>
                                          {getLuggageLabel(luggageOption, t)}
                                        </Styled.LuggageName>
                                        <Styled.LuggageDetails>
                                          {getOptionPrice(
                                            luggageOption,
                                            waysNumber,
                                            t,
                                            currencyFormatter
                                          )}
                                        </Styled.LuggageDetails>
                                      </Styled.LuggageNameContainer>
                                      <Styled.Radio
                                        checked={isSelected}
                                        value={luggageOption}
                                        disabled={isLoading}
                                        disableRipple
                                        disableFocusRipple
                                        disableTouchRipple
                                      />
                                    </Styled.RadioContainer>
                                  </Styled.OptionContainer>
                                );
                              })}
                          </Styled.OptionsContainer>
                          {luggage.luggageCommunications.find(
                            (e) => e.type === 'LUGGAGE_INCLUDED'
                          ) && (
                            <Styled.OptionContainer
                              key={`included_luggage_option`}
                              $selected={true}
                              primarycolorbg={primaryColorBg}
                            >
                              <Styled.Image src={lmn_luggage_url} />
                              <Styled.IncludedContainer>
                                <Styled.LuggageNameContainer>
                                  <Styled.LuggageName>
                                    {t('flight.luggage.included')}
                                  </Styled.LuggageName>
                                  <Styled.LuggageDetails>
                                    {luggage.luggageCommunications.map((e) => (
                                      <div>{e.label}</div>
                                    ))}
                                  </Styled.LuggageDetails>
                                </Styled.LuggageNameContainer>
                              </Styled.IncludedContainer>
                            </Styled.OptionContainer>
                          )}
                        </div>
                      ))}
                    </Styled.AccordionDetails>
                  </Styled.Accordion>
                ))}
              </div>
            </Styled.Container>
          )}
        </Styled.Container>
      </SectionCard>
      <Modal
        title={t('flight.luggage')}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
      >
        <ModalContent luggagesByTraveller={luggagesByTraveller} />
      </Modal>
    </Fragment>
  );
};
