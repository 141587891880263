import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InputAdornment } from '@mui/material';

export const getInputAdornment = ({
  freeFlightVoucherIsValid,
  freeFlightVoucherError,
}: {
  freeFlightVoucherIsValid: boolean;
  freeFlightVoucherError: boolean;
}) => {
  if (freeFlightVoucherError) {
    return (
      <InputAdornment position="end">
        <CancelIcon style={{ color: '#f2142a' }} />
      </InputAdornment>
    );
  }
  if (freeFlightVoucherIsValid) {
    return (
      <InputAdornment position="end">
        <CheckCircleIcon style={{ color: '#65b700' }} />
      </InputAdornment>
    );
  }

  return null;
};
