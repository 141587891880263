import {
  AdultIcon,
  CalendarIcon,
  ChildIcon,
  InfantIcon,
  PlaneNEIcon,
} from '@/components/LmnIcons/LmnIcons';
import { FC } from 'react';
import { Pill } from './Products/Products.styled';

const getIcon = (type: 'adult' | 'children' | 'infant' | 'calendar') => {
  switch (type) {
    case 'adult':
      return <AdultIcon />;
    case 'children':
      return <ChildIcon />;
    case 'infant':
      return <InfantIcon />;
    case 'calendar':
      return <CalendarIcon size={16} />;
    default:
      return <PlaneNEIcon />;
  }
};

export const Counter: FC<{
  type: 'adult' | 'children' | 'infant' | 'calendar';
  value: string | number;
}> = ({ type, value }) => (
  <Pill>
    <p style={{ margin: 0, fontSize: 14 }}>
      {getIcon(type)} {value}
    </p>
  </Pill>
);
