import { useTrack } from '@/hooks/useTrack';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import { isEmpty } from '@/utils/isEmpty';
import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './AirportFilters.styled';
import { Airports } from './Airports';

export function AirportFilters() {
  const {
    flightsStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getFlightsProposals,
    retrieveProporsals,
    flightFilters,
  } = useGetFiltersData();

  const {
    airportStaticData,
    outboundDepartures,
    outboundArrival,
    inboundDepartures,
    inboundArrival,
    arrivalCityName,
    departureCityName,
  } = flightsStaticData;

  const { t } = useTranslate();
  const track = useTrack();

  const onOutboundDeparturesHandle = (e) => {
    const outboundDepartureLocationUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'outboundDepartureLocation',
      e.target
    );

    track({
      eventName: 'clicked.filter.outbound-departure',
      logLevel: 'info',
      eventProperties: {
        airport: outboundDepartureLocationUpdated,
      },
    });

    getFlightsProposals(searchId, [
      { outboundDepartureLocation: outboundDepartureLocationUpdated },
    ]);
  };

  const onOutboundArrivalHandle = (e) => {
    const outboundArrivalLocationUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'outboundArrivalLocation',
      e.target
    );

    track({
      eventName: 'clicked.filter.outbound-arrival',
      logLevel: 'info',
      eventProperties: {
        airport: outboundArrivalLocationUpdated,
      },
    });

    getFlightsProposals(searchId, [
      { outboundArrivalLocation: outboundArrivalLocationUpdated },
    ]);
  };

  const onInboundDeparturesHandle = (e) => {
    const inboundDepartureLocationUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'inboundDepartureLocation',
      e.target
    );

    track({
      eventName: 'clicked.filter.inbound-departure',
      logLevel: 'info',
      eventProperties: {
        airport: inboundDepartureLocationUpdated,
      },
    });

    getFlightsProposals(searchId, [
      { inboundDepartureLocation: inboundDepartureLocationUpdated },
    ]);
  };

  const onInboundArrivalHandle = (e) => {
    const inboundArrivalLocationUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'inboundArrivalLocation',
      e.target
    );

    track({
      eventName: 'clicked.filter.inbound-arrival',
      logLevel: 'info',
      eventProperties: {
        airport: inboundArrivalLocationUpdated,
      },
    });

    getFlightsProposals(searchId, [
      { inboundArrivalLocation: inboundArrivalLocationUpdated },
    ]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <>
          <Styled.Title>{t('general.trip.outbound')}</Styled.Title>
          <Styled.Subtitle>
            {departureCityName &&
              arrivalCityName &&
              `${departureCityName} - ${arrivalCityName}`}
          </Styled.Subtitle>
        </>
        <Styled.Title>{t('filter.airport.departure_airport')}</Styled.Title>
        <Airports
          data={outboundDepartures}
          onAirportHandle={onOutboundDeparturesHandle}
          airportStaticData={airportStaticData}
          disabled={isFecthRetrieveProporsals}
          airportFilter={flightFilters.outboundDepartureLocation}
        />
        <Styled.Divider />
        <Styled.Title>{t('filter.airport.arrival_airport')}</Styled.Title>
        <Airports
          data={outboundArrival}
          onAirportHandle={onOutboundArrivalHandle}
          airportStaticData={airportStaticData}
          disabled={isFecthRetrieveProporsals}
          airportFilter={flightFilters.outboundArrivalLocation}
        />
      </Styled.FiltersContainer>
      <Styled.FiltersContainer>
        <>
          <Styled.Title>{t('general.trip.inbound')}</Styled.Title>
          <Styled.Subtitle>
            {departureCityName &&
              arrivalCityName &&
              `${arrivalCityName} - ${departureCityName}`}
          </Styled.Subtitle>
        </>
        <Styled.Title>{t('filter.airport.departure_airport')}</Styled.Title>
        <Airports
          data={inboundDepartures}
          onAirportHandle={onInboundDeparturesHandle}
          airportStaticData={airportStaticData}
          disabled={isFecthRetrieveProporsals}
          airportFilter={flightFilters.inboundDepartureLocation}
        />
        <Styled.Divider />
        <Styled.Title>{t('filter.airport.arrival_airport')}</Styled.Title>
        <Airports
          data={inboundArrival}
          onAirportHandle={onInboundArrivalHandle}
          airportStaticData={airportStaticData}
          disabled={isFecthRetrieveProporsals}
          airportFilter={flightFilters.inboundArrivalLocation}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
