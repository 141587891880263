import styled from '@emotion/styled';

export const Container = styled.div`
  border-radius: 8px;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #fff;
  position: relative;
  z-index: 2;
  display: flex;
  padding: 16px;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
