import { useStore } from '@/store/useStore';
import { Button as ButtonMui, ButtonProps } from '@mui/material';

export function Button(props: ButtonProps) {
  const brandConfig = useStore((state) => state.brandConfig);

  const { accentColor } = brandConfig.theme;

  return (
    <ButtonMui
      {...props}
      variant="contained"
      onClick={props.onClick}
      disableElevation
      sx={{
        backgroundColor: accentColor,
        borderColor: accentColor,
      }}
    >
      {props.children}
    </ButtonMui>
  );
}
