import styled from '@emotion/styled';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
export const Container = styled.div`
  gap: 8px;
  display: flex;
`;
export const TextContainer = styled.div`
  margin-left: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
  margin-right: 0px;

  & .MuiCheckbox-root {
    margin-top: 4px;
    padding: 0px;
    min-width: 24px;
  }

  & .MuiTypography-root {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #3c3c3c;
  }
`;

export const Accordion = styled(MuiAccordion)`
  & .MuiAccordionDetails-root {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 8px 16px ;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
  padding-left: 0px;
  flex-direction: row-reverse;
  gap: 4px;
  min-height: unset !important;
  & .MuiAccordionSummary-content {
    margin: 8px 0 !important;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    margin-left: -2px;
  }
`;

export const DetailsContent = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;

  & ul {
    list-style: none;
    padding-left: 0px;

    & li {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const Content = styled.span`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;

  & ul {
    padding-left: 16px;
  }
`;
