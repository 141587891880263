import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './CharacteristicsFilters.styled';
import { isEmpty } from '@/utils/isEmpty';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import { Characteristic } from './Characteristic';

export function CharacteristicsFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { characteristic } = hdpStaticData;

  const { t } = useTranslate();

  const onCharacteristicHandle = (e) => {
    const characteristicsUpdated = getCheckboxFilterUpdated(
      hdpFilters,
      'characteristics',
      e.target
    );

    getHDPProposals(searchId, [{ characteristics: characteristicsUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Characteristic
          data={characteristic}
          onCharacteristicHandle={onCharacteristicHandle}
          disabled={isFecthRetrieveProporsals}
          characteristicFilter={hdpFilters.characteristics}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
