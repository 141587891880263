import { Modal } from '@/components/Modal';
import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { ClickAwayListener, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as Locale from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import 'react-day-picker/style.css';
import * as Styled from './DateSelector.styled';

interface DateSelectorProps {
  type: 'RETURN' | 'DEPARTURE';
  dateRange: {
    from?: Dayjs;
    to?: Dayjs;
  };
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      from?: Dayjs;
      to?: Dayjs;
    }>
  >;
}

const DateSelector: FC<DateSelectorProps> = ({
  type,
  dateRange,
  setDateRange,
}) => {
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [firstSelected, setFirstSelected] = useState<boolean>(false);
  const { isMobile } = useIsMobile();
  const track = useTrack();
  const bookingType = useBookingType();
  const brandConfig = useStore((state) => state.brandConfig);
  const { primaryColor, primaryColorBg } = brandConfig.theme;
  const { t } = useTranslate();
  const nightsAmount = dayjs(dateRange.to).diff(dateRange.from, 'days');

  const locale = Locale[brandConfig.language];

  const handleOpenSelector = () => {
    if (isMobile) {
      setIsModalOpen(true);
    } else {
      setOpenDateSelector(true);
    }
    track({
      eventName: 'opened.dates-selector',
      logLevel: 'info',
    });
  };

  const handleCloseSelector = () => {
    if (isMobile) {
      setIsModalOpen(false);
    } else {
      setOpenDateSelector(false);
    }
    track({
      eventName: 'selected.dates',
      logLevel: 'info',
      eventProperties: {
        from: dateRange?.from.format(),
        to: dateRange?.to.format(),
      },
    });
  };

  const selected = {
    from: dateRange?.from.toDate(),
    to: dateRange?.to.toDate(),
  };

  const handleDisabledDates = (date: Date) => {
    if (dateRange && dateRange.from && dateRange.to) {
      return dayjs(date) < dayjs();
    }
    return (
      (dateRange && dateRange.from?.add(1, 'd').isAfter(date)) ||
      dayjs(date) < dayjs()
    );
  };

  const handleOnSelect = (day: Date) => {
    if (dayjs(day).isBefore(dateRange.from)) {
      setDateRange({
        from: dayjs(day),
        to: dateRange.to,
      });
    } else if (dayjs(day).isAfter(dateRange.to) && !firstSelected) {
      setDateRange({
        from: dayjs(day),
        to: dayjs(day).add(1, 'day'),
      });
      setFirstSelected(true);
    } else {
      setDateRange({
        from: dateRange.from,
        to: dayjs(day),
      });
      setFirstSelected(false);
      setOpenDateSelector(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Styled.Container>
        <TextField
          label={t('search.when')}
          value={
            (dateRange &&
              `${dateRange.from.format('ddd, DD MMM')} - ${dateRange.to.format('ddd, DD MMM')}`) ||
            ''
          }
          onClick={() => handleOpenSelector()}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          sx={{
            '& label': {
              fontSize: '18px',
            },
            '& input': {
              textOverflow: 'ellipsis',
            },
          }}
        />
      </Styled.Container>
      {openDateSelector && (
        <ClickAwayListener
          children={
            <Styled.CalendarContainer>
              {type === 'RETURN' && (
                <Styled.DatePicker
                  mode="range"
                  numberOfMonths={2}
                  weekStartsOn={1}
                  $color={primaryColor}
                  $bgColor={primaryColorBg}
                  selected={selected}
                  disabled={handleDisabledDates}
                  defaultMonth={dateRange.from.toDate()}
                  onDayClick={(day) => handleOnSelect(day)}
                  locale={locale}
                />
              )}
              {type === 'DEPARTURE' && (
                <Styled.DatePicker
                  mode="single"
                  numberOfMonths={1}
                  onSelect={(date) =>
                    setDateRange({ from: dayjs(date), to: undefined })
                  }
                  selected={dateRange.from.toDate()}
                  disabled={(date) => dayjs(date) < dayjs()}
                  $color={primaryColor}
                  $bgColor={primaryColorBg}
                  defaultMonth={dateRange.from.toDate()}
                  locale={locale}
                />
              )}

              {bookingType !== BookingType.Flight && (
                <Styled.NightsCounter>
                  {nightsAmount}{' '}
                  {nightsAmount >= 2 ? t('general.nights') : t('general.night')}
                </Styled.NightsCounter>
              )}
            </Styled.CalendarContainer>
          }
          onClickAway={handleCloseSelector}
        />
      )}
      <Modal
        title={t('search.dates')}
        isModalOpen={isModalOpen}
        fullHeight
        onCloseModal={handleCloseSelector}
      >
        <Styled.MobileCalendarContainer>
          <Styled.MobileDatesContainer>
            {type === 'RETURN' && (
              <Styled.Item $selected={false} $color={primaryColor}>
                {dateRange?.from.format('ddd, DD MMM')}
              </Styled.Item>
            )}
            <Styled.DatesDivider>-</Styled.DatesDivider>
            <Styled.Item $selected={false} $color={primaryColor}>
              {dateRange?.to.format('ddd, DD MMM')}
            </Styled.Item>
          </Styled.MobileDatesContainer>
          <Styled.DateSelectorContainer>
            {type === 'RETURN' && (
              <Styled.DatePicker
                mode="range"
                numberOfMonths={12}
                hideNavigation
                weekStartsOn={1}
                $color={primaryColor}
                $bgColor={primaryColorBg}
                onDayClick={(day) => handleOnSelect(day)}
                selected={selected}
                disabled={handleDisabledDates}
                locale={locale}
              />
            )}
            {type === 'DEPARTURE' && (
              <Styled.DatePicker
                mode="single"
                hideNavigation
                numberOfMonths={1}
                onSelect={(date) =>
                  setDateRange({ from: dayjs(date), to: undefined })
                }
                selected={dateRange.from.toDate()}
                disabled={(date) => dayjs(date) < dayjs()}
                $color={primaryColor}
                $bgColor={primaryColorBg}
                locale={locale}
              />
            )}
          </Styled.DateSelectorContainer>
        </Styled.MobileCalendarContainer>
      </Modal>
    </div>
  );
};

export { DateSelector };
