import {
  Proposal,
  Rate,
  TransportAccommodationRate,
} from '@/services/codegen-romulo';

export type LastminuteDealProps = {
  id: number;
  type: string;
  accommodation: {
    label: string;
    description: string;
  };
  addedValues: Array<any>;
  providerIds: Array<number>;
  stayDate: {
    from: string;
    to: string;
  };
  bookDate: {
    from: string;
    to: string;
  };
};

type GetLastminuteDealProps = {
  rate: Rate;
  promotions: TransportAccommodationRate['promotions'];
};

const PROMOTION = 'FLASH_SALE';

export function getLastminuteDeal({
  rate,
  promotions,
}: GetLastminuteDealProps): LastminuteDealProps | undefined {
  const accommodationRatePromotion = rate.accommodationRate.promotions.find(
    (promotion) => promotion.type === PROMOTION
  );

  return promotions.find((e) => e.id === accommodationRatePromotion?.id);
}
