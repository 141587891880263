import { StaticData, TransportProvider } from '@/services/codegen-romulo';

export const staticDataToAirlines = (
  el: StaticData
): Record<string, TransportProvider> => {
  var rv = {};
  el?.airlines?.forEach((e) => {
    rv[e.id] = {
      label: e.label,
      lowCost: e.lowCost,
    };
  });
  return rv;
};
