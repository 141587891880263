import { Traveller } from '@/services/codegen-romulo';

export const getTravellerFullName = (
  traveller: Traveller,
  t: (key: string) => string
): string => {
  const { name, surname } = traveller;
  return name && surname
    ? `${name} ${surname}`
    : `${t('general.traveller')} ${traveller.id}`;
};
