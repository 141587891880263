import styled from '@emotion/styled';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
export const TextContainer = styled.div`
  gap: 8px;
  display: flex;
`;
export const LegalText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #74747c;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
  margin-right: 0px;

  & .MuiCheckbox-root {
    margin-top: 4px;
    padding: 0px;
    min-width: 24px;
  }

  & div {
    padding-left: 8px;
  }

  & .MuiTypography-root {
    flex: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #74747c;
  }
`;
