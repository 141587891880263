import { SortSelector } from '@/components/SortSelector';
import { sortOptions } from './utils';
import { FlightsList } from '@/components/FlightsList';
import * as Styled from './Flights.styled';
import { TotalItems } from '@/components/Filters/TotalItems';
import { Header } from '@/components/Header';
import { LmnFooter } from '@/components/LmnFooter';
import { FlightsResponsiveFilters } from '@/components/FlightsResponsiveFilters';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { FlightFiltersContainer } from '@/components/Filters/Flights/FlightFiltersContainer';
import { useStore } from '@/store/useStore';

export function Flights() {
  const freeFlightCampaign = useFreeFlightCampaign();

  const [onChangeSortSelector, sortSelector] = useStore((state) => [
    state.onChangeSortSelector,
    state.sortSelector,
  ]);

  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  return (
    <Styled.Container>
      <Header showSearchSummary />
      <FlightsResponsiveFilters />
      <Styled.InfoContainer>
        <Styled.VerticalFiltersContainer>
          <TotalItems />
          <FlightFiltersContainer />
        </Styled.VerticalFiltersContainer>
        <Styled.ResultsContainer>
          {!isFreeFlight && (
            <Styled.SortSelectorContainer>
              <SortSelector
                sortOptions={sortOptions}
                onChangeSortSelector={onChangeSortSelector}
                sortSelector={sortSelector}
              />
            </Styled.SortSelectorContainer>
          )}
          <FlightsList isFreeFlight={isFreeFlight} />
        </Styled.ResultsContainer>
      </Styled.InfoContainer>
      <LmnFooter />
    </Styled.Container>
  );
}
