import { TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';

interface EmissionDateFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const EmissionDateField: FC<EmissionDateFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();

  return (
    <TextField
      variant="filled"
      label={t('form.passenger.traveller_details.document.emission_date')}
      name={`documentEmissionDate`}
      error={hasError}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      helperText={hasError && t('form.validation.document.emission_date')}
      disabled={disabled}
    />
  );
};

export default EmissionDateField;
