import {
  StaticDataTransportDetail,
  TransportProvider,
} from '@/services/codegen-romulo';
import { getTimeInTheTimezone, minutesToHours } from '@/utils/timeUtils';
import { MapperTransportObject } from '../types/MapperTransportObject';
import { TransportRowProps } from '../types/TransportRowProps';
import { TransportType } from '../types/TransportType';
import { getStopsLabel } from './getStopsLabel';

export const transportProposalWaytoTransportRowAdapter = (
  transport: MapperTransportObject,
  airports: Record<string, StaticDataTransportDetail>,
  airlines: Record<string, TransportProvider>,
  isOutbound: boolean
): TransportRowProps => {
  const {
    segments,
    isoZonedDepartureDateTime,
    departureId,
    transportType,
    durationInMinutes,
    arrivalId,
    isoZonedArrivalDateTime,
    handBaggageIncluded,
    baggageIncluded,
  } = transport;

  const getAirport = (id: string) => airports[id]?.cityName || id;
  const getSupplier = (id: string) => airlines[id]?.label || id;

  const isSameDay =
    getTimeInTheTimezone(isoZonedDepartureDateTime, undefined, true).format(
      'YYYY-MM-DD'
    ) ===
    getTimeInTheTimezone(isoZonedArrivalDateTime, undefined, true).format(
      'YYYY-MM-DD'
    );

  const stopsLabel = getStopsLabel(segments, airports);

  const departureCity = getAirport(departureId);
  const arrivalCity = getAirport(arrivalId);

  return {
    direction: isOutbound ? 'outbound' : 'inbound',
    dateAsString: `${getTimeInTheTimezone(isoZonedDepartureDateTime)
      .locale('it')
      .format('ddd, D MMMM')
      .toUpperCase()}`,
    companies: segments.map((segment) => {
      const supplier = getSupplier(segment.supplierId);
      return {
        code: segment.supplierId,
        type: segment.transportType as TransportType,
        name: supplier,
        id: supplier,
      };
    }),
    departureInfo: {
      time: getTimeInTheTimezone(
        isoZonedDepartureDateTime,
        undefined,
        true
      ).format('HH:mm'),
      location: {
        iataCode: departureId,
        label: '',
        highlight: false,
        transportType: transportType,
      },
      city: departureCity,
      differentDate: undefined,
    },
    duration: minutesToHours(durationInMinutes),
    stops: segments.length - 1,
    stopsLabel: stopsLabel.label,
    segmentsInfo: stopsLabel.segmentsInfo,
    arrivalInfo: {
      time: getTimeInTheTimezone(
        isoZonedArrivalDateTime,
        undefined,
        true
      ).format('HH:mm'),
      location: {
        iataCode: arrivalId,
        label: '',
        highlight: false,
        transportType: transportType,
      },
      city: arrivalCity,
      differentDate: !isSameDay
        ? {
            date: getTimeInTheTimezone(
              isoZonedArrivalDateTime,
              undefined,
              true
            ).format('dd/mm/yyyy'),
            plusDays: 1,
          }
        : undefined,
    },
    baggageInfo: {
      carryOnIncluded: handBaggageIncluded,
      checkedBaggageIncluded: baggageIncluded,
    },
  };
};
