import styled from '@emotion/styled';
import { StepConnector, stepConnectorClasses } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const StepperContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
    flex-direction: row;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
    flex-direction: row;
  }

  & .MuiStepLabel-label {
    margin-top: 8px;
  }

  & .MuiStepLabel-label.Mui-active {
    font-weight: bold;
    color: #3c3c3c !important;
  }
`;

export const Connector = styled(StepConnector)<{ primarycolor: string }>`
  &.${stepConnectorClasses.active} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ primarycolor }) => primarycolor};
      border-width: 2px;
    }
  }
  &.${stepConnectorClasses.completed} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ primarycolor }) => primarycolor};
      border-width: 2px;
    }
  }
  &.${stepConnectorClasses.disabled} {
    & .${stepConnectorClasses.line} {
      border-color: #eaeaeb;
    }
  }
`;

export const ColorlibStepIconRoot = muiStyled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: '#eaeaeb',
  fontSize: '12px',
  zIndex: 1,
  color: '#babac0',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontWeight: 'bold',
        ['&:before']: {
          opacity: 0.3,
          content: '""',
          width: '30px',
          height: '30px',
          position: 'absolute',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
        },
        ['& span']: {
          zIndex: 1,
        },
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        ['& .MuiSvgIcon-root']: {
          width: '16px;',
          height: '16px;',
        },
      },
    },
  ],
}));
