import SliderMui from '@mui/material/Slider';

export type SliderProps = {
  defaultValue?: number[];
  disabled?: boolean;
  onChange?: (_, value: number[]) => void;
  min?: number;
  max?: number;
  valueLabelFormat?: (value: number) => any;
  value?: number[];
};

export function Slider({
  defaultValue,
  disabled,
  onChange,
  min,
  max,
  valueLabelFormat,
  value,
}: SliderProps) {
  return (
    <SliderMui
      defaultValue={defaultValue}
      step={30}
      disabled={disabled}
      onChangeCommitted={onChange}
      valueLabelDisplay="auto"
      min={min}
      max={max}
      valueLabelFormat={valueLabelFormat}
      sx={{
        '& .MuiSlider-thumb': {
          backgroundColor: '#fff',
        },
      }}
      value={value}
    />
  );
}
