import { FullTransportLogos } from '@/components/TransportCard/FullTransportLogos/FullTransportLogos';
import { TransportLogosCompany } from '@/components/TransportCard/FullTransportLogos/TransportLogosTypes';
import { Tooltip } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Flights.styled';

export interface FlightRowProps {
  companies: TransportLogosCompany[];
  departure: { time: string; airport: { name: string; IATA: string } };
  arrival: { time: string; airport: { name: string; IATA: string } };
  stops: string;
  duration: string;
  differentDay?: boolean;
}
export const FlightRow: FC<FlightRowProps> = ({
  companies,
  departure,
  arrival,
  stops,
  duration,
  differentDay,
}) => {
  const { t } = useTranslate();
  return (
    <Styled.RowContainer>
      <Styled.LogoColumn>
        <FullTransportLogos companies={companies} />
      </Styled.LogoColumn>
      <Styled.InfoColumn>
        <Styled.Time>
          {departure.time} - {arrival.time}
        </Styled.Time>
        <Styled.Airports>
          <Tooltip title={departure.airport.name} arrow placement="top">
            <span>{departure.airport.IATA}</span>
          </Tooltip>
          {' - '}
          <Tooltip title={arrival.airport.name} arrow placement="top">
            <span> {arrival.airport.IATA}</span>
          </Tooltip>
        </Styled.Airports>
      </Styled.InfoColumn>
      <Styled.DetailsColumn>
        <Styled.Stops>{stops}</Styled.Stops>
        <Styled.Duration>
          {duration}
          {differentDay && (
            <Tooltip title={departure.airport.name} arrow placement="top">
              <span>` (+1 ${t('general.time.day')})`</span>
            </Tooltip>
          )}
        </Styled.Duration>
      </Styled.DetailsColumn>
    </Styled.RowContainer>
  );
};
