import { useGetParam } from '@/hooks/useGetParam';
import { FC, useEffect, useState } from 'react';
import { useGetDestinations } from '../../hooks/useGetDestinations';
import { ResultElementProps } from '../SearchInput/ResultElement';
import { SearchInput } from '../SearchInput/SearchInput';

interface DestinationSearchProps {
  label: string;
  placeholder: string;
  error?: string;
  onChange: (e: ResultElementProps | undefined) => void;
  handleOnClickInput: () => void;
}

const DestinationSearch: FC<DestinationSearchProps> = ({
  label,
  placeholder,
  error,
  onChange,
  handleOnClickInput,
}) => {
  const getDestinations = useGetDestinations();
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState<ResultElementProps>(undefined);
  const [results, setResults] = useState<ResultElementProps[]>(undefined);
  const getParam = useGetParam();
  const destinationCode = getParam('destinationId');
  const destinationName = getParam('destinationName');

  useEffect(() => {
    const fillFromQueryParams = async () => {
      if (destinationName) {
        const destinationResults = await getDestinations(destinationName);
        const selectedInbound = destinationResults.find(
          (e) => e.code === destinationCode.toUpperCase()
        );
        setResults(destinationResults);
        setSelected(selectedInbound);
      }
    };

    fillFromQueryParams();
  }, [destinationName]);

  useEffect(() => {
    const getData = async (e: string) => {
      const result = await getDestinations(e);
      setResults(result);
    };

    const timer = setTimeout(() => {
      if (searchText) getData(searchText);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const handleSelect = (e: ResultElementProps) => {
    setSelected(e);
  };

  const handleClear = () => {
    setResults(undefined);
    setSelected(undefined);
    setSearchText('');
  };

  return (
    <SearchInput
      label={label}
      placeholder={placeholder}
      value={searchText}
      setValue={setSearchText}
      selected={selected}
      setSelected={setSelected}
      results={results}
      onSelect={handleSelect}
      onClear={handleClear}
      error={error}
      onClickInput={handleOnClickInput}
    />
  );
};

export { DestinationSearch };
