import styled from '@emotion/styled';

export const Container = styled.div`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eaeaeb;
`;

export const WayType = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #babac0;
  font-weight: 700;
`;

export const WayDate = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export const WayTextContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #babac0;
`;
