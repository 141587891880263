type GetMessageProps = {
  amountNights: number;
  destination: string;
  t: any;
};

export function getMessage({ amountNights, destination, t }: GetMessageProps) {
  if (amountNights && destination) {
    return amountNights === 1
      ? t('widget.trip_card.way.night.in.destination', '', {
          destination: destination,
        })
      : t('widget.trip_card.way.nights.in.destination', '', {
          destination: destination,
          nights: amountNights,
        });
  }

  return null;
}
