/* eslint-disable */
import { useStore } from '@/store/useStore';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Checkbox } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter';
import { useRemoveProducts } from '../../hooks/useRemoveProduct';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import * as Styled from './WebCheckIn.styled';
const WCINP_lmn =
  'https://assets.staticroot.com/lmn-assets-ui/4/webcheckin-widget/web_checkin_logo_pink.svg';

const WCINP_vg =
  'https://assets.staticroot.com/lmn-assets-ui/4/webcheckin-widget/web_checkin_logo_blue.svg';

interface WebCheckIn {
  travellerId: string;
  assistanceType: string;
}

const WebCheckIn: FC = () => {
  const [availableProducts] = useStore((state) => [state.availableProducts]);
  const brandConfig = useStore((state) => state.brandConfig);
  const { brandBase } = brandConfig.brand;
  const addProduct = useAddProduct();
  const removeProduct = useRemoveProducts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currencyFormatter = useCurrencyFormatter();
  const [selected, setSelected] = useState(false);
  const { t } = useTranslate();

  const webCheckIn = availableProducts?.products.WEBCHECKIN;

  if (!availableProducts) {
    return <Skeleton />;
  }

  if (!webCheckIn) {
    return null;
  }

  const handleChange = async (value: boolean) => {
    setIsLoading(true);

    if (value === false) {
      try {
        await removeProduct('WEBCHECKIN')
          .then((e) => {
            setSelected(value);
          })
          .finally(() => setIsLoading(false));
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      const insuranceObject = [
        {
          type: 'WEBCHECKIN',
          detail: {
            lmnId: '69',
            ancillary: {
              label: 'Check-in NoProblem',
            },
          },
          cost: {
            amount: webCheckIn.priceTotal,
          },
        },
      ];
      try {
        await addProduct('INSURANCE', insuranceObject)
          .then((e) => {
            setSelected(value);
          })
          .finally(() => setIsLoading(false));
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <SectionCard title={webCheckIn.labels['webcheckin.widget.main.title']}>
      <div>
        <Styled.FormControlLabel
          control={
            <Checkbox
              disabled={isLoading}
              value={selected}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onChange={(e) => handleChange(e.target.checked)}
            ></Checkbox>
          }
          label={
            <Styled.TextContainer>
              <img
                style={{ width: 40, height: 'fit-content' }}
                src={brandBase === 'Lastminute' ? WCINP_lmn : WCINP_vg}
              />
              <span>
                {webCheckIn.labels['webcheckin.widget.description'].replaceAll(
                  '{{price}}',
                  `${currencyFormatter.formatNumber(
                    webCheckIn.pricePerPax.value / 100
                  )}`
                )}
              </span>
            </Styled.TextContainer>
          }
        />

        <Styled.Accordion elevation={0} onClick={(e) => e.preventDefault()}>
          <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {t('form.product.insurance.show_more')}
          </Styled.AccordionSummary>
          <AccordionDetails>
            <Styled.DetailsContent>
              <ul>
                <li>
                  <CheckIcon />
                  {
                    webCheckIn.labels[
                      'flights_app.mypage.my_bookings.checkin_no_problem.usp1'
                    ]
                  }
                </li>
                <li>
                  <CheckIcon />
                  {
                    webCheckIn.labels[
                      'flights_app.mypage.my_bookings.checkin_no_problem.usp2'
                    ]
                  }
                </li>
                <li>
                  <CheckIcon />
                  {
                    webCheckIn.labels[
                      'flights_app.mypage.my_bookings.checkin_no_problem.usp3'
                    ]
                  }
                </li>
              </ul>
            </Styled.DetailsContent>
          </AccordionDetails>
        </Styled.Accordion>
      </div>
    </SectionCard>
  );
};

export default WebCheckIn;
