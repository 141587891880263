import { useTranslate } from '@tolgee/react';
import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { TravelSearchInfoProps } from './TravelSearchInfo.types';
import * as Styled from './TravelSearchInfo.styled';

export function TravelSearchInfo({
  title,
  subtitle,
  description,
}: TravelSearchInfoProps) {
  const { t } = useTranslate();

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Row justify={'center'}>
          <Col>
            <Title style={{ color: '#3c3c3c' }} level={1}>
              {t(title)}
            </Title>
            {subtitle && (
              <Title style={{ color: '#3c3c3c', marginTop: 0 }} level={2}>
                {t(subtitle)}
              </Title>
            )}
            <Styled.Text dangerouslySetInnerHTML={{ __html: t(description) }} />
          </Col>
        </Row>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
