import { LastminuteDealWhiteIcon } from '@/components/LmnIcons/LmnIcons';
import { Tag as TagAntd } from 'antd';
import styled from '@emotion/styled';

export const Tag = styled(TagAntd)`
  margin-right: 0;
  font-size: 14;
  padding: '4px 6px 4px 6px';
  width: 'fit-content';
  margin-bottom: 6;
  border-radius: 4;
`;

export const LastMinuteLabel = styled.div`
  border-radius: 4px;
  min-height: 28px;
  padding: 2px 8px 2px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/last_minute_deals.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 14px;
  color: #fff;
  font-weight: 700;
`;

export const Icon = styled(LastminuteDealWhiteIcon)`
  color: 'white';
  margin-right: 4px;
`;
