import { useStore } from '@/store/useStore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingButton } from '@mui/lab';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './StepNavigator.styled';

interface StepNavigatorProps {
  onBack: () => void;
  onNext: () => void;
  isLoading: boolean;
  currentStep: number;
}

export const StepNavigator: FC<StepNavigatorProps> = ({
  onBack,
  onNext,
  isLoading,
  currentStep,
}) => {
  const { t } = useTranslate();
  const brandConfig = useStore((s) => s.brandConfig);

  if (currentStep === 4) {
    return null;
  }

  return (
    <Styled.Container>
      <LoadingButton
        variant="outlined"
        sx={{ minWidth: 'unset', width: '40px', padding: '5px' }}
        onClick={onBack}
        disabled={currentStep <= 1}
      >
        <KeyboardArrowLeftIcon />
      </LoadingButton>
      {currentStep <= 3 && (
        <Styled.LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={onNext}
        >
          {currentStep === 3
            ? t(
                brandConfig.checkout?.createReservationButtonText ||
                  'form.button.confirm_reservation'
              )
            : t('general.actions.next')}
        </Styled.LoadingButton>
      )}
    </Styled.Container>
  );
};
