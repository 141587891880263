import { Way } from '../types/tripDetailsTypes/Way';
import { getTransportStopLabels } from './getTransportStopLabels';

export type WayStopsProps = {
  way: Way;
};

export const wayStopsAdapter = {
  adapt: (way: Way, t: (key: string, x: any, replaces: any) => string) => {
    const numberOfStops = way.segments.length - 1;
    const labels = getTransportStopLabels(way);
    const stopsKeys = {
      0: 'widget.trip_card.stops.direct',
      1: labels.singular,
      more: labels.plural,
    };

    return t(stopsKeys[numberOfStops] || stopsKeys.more, '', {
      stops: numberOfStops.toString(),
    });
  },
};
