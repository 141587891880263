import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './StarsFilters.styled';
import { Stars } from './Star';
import { isEmpty } from '@/utils/isEmpty';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import { Rating } from '@mui/material';

export function StarsFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { stars } = hdpStaticData;

  const onStarsHandle = (e) => {
    const starsUpdated = getCheckboxFilterUpdated(
      hdpFilters,
      'stars',
      e.target
    );

    getHDPProposals(searchId, [{ stars: starsUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Stars
          data={stars}
          onStarsHandle={onStarsHandle}
          disabled={isFecthRetrieveProporsals}
          starsFilter={hdpFilters.stars}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
