import { useGetFlag } from '@/hooks/useGetFlag';
import { Checkout } from '@/views/Checkout/Checkout';
import { Flights } from '@/views/Flights';
import { HotelDetail } from '@/views/HotelDetail';
import { HotelsFlightsSearch } from '@/views/HotelsFlightsSearch';
import { TravelSearch } from '@/views/TravelSearch';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Route, Routes } from 'react-router-dom';
import { ErrorScreen } from '../ErrorScreen/ErrorScreen';

dayjs.extend(utc);
dayjs.extend(timezone);

export function Router() {
  const maintenanceMode = useGetFlag('travel.general.maintenance');
  const { t } = useTranslate();

  if (maintenanceMode) {
    return (
      <ErrorScreen
        title={t('maintenance.title')}
        subtitle={t('maintenance.description')}
        ctaLabel={t('maintenance.cta')}
      />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<TravelSearch />} />
      <Route path="/DP" element={<HotelsFlightsSearch />} />
      <Route path="/F" element={<Flights />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/hotel-detail" element={<HotelDetail />} />
    </Routes>
  );
}
