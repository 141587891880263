import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';
import styled from '@emotion/styled';
import Adult from './icons/adult.svg?react';
import AllFilters from './icons/all_filters.svg?react';
import AllInclusive from './icons/allinclusive.svg?react';
import AnyBoard from './icons/anyboard.svg?react';
import ArrowDown from './icons/arrow_small.svg?react';
import BackPack from './icons/backpack_2.svg?react';
import CarryOn from './icons/baggage_carryon.svg?react';
import Baggage from './icons/baggage_checked.svg?react';
import Bed from './icons/bed.svg?react';
import Breakfast from './icons/breakfast.svg?react';
import Building from './icons/building.svg?react';
import Bus from './icons/bus.svg?react';
import BusLine from './icons/busline.svg?react';
import Calendar from './icons/calendar.svg?react';
import ChangeAirport from './icons/change_airport.svg?react';
import Check from './icons/check.svg?react';
import Child from './icons/child.svg?react';
import ClearX from './icons/clear_x.svg?react';
import Copy from './icons/copy.svg?react';
import Dot from './icons/dot.svg?react';
import FreeCancellation from './icons/freeCancellation.svg?react';
import FullBoard from './icons/fullboard.svg?react';
import GlobeMap from './icons/globe-map.svg?react';
import HalfBoard from './icons/halfboard.svg?react';
import HandLuggage from './icons/hand_luggage.svg?react';
import Hotel from './icons/hotel.svg?react';
import HotelBed from './icons/hotelBed.svg?react';
import Infant from './icons/infant.svg?react';
import Info from './icons/info.svg?react';
import Intro from './icons/intro.svg?react';
import LastminuteDeal from './icons/lastminute_deals.svg?react';
import LastminuteDealWhite from './icons/lastminute_deals_white.svg?react';
import Luggage from './icons/luggage.svg?react';
import Marker from './icons/marker.svg?react';
import Moon from './icons/moon.svg?react';
import MultipleAirlines from './icons/multiple_airlines.svg?react';
import NoAirlineLogo from './icons/no_airline_logo.svg?react';
import NoCarryOn from './icons/no_baggage_carryon.svg?react';
import NoBaggage from './icons/no_baggage_checked.svg?react';
import NoTrainCompanyLogo from './icons/no_traincompany_logo.svg?react';
import NoMeal from './icons/nomeal.svg?react';
import OverFilter from './icons/over_filtered.svg?react';
import Passport from './icons/passport.svg?react';
import Person from './icons/person.svg?react';
import Phone from './icons/phone.svg?react';
import Pin from './icons/pin.svg?react';
import PinFlight from './icons/pin_flight.svg?react';
import Plane from './icons/plane.svg?react';
import PlaneNE from './icons/plane_ne.svg?react';
import Priority from './icons/priority.svg?react';
import Search from './icons/search.svg?react';
import SeatNotAvailable from './icons/seat_not_available.svg?react';
import Shield from './icons/shield.svg?react';
import Sort from './icons/sort.svg?react';
import SortArrow from './icons/sort_arrow.svg?react';
import TopSecretWhite from './icons/top_secret.svg?react';
import TopSecretWhiteMask from './icons/top_secret_mask.svg?react';
import TopSecretWhiteMask2 from './icons/top_secret_mask_2.svg?react';
import TrainLine from './icons/trainline.svg?react';
import Transportation from './icons/transportation.svg?react';
import ViewList from './icons/view_list.svg?react';
import ViewMap from './icons/view_map.svg?react';

export const ArrowDownIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={ArrowDown} {...props} style={{ fontSize: props.size }} />;

export const PersonIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={Person} {...props} style={{ fontSize: props.size }} />;

export const SortArrowIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={SortArrow} {...props} style={{ fontSize: props.size }} />;

export const OverFilterIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
  }
) => <Icon component={OverFilter} {...props} />;

export const HotelIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Hotel}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const BackPackIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={BackPack} {...props} style={{ fontSize: props.size }} />;

export const ClearXIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ClearX}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const IntroIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Intro}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CheckIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Check}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const PlaneIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Plane}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PinFlightIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={PinFlight} {...props} style={{ fontSize: props.size }} />;
export const CityIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Building}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoMealIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoMeal}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HalfBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HalfBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const FullBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={FullBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AnyBoardIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AnyBoard}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BreakFastIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Breakfast}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AllInclusiveIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AllInclusive}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LastminuteDealIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={LastminuteDeal}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LastminuteDealWhiteIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={LastminuteDealWhite}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhite}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteMaskIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhiteMask}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const TopSecretWhiteMask2Icon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TopSecretWhiteMask2}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const InfoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Info}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const MultipleAirlinesIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={MultipleAirlines}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PlaneNEIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={PlaneNE}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BaggageCheckedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Baggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CarryOnIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={CarryOn}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoBaggageCheckedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoBaggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PriorityIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Priority}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const NoCarryOnIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoCarryOn}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const BedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Bed}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HotelBedIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HotelBed}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const MoonIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => <Icon component={Moon} {...props} style={{ ...props.style }} />;
export const MarkerIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Marker}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const FreeCancellationIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={FreeCancellation}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const CalendarIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Calendar}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const ShieldIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Shield}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PassportIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Passport}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const AdultIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Adult}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const ChildIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Child}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const InfantIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Infant}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const CopyIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Copy}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const DotIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Dot}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const PhoneIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Phone}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SearchIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Search}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const TransportationIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Transportation}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const NoAirlineLogoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoAirlineLogo}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const NoTrainCompanyLogoIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={NoTrainCompanyLogo}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const BusIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Bus}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const BusLineIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={BusLine}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const TrainLineIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={TrainLine}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SeatNotAvailableIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={SeatNotAvailable}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ChangeAirportIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ChangeAirport}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const LuggageIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Luggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const HandLuggageIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={HandLuggage}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const SortIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Sort}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const AllFiltersIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={AllFilters}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ViewMapIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ViewMap}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const ViewListIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={ViewList}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const PinIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={Pin}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);
export const GlobeMapIcon = (
  props: Partial<CustomIconComponentProps> & {
    onClick?: () => void;
    size?: number;
  }
) => (
  <Icon
    component={GlobeMap}
    {...props}
    style={{ ...props.style, fontSize: props.size }}
  />
);

export const IconWrapper = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: white;
  border-radius: 10px;
  font-size: 20px;
`;
