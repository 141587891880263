import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: 744px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const Card = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const BlockTitle = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
  margin: 0px 0px 0.5rem;
  display: flex;
  align-items: center;
  gap: 8px;
  & svg {
    font-size: 20px;
  }
`;

export const FacilitiesList = styled.ul`
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FacilitiesContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 16px;
  column-gap: 24px;
  row-gap: 8px;
`;

export const FacilityElement = styled.div`
  color: #3c3c3c;
  font-size: 14px;
  line-height: 1.5rem;
  display: flex;
  gap: 8px;
  align-items: center;
  & svg {
    font-size: 20px;
  }
`;

export const Divider = styled.div`
  margin: 24px 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-image: initial;
  border-top: 1px solid #eaeaeb;
`;

export const FacilityListElement = styled.li`
  color: #3c3c3c;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  & svg {
    margin-left: 2px;
    font-size: 16px;
    opacity: 0.75;
  }
`;

export const ExpandMoreButton = styled.button`
  margin-top: 16px;
  cursor: pointer;
  color: red;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: unset;
  background-color: unset;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const BlocksViewPart = styled.div<{ $expanded: boolean }>`
  ${({ $expanded }) =>
    $expanded &&
    css`
      overflow: hidden;
      max-height: 140px;
    `}
`;
export const BlocksContainer = styled.div`
  column-gap: 50px;

  @media (min-width: 744px) {
    column-count: 2;
  }

  @media (min-width: 834px) {
    column-count: 3;
  }
`;

export const Block = styled.div`
  margin-bottom: 24px;
  break-inside: avoid;
`;
