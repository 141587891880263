import { useStore } from '@/store/useStore';
import qs from 'qs';
import * as React from 'react';

export function usePopulateTravelProduct() {
  const brandConfig = useStore((state) => state.brandConfig);

  const defaultProduct = brandConfig.brand.productTypesOrder[0];

  const populateTravelProduct = () => {
    const queryParam = qs.parse(window.location.search.substring(1));

    if (!!queryParam.product) return;

    const product = defaultProduct;

    const params = {
      ...queryParam,
      product: product,
    };

    const query = qs.stringify(params);

    window.location.search = query;
  };

  React.useEffect(() => {
    populateTravelProduct();
  }, []);
}
