import { Alert as AlertAntd } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  margin: 8px 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;

export const DescriptionContainer = styled.div``;

export const DifferentTransportContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ff4800;
  margin-top: 8px;

  & svg {
    margin-right: 8px;
  }
`;

export const Alert = styled(AlertAntd)<{ $withoutBorder?: boolean }>`
  background-color: #e3e3e3 !important;
  ${({ $withoutBorder }) =>
    $withoutBorder
      ? css`
          border: 1px solid #e3e3e3 !important;
        `
      : css`
          border: 1px solid rgba(128, 128, 128, 0.24) !important;
        `};
  & span {
    color: rgba(128, 128, 128, 1) !important;
  }
`;
