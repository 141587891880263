import styled from '@emotion/styled';
import {
  FormControl as MuiFormControl,
  Select as MuiSelect,
  TextField as MuiTextField,
} from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & .MuiInputBase-root {
    width: 200px;
    height: 56px;
  }

  & .MuiFormControl-root {
    width: 100%;
    & .MuiInputBase-root {
      width: 100%;
    }
  }
`;

export const Title = styled.span`
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
`;

export const Select = styled(MuiSelect)`
  border-radius: 8px;

  & .MuiSelect-select:focus {
    background-color: unset !important;
  }
`;

export const TextField = styled(MuiTextField)`
  width: 100%;
`;

export const FormControl = styled(MuiFormControl)`
  width: 160px !important;

  @media (max-width: 768px) {
    width: 112px !important;
  }
`;
