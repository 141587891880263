import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #eaeaeb;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 24px;
  margin-top: 0px;
`;

export const Time = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
`;
export const Airports = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #74747c;
`;

export const Stops = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #74747c;
`;

export const Duration = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #74747c;
`;

export const LogoColumn = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  margin: 0px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DetailsColumn = styled.div`
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
`;
