import { PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { FlightRow } from './FlightRow';
import * as Styled from './Flights.styled';
import { flightRowAdapter } from './utils/flightRowAdapter';

export const Flights: FC = () => {
  const { t } = useTranslate();
  const cartDetail = useStore((state) => state.cartDetail);

  const flightProduct = cartDetail?.products?.find((e) => e.type === 'FLIGHT');

  if (!flightProduct) {
    return null;
  }

  const outboundFlight = flightProduct.detail.itinerary?.outboundTransport?.[0];
  const inboundFlight = flightProduct.detail.itinerary?.inboundTransport?.[0];

  const outbound = flightRowAdapter(outboundFlight, t);
  const inbound = inboundFlight && flightRowAdapter(inboundFlight, t);

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title>
          <PlaneNEIcon style={{ marginRight: 8 }} />
          {t('general.product.flight')}
        </Styled.Title>
      </Styled.TitleContainer>
      <Styled.ContentContainer>
        <FlightRow {...outbound} />
        {inbound && <FlightRow {...inbound} />}
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
