import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { FC } from 'react';

import { Modal } from '@/components/Modal';
import { Amount } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import CurrencyFormatter from '@/utils/currencyFormatter';
import { useTranslate } from '@tolgee/react';

interface CancellationModalProps {
  hotel?: {
    cancellationDeadline: string;
    cost: Amount;
    freeCancellation: boolean;
  };
  flight: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

const CancellationModal: FC<CancellationModalProps> = ({
  hotel,
  flight,
  isModalOpen,
  onCloseModal,
}) => {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { language, country } = brandConfig;

  const currencyFormatter = new CurrencyFormatter(
    `${language}-${country}`,
    hotel?.cost?.currency || 'EUR',
    {
      minimumFractionDigits: 2,
    }
  );

  return (
    <Modal
      title={t('general.cancellation_policy')}
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
    >
      <Flex vertical>
        <div>
          <div>
            <span>{t('form.subscription.modal.cancellation.description')}</span>
          </div>
        </div>
        {hotel && hotel.cancellationDeadline ? (
          <div>
            <div>
              <Title level={5}>
                {t('form.subscription.modal.cancellation.hotel')}
              </Title>
            </div>
            <div data-test="service-conditions-text">
              <span
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    'form.subscription.modal.cancellation.hotel.to',
                    '',
                    {
                      to: dayjs(hotel.cancellationDeadline).format(
                        'DD MMM YYYY'
                      ),
                    }
                  )}`,
                }}
              />
            </div>
            <div data-test="service-conditions-text">
              <span
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    'form.subscription.modal.cancellation.hotel.from',
                    '',
                    {
                      from: dayjs(hotel.cancellationDeadline).format(
                        'DD MMM YYYY'
                      ),
                      value: currencyFormatter.formatNumber(
                        hotel?.cost?.value / 100
                      ),
                    }
                  )}`,
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Title level={5}>
                {t('form.subscription.modal.cancellation.hotel')}
              </Title>
            </div>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    'form.subscription.modal.cancellation.hotel.cost',
                    '',
                    {
                      cost: currencyFormatter.formatNumber(
                        hotel?.cost?.value / 100
                      ),
                    }
                  )}`,
                }}
              />
            </div>
          </div>
        )}

        {flight ? (
          <div>
            <div>
              <Title level={5}>
                {t('form.subscription.modal.cancellation.flight')}
              </Title>
            </div>
            <div>
              <span>
                {t('form.subscription.modal.cancellation.flight.cost')}
              </span>
            </div>
          </div>
        ) : undefined}
        <div>
          <div>
            <Title level={5}>
              {t(
                'form.subscription.modal.cancellation.flight.additional_products'
              )}
            </Title>
          </div>
          <div>
            <span>
              {t(
                'form.subscription.modal.cancellation.flight.additional_products.description'
              )}
            </span>
          </div>
        </div>
      </Flex>
    </Modal>
  );
};

export default CancellationModal;
