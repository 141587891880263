import { useSearchParams } from 'react-router-dom';

export function useHandleFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const addFilter = (searchKey: string, value: string): any => {
    const params = {};
    searchParams.forEach((el, key) => {
      params[key] = el;
    });
    setSearchParams({ ...params, [searchKey]: value });
  };

  const removeFilter = (searchKey: string): any => {
    searchParams.delete(searchKey);
    setSearchParams(searchParams);
  };

  return {
    addFilter,
    removeFilter,
  };
}
