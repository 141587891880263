import { useTranslate } from '@tolgee/react';
import { MoonIcon } from '../LmnIcons/LmnIcons';
import { Modal } from '../Modal';
import { TransportDetail } from '../TransportDetail';
import * as Styled from './ModalFlight.styled';
import { ModalFlightProp } from './ModalFlight.types';
import { getMessage } from './utils';
import { Button } from '../Button';
import { CircularProgress } from '@mui/material';

export function ModalFlight({
  title,
  isModalOpen,
  onCloseModal,
  tripDetails,
  isFreeFlight = false,
  amountNights,
  destination,
  price,
  isButtonLoading,
  onModalButtonClick,
  disabledFooter,
}: ModalFlightProp) {
  const { t } = useTranslate();

  const message = getMessage({ amountNights, destination, t });

  const ModalFooter = () => {
    return (
      <Styled.ModalFooterContainer>
        {!isFreeFlight && (
          <Styled.ModalFooterPriceContainer>
            <Styled.ModalFooterPrice>
              {price}
              <Styled.ModalFooterPriceUnity>pp</Styled.ModalFooterPriceUnity>
            </Styled.ModalFooterPrice>
          </Styled.ModalFooterPriceContainer>
        )}
        <Button onClick={onModalButtonClick}>
          {isButtonLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
              }}
            />
          ) : (
            t('flight.select')
          )}
        </Button>
      </Styled.ModalFooterContainer>
    );
  };

  const Footer = !disabledFooter && ModalFooter;

  return (
    <Modal
      title={title}
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      Footer={Footer}
    >
      <Styled.Container>
        <TransportDetail
          locale={'it'}
          isInbound={false}
          {...tripDetails.outbound}
          isFreeFlight={isFreeFlight}
        />
        {message && (
          <Styled.MessageContainer>
            <MoonIcon />
            <Styled.Text>{message}</Styled.Text>
          </Styled.MessageContainer>
        )}
        <TransportDetail
          locale={'it'}
          isInbound={true}
          {...tripDetails.inbound}
          isFreeFlight={isFreeFlight}
        />
      </Styled.Container>
    </Modal>
  );
}
