import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PlaneIcon } from '../../LmnIcons/LmnIcons';
import { CdnImage } from './CdnImage';

export enum TransportType {
  FLIGHT = 'FLIGHT',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
}

type Props = {
  companyCode: string;
  logoSize: number;
  iconSize: number;
};

const LOGOS_BASE_URL =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/q_auto/v1/DAM/Commercial%20Partners/Airlines/Icons';

export const TransportLogo: React.FC<Props> = ({
  companyCode,
  logoSize,
  iconSize,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  return hasError ? (
    <FallbackContainer size={logoSize}>
      <PlaneIcon style={{ fontSize: iconSize }} />
    </FallbackContainer>
  ) : (
    <CdnImage
      src={`${LOGOS_BASE_URL}/${companyCode}`}
      alt={companyCode}
      width={logoSize}
      height={logoSize}
      onError={() => setHasError(true)}
      transformations={{
        size: {
          type: 'fill',
          heightInPx: logoSize * 2,
          widthInPx: logoSize * 2,
        },
        format: 'auto',
        quality: 'auto',
      }}
    />
  );
};

const FallbackContainer = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: lightgray;
`;
