import * as React from 'react';
import { Header } from '@/components/Header';
import { Facilities } from './Facilities';
import { FlightDetail } from './FlightDetail';
import { GeneralInformation } from './GeneralInformation';
import { HotelSummary } from './HotelSummary';
import { RoomDetail } from './RoomDetail';
import * as Styled from './HotelDetail.styled';
import { useGetParamsInfo } from './useGetParamsInfo';
import { isEmpty } from '@/utils/isEmpty';
import { useStoreHotelDetail } from '@/store/useStoreHotelDetail';
import { StoreHotelDetailProvider } from '@/store/storeHotelDetailProvider';

export function HotelDetail() {
  return (
    <StoreHotelDetailProvider>
      <Component />
    </StoreHotelDetailProvider>
  );
}

function Component() {
  const [retrievePricingProporsals, getRetrievePricingProporsals] =
    useStoreHotelDetail((state) => [
      state.retrievePricingProporsals,
      state.getRetrievePricingProporsals,
    ]);

  const { searchId, proposalId } = useGetParamsInfo();

  React.useEffect(() => {
    getRetrievePricingProporsals(searchId, proposalId);
  }, []);

  if (isEmpty(retrievePricingProporsals)) {
    return null;
  }

  return (
    <Styled.Container>
      <Header showSearchSummary />
      <HotelSummary />
      <Styled.ColumnsContainer>
        <FlightDetail />
        <RoomDetail />
        <GeneralInformation />
        <Facilities />
      </Styled.ColumnsContainer>
    </Styled.Container>
  );
}
