import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const BaggageContainer = styled.div<{ $highlight?: boolean }>`
  display: flex;
  font-size: 14px;
  line-height: 20px;
`;

export const IconContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 8px;
`;
