import { useTrack } from '@/hooks/useTrack';
import { isEmpty } from '@/utils/isEmpty';
import { explodeDuration, getHoursString } from '@/utils/timeUtils';
import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { Slider } from '../../Slider';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './DepartureTimeFilters.styled';

export function DepartureTimeFilters() {
  const {
    flightsStaticData,
    flightFilters,
    searchId,
    isFecthRetrieveProporsals,
    getFlightsProposals,
    retrieveProporsals,
  } = useGetFiltersData();

  const {
    departureCityName,
    arrivalCityName,
    inboundDepartureTimeData,
    outboundDepartureTimeRangeFormatted,
    inboundDepartureTimeRangeFormatted,
    timeRange,
    outboundDepartureTimeRange,
    inboundDepartureTimeRange,
    tripDuration,
  } = flightsStaticData;

  const { t } = useTranslate();
  const track = useTrack();

  const onOutboundTimeHandle = (_, e: number[]) => {
    track({
      eventName: 'clicked.filter.outbound-departure-time',
      logLevel: 'info',
      eventProperties: {
        departureTime: e,
      },
    });
    getFlightsProposals(searchId, [{ outboundDepartureTime: e }]);
  };

  const onInboundTimeHandle = (_, e: number[]) => {
    track({
      eventName: 'clicked.filter.inbound-departure-time',
      logLevel: 'info',
      eventProperties: {
        departureTime: e,
      },
    });
    getFlightsProposals(searchId, [{ inboundDepartureTime: e }]);
  };

  const onTimeFlightHandle = (_, e: number[]) => {
    track({
      eventName: 'clicked.filter.trip-duration',
      logLevel: 'info',
      eventProperties: {
        tripDuration: e,
      },
    });
    getFlightsProposals(searchId, [{ tripDuration: e }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <>
          <Styled.Title>{t('general.trip.outbound')}</Styled.Title>
          <Styled.Subtitle>
            {departureCityName &&
              arrivalCityName &&
              `${departureCityName} - ${arrivalCityName}`}
          </Styled.Subtitle>
        </>
        <Styled.SliderValuesContainer>
          <Styled.SliderValue>
            {outboundDepartureTimeRangeFormatted[0]}
          </Styled.SliderValue>
          <Styled.SliderValue>
            {outboundDepartureTimeRangeFormatted[1]}
          </Styled.SliderValue>
        </Styled.SliderValuesContainer>
        <Styled.SliderContainer>
          <Slider
            defaultValue={
              flightFilters.outboundDepartureTime
                ? flightFilters.outboundDepartureTime
                : outboundDepartureTimeRange
            }
            disabled={isFecthRetrieveProporsals}
            onChange={onOutboundTimeHandle}
            min={outboundDepartureTimeRange[0]}
            max={outboundDepartureTimeRange[1]}
            valueLabelFormat={(value) => getHoursString(value)}
          />
        </Styled.SliderContainer>
      </Styled.FiltersContainer>
      {inboundDepartureTimeData && (
        <Styled.FiltersContainer>
          <>
            <Styled.Title>{t('general.trip.inbound')}</Styled.Title>
            <Styled.Subtitle>
              {departureCityName &&
                arrivalCityName &&
                `${arrivalCityName} - ${departureCityName}`}
            </Styled.Subtitle>
          </>
          <Styled.SliderValuesContainer>
            <Styled.SliderValue>
              {inboundDepartureTimeRangeFormatted[0]}
            </Styled.SliderValue>
            <Styled.SliderValue>
              {inboundDepartureTimeRangeFormatted[1]}
            </Styled.SliderValue>
          </Styled.SliderValuesContainer>
          <Styled.SliderContainer>
            <Slider
              defaultValue={
                flightFilters.inboundDepartureTime
                  ? flightFilters.inboundDepartureTime
                  : inboundDepartureTimeRange
              }
              disabled={isFecthRetrieveProporsals}
              onChange={onInboundTimeHandle}
              min={inboundDepartureTimeRange[0]}
              max={inboundDepartureTimeRange[1]}
              valueLabelFormat={(value) => getHoursString(value)}
            />
          </Styled.SliderContainer>
        </Styled.FiltersContainer>
      )}
      <Styled.FiltersContainer>
        <Styled.Title>{t('flight.duration')}</Styled.Title>
        <Styled.SliderValuesContainer>
          <Styled.SliderValue>{`${tripDuration[0].hours}h ${tripDuration[0].minutes}m`}</Styled.SliderValue>
          <Styled.SliderValue>{`${tripDuration[1].hours}h ${tripDuration[1].minutes}m`}</Styled.SliderValue>
        </Styled.SliderValuesContainer>
        <Styled.SliderContainer>
          <Slider
            defaultValue={
              flightFilters.tripDuration
                ? flightFilters.tripDuration
                : Number(timeRange?.outerRange?.max)
            }
            disabled={isFecthRetrieveProporsals}
            onChange={onTimeFlightHandle}
            min={Number(timeRange?.outerRange?.min)}
            max={Number(timeRange?.outerRange?.max)}
            valueLabelFormat={(e) => {
              const duration = explodeDuration(e);
              return `${duration.hours}h ${duration.minutes}m`;
            }}
          />
        </Styled.SliderContainer>
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
