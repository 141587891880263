import { useGetTransports } from '@/views/FlightsSearch/useGetTransports/useGetTransports';
import dayjs from 'dayjs';
import { useGetParamsInfo } from '../useGetParamsInfo';
import { useStoreHotelDetail } from '@/store/useStoreHotelDetail';

export function useGetFlightInfo() {
  const [retrievePricingProporsals] = useStoreHotelDetail((state) => [
    state.retrievePricingProporsals,
  ]);

  const { destinationId } = useGetParamsInfo();

  const accomodation = retrievePricingProporsals?.proposal.accommodation;

  const amountNights = dayjs(accomodation?.checkout)?.diff(
    accomodation?.checkin,
    'days'
  );

  const destination = retrievePricingProporsals?.staticData?.cities.find(
    (e) => e.id === Number(destinationId)
  );

  const { getDPTransportProposal } = useGetTransports();

  return {
    ...getDPTransportProposal(retrievePricingProporsals),
    amountNights,
    destination,
  };
}
