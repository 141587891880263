import { Button as ButtonAntd } from 'antd';
import styled from '@emotion/styled';

export const Container = styled.a<{ $imgUrl: string }>`
  height: 200px;
  width: 100%;
  background-color: lightgray;
  border-radius: 10px;

  @media (max-width: 768px) {
    min-height: 230px;
    height: unset;
  }

  background-image: url(${({ $imgUrl }) => $imgUrl});
  transition: box-shadow 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

export const InnerContainer = styled.div`
  border-radius: 10px;
  padding: 24px 16px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    min-height: 230px;
    height: unset;
  }
`;

export const Title = styled.span`
  color: white;
  font-size: 32px;
  font-weight: 700;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const Button = styled(ButtonAntd)`
  min-width: 218px;
  padding: 0px 32px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
