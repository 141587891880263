import { Tooltip } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { MultipleAirlinesIcon } from '../../LmnIcons/LmnIcons';
import { TransportLogo, TransportType } from './TransportLogo';
import {
  TransportLogosCompany,
  TransportLogosProps,
} from './TransportLogosTypes';

const ONE_LOGO_SIZE = 40;
const ONE_ICON_SIZE = 24;
const TWO_LOGO_SIZE = 22;
const TWO_ICON_SIZE = 16;
const MAX_LOGOS_COUNT = 2;

export const FullTransportLogos: React.FC<TransportLogosProps> = ({
  isMixedWay,
  companies,
}) => {
  const companyNames = companies.map((company) => company.name);
  const logoSize =
    companies.length < MAX_LOGOS_COUNT ? ONE_LOGO_SIZE : TWO_LOGO_SIZE;
  const iconSize =
    companies.length < MAX_LOGOS_COUNT ? ONE_ICON_SIZE : TWO_ICON_SIZE;
  const showCompanyLogos = companies.length <= MAX_LOGOS_COUNT;

  if (isMixedWay) {
    return (
      <Tooltip title={companyNames.join(', ')} arrow placement="top">
        <LogoWrapper>
          <IconContainer size={TWO_LOGO_SIZE}>
            <isMixedWay.firstIcon size={TWO_ICON_SIZE} color={'white'} />
          </IconContainer>
          <IconContainer size={TWO_LOGO_SIZE}>
            <isMixedWay.secondIcon size={TWO_ICON_SIZE} color={'white'} />
          </IconContainer>
        </LogoWrapper>
      </Tooltip>
    );
  }

  if (showCompanyLogos) {
    return (
      <Tooltip title={companyNames.join(', ')} arrow placement="top">
        <LogoWrapper>
          {companies.map(({ code, type }, index) => (
            <LogoContainer key={`${code}_${index}`}>
              <TransportLogo
                companyCode={code}
                logoSize={logoSize}
                iconSize={iconSize}
              />
            </LogoContainer>
          ))}
        </LogoWrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={companyNames.join(', ')} arrow placement="top">
      <IconContainer size={ONE_LOGO_SIZE}>
        {logoForAllSameCompaniesType(companies, ONE_ICON_SIZE)}
      </IconContainer>
    </Tooltip>
  );
};

const logoForAllSameCompaniesType = (
  companies: TransportLogosCompany[],
  iconSize: number
): JSX.Element => {
  const multipleTransportIconMap = {
    // [TransportType.TRAIN]: <IconTrainline size={iconSize} color={theme.commons.colors.gray800} />,
    [TransportType.FLIGHT]: (
      <MultipleAirlinesIcon style={{ fontSize: iconSize, color: 'gray' }} />
    ),
    // [TransportType.BUS]: <IconBusline size={iconSize} color={theme.commons.colors.gray800} />,
  };
  const logoCompanyTypes = companies.map((company) => company.type);
  let multipleLogoToUse = multipleTransportIconMap[TransportType.FLIGHT];

  if (logoCompanyTypes.every((type) => type === TransportType.TRAIN)) {
    multipleLogoToUse = multipleTransportIconMap[TransportType.TRAIN];
  } else if (logoCompanyTypes.every((type) => type === TransportType.BUS)) {
    multipleLogoToUse = multipleTransportIconMap[TransportType.BUS];
  }

  return multipleLogoToUse;
};

const LogoWrapper = styled.div`
  position: relative;
  height: ${ONE_LOGO_SIZE}px;
  width: 100%;

  > *:last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const LogoContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  line-height: 0;
  border: 1px solid white;
  background-color: white;
  border-radius: 4px;
`;

const IconContainer = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: white;
`;
