import { useTrack } from '@/hooks/useTrack';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import { isEmpty } from '@/utils/isEmpty';
import { Switch } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import { Stop } from './Stop';
import * as Styled from './StopFilters.styled';

export function StopFilters() {
  const {
    flightsStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getFlightsProposals,
    retrieveProporsals,
    flightFilters,
  } = useGetFiltersData();

  const { arrivalCityName, departureCityName, outboundStops, inboundStops } =
    flightsStaticData;

  const { t } = useTranslate();
  const track = useTrack();
  const [onlyDirectRoute, setOnlyDirectRoute] = useState<boolean>(false);

  const onOutboundStopsHandle = (e) => {
    setOnlyDirectRoute(false);

    const outboundStopsUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'outboundStops',
      e.target
    );

    track({
      eventName: 'clicked.filter.outbound-stops-amount',
      logLevel: 'info',
      eventProperties: {
        amount: outboundStopsUpdated,
      },
    });

    getFlightsProposals(searchId, [{ outboundStops: outboundStopsUpdated }]);
  };

  const onInboundStopsHandle = (e) => {
    setOnlyDirectRoute(false);

    const inboundStopsUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'inboundStops',
      e.target
    );

    track({
      eventName: 'clicked.filter.inbound-stops-amount',
      logLevel: 'info',
      eventProperties: {
        amount: inboundStopsUpdated,
      },
    });

    getFlightsProposals(searchId, [{ inboundStops: inboundStopsUpdated }]);
  };

  const onSwitchOnlyDirectRoutes = (e) => {
    setOnlyDirectRoute(true);

    track({
      eventName: 'clicked.filter.direct-stops',
      logLevel: 'info',
    });

    getFlightsProposals(
      searchId,
      e.target.value
        ? [
            {
              outboundStops: ['0'],
            },
            {
              inboundStops: ['0'],
            },
          ]
        : [
            {
              outboundStops: [],
            },
            {
              inboundStops: [],
            },
          ]
    );
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        {' '}
        <Styled.RowContainer>
          <Styled.RowTitle>
            <Styled.Title>
              {t('flight.stops.show_direct_routes_only')}
            </Styled.Title>
          </Styled.RowTitle>
          <Styled.RowSwitch>
            <Switch
              checked={onlyDirectRoute}
              onChange={onSwitchOnlyDirectRoutes}
            />
          </Styled.RowSwitch>
        </Styled.RowContainer>
      </Styled.FiltersContainer>
      <Styled.FiltersContainer>
        <Styled.Title>{t('general.trip.outbound')}</Styled.Title>
        <Styled.Subtitle>
          {departureCityName &&
            arrivalCityName &&
            `${departureCityName} - ${arrivalCityName}`}
        </Styled.Subtitle>

        <Stop
          data={outboundStops}
          onStopHandle={onOutboundStopsHandle}
          disabled={isFecthRetrieveProporsals}
          stopFilter={flightFilters.outboundStops}
        />
      </Styled.FiltersContainer>
      <Styled.FiltersContainer>
        <Styled.Title>{t('general.trip.inbound')}</Styled.Title>
        <Styled.Subtitle>
          {departureCityName &&
            arrivalCityName &&
            `${arrivalCityName} - ${departureCityName}`}
        </Styled.Subtitle>

        <Stop
          data={inboundStops}
          onStopHandle={onInboundStopsHandle}
          disabled={isFecthRetrieveProporsals}
          stopFilter={flightFilters.inboundStops}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
