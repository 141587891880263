import * as Styled from './Skeleton.styled';
import SkeletonMui, { SkeletonProps } from '@mui/material/Skeleton';

function SkeletonUI({ sx, ...props }: SkeletonProps) {
  return (
    <SkeletonMui
      animation="wave"
      variant="rectangular"
      sx={{ borderRadius: '4px', bgcolor: 'rgba(0, 0, 0, 0.06)', ...sx }}
      {...props}
    />
  );
}

export function FiltersSkeleton() {
  return (
    <Styled.Container>
      <Styled.Stack>
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: 'calc(100% - 20px)',
          }}
        />
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: '200px',
          }}
        />
      </Styled.Stack>
      <Styled.Stack>
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: 'calc(100% - 20px)',
          }}
        />
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: '200px',
          }}
        />
      </Styled.Stack>
      <Styled.Stack>
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: 'calc(100% - 20px)',
          }}
        />
        <SkeletonUI
          height={15}
          sx={{
            marginBottom: '16px',
            width: '200px',
          }}
        />
      </Styled.Stack>
    </Styled.Container>
  );
}

export function Skeleton() {
  return (
    <Styled.ContainerSkeletonList>
      <FiltersSkeleton />
      <FiltersSkeleton />
    </Styled.ContainerSkeletonList>
  );
}
