import { InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

export const Container = styled.div`
  @media (min-width: 768px) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: -16px;
      bottom: 0px;
      width: 16px;
      box-shadow:
        rgba(0, 0, 0, 0.04) -16px 0px 20px -16px inset,
        rgba(0, 0, 0, 0.04) -16px 0px 10px -16px inset,
        rgba(0, 0, 0, 0.02) -16px 0px 4px -16px inset;
    }
  }

  @media (min-width: 768px) {
    width: 180px;
  }

  @media (min-width: 1440px) {
    width: 200px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  padding: 16px;
`;

export const ProductType = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: #3c3c3c;
`;

export const OfferPrice = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #f2007d;
  line-height: 26px;
`;

export const OriginalPrice = styled.span`
  font-style: normal;
  display: inline;
  height: 20px;
  text-decoration-line: line-through;
  text-decoration-style: solid;
  text-decoration-color: #f2007d;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 0px;
  text-decoration-thickness: 2px;
  color: #74747c;
  margin-right: 8px;
`;

export const PriceUnity = styled.span`
  font-size: 12px;
  color: #f2007d;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const ExtraFeeInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #74747c;
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
`;

export const IconCircleInfo = styled(InfoCircleOutlined)`
  margin-left: 4px;
`;

export const TotalPrice = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  text-align: right;
  font-weight: 700;
  letter-spacing: 0.01em;
`;

export const TopSecretLabel = styled.div`
  border-radius: 4px;
  min-height: 28px;
  padding: 2px 8px 2px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/tsh_card.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 14px;
  background-color: #65277f;
  color: #fff;
  font-weight: 700;
`;

export const LastMinuteLabel = styled.div`
  border-radius: 4px;
  min-height: 28px;
  padding: 2px 8px 2px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/last_minute_deals.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 14px;
  background-color: #f2007d;
  color: #fff;
  font-weight: 700;
`;

export const LabelsContainer = styled.div`
  max-width: 100%;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;

  @media (max-width: 1190px) {
    flex-flow: wrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const ExtraMessageLabel = styled.span`
  margin-right: 4;
  line-height: 16px;
`;

export const ContainerTag = styled.div`
  position: absolute;
  top: 16px;
`;
