import {
  BreakFastIcon,
  FreeCancellationIcon,
} from '@/components/LmnIcons/LmnIcons';
import { Amenity, MealPlan } from '@/services/codegen-romulo';
import { CheckOutlined } from '@ant-design/icons';

export type Facility = Amenity & {
  label: string;
  icon?: React.JSX.Element;
};

export type GetFacilitiesOrdered = {
  amenities: Amenity[];
  hasFreeCancellation: boolean;
  mealPlan: MealPlan;
};

const MEAL_PLAN_ID = {
  ACCOMODATION_AND_BREAKFAST: 'hotel.meal_plans.breakfast',
  HALF_BOARD: 'hotel.meal_plans.half_board',
  ALL_INCLUSIVE: 'hotel.meal_plans.all_inclusive',
};

const ORDER_FACILITIES = [
  'SEAFRONT',
  'SPA',
  'POOL',
  'PARKING',
  'FREE_WIFI',
  'FACILITIES_CHILDREN',
  'ADULTS_ONLY',
  'ENTERTAINMENT',
  'PETS_ALLOWED',
  'KITCHEN',
];

const FREE_CANCELLATION_LABEL = 'filter.title.free_cancellation';

export function getFacilitiesOrdered({
  amenities,
  hasFreeCancellation,
  mealPlan,
}: GetFacilitiesOrdered) {
  if (amenities) {
    const facilitiesNotIncludedInNewOrder = amenities?.filter(
      (e) => !ORDER_FACILITIES.includes(e.id)
    );

    const facilitiesIncludedOrdered = ORDER_FACILITIES.map((item) =>
      amenities.find((facility) => facility?.id === item)
    );

    const facilityFreeCancellation = hasFreeCancellation
      ? { label: FREE_CANCELLATION_LABEL, icon: <FreeCancellationIcon /> }
      : null;

    const facilityMealPlan = MEAL_PLAN_ID[mealPlan.id]
      ? { label: MEAL_PLAN_ID[mealPlan.id], icon: <BreakFastIcon /> }
      : null;

    return [
      facilityMealPlan,
      facilityFreeCancellation,
      ...facilitiesIncludedOrdered,
      ...facilitiesNotIncludedInNewOrder,
    ]
      .filter((facility) => Boolean(facility))
      .map((facility: Facility) => ({
        icon: facility.icon ?? <CheckOutlined />,
        label: facility.label,
      }));
  }

  return null;
}
