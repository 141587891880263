import { useStore } from '@/store/useStore';
import countries from '@/utils/dictionaries/countriesWithISO.json';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';

interface NationalityFieldProps {
  source: 'CONTACT' | 'TRAVELLER';
  hasError: boolean;
  value: string | number;
  handleChange: (
    event: SelectChangeEvent<string | number>,
    child: React.ReactNode
  ) => void;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const NationalityField: FC<NationalityFieldProps> = ({
  source,
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();
  const brandConfig = useStore((state) => state.brandConfig);
  const { language } = brandConfig;
  return (
    <FormControl variant="filled">
      <InputLabel id="nationality-select-label">
        {source === 'CONTACT'
          ? t('form.contact.country')
          : t('form.contact.nationality')}
      </InputLabel>
      <Select
        labelId="nationality-select-label"
        name={'nationality'}
        error={hasError}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        disabled={disabled}
      >
        {countries.map((e, index) => (
          <MenuItem
            key={`${e.countryCode}_nationality_${index}`}
            value={e.countryCode}
          >
            {e.label[language]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NationalityField;
