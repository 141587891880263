import styled from '@emotion/styled';
import { LoadingButton as LoadingButtonMui } from '@mui/lab';
import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

export const RoomContainer = styled.div`
  display: flex;

  @media (max-width: 1280px) {
    background-color: white;
    flex-direction: column;
    padding: 0px;
    border-radius: 8px;
  }
`;
export const RoomSummaryContainer = styled.div`
  display: flex;

  @media (max-width: 1280px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const RoomDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 0px 16px;

    & > div:first-child {
      width: 100% !important;
      min-width: 0px;
    }

    & > div:nth-of-type(3) {
      width: 100% !important;
    }
  }

  @media (min-width: 1280px) {
    padding-bottom: 32px;
  }

  & > div:first-child {
    width: 60%;
    min-width: 0px;
  }
  & > div:nth-of-type(3) {
    width: 40%;
  }
`;
export const SelectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
export const RoomSummaryMainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const RoomMainImage = styled.div`
  width: 280px;
  height: 140px;
  position: relative;
  cursor: pointer;
  background-color: green;
  border-radius: 8px;

  @media (max-width: 1280px) {
    width: 100%;
    height: 300px;
  }
`;

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1280px) {
    padding: 0px 16px;
  }
`;

export const RoomTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`;

export const FacilitiesList = styled.ul`
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
`;

export const Facility = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #74747c;
  & svg {
    font-size: 16px;
    opacity: 0.75;
  }
`;

export const ShowDetailsButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
  padding: 0px;
  border: unset;
  background: unset;
  width: fit-content;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;

  @media (max-width: 744px) {
    width: 100%;
  }
`;

export const SelectorTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

export const OptionSelector = styled(MuiRadioGroup)`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid #eaeaeb;
`;

export const Option = styled(MuiFormControlLabel)`
  padding: 12px 0px;
  margin: 0px;

  & .MuiTypography-root {
    width: 100%;
  }

  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;

  & .MuiRadio-root {
    padding: 0px;
    margin-right: 8px;
    width: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaeb;
  }
`;

export const LabelPrice = styled.span`
  font-weight: 700;
`;
export const SecondLine = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;
export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LabelTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: auto;
  margin: 0px 24px;
  border-left: 1px solid #eaeaeb;

  @media (max-width: 1280px) {
    width: calc(100% - 32px);
    height: 0px;
    margin: 16px 16px;
    border-left: none;
    border-bottom: 1px solid #eaeaeb;
  }
`;
export const HorizontalDivider = styled.div`
  width: 100%;
  margin: 24px 0px;
  border-bottom: 1px solid #eaeaeb;

  @media (max-width: 1280px) {
    width: calc(100%);
    margin: 16px 0px;
  }
`;
export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    margin-bottom: 16px;
  }
`;

export const LoadingButton = styled(LoadingButtonMui)`
  width: 100%;
  // @media (min-width: 768px) {
  //   width: 279px;
  // }
`;
