import {
  TransportProposalUpdated,
  TransportProvider,
} from '@/services/codegen-romulo';
import { ProposalTransportWay } from '../types/ProposalTransportWay';

export const transportProposalToWayDetails = (
  transport: any,
  airports: Record<string, TransportProvider>
): {
  outbound: ProposalTransportWay;
  inbound: ProposalTransportWay;
} => {
  const outbound = transport.transport.outbound;
  const inbound = transport.transport.inbound;

  const outboundMapped: ProposalTransportWay = {
    arrivalId: outbound.arrivalId,
    departureId: outbound.departureId,
    durationInMinutes: outbound.duration ?? outbound.durationInMinutes,
    handBaggageIncluded: outbound.segments[0].handBaggageIncluded,
    baggageIncluded: outbound.segments[0].baggageIncluded,
    segments: outbound.segments.map((se) => ({
      terminal: se.departureTerminal,
      departureId: se.departureId,
      isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
      arrivalId: se.arrivalId,
      isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
      classAvailability: se.classAvailability,
      supplier: {
        id: se.supplier?.id,
        carrierId: se.supplier?.carrierId,
        operatorId: se.supplir?.operatorId,
      },
      technicalStops: se.technicalStops.map((te) => {
        const hub = airports[te.locationId];
        return {
          locationId: te.locationId,
          isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
          isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
          hub: {
            id: te.locationId,
            label: hub?.label,
          },
        };
      }),
    })),
  };

  if (inbound) {
    const inboundMapped: ProposalTransportWay = {
      arrivalId: inbound.arrivalId,
      departureId: inbound.departureId,
      durationInMinutes: inbound.duration ?? inbound.durationInMinutes,
      handBaggageIncluded: inbound.segments[0].handBaggageIncluded,
      baggageIncluded: inbound.segments[0].baggageIncluded,
      segments: inbound.segments.map((se) => ({
        terminal: se.departureTerminal,
        departureId: se.departureId,
        isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
        arrivalId: se.arrivalId,
        isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
        classAvailability: se.classAvailability,
        supplier: {
          id: se.supplier.id,
          carrierId: se.supplier?.carrierId,
          operatorId: se.supplir?.operatorId,
        },
        technicalStops: se.technicalStops.map((te) => {
          const hub = airports[te.locationId];
          return {
            locationId: te.locationId,
            isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
            isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
            hub: {
              id: te.locationId,
              label: hub?.label,
            },
          };
        }),
      })),
    };
    return {
      outbound: outboundMapped,
      inbound: inboundMapped,
    };
  }

  return {
    outbound: outboundMapped,
    inbound: undefined,
  };
};

export const transportProposalToWayDetailsAdapter = (
  el: TransportProposalUpdated,
  airports: Record<string, TransportProvider>
): {
  outbound: ProposalTransportWay;
  inbound: ProposalTransportWay;
} => {
  const outbound = el.transport.ways[0];
  const inbound = el.transport.ways[1];

  const outboundMapped: ProposalTransportWay = {
    arrivalId: outbound.arrivalId,
    departureId: outbound.departureId,
    durationInMinutes: outbound.duration,
    handBaggageIncluded: outbound.segments[0].handBaggageIncluded,
    baggageIncluded: outbound.segments[0].baggageIncluded,
    price: {
      adult: {
        fare: outbound.wayPrice.adult.fare,
        tax: outbound.wayPrice.adult.tax,
      },
      child: {
        fare: outbound.wayPrice.child.fare,
        tax: outbound.wayPrice.child.tax,
      },
      infant: {
        fare: outbound.wayPrice.infant.fare,
        tax: outbound.wayPrice.infant.tax,
      },
    },
    segments: outbound.segments.map((se) => ({
      terminal: se.departureTerminal,
      departureId: se.departureId,
      isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
      arrivalId: se.arrivalId,
      isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
      classAvailability: se.classAvailability,
      supplier: {
        id: se.supplier.carrierId,
        carrierId: se.flightNumber,
      },
      technicalStops: se.technicalStops.map((te) => {
        const hub = airports[te.locationId];
        return {
          locationId: te.locationId,
          isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
          isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
          hub: {
            id: te.locationId,
            label: hub?.label,
          },
        };
      }),
    })),
  };

  if (inbound) {
    const inboundMapped: ProposalTransportWay = {
      arrivalId: inbound.arrivalId,
      departureId: inbound.departureId,
      durationInMinutes: inbound.duration,
      handBaggageIncluded: inbound.segments[0].handBaggageIncluded,
      baggageIncluded: inbound.segments[0].baggageIncluded,
      price: {
        adult: {
          fare: inbound.wayPrice.adult.fare,
          tax: inbound.wayPrice.adult.tax,
        },
        child: {
          fare: inbound.wayPrice.child.fare,
          tax: inbound.wayPrice.child.tax,
        },
        infant: {
          fare: inbound.wayPrice.infant.fare,
          tax: inbound.wayPrice.infant.tax,
        },
      },
      segments: inbound.segments.map((se) => ({
        terminal: se.departureTerminal,
        departureId: se.departureId,
        isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
        arrivalId: se.arrivalId,
        isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
        classAvailability: se.classAvailability,
        supplier: {
          id: se.supplier.carrierId,
          carrierId: se.flightNumber,
        },
        technicalStops: se.technicalStops.map((te) => {
          const hub = airports[te.locationId];
          return {
            locationId: te.locationId,
            isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
            isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
            hub: {
              id: te.locationId,
              label: hub?.label,
            },
          };
        }),
      })),
    };
    return {
      outbound: outboundMapped,
      inbound: inboundMapped,
    };
  }

  return {
    outbound: outboundMapped,
    inbound: undefined,
  };
};
