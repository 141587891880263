import { Alert as AlertAntd } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  border-radius: 8px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #fff;
  cursor: pointer;
  // TO DELETE
  letter-spacing: 0px;
  font-family: Open Sans;

  & > :hover {
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }
`;

export const InnerContainer = styled.div`
  display: grid;
  row-gap: 8px;
  grid-template-columns: 1fr 1px 1fr;
  align-items: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 0px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const Divider = styled.div`
  @media (min-width: 768px) {
    width: 1px;
    height: 100%;
    top: 12px;
    bottom: 12px;
    background: #eaeaeb;
  }
`;

export const TransportContainer = styled.div`
  padding-bottom: 0px;
  padding-right: 16px;
  padding-left: 10px;
`;

export const Date = styled.span`
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #babac0;
  text-transform: uppercase;
`;

export const Alert = styled(AlertAntd)<{ $withoutBorder?: boolean }>`
  background-color: #e3e3e3 !important;
  ${({ $withoutBorder }) =>
    $withoutBorder
      ? css`
          border: 1px solid #e3e3e3 !important;
        `
      : css`
          border: 1px solid rgba(128, 128, 128, 0.24) !important;
        `};
  & span {
    color: rgba(128, 128, 128, 1) !important;
  }
`;

export const AlertContainer = styled.div`
  margin: 8px 0;
`;
