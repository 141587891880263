import { useStore } from '@/store/useStore';
import { useEffect } from 'react';
import { useContactDetailsStore } from './useContactDetailsStore';

export interface ContactDetailsValues {
  name: string;
  surname: string;
  fiscalCode: string;
  email: string;
  isTraveller: string;
  gender: string;
  phoneNumber: string;
  phoneIsoCode: string;
  addressCity: string;
  addressPostalCode: string;
  addressCountryIsoCode: string;
  addressStreetName: string;
  addressStreetNumber: string;
  nationality: string;
  dateOfBirth: string;
  documentType: string;
  documentExpirationDate: string;
  documentNumber: string;
  documentEmissionDate: string;
  documentEmissionCountry: string;
}

export const useContactDetailsDefaultValues = () => {
  const [brandConfig, cartConfig] = useStore((state) => [
    state.brandConfig,
    state.cartConfig,
  ]);

  const [setContactDetails] = useContactDetailsStore(
    ({ setContactDetails }) => [setContactDetails]
  );

  const requiredDetails = cartConfig?.contact?.requiredDetails;
  const { country } = brandConfig;

  const setContactDetailsDefaultValues = () => {
    if (requiredDetails) {
      let keys = {} as ContactDetailsValues;
      requiredDetails?.forEach((e) => {
        switch (e) {
          case 'NAME':
            keys = {
              ...keys,
              name: sessionStorage.getItem('formData.name') || '',
            };
            break;
          case 'SURNAME':
            keys = {
              ...keys,
              surname: sessionStorage.getItem('formData.surname') || '',
            };
            break;
          case 'EMAIL':
            keys = {
              ...keys,
              email: sessionStorage.getItem('formData.email') || '',
            };
            break;
          case 'ADDRESS':
            keys = {
              ...keys,
              addressStreetName:
                sessionStorage.getItem('formData.addressStreetName') || '',
            };
            break;
          case 'STREET_NUMBER':
            keys = {
              ...keys,
              addressStreetNumber:
                sessionStorage.getItem('formData.addressStreetNumber') || '',
            };
            break;
          case 'POSTAL_CODE':
            keys = {
              ...keys,
              addressPostalCode:
                sessionStorage.getItem('formData.addressPostalCode') || '',
            };
            break;
          case 'CITY':
            keys = {
              ...keys,
              addressCity: sessionStorage.getItem('formData.addressCity') || '',
            };
            break;
          case 'PHONE':
            keys = {
              ...keys,
              phoneNumber: sessionStorage.getItem('formData.phoneNumber') || '',
              phoneIsoCode:
                sessionStorage.getItem('formData.phoneIsoCode') ||
                country ||
                '',
            };
            break;
          case 'NOT_GUEST':
            keys = {
              ...keys,
              isTraveller: sessionStorage.getItem('formData.isTraveller') || '',
            };
            break;
          case 'NATIONALITY':
            keys = {
              ...keys,
              nationality:
                sessionStorage.getItem('formData.nationality') || country || '',
            };
            break;
          case 'BIRTH_DATE':
            keys = {
              ...keys,
              dateOfBirth: sessionStorage.getItem('formData.dateOfBirth') || '',
            };
            break;
          case 'GENDER':
            keys = {
              ...keys,
              gender: sessionStorage.getItem('formData.gender') || '',
            };
            break;
          case 'FISCAL_CODE':
            keys = {
              ...keys,
              fiscalCode: sessionStorage.getItem('formData.fiscalCode') || '',
            };
            break;
          case 'DOCUMENT_TYPE':
            keys = {
              ...keys,
              documentType:
                sessionStorage.getItem('formData.documentType') || '',
            };
            break;
          case 'DOCUMENT_NUMBER':
            keys = {
              ...keys,
              documentNumber:
                sessionStorage.getItem('formData.documentNumber') || '',
            };
            break;
          case 'EMISSION_COUNTRY':
            keys = {
              ...keys,
              documentEmissionCountry:
                sessionStorage.getItem('formData.documentEmissionCountry') ||
                '',
            };
            break;
          case 'EXPIRATION_DATE':
            keys = {
              ...keys,
              documentEmissionDate:
                sessionStorage.getItem('formData.documentExpirationDate') || '',
            };
            break;
          case 'EMISSION_DATE':
            keys = {
              ...keys,
              documentExpirationDate:
                sessionStorage.getItem('formData.documentEmissionDate') || '',
            };
            break;
          default:
            break;
        }
      });

      setContactDetails(keys);
    }
  };

  useEffect(() => {
    setContactDetailsDefaultValues();
  }, [requiredDetails]);
};
