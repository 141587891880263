import { ResultElementProps } from '@/components/Search/components/LocationSelector/components/SearchInput/ResultElement';
import { SearchInputDto, TravellerGroup } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { Dayjs } from 'dayjs';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { BookingType, useBookingType } from './useBookingType';
import { useGetParam } from './useGetParam';
import { useTrack } from './useTrack';

const getDestination = (destination: ResultElementProps) => {
  const DESTINATION_VALUES = {
    Tag: { tag: destination.id },
    City: { cities: [parseInt(destination.id, 10)] },
    Hotel: { hotelids: [parseInt(destination.id, 10)] },
  };

  return DESTINATION_VALUES[destination.type] ?? undefined;
};

export function useCreateSearch() {
  const navigate = useNavigate();
  const track = useTrack();
  const bookingType = useBookingType();
  const getParam = useGetParam();
  const [brandConfig, createSearchId, createFlightSearchId] = useStore(
    (store) => [
      store.brandConfig,
      store.createSearchId,
      store.createFlightSearchId,
    ]
  );
  const {
    businessProfileIdDP,
    businessProfileIdF,
    businessProfileIdH,
    hasAuthentication,
  } = brandConfig.brand;

  const currency = brandConfig.brand.currency || 'EUR';

  const getSearchId = async ({
    departure,
    destination,
    dateRange,
    withHotel,
    travellerGroups,
  }: {
    departure: ResultElementProps;
    destination: ResultElementProps;
    dateRange: {
      from?: Dayjs;
      to?: Dayjs;
    };
    withHotel: boolean;
    travellerGroups: TravellerGroup[];
  }) => {
    const startDate = dateRange.from?.format('YYYY-MM-DD');

    const endDate = dateRange.to?.format('YYYY-MM-DD');

    switch (bookingType) {
      case BookingType.FlightAndHotel:
        const flightHotelSearchRequest: SearchInputDto = {
          businessProfileId: businessProfileIdDP,
          departure: {
            airports: [departure.code],
          },
          destination: getDestination(destination),
          interval: {
            start: {
              date: startDate,
            },
            end: {
              date: endDate,
            },
          },
          travellerGroups,
          currency,
        };

        return await createSearchId(flightHotelSearchRequest);

      case BookingType.Hotel:
        const hotelSearchRequest = {
          businessProfileId: businessProfileIdH,
          departure: {},
          destination: getDestination(destination),
          interval: {
            start: {
              date: startDate,
            },
            end: {
              date: endDate,
            },
          },
          travellerGroups,
          currency,
        };
        return await createSearchId(hotelSearchRequest);

      case BookingType.Flight:
        const adultsCount = travellerGroups.reduce((acc, curr) => {
          acc = acc + curr.adults;
          return acc;
        }, 0);

        const minorsCount = travellerGroups.reduce((acc, curr) => {
          acc = acc.concat(curr.childrenAges);
          return acc;
        }, []);

        const childrenCount = minorsCount.filter((e) => e >= 2).length;
        const infantsCount = minorsCount.filter((e) => e < 2).length;
        const flightSearchRequest = {
          businessProfile: businessProfileIdF,
          departure: departure?.code,
          arrival: destination?.code,
          interval: {
            start: startDate,
            end: endDate,
          },
          travellers: {
            adults: `${adultsCount}`,
            children: `${childrenCount}`,
            infants: `${infantsCount}`,
          },
          currency,
        };
        return await createFlightSearchId(flightSearchRequest);

      default:
        return undefined;
    }
  };

  const createSearch = async ({
    departure,
    destination,
    dateRange,
    withHotel,
    travellerGroups,
  }: {
    departure: ResultElementProps;
    destination: ResultElementProps;
    dateRange: {
      from?: Dayjs;
      to?: Dayjs;
    };
    withHotel: boolean;
    travellerGroups: TravellerGroup[];
  }) => {
    const searchId = await getSearchId({
      departure,
      destination,
      dateRange,
      withHotel,
      travellerGroups,
    }).then((e) => {
      track({
        eventName: 'created.search',
        logLevel: 'info',
      });

      return e;
    });

    const adultsCount = travellerGroups.reduce((acc, curr) => {
      acc = acc + curr.adults;
      return acc;
    }, 0);

    const childrenCount = travellerGroups.reduce((acc, curr) => {
      acc = acc.concat(curr.childrenAges);
      return acc;
    }, []);

    const product = getParam('product');

    const token = hasAuthentication ? { token: getParam('a') } : undefined;

    const navigateQueryParams = {
      ...token,
      product,
      searchId: searchId,
      departure: departure ? departure.id.toLocaleLowerCase() : null,
      departureName: departure ? departure.location || departure.name : null,
      destinationId: destination.code.toLocaleLowerCase(),
      destinationName: destination.location || destination.name,
      outboundDate: dateRange.from?.format('YYYYMMDD'),
      returnDate: dateRange.to?.format('YYYYMMDD'),
      adults: String(adultsCount),
      childrenAges: childrenCount,
    };

    return new Promise(
      (
        resolve // TODO Check if this solution is necessary
      ) =>
        setTimeout(
          () => {
            navigate({
              pathname: `/${product}`,
              search: `?${createSearchParams({
                ...navigateQueryParams,
              })}`,
            });
            resolve;
          },
          product === 'F' ? 5000 : 0
        )
    );
  };

  return createSearch;
}
