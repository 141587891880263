import { create } from 'zustand';

export type LuggageOption = {
  id: string;
  compositeId: {
    productId: string;
    reservationId: string;
    code: string;
  };
  label: string;
  price: {
    fare: {
      currency: string;
      value: number;
      scale: number;
    };
    markup: {
      currency: string;
      value: number;
      scale: number;
    };
    total: {
      currency: string;
      value: number;
      scale: number;
    };
  };
  additionalInfo: {
    totalWeight: number;
    numberOfPieces: number;
    weightUnitOfMeasure: string;
  };
};

interface LuggageStore {
  luggageSelected: {
    [travellerId: number]: { [direction: number]: LuggageOption };
  };
  setLuggageSelected: (
    travellerId: number,
    details: { direction: number; luggageOption: LuggageOption }
  ) => void;
}

export const useLuggageStore = create<LuggageStore>((set, get) => ({
  luggageSelected: {},
  setLuggageSelected: (
    travellerId: number,
    details: { direction: number; luggageOption: LuggageOption }
  ) => {
    const currentLuggage = get().luggageSelected[travellerId];

    set({
      luggageSelected: {
        [travellerId]: {
          ...currentLuggage,
          [details.direction]: details.luggageOption,
        },
      },
    });
  },
}));
