export const filtersTabs = [
  {
    key: 'RECOMMENDED',
    label: 'filter.sort.best',
  },
  {
    key: 'DURATION',
    label: 'filter.sort.fastest',
  },
  {
    key: 'PRICE',
    label: 'filter.sort.cheapest',
  },
];
