import * as Styled from './MealPlansFilters.styled';
import { useStore } from '@/store/useStore';
import { MealPlanProps } from './MealPlansFilters.types';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { MealPlanFullInfo } from './MealPlansFilters';

export function MealPlans({
  mealPlansFullInfoState,
  onMealPlanHandle,
}: MealPlanProps) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const [mealPlansFullInfo, setMealPlansFullInfo] = mealPlansFullInfoState;

  const [filterChanged, setFilterChanged] = useState<string>(undefined);

  const { t } = useTranslate();

  useEffect(() => {
    console.log('mealPlansFullInfo', mealPlansFullInfo);
    const newMealPlans = mealPlansFullInfo
      .map((plan) => plan.active && plan.selected && plan.code)
      .filter((item) => item);

    filterChanged &&
      onMealPlanHandle(
        newMealPlans[0] === 'ALL_MEAL_PLANS'
          ? mealPlansFullInfo.map((item) => item.code)
          : newMealPlans
      );
  }, [filterChanged]);

  const handleOnClick = (clickedMealPlan: string) => {
    setFilterChanged(clickedMealPlan);
    setMealPlansFullInfo((prevItems) => {
      const newState = prevItems.map((item) =>
        clickedMealPlan === 'ALL_MEAL_PLANS'
          ? { ...item, selected: item.code === 'ALL_MEAL_PLANS' }
          : item.code === 'ALL_MEAL_PLANS'
            ? { ...item, selected: false }
            : item.code === clickedMealPlan
              ? { ...item, selected: !item.selected }
              : item
      );

      const allUnselected = newState.every((item) => !item.selected);
      return allUnselected
        ? [{ ...newState[0], selected: true }, ...newState.slice(1)]
        : newState;
    });
  };

  return (
    <Styled.FilterOptionsContainer>
      <Styled.MealPlansContainer>
        {mealPlansFullInfo?.map((plan, index) => {
          return (
            <Styled.MealPlan
              key={index}
              onClick={() => handleOnClick(plan.code)}
            >
              <Styled.IconCircle
                $selected={plan.selected}
                $disabled={!plan.active}
                $theme={brandConfig.theme}
              >
                <Styled.IconImg>{plan.icon}</Styled.IconImg>
              </Styled.IconCircle>
              <span>{t(plan.name)}</span>
              <br />
              <span>({plan.metadata.count})</span>
            </Styled.MealPlan>
          );
        })}
      </Styled.MealPlansContainer>
    </Styled.FilterOptionsContainer>
  );
}
