import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useGetParam } from '@/hooks/useGetParam';
import { useStore } from '@/store/useStore';
import { explodeDuration, hoursToMinutes } from '@/utils/timeUtils';
import { useTranslate } from '@tolgee/react';

const OUTBOUND_DEPARTURE_LOCATION = 'outboundDepartureLocation';

const OUTBOUND_ARRIVAL_LOCATION = 'outboundArrivalLocation';

const INBOUND_DEPARTURE_LOCATION = 'inboundDepartureLocation';

const INBOUND_ARRIVAL_LOCATION = 'inboundArrivalLocation';

const OUTBOUND_DEPARTURE_TIME = 'outboundDepartureTime';

const INBOUND_DEPARTURE_TIME = 'inboundDepartureTime';

const TRIP_DURATION = 'tripDuration';

const FLIGHT_BUDGET = 'price';

const HDP_BUDGET = 'price-range';

const OUTBOUND_STOPS = 'outboundStops';

const INBOUND_STOPS = 'inboundStops';

const INCLUSIVE_AIRLINES = 'inclusiveProviders';

const DEALS = 'promotion';

const PROPERTY_TYPES = 'property-type';

const STARS = 'stars';

const MEAL_PLANS = 'meal-plan';

const RATING = 'opinion-rating';

const AMENITIES = 'amenities';

const CHARACTERISTIC = 'characteristics';

export function useGetFiltersData() {
  const [
    retrieveProporsals,
    isFecthRetrieveProporsals,
    getFlightsProposals,
    getHDPProposals,
    currentSearchId,
    flightFilters,
    hdpFilters,
  ] = useStore((state) => [
    state.retrieveProporsals,
    state.isFecthRetrieveProporsals,
    state.getFlightsProposals,
    state.getHDPProposals,
    state.currentSearchId,
    state.flightFilters,
    state.hdpFilters,
  ]);

  const bookingType = useBookingType();

  const { t } = useTranslate();

  const getParam = useGetParam();

  const departureCityName = getParam('departureName');

  const arrivalCityName = getParam('destinationName');

  const searchId = currentSearchId ?? getParam('searchId');

  const airportStaticData = retrieveProporsals?.staticData?.airports;

  const airlinesStaticData = retrieveProporsals?.staticData?.providers;

  const facets = retrieveProporsals?.facets;

  const getFacet = (key: string) => {
    return facets?.value?.find((e) => e.id === key);
  };

  // Airports

  const outboundDepartures = getFacet(OUTBOUND_DEPARTURE_LOCATION)?.items;

  const outboundArrival = getFacet(OUTBOUND_ARRIVAL_LOCATION)?.items;

  const inboundDepartures = getFacet(INBOUND_DEPARTURE_LOCATION)?.items;

  const inboundArrival = getFacet(INBOUND_ARRIVAL_LOCATION)?.items;

  // Departure time - Outbound

  const outboundDepartureTimeData = getFacet(OUTBOUND_DEPARTURE_TIME)
    ?.ranges[0];

  const outboundMin = hoursToMinutes(
    outboundDepartureTimeData?.outerRange?.min
  );

  const outboundMax = hoursToMinutes(
    outboundDepartureTimeData?.outerRange?.max
  );

  const outboundMinFormatted = () => {
    const duration = explodeDuration(outboundMin);
    return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
      duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
    }`;
  };

  const outboundMaxFormatted = () => {
    const duration = explodeDuration(outboundMax);
    return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
      duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
    }`;
  };

  const outboundDepartureTimeRange = [outboundMin, outboundMax];

  const outboundDepartureTimeRangeFormatted = [
    outboundMinFormatted(),
    outboundMaxFormatted(),
  ];

  // Departure time - Inbound

  const inboundDepartureTimeData = getFacet(INBOUND_DEPARTURE_TIME)?.ranges[0];

  const inboundMin = hoursToMinutes(inboundDepartureTimeData?.outerRange?.min);

  const inboundMax = hoursToMinutes(inboundDepartureTimeData?.outerRange?.max);

  const inboundMinFormatted = () => {
    const duration = explodeDuration(inboundMin);
    return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
      duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
    }`;
  };

  const inboundMaxFormatted = () => {
    const duration = explodeDuration(inboundMax);
    return `${duration.hours < 10 ? `0${duration.hours}` : duration.hours}:${
      duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
    }`;
  };

  const inboundDepartureTimeRange = [inboundMin, inboundMax];

  const inboundDepartureTimeRangeFormatted = [
    inboundMinFormatted(),
    inboundMaxFormatted(),
  ];

  // Departure time - Duration

  const timeRange = getFacet(TRIP_DURATION)?.ranges[0];

  const minTime = explodeDuration(Number(timeRange?.outerRange.min));

  const maxTime = explodeDuration(
    (flightFilters?.connectionTime && flightFilters?.connectionTime) ||
      Number(timeRange?.outerRange.max)
  );

  const tripDuration = [minTime, maxTime];

  // Budget

  const getBudget = () => {
    if (bookingType === BookingType.Flight) {
      const budgetRange = getFacet(FLIGHT_BUDGET)?.ranges[0].outerRange;
      return budgetRange
        ? [budgetRange.min.total, budgetRange.max.total]
        : [undefined, undefined];
    } else {
      const budgetRangeString = getFacet(HDP_BUDGET)?.items[0].code;
      const budgetRangeArray = budgetRangeString?.split('-');
      return budgetRangeString
        ? [Number(budgetRangeArray[0]), Number(budgetRangeArray[1])]
        : [undefined, undefined];
    }
  };

  const budgetRange = getBudget();

  const currency = retrieveProporsals?.priceInfo?.currency || 'EUR';

  // Stops

  const getStopsData = (key: string) => {
    const stopFacets = getFacet(key)?.items;
    return stopFacets
      ?.map((e) => {
        return {
          code: e.code,
          disabled: e.active ? false : true,
          label:
            e.code === '0'
              ? t('flight.stops.direct')
              : e.code === '1'
                ? `${e.code} ${t('flight.stops.stop')}`
                : t('fligt.stops.two_or_more'),
        };
      })
      .sort((a, b) => a.code - b.code);
  };

  const outboundStops = getStopsData(OUTBOUND_STOPS);
  const inboundStops = getStopsData(INBOUND_STOPS);

  // Airlines

  const inclusiveAirlines = getFacet(INCLUSIVE_AIRLINES)?.items;

  // Deals

  /*const getDeals = (key: string) => {
    return facets?.value?.find((e) => e.id === key)?.items;
  };

  const deals = getDeals(DEALS);*/

  // Property types

  const propertyTypes = getFacet(PROPERTY_TYPES)?.items;

  // Stars

  const getStarsData = () => {
    const stars = getFacet(STARS)?.items;
    const starsFormatted = stars
      ?.filter((e) => e.code !== 'OTHER' && e.code !== '0')
      .sort((a, b) => Number(b.code) - Number(a.code));

    return starsFormatted;
  };

  const stars = getStarsData();

  // Meal plans

  const getMealPlansData = () => {
    const mealPlans = getFacet(MEAL_PLANS)?.items;

    return mealPlans;
  };

  const mealPlans = getMealPlansData();

  // Rating

  const getRatings = () => {
    const rating = getFacet(RATING)?.items;
    const ratingSort = ['EXCELLENT', 'VERY_GOOD', 'GOOD', 'PLEASANT'];

    const ratingSorted = ratingSort
      .map((item) => rating?.find((i) => i.code === item))
      .filter((item) => item);

    return ratingSorted;
  };

  const rating = getRatings();

  // Amenities

  const getAmenities = () => {
    const amenities = getFacet(AMENITIES)?.items;

    return amenities?.filter((item) => item.code !== 'NONE');
  };
  const amenities = getAmenities();

  // Characteristic

  const getCharacteristics = () => {
    const characteristic = getFacet(CHARACTERISTIC)?.items;

    return characteristic?.filter((item) => item.code !== 'NONE');
  };
  const characteristic = getCharacteristics();

  // Reset flight filters

  const resetAllFlightFilters = () => {
    const newFilters = [
      {
        budgetRange,
        outboundStops: [],
        inboundStops: [],
        luggageOptions: [],
        outboundDepartureTime: outboundDepartureTimeRange,
        inboundDepartureTime: inboundDepartureTimeRange,
        tripDuration: timeRange?.outerRange.max,
        outboundDepartureLocation: [],
        outboundArrivalLocation: [],
        inboundDepartureLocation: [],
        inboundArrivalLocation: [],
        airlines: [],
      },
    ];

    getFlightsProposals(searchId, newFilters);
  };

  // Reset hdp filters

  const resetAllHDPFilters = () => {
    const newFilters = [
      {
        amenities: [],
        budgetRange,
        characteristics: [],
        freeCancellation: [],
        mealPlan: [],
        propertyType: [],
        rating: [],
        stars: [],
      },
    ];

    getHDPProposals(searchId, newFilters);
  };

  const flightsStaticData = {
    airportStaticData,
    airlinesStaticData,
    outboundDepartures,
    outboundArrival,
    inboundDepartures,
    inboundArrival,
    departureCityName,
    arrivalCityName,
    timeRange,
    outboundDepartureTimeRange,
    outboundDepartureTimeRangeFormatted,
    inboundDepartureTimeRange,
    inboundDepartureTimeRangeFormatted,
    inboundDepartureTimeData,
    tripDuration,
    budgetRange,
    outboundStops,
    inboundStops,
    inclusiveAirlines,
  };

  const hdpStaticData = {
    budgetRange,
    /*deals,*/ propertyTypes,
    stars,
    rating,
    amenities,
    characteristic,
    mealPlans,
  };

  return {
    searchId,
    currency,
    flightsStaticData,
    hdpStaticData,
    isFecthRetrieveProporsals,
    retrieveProporsals,
    flightFilters,
    hdpFilters,
    resetAllFlightFilters,
    resetAllHDPFilters,
    getFlightsProposals,
    getHDPProposals,
  };
}
