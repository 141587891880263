import {
  StaticData,
  StaticDataTransportDetail,
} from '@/services/codegen-romulo';

export const staticDataToAirports = (
  el: StaticData
): Record<string, StaticDataTransportDetail> => {
  var rv = {};
  el?.airports?.forEach((e) => {
    rv[e.id] = {
      label: e.label,
      countryId: e.countryId,
      coordinates: e.coordinates,
      cityName: e.cityName,
    };
  });
  return rv;
};
