import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #eaeaeb;
`;

export const SegmentsDetailsContainer = styled.div`
  padding-bottom: 12px;
`;

export const PriceContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #74747c;
  padding-top: 12px;
  border-top: 1px solid #eaeaeb;

  > p {
    padding-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const DifferentDateContainer = styled.div`
  margin-bottom: 8px;
`;
