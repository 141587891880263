import { useStore } from '@/store/useStore';
import countries from '@/utils/dictionaries/countriesWithISO.json';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';
import * as Styled from './PhoneField.styled';

interface PhoneFieldProps {
  hasError: boolean;
  value: string | number;
  countryValue: string | number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleChangeCountry: (event: SelectChangeEvent<string | number>) => void;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const PhoneField: FC<PhoneFieldProps> = ({
  hasError,
  handleChange,
  handleChangeCountry,
  countryValue,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();
  const brandConfig = useStore((state) => state.brandConfig);
  const { language } = brandConfig;

  return (
    <Styled.Container>
      <Styled.Title>{t('form.contact.phone_input_description')}</Styled.Title>
      <Styled.InputsContainer>
        <Styled.FormControl variant="filled">
          <InputLabel id="phone-code-select-label">
            {t('form.contact.phone_code')}
          </InputLabel>
          <Styled.Select
            labelId="phone-code-select-label"
            variant="filled"
            label={t('form.contact.phone_code')}
            name={'phoneIsoCode'}
            onChange={handleChangeCountry}
            value={countryValue}
            renderValue={(option: any) =>
              `+${countries.find((e) => e.countryCode === option).phoneCode}`
            }
            disabled={disabled}
          >
            {countries.map((e, index) => (
              <MenuItem
                key={`${e.countryCode}_phone_${index}`}
                value={e.countryCode}
              >
                {`${e.label[language]} (+${e.phoneCode})`}
              </MenuItem>
            ))}
          </Styled.Select>
        </Styled.FormControl>
        <Styled.TextField
          variant="filled"
          label={t('form.contact.phone_number')}
          name={'phoneNumber'}
          type="phoneNumber"
          error={hasError}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          helperText={hasError && t('form.validation.phone')}
          disabled={disabled}
        />
      </Styled.InputsContainer>
    </Styled.Container>
  );
};

export default PhoneField;
