import Checkbox from '@mui/material/Checkbox';
import * as Styled from './StarsFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { StarsProps } from './StarsFilters.types';
import { Rating } from '@mui/material';
import starImg from '@/assets/images/star-rating.svg';

export function Stars({
  data,
  onStarsHandle,
  disabled,
  starsFilter,
}: StarsProps) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <>
      <FormGroup onChange={onStarsHandle}>
        <Styled.FilterOptionsContainer>
          {data?.map((star, index) => {
            return (
              <Styled.FormLabel
                disabled={disabled}
                key={`${star.code}_${index}_star`}
                control={
                  <Checkbox
                    disabled={!star.active || disabled}
                    value={star.code}
                    icon={<Styled.Checkbox />}
                    disableRipple
                    checkedIcon={
                      <Styled.CheckIconContainer $color={accentColor}>
                        <Styled.CheckIcon />
                      </Styled.CheckIconContainer>
                    }
                    checked={starsFilter?.includes(star.code) ?? false}
                  />
                }
                labelPlacement="start"
                label={
                  <Styled.LabelOption>
                    {Array.from(
                      { length: Number(star.code) },
                      (_, index) => index + 1
                    ).map((item, key) => (
                      <Styled.RatingStar src={starImg} key={`star_${key}`} />
                    ))}
                    <Rating value={2} readOnly />
                  </Styled.LabelOption>
                }
              />
            );
          })}
        </Styled.FilterOptionsContainer>
      </FormGroup>
    </>
  );
}
