import styled from '@emotion/styled';
import { Drawer as MuiDrawer } from '@mui/material';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: 744px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`;

export const FlightsContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Transport = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
`;

export const ButtonsContainer = styled.div`
  border-top: 1px solid #eaeaeb;
  display: flex;
`;

export const CardButton = styled.button`
  width: 100%;
  background: unset;
  border: unset;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 0.5rem;
  color: red;
  flex-grow: 1;
  min-width: 50%;
  border-bottom: 2px solid transparent;
  transition: border 0.2s;

  &:hover {
    border-bottom: 2px solid #babac0;
  }
`;

export const DrawerContent = styled.div`
  max-width: 1024px;
  width: calc(100% + 0px);
  background-color: #f5f5f5;
`;

export const Drawer = styled(MuiDrawer)`
  & .MuiModal-backdrop {
    background: rgba(0, 0, 0, 0.33);
  }

  & .MuiPaper-root {
    width: 100%;
    max-width: 1024px;
  }
`;

export const FLightsContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex: 1;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

export const SortSelectorContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
