import {
  BaggageCheckedIcon,
  CarryOnIcon,
  NoBaggageCheckedIcon,
  NoCarryOnIcon,
} from '@/components/LmnIcons/LmnIcons';
import { useTranslate } from '@tolgee/react';
import * as Styled from './TransportBaggage.styled';
import { TransportBaggageLabelProps } from './TransportBaggage.types';

const ICON_SIZE = 16;

export function TransportBaggage({ baggage }: TransportBaggageLabelProps) {
  const { t } = useTranslate();

  const { holdIncluded, handIncluded } = baggage || {
    holdIncluded: false,
    handIncluded: false,
  };

  //NOTE: check translate
  const holdBaggageTranslation = t('widget.trip_card.baggage.hold.label');
  const holdNoBaggageTranslation = t('widget.trip_card.baggage.no_hold.label');
  const handBaggageTranslation = t('widget.trip_card.baggage.hand.label');
  const handNoBaggageTranslation = t('widget.trip_card.baggage.no_hand.label');

  return (
    <Styled.Container>
      <Styled.BaggageContainer $highlight={!handIncluded}>
        <Styled.IconContainer>
          {handIncluded ? (
            <CarryOnIcon style={{ fontSize: ICON_SIZE }} />
          ) : (
            <NoCarryOnIcon style={{ fontSize: ICON_SIZE }} />
          )}
        </Styled.IconContainer>
        <span>
          {handIncluded ? handBaggageTranslation : handNoBaggageTranslation}
        </span>
      </Styled.BaggageContainer>
      <Styled.BaggageContainer $highlight={!holdIncluded}>
        <Styled.IconContainer>
          {holdIncluded ? (
            <BaggageCheckedIcon style={{ fontSize: ICON_SIZE }} />
          ) : (
            <NoBaggageCheckedIcon style={{ fontSize: ICON_SIZE }} />
          )}
        </Styled.IconContainer>
        <span>
          {holdIncluded ? holdBaggageTranslation : holdNoBaggageTranslation}
        </span>
      </Styled.BaggageContainer>
    </Styled.Container>
  );
}
