import { css } from '@emotion/react';
import styled from '@emotion/styled';

export * from '../../Filters.styled';

export const MealPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: unset;
`;

export const MealPlan = styled.div`
  flex-grow: 1;
  width: 33%;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const IconCircle = styled.div<{
  $selected: boolean;
  $disabled: boolean;
  $theme: any;
}>`
  width: 48px;
  height: 48px;
  min-height: 48px;
  margin: auto;
  border-radius: 24px;
  display: flex;
  -webkit-box-align: center;
  transition: border-color 0.3s ease-in-out 0s;
  border: 1px solid
    ${({ $selected, $theme, $disabled }) => {
      return $disabled
        ? 'transparent !important'
        : $selected
          ? $theme.primaryColorBg
          : '#eaeaeb';
    }};
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: ${({ $selected, $theme, $disabled }) => {
    return $disabled ? '#f5f5f5' : $selected ? $theme.primaryColorBg : 'white';
  }};
  color: ${({ $selected, $theme, $disabled }) => {
    return $disabled ? '#babac0' : $selected ? $theme.primaryColor : 'black';
  }};

  &:hover {
    color: ${({ $theme, $disabled, $selected }) =>
      !$selected && !$disabled && $theme.primaryColor};
    border: 1px solid
      ${({ $theme, $disabled, $selected }) =>
        !$selected && !$disabled && $theme.primaryColor};
  }
`;

export const IconImg = styled.div`
  font-size: 24px;
  transition: color 0.3s ease-in-out 0s;
`;
