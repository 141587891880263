import CurrencyFormatter from '@/utils/currencyFormatter';
import { TransportProposalAdapterInput } from '../types/TransportProposalAdapterInput';
import { TransportFlightOption } from '../types/TrasportFlightOption';
import { calculatePriceDiff } from './calculatePriceDiff';
import { flightDetailsMapper } from './proposalTransportToTransportDetail';
import { transportProposalWaytoTransportRowAdapter } from './transportProposalWaytoTransportRowAdapter';

export const transportProposalMapperToFlightOption = ({
  t,
  proposalFlight,
  tripDetails,
  adults,
  childrenAges,
  userBudget,
  airports,
  airlines,
  currency,
  language,
}: TransportProposalAdapterInput): TransportFlightOption => {
  const currencyFormatter = new CurrencyFormatter(language, currency, {
    minimumFractionDigits: 0,
  });

  const numberOfLegs = !!tripDetails?.inbound ? 2 : 1;
  const peopleNumber = adults + (childrenAges?.length ?? 0);
  const wayFare = proposalFlight.agencyCommission / numberOfLegs;

  const outbound = transportProposalWaytoTransportRowAdapter(
    proposalFlight.outbound,
    airports,
    airlines,
    true
  );

  const inbound = proposalFlight?.inbound
    ? transportProposalWaytoTransportRowAdapter(
        proposalFlight.inbound,
        airports,
        airlines,
        false
      )
    : undefined;

  const tripDetailsMapped = tripDetails
    ? flightDetailsMapper(
        t,
        tripDetails,
        airports,
        airlines,
        wayFare,
        adults,
        childrenAges,
        language,
        currency
      )
    : undefined;

  const fullPrice = proposalFlight.fullPrice / 100;

  const isRyanair = !!proposalFlight.inbound
    ? proposalFlight.outbound.segments[0].supplierId === 'FR' ||
      proposalFlight.inbound?.segments[0].supplierId === 'FR'
    : proposalFlight.outbound.segments[0].supplierId === 'FR';

  const agencyFee = isRyanair
    ? currencyFormatter.formatNumber(
        proposalFlight.agencyCommission / 100 / peopleNumber,
        2
      )
    : undefined;

  // TODO REVIEW IF THIS SIMPLER LOGIC FITS ALL NEEDS
  const price = currencyFormatter.formatNumber(fullPrice / peopleNumber, 2);
  const alternativeId = proposalFlight.id;
  const fitInBudget = fullPrice <= userBudget;
  const fullPriceFormatted = currencyFormatter.formatNumber(fullPrice);

  return {
    outbound,
    inbound,
    tripDetails: tripDetailsMapped,
    price: price,
    fullPrice: fullPrice,
    fullPriceFormatted: fullPriceFormatted,
    agencyFee: agencyFee,
    available: fitInBudget,
    alternativeId,
    currency,
  };
};
