import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { getTravellerFullName } from '../utils/getTravellerName';
import { getWaysNumber } from '../utils/getWaysNumber';

const getLuggageSelection = (type, productId, reservationId, luggages) => {
  const selection = luggages[type][`${productId}_${reservationId}`];
  const communications = selection?.communicationIds?.map((communicationId) =>
    luggages.communications.find(
      (communication) => communication.id === communicationId
    )
  );
  const options = selection?.options;
  return { options, communications };
};

export const useGetLuggagesInfo = () => {
  const { t } = useTranslate();
  const [availableProducts, cartDetail] = useStore((state) => [
    state.availableProducts,
    state.cartDetail,
  ]);

  const luggages = availableProducts?.products?.LUGGAGE ?? undefined;
  const flightInfo = cartDetail?.products?.find((e) => e.type === 'FLIGHT');

  if (!luggages || !flightInfo) {
    return null;
  }

  const outboundSegments =
    flightInfo.detail.itinerary.outboundTransport[0]?.segments;
  const returnSegments =
    flightInfo.detail.itinerary?.inboundTransport[0]?.segments;

  const outboundReservationId =
    flightInfo?.detail?.itinerary?.outboundTransport[0]?.id?.reservationId;
  const inboundReservationId =
    flightInfo?.detail?.itinerary?.inboundTransport[0]?.id?.reservationId;

  const waysNumber = getWaysNumber(outboundReservationId, inboundReservationId);

  const outboundDetail = `${outboundSegments[0].departureStation.cityName} - ${outboundSegments[outboundSegments.length - 1].destinationStation.cityName}`;
  const inboundDetail = `${returnSegments[0].departureStation.cityName} - ${returnSegments[returnSegments.length - 1].destinationStation.cityName}`;

  const luggagesByTraveller =
    luggages &&
    cartDetail?.travellers?.map((traveller) => {
      const luggageMapBase = {
        travellerType: traveller.type,
        travellerId: traveller.id,
        travellerFullName: getTravellerFullName(traveller, t),
        selectedLuggages: undefined,
      };

      if (outboundReservationId === inboundReservationId) {
        const travellerOutboundLuggageSelection =
          luggages[traveller.type][
            `${flightInfo?.id}_${outboundReservationId}`
          ];
        const travellerLuggageCommunications =
          travellerOutboundLuggageSelection?.communicationIds?.map(
            (communicationId) =>
              luggages.communications.find(
                (communication) => communication.id === communicationId
              )
          );
        const luggageMapped = {
          ...luggageMapBase,
          luggages: [
            {
              luggageOptions: travellerOutboundLuggageSelection?.options,
              luggageCommunications: travellerLuggageCommunications,
            },
          ],
        };

        return luggageMapped;
      } else {
        const travellerOutboundLuggage = getLuggageSelection(
          traveller.type,
          flightInfo?.id,
          outboundReservationId,
          luggages
        );

        const travellerInboundLuggage = getLuggageSelection(
          traveller.type,
          flightInfo?.id,
          inboundReservationId,
          luggages
        );

        const luggageMapped = {
          ...luggageMapBase,
          luggages: [
            {
              luggageOptions: travellerOutboundLuggage.options,
              luggageCommunications: travellerOutboundLuggage.communications,
            },
            {
              luggageOptions: travellerInboundLuggage.options,
              luggageCommunications: travellerInboundLuggage.communications,
            },
          ],
        };

        return luggageMapped;
      }
    });

  return { luggagesByTraveller, waysNumber, outboundDetail, inboundDetail };
};
