import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './BudgetFilters.styled';
import { Slider } from '../../Slider';
import CurrencyFormatter from '@/utils/currencyFormatter';
import { isEmpty } from '@/utils/isEmpty';

export function BudgetFilters() {
  const {
    hdpFilters,
    isFecthRetrieveProporsals,
    hdpStaticData,
    currency,
    searchId,
    getHDPProposals,
    retrieveProporsals,
  } = useGetFiltersData();

  const { budgetRange } = hdpStaticData;

  const { t } = useTranslate();

  const currencyFormatter = new CurrencyFormatter('it-IT', currency, {
    minimumFractionDigits: 0,
  });

  const getPriceFormatted = (value: number) => {
    return currencyFormatter.formatNumber(value / 100);
  };

  const onUpdateSlider = (_, e: number[]) => {
    getHDPProposals(searchId, [{ budgetRange: e }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  const minValue = (hdpFilters.budgetRange ?? budgetRange)[0];

  const maxValue = (hdpFilters.budgetRange ?? budgetRange)[1];

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Styled.Title>{t('general.price.per_person')}</Styled.Title>
        <Styled.SliderValuesContainer>
          <Styled.SliderValue>{getPriceFormatted(minValue)}</Styled.SliderValue>
          <Styled.SliderValue>{getPriceFormatted(maxValue)}</Styled.SliderValue>
        </Styled.SliderValuesContainer>
        <Styled.SliderContainer>
          <Slider
            defaultValue={[minValue, maxValue]}
            value={[minValue, maxValue]}
            disabled={isFecthRetrieveProporsals}
            onChange={onUpdateSlider}
            min={budgetRange[0]}
            max={budgetRange[1]}
            valueLabelFormat={(value) => `${getPriceFormatted(value)}`}
          />
        </Styled.SliderContainer>
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
