import { TransportFlightProposal } from '@/services/codegen-romulo';
import { TransportOption } from '../types/TransportOption';
import { transportWayAdapter } from './transportWayAdapter';

export const transportProposalToTransportOptionAdapter = (
  el: TransportFlightProposal
): TransportOption => ({
  id: el.id,
  outbound: transportWayAdapter(el.transport.ways[0]),
  inbound: el.transport.ways[1]
    ? transportWayAdapter(el.transport.ways[1])
    : undefined,
  fullPrice: el.transport.price.amount,
  agencyCommission: el.transport.price.agencyCommission,
  discount: el.transport.price.discount,
});
