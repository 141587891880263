import { useStore } from '@/store/useStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Divider, Radio } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useRemoveProducts } from '../../hooks/useRemoveProduct';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import * as Styled from './Insurance.styled';
import { useInsuranceDetails } from './utils/useInsuranceDetails';

export const Insurance: FC = () => {
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const addProduct = useAddProduct();
  const removeProduct = useRemoveProducts();
  const [selected, setSelected] = useState<string | number>(undefined);

  const [availableProducts, cartDetail] = useStore((state) => [
    state.availableProducts,
    state.cartDetail,
  ]);
  const insurace = availableProducts?.products.INSURANCE;
  const getInsuranceDetails = useInsuranceDetails();

  if (!availableProducts) {
    return <Skeleton />;
  }

  if (!insurace) {
    return null;
  }

  const insuranceDetails = getInsuranceDetails();

  const handleChange = async (insuranceId: string | number) => {
    setIsLoading(true);

    if (insuranceId === 'NO_INSURANCE') {
      try {
        await removeProduct('INSURANCE')
          .then((e) => {
            setSelected(insuranceId);
          })
          .finally(() => setIsLoading(false));
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      const selectedInsurance = insuranceDetails.find(
        (e) => e.id === Number(insuranceId)
      );

      const insuranceObject = [
        {
          type: 'INSURANCE',
          detail: {
            lmnId: `${selectedInsurance.id}`,
            ancillary: {
              label: selectedInsurance.label,
            },
          },
          cost: {
            amount: {
              value: selectedInsurance.priceTotalMoney.value,
              scale: selectedInsurance.priceTotalMoney.scale,
              currency: availableProducts?.products?.INSURANCE?.currency,
            },
          },
        },
      ];
      try {
        await addProduct('INSURANCE', insuranceObject)
          .then((e) => {
            setSelected(insuranceId);
          })
          .finally(() => setIsLoading(false));
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <SectionCard title={t('form.product.insurance.protect_trip')}>
      <Styled.RadioGroup onChange={(e) => handleChange(e.target.value)}>
        {insuranceDetails.map((insurance) => (
          <div style={{ width: '100%' }}>
            <Styled.FormControlLabel
              value={insurance.id}
              disabled={isLoading}
              control={<Radio />}
              label={
                <Styled.TitleContainer>
                  <Styled.Image
                    style={{
                      width: 40,
                      height: 'fit-content',
                      marginRight: '8px',
                    }}
                    src={insurance.providerLogo}
                  />
                  <div>
                    <Styled.Title
                      dangerouslySetInnerHTML={{
                        __html: insurance.name,
                      }}
                    />
                    <Styled.Price
                      dangerouslySetInnerHTML={{
                        __html: insurance.price,
                      }}
                    />
                  </div>
                </Styled.TitleContainer>
              }
            />
            <Styled.InsuranceContainer>
              <Styled.Content
                dangerouslySetInnerHTML={{
                  __html: insurance.coverage,
                }}
              />
              <div onClick={(e) => e.preventDefault()}>
                <Styled.Accordion elevation={0}>
                  <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {t('form.product.insurance.show_more')}
                  </Styled.AccordionSummary>
                  <AccordionDetails>
                    <Styled.DetailsContent
                      dangerouslySetInnerHTML={{
                        __html: insurance.summary,
                      }}
                    />
                  </AccordionDetails>
                </Styled.Accordion>
              </div>
              <Styled.Terms
                dangerouslySetInnerHTML={{
                  __html: insurance.termsAndConditions,
                }}
              />
              <Divider sx={{ marginTop: '8px' }} />
            </Styled.InsuranceContainer>
          </div>
        ))}
        <Styled.NoInsuranceFormControlLabel
          value={'NO_INSURANCE'}
          control={<Radio />}
          disabled={isLoading}
          label={
            <Styled.TitleContainer>
              <Styled.NoInsuranceTitle>
                {t('form.product.insurance.no_insurance')}
              </Styled.NoInsuranceTitle>
            </Styled.TitleContainer>
          }
        />
      </Styled.RadioGroup>
    </SectionCard>
  );
};
