import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './PropertyTypeFilters.styled';
import { PropertyType } from './PropertyType';
import { isEmpty } from '@/utils/isEmpty';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';

export function PropertyTypesFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { propertyTypes } = hdpStaticData;

  const onPropertyTypeHandle = (e) => {
    const propertyTypeUpdated = getCheckboxFilterUpdated(
      hdpFilters,
      'propertyType',
      e.target
    );

    getHDPProposals(searchId, [{ propertyType: propertyTypeUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <PropertyType
          data={propertyTypes}
          onPropertyTypeHandle={onPropertyTypeHandle}
          disabled={isFecthRetrieveProporsals}
          propertyTypeFilter={hdpFilters.propertyType}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
