import { TransportationIcon } from '@/components/LmnIcons/LmnIcons';
import { JourneyContractSegmentTransportType } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/JourneyContract';
import React from 'react';
import styled from '@emotion/styled';

export function getTransportIconByType(
  DynamicFlightIcon: React.ComponentType,
  DynamicTrainIcon: React.ComponentType,
  DynamicBusIcon: React.ComponentType,
  transportType: JourneyContractSegmentTransportType | 'MIXED'
): React.ComponentType {
  const iconsMapping = {
    [JourneyContractSegmentTransportType.FLIGHT]: DynamicFlightIcon,
    [JourneyContractSegmentTransportType.TRAIN]: DynamicTrainIcon,
    [JourneyContractSegmentTransportType.BUS]: DynamicBusIcon,
  };

  return iconsMapping[transportType] || TransportationIcon;
}

export const reverseIcon = (
  Icon: React.ComponentType,
  isInbound: boolean
): React.ReactElement =>
  isInbound ? (
    <InboundIcon>
      <Icon />
    </InboundIcon>
  ) : (
    <Icon />
  );

const InboundIcon = styled.div`
  transform: scaleX(-1);
`;
