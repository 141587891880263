import { Proposal } from '@/services/codegen-romulo';

export const getRating = (accommodation: Proposal['accommodation']) => {
  if (!accommodation.opinion) {
    return null;
  }

  return {
    value: accommodation.opinion.rating,
    numberOfReviews: accommodation.opinion.numberOfReviews,
  };
};
