import styled from '@emotion/styled';
import { Select as MuiSelect } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const Title = styled.span`
  color: #74747c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Select = styled(MuiSelect)`
  border-radius: 8px;
  width: 100%;
  height: 56px;
  & .MuiSelect-select:focus {
    background-color: unset !important;
  }
`;
export const ErrorMsg = styled.span`
  margin-top: -4px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #d32f2f;
`;
