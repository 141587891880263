import { CdnImageAdapterRequest, CdnImageAdapterResponse, CdnImageUrlAdapter } from './CdnImageUrlAdapter';

export const buildCloudinaryCdnImageUrlAdapter = (): CdnImageUrlAdapter => {
  return {
    adaptUrl(req: CdnImageAdapterRequest): CdnImageAdapterResponse {
      const isProcessable = isCloudinaryUrl(req.url);

      if (!isProcessable) return { processed: false, url: req.url };

      let processedUrl = req.url;
      const sizeDescriptor = req.transformations.size;
      const qualityDescriptor = req.transformations.quality;
      const formatDescriptor = req.transformations.format;
      const cornerRadiusDescriptor = req.transformations.cornerRadius;
      const transformations: Array<string> = [];

      if (sizeDescriptor) {
        if (sizeDescriptor.type === 'fill') {
          transformations.push(`c_fill,h_${sizeDescriptor.heightInPx},w_${sizeDescriptor.widthInPx}`);
        }
        if (sizeDescriptor.type === 'fit') {
          transformations.push(`c_fit,h_${sizeDescriptor.heightInPx},w_${sizeDescriptor.widthInPx}`);
        }
      }

      if (qualityDescriptor) {
        if (qualityDescriptor === 'auto') {
          transformations.push(`q_auto`);
        } else {
          transformations.push(`q_${qualityDescriptor}`);
        }
      }

      if (formatDescriptor) {
        if (formatDescriptor === 'auto') {
          transformations.push(`f_auto`);
        }
      }

      if (cornerRadiusDescriptor) {
        transformations.push(`r_${cornerRadiusDescriptor}`);
      }

      processedUrl = processedUrl.replace(
        /^(.*\/image\/upload\/)(.*)(\/v\d+\/.*)/,
        `$1${transformations.length ? transformations.join(',') : '$2'}$3`
      );

      return {
        processed: isProcessable,
        url: processedUrl,
      };
    },
  };

  function isCloudinaryUrl(url: string) {
    return /^(https:)?\/\/res\.cloudinary\.com\/.*/.test(url);
  }
};
