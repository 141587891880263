import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddressContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template: 'address number' / 1fr 155px;
`;

export const CountryContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template: 'zipcode city' / 155px 1fr;
`;

export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  margin-bottom: 8px;
  line-height: 24px;
`;
