import { useStore } from '@/store/useStore';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './PriceDetails.styled';
import { useGetProductPrices } from './utils/getProductPrices';

export const PriceDetails: FC = () => {
  const { t } = useTranslate();
  const currencyFormatter = useCurrencyFormatter();
  const cartDetail = useStore((state) => state.cartDetail);
  const getProductPrices = useGetProductPrices();

  if (!cartDetail) return null;

  const totalCost = currencyFormatter.formatNumber(
    cartDetail.receipt.totalCost.value / 100
  );

  const productPrices = getProductPrices();

  const priceBeforeDiscount = '20€';
  const totalDiscount = '20€';

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Accordion elevation={0}>
          <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Styled.TitleWrapper>
              <Styled.Title>{t('general.price.total')}</Styled.Title>
              <Styled.Subtitle>
                {t('general.price.total.rates')}
              </Styled.Subtitle>
            </Styled.TitleWrapper>
          </Styled.AccordionSummary>
          <AccordionDetails>
            {productPrices.map((e) => (
              <Styled.PriceRow>
                <Styled.Span>{e.title}</Styled.Span>
                <Styled.Span>{e.price}</Styled.Span>
              </Styled.PriceRow>
            ))}
          </AccordionDetails>
        </Styled.Accordion>
      </Styled.TitleContainer>
      <Styled.ContentContainer>
        {priceBeforeDiscount && (
          <Styled.PriceRow>
            <Styled.Span>{'Price before discount'}</Styled.Span>
            <Styled.Span>{priceBeforeDiscount}</Styled.Span>
          </Styled.PriceRow>
        )}
        {totalDiscount && (
          <Styled.PriceRow>
            <Styled.Span>{totalDiscount}</Styled.Span>
            <Styled.Span>{totalDiscount}</Styled.Span>
          </Styled.PriceRow>
        )}
        {totalCost && (
          <Styled.PriceRow>
            <Styled.TotalPrice>{t('general.price.total')}</Styled.TotalPrice>
            <Styled.TotalPrice>{totalCost}</Styled.TotalPrice>
          </Styled.PriceRow>
        )}
      </Styled.ContentContainer>
    </Styled.Container>
  );
};
