import CurrencyFormatter from '@/utils/currencyFormatter';
import { wayStopsAdapter } from './wayStopsAdapter';
import { JourneyContractWayPrice } from '../types/tripDetailsTypes/JourneyContract';
import { getStopInfoBasedOnType } from './getStopInfoBasedOnType';
import { journeyDetailsSegmentAdapter } from './journeyDetailsSegmentAdapter';
import { Way } from '../types/tripDetailsTypes/Way';
import { JourneyDetailsWayIntroProps } from '../types/tripDetailsTypes/JourneyDetailsWayIntroProps';
import { JourneyDetailsSegmentProps } from '../types/tripDetailsTypes/JourneyDetailsSegmentProps';

export type WayDetailsType = {
  isDepartingOnDifferentDate: boolean;
  priceInformation?: string | undefined;
  segmentsDetails: Omit<JourneyDetailsSegmentProps, 'isInbound'>[];
  introInfo: Omit<JourneyDetailsWayIntroProps, 'isInbound' | 'locale'>;
};

export const journeyDetailsWayAdapter = {
  adapt: (
    t: (key: string, x: any, replaces: any) => string,
    way: Way,
    currencyFormatter: CurrencyFormatter,
    seatSelectionMessage?: string[],
    slimIntro = false
  ): WayDetailsType => {
    const waySegments = way.getSegments();
    const segmentDetails = waySegments.map((segment, index) => {
      const stopInformation = !segment.isLastWaySegment()
        ? getStopInfoBasedOnType(segment, waySegments, index)
        : undefined;

      return journeyDetailsSegmentAdapter.adapt(
        t,
        segment,
        stopInformation,
        seatSelectionMessage
      );
    });

    const priceInformation = way.getPriceInformation();
    const priceInformationContent =
      priceInformation && getPriceInfoContent(priceInformation);

    const priceResult =
      priceInformationContent &&
      t(priceInformationContent.key, '', {
        price: currencyFormatter.formatNumber(
          priceInformationContent.placeholders.price
        ),

        tax: priceInformationContent.placeholders?.tax
          ? currencyFormatter.formatNumber(
              priceInformationContent.placeholders.tax
            )
          : '',
      });

    const wayStopsLabel = wayStopsAdapter.adapt(way, t);

    return {
      isDepartingOnDifferentDate: way.isDepartingOnDifferentDate(),
      priceInformation: priceResult,
      segmentsDetails: segmentDetails,
      introInfo: {
        allSegmentTypes: way.getAllSegmentTypes(),
        locations: way.getLocations(),
        departureDate: way.getDepartureDate(),
        durationMinutes: way.getDurationMinutes(),
        wayStopsLabel,
        slimVersion: slimIntro,
      },
    };
  },
};

function getPriceInfoContent(priceInformation: JourneyContractWayPrice): {
  key: string;
  placeholders: {
    price: number;
    tax?: number;
  };
} {
  const priceInformationKey =
    priceInformation.taxes && priceInformation.taxes.perPassenger.amount > 0
      ? 'widget.trip_card.way.price.pp'
      : 'widget.trip_card.way.price.pp.without.taxes';

  const priceInformationPlaceholders =
    priceInformation.taxes && priceInformation.taxes.perPassenger.amount > 0
      ? {
          price: priceInformation.price.perPassenger.amount,
          tax: priceInformation.taxes.perPassenger.amount,
        }
      : {
          price: priceInformation.price.perPassenger.amount,
        };

  return {
    key: priceInformationKey,
    placeholders: priceInformationPlaceholders,
  };
}
