import { calculateDifferenceInMinutes, formatHour } from '@/utils/timeUtils';

import { DIFFERENT_DAY_SEGMENT } from './oneDayDifferenceKey';
import { getDurationLabel } from './getDurationLabel';
import {
  JourneyContractSegmentLocation,
  JourneyContractSegmentTransport,
  JourneyContractSegmentTransportType,
} from '../types/tripDetailsTypes/JourneyContract';
import { Segment } from '../types/tripDetailsTypes/Segment';
import { SegmentStopInformation } from '../types/tripDetailsTypes/SegmentStopInformation';
import { JourneyDetailsSegmentProps } from '../types/tripDetailsTypes/JourneyDetailsSegmentProps';

const DIFFERENT_PLACE_LABEL = {
  [JourneyContractSegmentTransportType.FLIGHT]:
    'widget.trip_card.way.different.airport',
  [JourneyContractSegmentTransportType.TRAIN]:
    'widget.trip_card.way.different.station',
  [JourneyContractSegmentTransportType.BUS]:
    'widget.trip_card.way.different.bus_station',
};

const TECH_STOP_LABEL = {
  [JourneyContractSegmentTransportType.FLIGHT]:
    'widget.trip_card.way.stop.technical.airplane.change',
  [JourneyContractSegmentTransportType.TRAIN]:
    'widget.trip_card.way.stop.technical.train.change',
  [JourneyContractSegmentTransportType.BUS]:
    'widget.trip_card.way.stop.technical.bus.change',
};

export const journeyDetailsSegmentAdapter = {
  adapt: (
    t: (key: string, x?: any, replaces?: any) => string,
    segmentDomain: Segment,
    segmentStopInformation?: SegmentStopInformation,
    seatSelectionMessage?: string[]
  ): Omit<JourneyDetailsSegmentProps, 'isInbound'> => {
    const departure = segmentDomain.getDeparture();
    const arrival = segmentDomain.getArrival();
    const transport = segmentDomain.getTransport();
    const isDepartureAfterStop = !segmentDomain.isFirstWaySegment();
    const isArrivalBeforeStop = !segmentDomain.isLastWaySegment();
    const differentTransportLabel = t(DIFFERENT_PLACE_LABEL[transport.type]);
    const differentDayArrival = segmentDomain.getDifferentDayArrival();
    const differentDayDeparture = segmentDomain.getDifferentDayDeparture();
    const duration = segmentDomain.getDuration();

    const differentDayKeyMapping = {
      [DIFFERENT_DAY_SEGMENT.PREVIOUS_DAY]:
        'widget.trip_card.way.details.previous_day',
      [DIFFERENT_DAY_SEGMENT.NEXT_DAY]: 'widget.trip_card.way.details.next_day',
    };

    const hasCompany = seatSelectionMessage?.some(
      (code) => transport.companyCode === code.toUpperCase()
    );
    const hasOperatedBy = seatSelectionMessage?.some(
      (code) => transport.operateBy?.code === code.toUpperCase()
    );

    const showSeatSelectionMessage = hasCompany || hasOperatedBy || false;
    const companyNameSeatSelectionMessage =
      (hasCompany && transport.companyName) ||
      (hasOperatedBy && transport.operateBy?.name) ||
      '';

    const stopDurationInMinutes =
      segmentStopInformation &&
      calculateDifferenceInMinutes(
        segmentStopInformation?.stopLandingTime || '',
        segmentStopInformation?.stopDepartureTime || ''
      );
    const durationLabel = segmentStopInformation?.isTechnical
      ? 'widget.trip_card.way.stop.technical.duration'
      : 'widget.trip_card.way.stop.duration';

    const stopInformation = segmentStopInformation && {
      title: t(durationLabel, '', {
        duration: getDurationLabel(t, stopDurationInMinutes!),
      }),
      description: segmentStopInformation?.isTechnical
        ? t(TECH_STOP_LABEL[transport.type])
        : undefined,
    };

    return {
      departure: {
        datetime: departure.datetime,
        date: formatHour(departure.datetime),
        differentDayDepartureKey:
          differentDayDeparture &&
          t(differentDayKeyMapping[differentDayDeparture]),
        differentTransportLabel,
        departingOnDifferentLocation:
          segmentDomain.isDepartingOnDifferentLocation(),
        hub: departure.hub,
        departureAfterStop: isDepartureAfterStop,
        terminalText: departure.hub.terminal
          ? t('widget.trip_card.way.location.terminal', '', {
              locationTerminal: departure.hub.terminal,
            })
          : undefined,
      },
      arrival: {
        datetime: arrival.datetime,
        date: formatHour(arrival.datetime),
        differentDayArrivalKey:
          differentDayArrival && t(differentDayKeyMapping[differentDayArrival]),
        differentTransportLabel,
        arrivalOnDifferentLocation:
          segmentDomain.isArrivingAtDifferentLocation(),
        hub: arrival.hub,
        arrivalBeforeStop: isArrivalBeforeStop,
        terminalText: arrival.hub.terminal
          ? t('widget.trip_card.way.location.terminal', undefined, {
              locationTerminal: arrival.hub.terminal,
            })
          : undefined,
        differentLocationLabel: segmentDomain.isChangingLocationNextSegment()
          ? t('widget.trip_card.way.different_location')
          : '',
        baggageRecheckLabel: segmentDomain.isChangingTransportTypeNextSegment()
          ? t('widget.trip_card.way.luggage_recheck')
          : '',
      },
      transport: {
        // TODO: code to be restored after the Air Malta temporary name switch period
        /* 
        ...transport,
        operatedByTransportLabel:
          transport.operateBy && transport.operateBy.code !== transport.companyCode
            ? t('widget.trip_card.way.segment.operated.by', '', {
                provider: handleTemporaryMaltaAirlinesNameSwitch(
                  transport,
                  departure
                ),
              })
            : undefined,
        */
        ...transport,
        operatedByTransportLabel:
          (transport.operateBy &&
            transport.operateBy.code !== transport.companyCode) ||
          isMaltaNameSwitchEligible(transport, departure)
            ? t('widget.trip_card.way.segment.operated.by', '', {
                provider: handleTemporaryMaltaAirlinesNameSwitch(
                  transport,
                  departure
                ),
              })
            : undefined,
      },
      transportDuration: getDurationLabel(t, duration),
      baggage: segmentDomain.getBaggage(),
      seatSelection: {
        showSeatSelectionMessage,
        companyNameSeatSelectionMessage,
      },
      stopInformation,
    };
  },
};

const isMaltaNameSwitchEligible = (
  transport: JourneyContractSegmentTransport,
  departure: JourneyContractSegmentLocation
): boolean => {
  const isMaltaAirlines = transport.companyCode === 'KM';

  if (isMaltaAirlines) {
    const switchDate = new Date('2024-03-31');
    const departureDate = new Date(departure.datetime);

    return departureDate > switchDate;
  }

  return false;
};

const handleTemporaryMaltaAirlinesNameSwitch = (
  transport: JourneyContractSegmentTransport,
  departure: JourneyContractSegmentLocation
): string => {
  const isMaltaAirlines = transport.companyCode === 'KM';
  const isAlreadyOperatedByAnotherprovider = !!transport.operateBy?.code;
  const switchDate = new Date('2024-03-31');
  const departureDate = new Date(departure.datetime);

  if (
    isMaltaAirlines &&
    !isAlreadyOperatedByAnotherprovider &&
    departureDate > switchDate
  ) {
    return 'KM Malta Airlines';
  }

  return transport.operateBy!.name;
};
