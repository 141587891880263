import { Way } from '../types/tripDetailsTypes/Way';
import { WAY_TRANSPORT_TYPES } from '../types/tripDetailsTypes/WayTransporTypes';

export const getTransportStopLabels = (
  way: Way
): { singular: string; plural: string } => {
  if (way.isFlightOnly()) {
    return WAY_TYPE_TO_STOP_KEY[WAY_TRANSPORT_TYPES.FLIGHT];
  }

  if (way.isTrainOnly()) {
    return WAY_TYPE_TO_STOP_KEY[WAY_TRANSPORT_TYPES.TRAIN];
  }

  if (way.isBusOnly()) {
    return WAY_TYPE_TO_STOP_KEY[WAY_TRANSPORT_TYPES.BUS];
  }

  return WAY_TYPE_TO_STOP_KEY[WAY_TRANSPORT_TYPES.MIXED];
};

const WAY_TYPE_TO_STOP_KEY = {
  [WAY_TRANSPORT_TYPES.FLIGHT]: {
    singular: 'widget.trip_card.stops.flight.one',
    plural: 'widget.trip_card.stops.flight.several',
  },
  [WAY_TRANSPORT_TYPES.TRAIN]: {
    singular: 'widget.trip_card.stops.train.one',
    plural: 'widget.trip_card.stops.train.several',
  },
  [WAY_TRANSPORT_TYPES.BUS]: {
    singular: 'widget.trip_card.stops.bus.one',
    plural: 'widget.trip_card.stops.bus.several',
  },
  [WAY_TRANSPORT_TYPES.MIXED]: {
    singular: 'widget.trip_card.stops.mixed.one',
    plural: 'widget.trip_card.stops.mixed.several',
  },
};
