export const getTravellerType = (
  type: string,
  t: (key: string) => string
): string => {
  switch (type) {
    case 'ADULT':
      return `${t('general.traveller_type.adult')} (12+)`;
    case 'CHILD':
      return `${t('general.traveller_type.child')} (2-11)`;
    case 'INFANT':
      return `${t('general.traveller_type.infant')} (0-23 ${t(
        'general.time.months'
      ).toLowerCase()})`;
    default:
      return '';
  }
};
