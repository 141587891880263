import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 0;
  width: 100%;
  align-self: center;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 1200px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;

export const SimpleContainer = styled(InfoContainer)``;

export const DualContainer = styled(InfoContainer)`
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0;

  @media (min-width: 576px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

export const HorizontalFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-right: 24px;
  height: 72px;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 0;
  }
`;
export const VerticalFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-right: 24px;
  width: 300px;

  > *:not(:last-child) {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex: 1;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ResultsDualContainer = styled(ResultsContainer)<{
  $isTablet: any;
}>`
  padding: 24px;
  position: relative;
  height: calc(-136px + 100vh);
  max-width: ${({ $isTablet }) => ($isTablet ? '100%' : '776px')};
  overflow: hidden auto;
`;

export const SortSelectorContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
