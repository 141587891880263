import { useTranslate } from '@tolgee/react';
import * as Styled from '../CheckedLuggage.styled';
import { LuggageTypeStatus } from './LuggageTypeStatus';

const only_small_bag_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1/DAM/SVG/Illustrations/2021%20redesign/Luggage/Pink_Luggage%20allowance.svg';

const small_normal_bags_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1/DAM/SVG/Illustrations/2021%20redesign/Luggage/Pink_Luggage%20allowance%202.svg';

const all_bags_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1/DAM/SVG/Illustrations/2021%20redesign/Luggage/lm_Luggage%20allowance%203.svg';

const small_and_checked_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1/DAM/SVG/Illustrations/2021%20redesign/Luggage/lm_Luggage%20allowance%204.svg';

export const AllLuggagesStatus = ({ luggagesByTraveller, direction }) => {
  const { t } = useTranslate();

  const hasCheckedLuggage = luggagesByTraveller
    .find((traveller) => traveller.travellerType === 'ADULT')
    ?.luggages?.[
      direction
    ]?.luggageCommunications?.find((communication) => communication.type === 'LUGGAGE_INCLUDED');

  const hasHandLuggage = luggagesByTraveller
    .find((traveller) => traveller.travellerType === 'ADULT')
    ?.luggages?.[
      direction
    ]?.luggageCommunications?.find((communication) => communication.type === 'HAND_LUGGAGE');

  const getImage = () => {
    if (hasCheckedLuggage && hasHandLuggage) {
      return all_bags_url;
    }
    if (hasCheckedLuggage && !hasHandLuggage) {
      return small_and_checked_url;
    }
    if (!hasCheckedLuggage && hasHandLuggage) {
      return small_normal_bags_url;
    }
    return only_small_bag_url;
  };

  return (
    <Styled.Container>
      <Styled.ImageContainer>
        <img style={{ width: '265px' }} src={getImage()} />
      </Styled.ImageContainer>
      <div>
        <Styled.Section>
          {t('form.product.priority.hand_luggage')}
        </Styled.Section>
        <LuggageTypeStatus
          direction={direction}
          luggagesByTraveller={luggagesByTraveller}
          luggageType={'HAND_LUGGAGE'}
        />
      </div>
      <div>
        <Styled.Section>{t('flight.luggage.checked_luggage')}</Styled.Section>
        <LuggageTypeStatus
          direction={direction}
          luggagesByTraveller={luggagesByTraveller}
          luggageType={'CHECKED_LUGGAGE'}
        />
      </div>
    </Styled.Container>
  );
};
