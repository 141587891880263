import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import {
  CartApiServiceService,
  ProductInputDto,
} from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { useEffect } from 'react';

export const useSelectFFDefaultProducts = ({
  cartId,
  requestUuid,
}: {
  cartId: string;
  requestUuid: string;
}) => {
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const [cartDetail, availableProducts] = useStore((state) => [
    state.cartDetail,
    state.availableProducts,
  ]);

  const addProductToCart = async ({
    cartId,
    requestUuid,
    requestBody,
  }: {
    cartId?: string;
    requestUuid?: string;
    requestBody?: Array<ProductInputDto>;
  }) => {
    return await CartApiServiceService.postApiServicesAppCartApiServiceAddProductToCart(
      { cartId, requestUuid, requestBody }
    ).catch((error) => error.response.data.error.message);
  };

  const updateProductToCart = async ({
    cartId,
    requestUuid,
    requestBody,
  }: {
    cartId?: string;
    requestUuid?: string;
    requestBody?: Array<ProductInputDto>;
  }) => {
    return await CartApiServiceService.postApiServicesAppCartApiServiceReplaceProductsInCart(
      { cartId, requestUuid, requestBody }
    ).catch((error) => error.response.data.error.message);
  };

  const addLuggage = async () => {
    const luggageOptions = cartDetail.products.filter(
      (product) => product.type === 'LUGGAGE'
    );

    const ways: ProductInputDto[] = Object.keys(
      availableProducts?.products?.['LUGGAGE']?.['ADULT']
    )
      .map((id) => {
        const freeLuggageOption = availableProducts?.products?.['LUGGAGE']?.[
          'ADULT'
        ]?.[id]?.options?.find((e) => e.price.total.value === 0);

        if (freeLuggageOption) {
          return {
            type: 'LUGGAGE',
            cost: { amount: freeLuggageOption.price.total },
            detail: {
              lmnId: freeLuggageOption.id,
              travellerId: 1,
            },
          };
        } else {
          return undefined;
        }
      })
      .filter((e) => e);

    luggageOptions.length > 0
      ? await updateProductToCart({
          cartId,
          requestUuid,
          requestBody: ways,
        })
      : await addProductToCart({
          cartId,
          requestUuid,
          requestBody: ways,
        });
  };

  const selectFFDefaultProducts = async () => {
    await addLuggage();
  };

  useEffect(() => {
    if (availableProducts && cartDetail && isFreeFlight) {
      selectFFDefaultProducts();
    }
  }, [availableProducts, cartDetail]);
};
