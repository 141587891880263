import dayjs from 'dayjs';

export function getDefaultDates() {
  const today = dayjs();

  const nextTuesday = today.day(2).isBefore(today)
    ? today.day(2).add(1, 'week')
    : today.day(2);
  const nextWednesday = nextTuesday.add(1, 'day');

  return {
    from: nextTuesday,
    to: nextWednesday,
  };
}
