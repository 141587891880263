export const getOptionPrice = (
  luggageOption: any,
  waysNumber: number,
  t: (key: string) => string,
  currencyFormatter: any
) => {
  return waysNumber > 1
    ? `${currencyFormatter.formatNumber(
        Number(luggageOption.price.total.value) / 100 / waysNumber
      )} ${t('general.price.per_way')}`
    : currencyFormatter.formatNumber(
        Number(luggageOption.price.total.value) / 100
      );
};
