/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckFreeFlightResponseDto } from '../models/CheckFreeFlightResponseDto';
import type { VoucherCodeInputDto } from '../models/VoucherCodeInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FreeFlightService {

    /**
     * @returns CheckFreeFlightResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppFreeFlightCheckVoucherCode({
        requestBody,
    }: {
        requestBody?: VoucherCodeInputDto,
    }): CancelablePromise<CheckFreeFlightResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/FreeFlight/CheckVoucherCode',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
