import * as Styled from './MapHotelCard.styled';
import { Rating } from '../HotelCard/Rating';
import { BookingType } from '@/hooks/useBookingType';

export function MapHotelCard({
  images,
  hotelName,
  rating,
  translate,
  bookingType,
  price,
  brandColor,
}) {
  return (
    <Styled.Container>
      <Styled.Carousel images={images} animation="fade" />
      <Styled.HotelInfo>
        <Styled.HotelName>{hotelName}</Styled.HotelName>
        <Rating
          stars={rating.stars}
          value={rating?.value}
          opinionsCount={rating.opinionsCount}
          translate={translate}
        />
      </Styled.HotelInfo>
      <Styled.PriceContainer>
        <Styled.ProductType>
          {bookingType === BookingType.FlightAndHotel
            ? translate('general.product.dp')
            : translate('general.product.hotel')}
        </Styled.ProductType>
        <Styled.PriceText color={brandColor}>
          {price.priceFormatted}
        </Styled.PriceText>
      </Styled.PriceContainer>
    </Styled.Container>
  );
}
