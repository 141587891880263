type GetExtraFeeInfoProps = {
  extraFeeAmountFormatted: string | null;
};
export function getExtraFeeInfo({
  extraFeeAmountFormatted,
}: GetExtraFeeInfoProps) {
  return (
    extraFeeAmountFormatted && {
      text: 'general.due_time.due_later',
      tooltipInfo: {
        text: 'general.due_time.due_later_text',
        extraFeeAmountFormatted,
      },
    }
  );
}
