import { Inbound, Outbound } from '@/services/codegen-romulo';
import { MapperTransportObject } from '../types/MapperTransportObject';
import { TransportType } from '../types/TransportType';

export const dpWayAdapter = (el: Outbound | Inbound): MapperTransportObject => {
  return {
    segments: el.segments.map((segment) => ({
      isoZonedDepartureDateTime: segment.isoZonedDepartureDateTime,
      isoZonedArrivalDateTime: segment.isoZonedArrivalDateTime,
      departureId: segment.departureId,
      supplierId: segment.supplier.id,
      transportType: segment.transportType as TransportType,
    })),
    isoZonedDepartureDateTime: el.isoZonedDepartureDateTime,
    departureId: el.departureId,
    isoZonedArrivalDateTime: el.isoZonedArrivalDateTime,
    arrivalId: el.arrivalId,
    transportType: TransportType.FLIGHT,
    durationInMinutes: el.durationInMinutes,
    handBaggageIncluded: el.handBaggageIncluded,
    baggageIncluded: el.baggageIncluded,
  };
};
