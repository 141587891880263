import * as React from 'react';
import {
  BusIcon,
  NoAirlineLogoIcon,
  NoTrainCompanyLogoIcon,
} from '@/components/LmnIcons/LmnIcons';
import { JourneyContractSegmentTransportType } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/JourneyContract';
import { getTransportIconByType, reverseIcon } from './reverseIcon';
import * as Styled from './LogoFallback.styled';

const logosBaseUrl =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/c_fill,w_40,q_auto,f_auto/v1/DAM/Commercial%20Partners/Airlines/Icons';

type LogoWithFallbackProps = {
  companyCode: string;
  isInbound: boolean;
  transportType: JourneyContractSegmentTransportType;
  logoSize: number;
};

export function LogoFallback({
  companyCode,
  isInbound,
  transportType,
  logoSize,
}: LogoWithFallbackProps) {
  const [hasError, setHasError] = React.useState<boolean>(false);

  return hasError ? (
    <Styled.Fallback logoSize={logoSize}>
      {reverseIcon(
        getTransportIconByType(
          NoAirlineLogoIcon,
          NoTrainCompanyLogoIcon,
          BusIcon,
          transportType
        ),
        isInbound
      )}
    </Styled.Fallback>
  ) : (
    <Styled.LogoInnerContainer logoSize={logoSize}>
      <img
        alt={companyCode}
        src={`${logosBaseUrl}/${companyCode}`}
        onError={() => setHasError(true)}
      />
    </Styled.LogoInnerContainer>
  );
}
