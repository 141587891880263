import { useTranslate } from '@tolgee/react';

const getInfoPerLuggageTypePerTravellerType = (
  luggagesByTraveller: any,
  travellerType: string,
  luggageType: string,
  direction: number
) => {
  return luggagesByTraveller
    ?.find((traveller) => traveller.travellerType === travellerType)
    ?.luggages?.[
      direction
    ]?.luggageCommunications?.find((communication) => communication.type === luggageType);
};

export const LuggageTypeStatus = ({
  luggagesByTraveller,
  luggageType,
  direction,
}) => {
  const { t } = useTranslate();

  const existsInfant = luggagesByTraveller?.find(
    (traveller) => traveller.travellerType === 'INFANT'
  );

  const smallHandLuggage =
    luggageType === 'HAND_LUGGAGE'
      ? getInfoPerLuggageTypePerTravellerType(
          luggagesByTraveller,
          'ADULT',
          'SMALL_HAND_LUGGAGE',
          direction
        )
      : undefined;
  const cabinBagLuggage =
    luggageType === 'HAND_LUGGAGE'
      ? getInfoPerLuggageTypePerTravellerType(
          luggagesByTraveller,
          'ADULT',
          'HAND_LUGGAGE',
          direction
        )
      : undefined;

  const checkedLuggage =
    luggageType === 'CHECKED_LUGGAGE'
      ? getInfoPerLuggageTypePerTravellerType(
          luggagesByTraveller,
          'ADULT',
          'LUGGAGE_INCLUDED',
          direction
        )
      : undefined;

  const infantCheckedLuggage =
    existsInfant && luggageType === 'CHECKED_LUGGAGE'
      ? getInfoPerLuggageTypePerTravellerType(
          luggagesByTraveller,
          'INFANT',
          'LUGGAGE_INCLUDED',
          direction
        )
      : undefined;

  return (
    <>
      {existsInfant ? (
        <>{`${t('general.passengers')} (2+ ${t(
          'general.time.years'
        ).toLowerCase()})`}</>
      ) : (
        <></>
      )}
      <ul>
        {luggageType === 'HAND_LUGGAGE' ? (
          smallHandLuggage?.label ? (
            <li>{smallHandLuggage.label}</li>
          ) : (
            <li>{t('form.product.luggage.modal.small_bag_description')}</li>
          )
        ) : (
          <></>
        )}
        {luggageType === 'HAND_LUGGAGE' ? (
          cabinBagLuggage?.label ? (
            <li>{cabinBagLuggage?.label}</li>
          ) : (
            <li>{t('form.product.luggage.modal.cabin_bag_description')}</li>
          )
        ) : (
          <></>
        )}
        {luggageType === 'CHECKED_LUGGAGE' ? (
          checkedLuggage?.label ? (
            <li>{checkedLuggage?.label}</li>
          ) : (
            <li>{t('form.product.luggage.modal.not_included')}</li>
          )
        ) : (
          <></>
        )}
      </ul>
      {existsInfant &&
        luggageType === 'CHECKED_LUGGAGE' &&
        infantCheckedLuggage?.label && (
          <>
            <p>{`${t('general.traveller_type.infants')} (0-23 ${t(
              'general.time.months'
            ).toLowerCase()})`}</p>
            <ul>
              <li>{infantCheckedLuggage?.label}</li>
            </ul>
          </>
        )}
    </>
  );
};
