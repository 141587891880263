import { Modal } from '@/components/Modal';
import { Alert } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useConfirmReservation } from '../../hooks/useConfirmReservation';

export function ErrorModal() {
  const { t } = useTranslate();

  const { reservationError, setReservationMessage } = useConfirmReservation();

  return (
    <Modal
      title={t('error.checkout.title')}
      isModalOpen={!!reservationError}
      onCloseModal={() => setReservationMessage(undefined)}
    >
      <Alert severity={reservationError?.type}>
        {reservationError?.message}
      </Alert>
    </Modal>
  );
}
