import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { transportProposalWaytoTransportRowAdapter } from '@/views/FlightsSearch/useGetTransports/utils/transportProposalWaytoTransportRowAdapter';
import { flightDetailsMap } from '@/views/HotelsFlightsSearch/utils';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import { MoonIcon } from '../LmnIcons/LmnIcons';
import { Modal } from '../Modal';
import { TransportDetail } from '../TransportDetail';
import * as Styled from './TransportCard.styled';
import { TransportationCardProps } from './TransportCard.types';
import { TransportInfo } from './TransportInfo';

export function TransportCard({
  transport,
  airports,
  airlines,
  numberOfNights,
  destination,
}: TransportationCardProps) {
  const { t } = useTranslate();
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const outbound = transportProposalWaytoTransportRowAdapter(
    transport?.outbound,
    airports,
    airlines,
    true
  );

  const inbound = transportProposalWaytoTransportRowAdapter(
    transport?.inbound,
    airports,
    airlines,
    false
  );

  const flightMap = flightDetailsMap(
    t,
    { outbound: transport?.outbound, inbound: transport?.inbound },
    airports,
    airlines
  );

  //NOTE: check translate

  return (
    <>
      <Styled.Container onClick={() => setIsModalOpen(true)}>
        <Styled.InnerContainer>
          <Styled.TransportContainer>
            <Date>{outbound.dateAsString}</Date>
            <TransportInfo
              companies={outbound.companies}
              departureInfo={outbound.departureInfo}
              stops={outbound.stops}
              stopsLabel={outbound.stopsLabel}
              arrivalInfo={outbound.arrivalInfo}
              duration={outbound.duration}
              baggageInfo={outbound.baggageInfo}
              segmentsInfo={outbound.segmentsInfo}
              inLine
            />
          </Styled.TransportContainer>
          <Styled.Divider />
          <Styled.TransportContainer>
            <Date>{inbound.dateAsString}</Date>
            <TransportInfo
              companies={inbound.companies}
              departureInfo={inbound.departureInfo}
              stops={inbound.stops}
              stopsLabel={inbound.stopsLabel}
              arrivalInfo={inbound.arrivalInfo}
              duration={inbound.duration}
              baggageInfo={inbound.baggageInfo}
              segmentsInfo={inbound.segmentsInfo}
              inLine
            />
          </Styled.TransportContainer>
        </Styled.InnerContainer>
      </Styled.Container>
      <Modal
        title={t('TripDetails')}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
      >
        <TransportDetail
          locale={'it'}
          isInbound={false}
          {...flightMap.outbound}
          isFreeFlight={isFreeFlight}
        />
        <Styled.AlertContainer>
          <Styled.Alert
            type="info"
            icon={<MoonIcon />}
            showIcon
            message={
              'message'
              // numberOfNights === 1
              //   ? t('widget.trip_card.way.night.in.destination', undefined, [
              //       { key: '__destination__', value: destination },
              //     ])
              //   : t('widget.trip_card.way.nights.in.destination', undefined, [
              //       { key: '__destination__', value: destination },
              //       { key: '__nights__', value: amountOfNights.toFixed() },
              //     ])
            }
            $withoutBorder={true}
          />
        </Styled.AlertContainer>
        <TransportDetail
          locale={'it'}
          isInbound={true}
          {...flightMap.inbound}
          isFreeFlight={isFreeFlight}
        />
      </Modal>
    </>
  );
}
