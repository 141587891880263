import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  padding: 16px;

  @media (max-width: 767px) {
    border-top: 1px solid #eaeaeb;
  }
`;

export const OriginalPrice = styled.span`
  color: #8c8c92;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
`;

export const OfferPrice = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #f2007d;
  line-height: 28px;
  display: flex;
  align-items: baseline;
`;

export const PriceUnity = styled.span`
  font-size: 14px;
  color: #f2007d;
  line-height: 16px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const PriceUnityMobile = styled.span`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #f2007d;
  line-height: 16px;

  @media (min-width: 767px) {
    display: none;
  }
`;

export const ExtraMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #74747c;
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
`;

export const ExtraMessageLabel = styled.span`
  margin-right: 4;
  line-height: 16px;
`;

export const TotalPrice = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  text-align: right;
  font-weight: 700;
  letter-spacing: 0.01em;
`;

export const TopSecretLabel = styled.div`
  border-radius: 4px;
  min-height: 28px;
  padding: 2px 8px 2px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/tsh_card.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 14px;
  background-color: #65277f;
  color: #fff;
  font-weight: 700;
`;

export const LastMinuteLabel = styled.div`
  border-radius: 4px;
  min-height: 28px;
  padding: 2px 8px 2px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-image: url(https://www.lastminute.com/s/hdp/search/images/icons/last_minute_deals.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 14px;
  background-color: #f2007d;
  color: #fff;
  font-weight: 700;
`;

export const LabelContainer = styled.div`
  max-width: 100%;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1190px) {
    flex-flow: wrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
