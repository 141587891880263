import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Alert as MuiAlert,
  Radio as MuiRadio,
} from '@mui/material';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #eaeaeb;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Section = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const FlightDetail = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Strong = styled.strong`
  text-decoration: underline;
`;
export const Alert = styled(MuiAlert)`
  cursor: pointer;
`;

export const LogoColumn = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
`;
export const DirectionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlightRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const SegmentContainer = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 10px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0px;

    > div:not(:last-child) {
      border-bottom: 1px solid #eaeaeb;
    }
    border: 1px solid #eaeaeb;
    border-radius: 8px;
    padding: 4px 16px;
  }
`;

export const OptionContainer = styled.div<{
  $selected: boolean;
  primarycolorbg: string;
}>`
  flex: 1 1 calc(33.33% - 8px);
  padding: 16px;
  transition: box-shadow 0.2s ease-in-out 0s;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0);
  &:hover {
    box-shadow:
      0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 10px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.02);
  }

  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eaeaeb;
  ${({ $selected, primarycolorbg }) =>
    $selected &&
    css`
      padding: calc(14px);
      border: 3px solid ${primarycolorbg};
    `}

  @media (max-width: 992px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 8px 0px;
    &:hover {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0);
    }
    border: unset;
    border-radius: 0px;
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 8px;

  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
    margin-bottom: 0px;
  }
`;

export const Accordion = styled(MuiAccordion)`
  background-color: white !important;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02) !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 8px !important;

  & .MuiAccordionDetails-root {
    padding: 0px 16px 16px;
  }

  & .MuiButtonBase-root {
    height: 56px;
  }

  &:before {
    background-color: unset;
  }
`;

export const PassengerName = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const LuggageName = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const BoundDirection = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const DirectionDetails = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
`;

export const BoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
export const ChiContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PassengerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 992px) {
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const IncludedContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 992px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
  }
`;

export const LuggageNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 992px) {
    align-items: flex-start;
  }
`;

export const LuggageDetails = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
  margin-right: 8px;
  text-align: center;
  @media (max-width: 992px) {
    text-align: left;
  }
`;

export const SelectedLuggageDetails = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
  margin-right: 8px;
  text-align: right;
`;

export const Radio = styled(MuiRadio)`
  padding: 0px;
  height: 24px !important;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
