import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const OptionContainer = styled.div<{
  $selected: boolean;
  primarycolorbg: string;
}>`
  width: 100%;
  transition: box-shadow 0.2s ease-in-out 0s;
  border-radius: 8px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid white;
  transition: 0.7s;
  ${({ $selected, primarycolorbg }) =>
    $selected &&
    css`
      border: 3px solid ${primarycolorbg};
    `}
`;

export const OptionTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  font-family: 'Open Sans';
`;

export const ContentContainer = styled.div`
  padding: 16px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eaeaeb;
  padding: 12px 8px;
  gap: 6px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalAmount = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
  font-family: 'Open Sans';
`;

export const FeesAmount = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
  font-family: 'Open Sans';
`;

export const DetailRowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  justify-content: space-between;
`;

export const DetailRowTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainTextRow = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
export const DetailTextRow = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
`;
