import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import CheckIcon from '@mui/icons-material/Check';
import { Stepper as MuiStepper, StepIconProps } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Stepper.styled';

const steps = ['search', 'details', 'personalise', 'review', 'done'];

interface StepperProps {
  currentStep: number;
}

export const Stepper: FC<StepperProps> = ({ currentStep }) => {
  const freeFlightCampaign = useFreeFlightCampaign();
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, icon } = props;

    return (
      <Styled.ColorlibStepIconRoot ownerState={{ completed, active }}>
        {completed ? <CheckIcon /> : <span>{icon}</span>}
      </Styled.ColorlibStepIconRoot>
    );
  }

  return (
    <Styled.StepperContainer>
      <MuiStepper
        activeStep={currentStep}
        alternativeLabel
        connector={
          <Styled.Connector primarycolor={brandConfig.theme.primaryColor} />
        }
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {t(`checkout.steps.${label}`)}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Styled.StepperContainer>
  );
};
