import styled from '@emotion/styled';
import { FormControlLabel, RadioGroup as MuiRadioGroup } from '@mui/material';

export const Container = styled.div``;

export const Option = styled(FormControlLabel)`
  border: 1px solid #eaeaeb;
  border-radius: 8px;
  padding: 12px 16px;
  margin-left: 0px;
  margin-right: 0px;

  & .MuiButtonBase-root {
    padding: 0px;
  }

  & .MuiTypography-root {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 12px;
    color: #3c3c3c;
  }
`;

export const RadioGroup = styled(MuiRadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ErrorMsg = styled.span`
  margin-top: -4px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #d32f2f;
`;
