import { Tooltip, theme } from 'antd';
import {
  BackPackIcon,
  BaggageCheckedIcon,
  CarryOnIcon,
  NoBaggageCheckedIcon,
  NoCarryOnIcon,
} from '../../LmnIcons/LmnIcons';
import { LuggageTooltip } from './LuggageTooltip';
import { LuggageDetailsProps } from './LuggageDetails.types';
import * as Styled from './LuggageDetails.styled';

export function LuggageDetails({
  carryOnIncluded,
  checkedBaggageIncluded,
}: LuggageDetailsProps) {
  const { token } = theme.useToken();
  return (
    <Tooltip
      title={
        <LuggageTooltip
          carryOnIncluded={carryOnIncluded}
          checkedBaggageIncluded={checkedBaggageIncluded}
        />
      }
      placement="top"
    >
      <Styled.Luggage>
        <BackPackIcon />
        {carryOnIncluded ? (
          <CarryOnIcon />
        ) : (
          <NoCarryOnIcon style={{ color: token.colorTextDisabled }} />
        )}
        {checkedBaggageIncluded ? (
          <BaggageCheckedIcon />
        ) : (
          <NoBaggageCheckedIcon style={{ color: token.colorTextDisabled }} />
        )}
      </Styled.Luggage>
    </Tooltip>
  );
}
