import { CircleInfo } from './CircleInfo';
import { useTranslate } from '@tolgee/react';
import { PriceProps } from './Price.types';
import * as Styled from './Price.styled';
import { AgencyFee } from '@/components/FlightCard/AgencyFee';

export function Price({
  isLastMinute,
  isTopSecret,
  originalPrice,
  totalPrice,
  offerPrice,
  extraMessage,
  agencyFee,
}: PriceProps) {
  const { t } = useTranslate();

  return (
    <Styled.Container>
      <Styled.LabelContainer>
        {isTopSecret && (
          <Styled.TopSecretLabel>Top Secret®</Styled.TopSecretLabel>
        )}
        {isLastMinute && (
          <Styled.LastMinuteLabel>Last Minute</Styled.LastMinuteLabel>
        )}
      </Styled.LabelContainer>
      <Styled.OriginalPrice>{originalPrice}</Styled.OriginalPrice>
      <Styled.OfferPrice>
        {offerPrice}
        <Styled.PriceUnityMobile>pp</Styled.PriceUnityMobile>
      </Styled.OfferPrice>
      <Styled.PriceUnity>
        {' '}
        {t('general.price.per_person').toLowerCase()}
      </Styled.PriceUnity>
      {totalPrice && <Styled.TotalPrice>{totalPrice}</Styled.TotalPrice>}
      {agencyFee && <AgencyFee fee={agencyFee} />}
      {extraMessage && (
        <Styled.ExtraMessage>
          <Styled.ExtraMessageLabel>
            {extraMessage.label}
          </Styled.ExtraMessageLabel>
          {extraMessage.popup && <CircleInfo />}
        </Styled.ExtraMessage>
      )}
    </Styled.Container>
  );
}
