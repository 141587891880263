import { CartDetailResponseDto } from '@/services/codegen-romulo';
import { getTimeInTheTimezone } from '@/utils/timeUtils';

const getFlightDates = (startingDate: string, endingDate: string) => {
  const outboundDeparture = getTimeInTheTimezone(
    startingDate,
    undefined,
    false
  );
  const inboundArrival = getTimeInTheTimezone(endingDate, undefined, false);
  const isSameDay = outboundDeparture.isSame(inboundArrival, 'day');

  if (isSameDay) {
    return outboundDeparture.format('DD MMM');
  } else {
    return `${outboundDeparture.format('DD MMM')} - ${inboundArrival.format(
      'DD MMM'
    )}`;
  }
};

export const getProductsSummary = (
  cartDetail: CartDetailResponseDto,
  order: any
) => {
  const flightProduct = cartDetail?.products?.find((e) => e.type === 'FLIGHT');
  const hotelProduct = cartDetail?.products?.find((e) => e.type === 'HOTEL');
  const flight = flightProduct?.detail?.itinerary;

  const from = flight?.outboundTransport[0].segments[0];
  const to =
    flight?.outboundTransport[0].segments[
      flight?.outboundTransport[0].segments.length - 1
    ];

  const arrival = flight?.inboundTransport[0]?.segments[0];
  const departureCity = from?.departureStation.cityName;
  const arrivalCity = to?.destinationStation.cityName;
  const startingDate = from?.departureDateTimeUtc;
  const endingDate = arrival?.departureDateTimeUtc || to?.departureDateTimeUtc;

  const adultsCount = cartDetail?.travellers.filter((e) => e.type === 'ADULT');
  const childrenCount = cartDetail?.travellers.filter(
    (e) => e.type === 'CHILD'
  );
  const infantCount = cartDetail?.travellers.filter((e) => e.type === 'INFANT');

  const dates = flight
    ? getFlightDates(startingDate, endingDate)
    : `${getTimeInTheTimezone(
        hotelProduct?.detail.accommodation.checkInDate
      ).format('DD MMM')} - ${getTimeInTheTimezone(
        hotelProduct?.detail.accommodation.checkOutDate
      ).format('DD MMM')}`;

  const fromToCities = flight
    ? `${departureCity} - ${arrivalCity}`
    : arrivalCity;

  return {
    fromToCities,
    dates,
    hasFlight: !!flightProduct,
    hasHotel: !!hotelProduct,
    adultsNumber: adultsCount?.length,
    childrenNumber:
      childrenCount?.length > 0 ? childrenCount.length : undefined,
    infantNumber: infantCount?.length > 0 ? infantCount.length : undefined,
    orderId: order?.orderId,
  };
};
