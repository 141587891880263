import { CartApiServiceService, Traveller } from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import { create } from 'zustand';
import { TravellerDetailsValues } from './useTravellerDetailsDefaultValues';

interface TravellerDetailsState {
  travellersDetails: { [K: number]: TravellerDetailsValues };
  setTravellerDetails: (
    travellersDetails: TravellerDetailsValues,
    travellerId: number
  ) => void;
  travellersInputErrors: {
    [K: number]: { [K in keyof TravellerDetailsValues]: boolean };
  };
  setTravellerInputErrors: (
    travellersInputErrors: {
      [K in keyof TravellerDetailsValues]: boolean;
    },
    travellerId: number
  ) => void;
  addTravellers: (cartId: string, uuid: string) => Promise<boolean>;
  validateTravellersValues: () => boolean;
}

export const useTravellerDetailsStore = create<TravellerDetailsState>(
  (set, get) => ({
    travellersDetails: [],
    travellersInputErrors: [],
    setTravellerDetails: (
      travellersDetails: TravellerDetailsValues,
      travellerId: number
    ) => {
      set({ travellersDetails: { [travellerId]: travellersDetails } });
    },
    setTravellerInputErrors: (
      travellersInputErrors: {
        [K in keyof TravellerDetailsValues]: boolean;
      },
      travellerId: number
    ) =>
      set({ travellersInputErrors: { [travellerId]: travellersInputErrors } }),
    addTravellers: async (cartId: string, uuid: string) => {
      const travellersData = get().travellersDetails;
      const travellersInputErrors = get().travellersInputErrors;

      const addTravellerRequest: Traveller[] = Object.entries(
        travellersData
      ).map(({ 0: travellerId, 1: travellersDetails }) => {
        const hasDocumentItems = !!travellersDetails.documentType;
        return {
          id: Number(travellerId),
          name: travellersDetails.name,
          surname: travellersDetails.surname,
          gender: travellersDetails.gender,
          dateOfBirth: dayjs(travellersDetails.dateOfBirth).format(
            'YYYY-MM-DD'
          ),
          nationality: travellersDetails.nationality,
          document: hasDocumentItems
            ? {
                type: travellersDetails.documentType,
                number: travellersDetails.documentNumber,
                issueDate: dayjs(travellersDetails.documentEmissionDate).format(
                  'YYYY-MM-DD'
                ),
                issueCountry: travellersDetails.documentEmissionDate,
                expiryDate: dayjs(
                  travellersDetails.documentExpirationDate
                ).format('YYYY-MM-DD'),
              }
            : undefined,
        };
      });

      try {
        return await CartApiServiceService.postApiServicesAppCartApiServiceAddTravellerToCart(
          {
            cartId,
            requestUuid: uuid,
            requestBody: addTravellerRequest,
          }
        );
      } catch (error) {
        console.log(
          'postApiServicesAppCartApiServiceAddTravellerToCart error',
          error
        );
        throw new Error(error);
      }
    },
    validateTravellersValues: () => {
      const { travellersDetails, setTravellerInputErrors } = get();

      const resultValidation = Object.entries(travellersDetails).map(
        ([travellerId, travellerDetails]) => {
          const validation = Object.entries(travellerDetails).reduce(
            (acc, [key, value]) => {
              acc[key] = !!!value;
              return acc;
            },
            {} as { [K in keyof TravellerDetailsValues]: boolean }
          );
          setTravellerInputErrors(validation, Number(travellerId));

          return Object.values(validation).every((value) => value === false);
        }
      );

      if (resultValidation.every((value) => value === true)) {
        return true;
      } else {
        return false;
      }
    },
  })
);
