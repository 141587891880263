import { LocationServiceService } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { formatDestination } from '../utils/formatDestination';

const useGetDestinations = () => {
  const brandConfig = useStore((state) => state.brandConfig);

  const getDestinations = async (phrase: string) => {
    // const language = brandConfig.language;
    const language = 'it-IT';

    const response =
      await LocationServiceService.postApiServicesAppLocationServiceGetDestinations(
        {
          requestBody: {
            language,
            phrase,
            courseType: 'Departure',
            purpose: 'Package',
          },
        }
      );

    return response.items.flatMap((e, index) => formatDestination(e, index));
  };

  return getDestinations;
};

export { useGetDestinations };
