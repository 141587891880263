import styled from '@emotion/styled';

export const Container = styled.div`
  border-radius: 8px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  padding: 16px;
  background-color: #fff;
  padding: 20px 16px;
  width: 300px;

  @media (max-width: 992px) {
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ContainerSkeletonList = styled.div`
  > *:not(:last-child) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
