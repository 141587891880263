import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment, useState } from 'react';
import * as Styled from '../Subscriptions.styled';
import {
  privacyRewardwave,
  rewardwaveTermsAndConditions,
} from '../utils/commonValues';
import { getBaseUrl } from '../utils/getBrandBase';
import CancellationModal from './CancellationModal';
export const DPContractPolicy: FC = () => {
  const { t } = useTranslate();
  const [cartConfig, brandConfig, cartDetail] = useStore((state) => [
    state.cartConfig,
    state.brandConfig,
    state.cartDetail,
  ]);
  const { brand, footer } = brandConfig;
  const baseUrl = getBaseUrl(brand.brandBase);
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);

  const termsAndConditionUrl = cartConfig?.subscription?.termsAndCondition?.url;
  const contractPolicy = cartConfig?.subscription.contractPolicy;

  const flight = cartDetail?.products?.find((e) => e.type === 'FLIGHT');
  const hotel = cartDetail?.products?.find((e) => e.type === 'HOTEL');

  return (
    <Fragment>
      <Styled.LegalText>
        {brand.brandOrigin == 'Roiward' ? (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'form.subscriptions.contract_policy.rewardwave.dp.description_pt_1',
                  '',
                  {
                    termsAndConditions: footer?.custom?.termsAndConditionsUrl,
                    rewardwaveTermsAndConditions: rewardwaveTermsAndConditions,
                    buttonText: t(
                      brandConfig.checkout?.createReservationButtonText
                    ),
                  }
                )}`,
              }}
            />{' '}
            <a rel="noopener" onClick={() => setCancellationModalOpen(true)}>
              {t(
                'form.subscriptions.contract_policy.common.dp.description_pt_2'
              )}
            </a>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${t(
                  'form.subscriptions.contract_policy.rewardwave.dp.description_pt_3',
                  '',
                  {
                    privacy: footer?.custom?.privacyUrl,
                    privacyRewardwave: privacyRewardwave,
                  }
                )}`,
              }}
            />
          </>
        ) : (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'form.subscriptions.contract_policy.lastminute.dp.description_pt_1',
                  '',
                  {
                    termsAndConditions: `${baseUrl}${termsAndConditionUrl}`,
                    buttonText: t(
                      brandConfig.checkout?.createReservationButtonText
                    ),
                  }
                )}`,
              }}
            />
            <a rel="noopener" onClick={() => setCancellationModalOpen(true)}>
              {t(
                'form.subscriptions.contract_policy.common.dp.description_pt_2'
              )}
            </a>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${t(
                  'form.subscriptions.contract_policy.lastminute.dp.description_pt_3',
                  '',
                  {
                    privacy: `${baseUrl}${contractPolicy.url}`,
                  }
                )}`,
              }}
            />
          </>
        )}
      </Styled.LegalText>
      <CancellationModal
        flight={!!flight}
        isModalOpen={cancellationModalOpen}
        onCloseModal={() => setCancellationModalOpen(false)}
        hotel={{
          cancellationDeadline:
            hotel?.detail.accommodation?.cancelPenalties[0].deadLine,
          cost: hotel?.detail.accommodation?.cancelPenalties[0].amount,
          freeCancellation: hotel?.detail.accommodation?.freeCancellation,
        }}
      />
    </Fragment>
  );
};
