import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  @media (min-width: 996px) {
    max-width: 400px;
  }
`;
export const TextField = styled(MuiTextField)`
  flex: 1;
`;

export const Button = styled.button<{ $color: string }>`
  align-items: center;
  background: ${({ $color }) => $color};
  border-radius: 8px;
  border: none;
  box-shadow: ${({ $color }) => $color} 0px 0px 0px 0px inset;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 56px;
  min-height: 56px;
  line-height: 56px;
  justify-content: center;
  outline: none;
  padding: 0px;
  transition: all 0.2s ease 0s;
  width: 56px;
`;
