import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: 744px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const Card = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BlockTitle = styled.h5`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3c3c3c;
  margin: 0px 0px 0.5rem;
`;

export const BlockContent = styled.p`
  color: #3c3c3c;
  font-size: 14px;
  line-height: 1.5rem;
  margin: 0px;
`;

export const ExpandMoreButton = styled.button`
  cursor: pointer;
  color: red;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: unset;
  background-color: unset;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
`;
