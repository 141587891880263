import { WAY_TRANSPORT_TYPES } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/WayTransporTypes';

export function getWayTypeKey(
  segmentTypes: WAY_TRANSPORT_TYPES[],
  isInbound: boolean
): { key: string; placeholders: string[] } {
  const placeholdersTypeKey = {
    [WAY_TRANSPORT_TYPES.FLIGHT]: 'widget.trip_card.way.flight',
    [WAY_TRANSPORT_TYPES.TRAIN]: 'widget.trip_card.way.train',
    [WAY_TRANSPORT_TYPES.BUS]: 'widget.trip_card.way.bus',
  };

  return {
    key: isInbound
      ? 'widget.trip_card.way.inbound'
      : 'widget.trip_card.way.outbound',
    placeholders: segmentTypes.map((type) => placeholdersTypeKey[type]),
  };
}
