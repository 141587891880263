import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import * as Styled from './GeneralInformation.styled';
const blocks = [1, 2, 3, 4];

export const GeneralInformation: FC = () => {
  const [expanded, setExpanded] = useState(blocks.length > 2 ? false : true);
  const { t } = useTranslate();
  return (
    <Styled.ContentContainer>
      <Styled.SectionTitle>General information</Styled.SectionTitle>
      <Styled.Card>
        {(expanded ? blocks : blocks.slice(0, 2)).map((e) => (
          <div>
            <Styled.BlockTitle>Location</Styled.BlockTitle>
            <Styled.BlockContent>
              When you a stay at Hôtel Restaurant Le Filosorma in Galeria,
              you'll be in the entertainment district, just steps from Regional
              Natural Park of Corsica and Corsica Beaches. This hotel is 0.1 mi
              (0.2 km) from Calanche of Piana, Gulf of Girolata, Scandola
              Reserve and 1.2 mi (1.9 km) from Calca Tavulaghjiu Lake.
            </Styled.BlockContent>
          </div>
        ))}
        <Styled.ExpandMoreButton onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Hide hotel details' : 'Read more about this hotel'}{' '}
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Styled.ExpandMoreButton>
      </Styled.Card>
    </Styled.ContentContainer>
  );
};
