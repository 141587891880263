import {
  CartApiServiceService,
  ContactInputDto,
} from '@/services/codegen-romulo';
import countriesWithISO from '@/utils/dictionaries/countriesWithISO.json';
import { create } from 'zustand';
import { ContactDetailsValues } from './useContactDetailsDefaultValues';

interface FreeFlightVoucherInfo {
  voucherCode: string;
  email: string;
}

interface ContactDetailsState {
  contactDetails: ContactDetailsValues;
  setContactDetails: (contactDetails: ContactDetailsValues) => void;

  inputErrors: { [K in keyof ContactDetailsValues]: boolean };
  setInputErrors: (inputErrors: {
    [K in keyof ContactDetailsValues]: boolean;
  }) => void;
  addContact: (cartId: string, uuid: string) => Promise<boolean>;
  validateContactValues: (isFreeFlight?: boolean) => boolean;

  freeFlightVoucherInfo: FreeFlightVoucherInfo;
  freeFlightVoucherIsValid: boolean;
  SetFreeFlightVoucherIsValid: (value: boolean) => void;
  freeFlightVoucherError: string | undefined;
  setfreeFlightVoucherError: (value: string) => void;
  addFreeFlightVoucherInfo: (
    freeFlightVoucherInfo: FreeFlightVoucherInfo
  ) => void;
  validateVoucher: () => boolean;
}

export const useContactDetailsStore = create<ContactDetailsState>(
  (set, get) => ({
    contactDetails: undefined,
    inputErrors: undefined,

    setContactDetails: (contactDetails: ContactDetailsValues) =>
      set({ contactDetails }),
    setInputErrors: (inputErrors: {
      [K in keyof ContactDetailsValues]: boolean;
    }) => set({ inputErrors }),

    addContact: async (cartId: string, uuid: string) => {
      const { contactDetails } = get();

      const addContactToCartRequestBody: ContactInputDto = {
        name: contactDetails.name,
        surname: contactDetails.surname,
        email: contactDetails.email,
        isTraveller: contactDetails.isTraveller === 'true',
        phone: {
          phoneNumber: contactDetails.phoneNumber,
          isoCode: contactDetails.phoneIsoCode,
          dialCode: countriesWithISO.find(
            (e) => e.countryCode === contactDetails.phoneIsoCode
          ).phoneCode,
        },
        address: {
          city: contactDetails.addressCity,
          postCode: contactDetails.addressPostalCode,
          countryIsoCode: contactDetails.nationality,
          streetName: contactDetails.addressStreetName,
          streetNumber: contactDetails.addressStreetNumber,
        },
      };
      try {
        return await CartApiServiceService.postApiServicesAppCartApiServiceAddContactToCart(
          {
            cartId,
            requestUuid: uuid,
            requestBody: addContactToCartRequestBody,
          }
        );
      } catch (error) {
        console.log(
          'postApiServicesAppCartApiServiceAddContactToCart error',
          error
        );
        return error;
      }
    },
    validateContactValues: (isFreeFlight?: boolean) => {
      const { contactDetails, freeFlightVoucherInfo, setInputErrors } = get();

      const validation = Object.entries(contactDetails).reduce(
        (acc, [key, value]) => {
          if (isFreeFlight && key === 'email') {
            acc[key] = freeFlightVoucherInfo?.email !== value;
          } else {
            acc[key] = !!!value;
          }
          return acc;
        },
        {} as { [K in keyof ContactDetailsValues]: boolean }
      );

      setInputErrors(validation);
      if (
        Object.entries(validation).every(([key, value]) => {
          if (key === 'isTraveller') {
            return false;
          } else {
            return value === false;
          }
        })
      ) {
        return false;
      } else {
        return true;
      }
    },

    freeFlightVoucherInfo: undefined,
    freeFlightVoucherIsValid: false,
    freeFlightVoucherError: undefined,
    addFreeFlightVoucherInfo: (freeFlightVoucherInfo: FreeFlightVoucherInfo) =>
      set({ freeFlightVoucherInfo }),
    SetFreeFlightVoucherIsValid: (freeFlightVoucherIsValid: boolean) =>
      set({ freeFlightVoucherIsValid }),
    setfreeFlightVoucherError: (freeFlightVoucherError: string) =>
      set({ freeFlightVoucherError }),
    validateVoucher: () => {
      const { freeFlightVoucherInfo, setfreeFlightVoucherError } = get();
      if (!freeFlightVoucherInfo) {
        setfreeFlightVoucherError('form.validation.voucher_code');
        return false;
      } else {
        return true;
      }
    },
  })
);
