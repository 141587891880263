import { SeatNotAvailableIcon } from '@/components/LmnIcons/LmnIcons';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import * as Styled from './SeatSelectionMessage.styled';

export function SeatSelectionMessage({ companyName }: { companyName: string }) {
  const { t } = useTranslate();

  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!isExpanded);

  //NOTE : check translate
  return (
    <Styled.Container>
      <Styled.IconContainer>
        <SeatNotAvailableIcon />
      </Styled.IconContainer>
      <Styled.TextContainer>
        <p>{t('widget.trip_card.way.segment.seat.selection')}</p>
        {isExpanded && (
          <p>
            {/* {t(
              'widget.trip_card.way.segment.seat.selection.expanded',
              undefined,
              [{ key: '__companyName__', value: companyName }]
            )} */}
          </p>
        )}
        <Styled.ExpandButton onClick={toggleExpand} role="button">
          {isExpanded ? (
            <>
              {t('widget.trip_card.show.less')}
              <Styled.ExpandButtonIconContainer>
                <ArrowUpOutlined size={14} />
              </Styled.ExpandButtonIconContainer>
            </>
          ) : (
            <>
              {t('widget.trip_card.show.more')}
              <Styled.ExpandButtonIconContainer>
                <ArrowDownOutlined size={14} />
              </Styled.ExpandButtonIconContainer>
            </>
          )}
        </Styled.ExpandButton>
      </Styled.TextContainer>
    </Styled.Container>
  );
}
