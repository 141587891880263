import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #babac0;
  color: #3c3c3c;
  height: 54px;
  padding: 16px;
  border-radius: 8px;
`;

export const Text = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
`;

export const ModalFooterPriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
`;

export const ModalFooterPrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #f2007d;
  line-height: 28px;
  display: flex;
`;

export const ModalFooterPriceUnity = styled.span`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #f2007d;
  line-height: 16px;
  align-self: center;
`;

export const ModalFooterExtraMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #74747c;
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
`;

export const ModalFooterExtraMessageLabel = styled.span`
  margin-right: 4;
  line-height: 16px;
`;
