import styled from '@emotion/styled';
export * from '../../Filters.styled';

export const RowContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const RowTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RowSwitch = styled.div`
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
`;
