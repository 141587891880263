import styled from '@emotion/styled';
import { LoadingButton as LoadingButtonMui } from '@mui/lab';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const LoadingButton = styled(LoadingButtonMui)`
  width: 100%;
  @media (min-width: 768px) {
    width: 279px;
  }
`;
