import {
  JourneyContractSegment,
  JourneyContractSegmentLocation,
  JourneyContractSegmentTransport,
  JourneyContractSegmentTransportType,
  JourneyContractTechnicalStop,
} from './JourneyContract';

export type TripBaggageAdapted = {
  handIncluded?: boolean;
  holdIncluded?: boolean;
};

export class Segment {
  private readonly technicalStops?: Array<JourneyContractTechnicalStop>;

  private readonly transport: JourneyContractSegmentTransport;

  private readonly departure: JourneyContractSegmentLocation;

  private readonly arrival: JourneyContractSegmentLocation;

  private readonly baggage: TripBaggageAdapted;

  private readonly isFirstSegment: boolean;

  private readonly isLastSegment: boolean;

  private readonly duration?: number;

  private isChangingLocation: boolean;

  private isChangingTransportType: boolean;

  private differentDeparture: boolean;

  private differentArrival: boolean;

  private differentDayArrival?: string;

  private differentDayDeparture?: string;

  constructor(
    journeySegment: JourneyContractSegment & {
      baggage: TripBaggageAdapted;
      isFirstSegment: boolean;
      isLastSegment: boolean;
      duration?: number;
      differentArrival?: boolean;
      differentDeparture?: boolean;
      differentDayArrival?: string;
      differentDayDeparture?: string;
      isChangingLocation?: boolean;
      isChangingTransportType?: boolean;
    }
  ) {
    this.technicalStops = journeySegment.technicalStops;
    this.transport = journeySegment.transport;
    this.departure = journeySegment.departure;
    this.arrival = journeySegment.arrival;
    this.baggage = journeySegment.baggage;
    this.isFirstSegment = journeySegment.isFirstSegment;
    this.isLastSegment = journeySegment.isLastSegment;
    this.differentArrival = journeySegment.differentArrival || false;
    this.differentDeparture = journeySegment.differentDeparture || false;
    this.differentDayArrival = journeySegment.differentDayArrival;
    this.differentDayDeparture = journeySegment.differentDayDeparture;
    this.duration = journeySegment.duration;
    this.isChangingLocation = journeySegment.isChangingLocation || false;
    this.isChangingTransportType =
      journeySegment.isChangingTransportType || false;
  }

  getDifferentDayDeparture() {
    return this.differentDayDeparture;
  }

  getDifferentDayArrival() {
    return this.differentDayArrival;
  }

  getTechnicalStops(): Array<JourneyContractTechnicalStop> | undefined {
    return this.technicalStops;
  }

  getTransport(): JourneyContractSegmentTransport {
    return this.transport;
  }

  getTransportType(): JourneyContractSegmentTransportType {
    return this.getTransport().type;
  }

  getDeparture(): JourneyContractSegmentLocation {
    return this.departure;
  }

  getArrival(): JourneyContractSegmentLocation {
    return this.arrival;
  }

  getBaggage(): TripBaggageAdapted {
    return this.baggage;
  }

  isArrivingAtDifferentLocation(): boolean {
    return this.differentArrival;
  }

  isDepartingOnDifferentLocation(): boolean {
    return this.differentDeparture;
  }

  setIsArrivingAtDifferentLocation(): void {
    this.differentArrival = true;
  }

  setIsDepartingOnDifferentLocation(): void {
    this.differentDeparture = true;
  }

  isFirstWaySegment(): boolean {
    return this.isFirstSegment;
  }

  isLastWaySegment(): boolean {
    return this.isLastSegment;
  }

  setIsChangingLocationNextSegment(): void {
    this.isChangingLocation = true;
  }

  isChangingLocationNextSegment(): boolean {
    return this.isChangingLocation;
  }

  setIsChangingTransportTypeNextSegment(): void {
    this.isChangingTransportType = true;
  }

  isChangingTransportTypeNextSegment(): boolean {
    return this.isChangingTransportType;
  }

  getDuration() {
    return this.duration;
  }
}
