import styled from '@emotion/styled';

export const Container = styled.div`
  background-color: #fff;
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 273px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out 0s;
  display: flex;

  @media (max-width: 767px) {
    min-height: unset;
    flex-direction: column;
  }

  &:hover {
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }
`;

export const HotelLink = styled.a`
  display: flex;
  @media (min-width: 768px) {
    @media (min-width: 768px) {
      width: calc(100% - 220px);
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const CounterContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
`;

export const IconContainer = styled.div`
  z-index: 2;
  left: 70px;
  top: 80px;
`;

export const ImagesCarouselContainer = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ImagesCarouselMobileContainer = styled.div`
  @media (min-width: 767px) {
    display: none;
  }
`;
