import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { CommonElement } from './CommonElement';
import { FreeFlightFooter } from './FreeFlightFooter';
import * as Styled from './LmnFooter.styled';
import { legalText } from './textConstants';

export function LmnFooter() {
  const brandConfig = useStore((state) => state.brandConfig);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const isLocal = window.location.hostname === 'localhost';

  // TODO DO IT DINAMIC
  if (isFreeFlight) {
    return <FreeFlightFooter />;
  }

  if (brandConfig.footer.embebed.scriptUrl && !isLocal) {
    return <CommonElement url={brandConfig.footer.embebed.scriptUrl} />;
  }

  return (
    <Styled.Container>
      <Styled.Text>
        {legalText} {legalText}
      </Styled.Text>
      <img
        src={brandConfig.theme.logoSecondaryUrl}
        alt="page logo"
        height="40px"
      />
    </Styled.Container>
  );
}
