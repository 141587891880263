import '@mapbox-controls/zoom/src/index.css';
import { useGetFiltersData } from '../Filters/hooks/useGetFiltersData';

export const useUpdateMap = () => {
  const { searchId, getHDPProposals } = useGetFiltersData();
  const updateMap = (bounds: mapboxgl.LngLatBounds) => {
    getHDPProposals(searchId, [
      {
        mapBoundingBox: {
          from: {
            latitude: Number(bounds._ne.lat.toFixed(5)),
            longitude: Number(bounds._sw.lng.toFixed(5)),
          },
          to: {
            latitude: Number(bounds._sw.lat.toFixed(5)),
            longitude: Number(bounds._ne.lng.toFixed(6)),
          },
        },
      },
    ]);
  };

  return updateMap;
};
