import type { RequiredDetails } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { getTimeInTheTimezone } from '@/utils/timeUtils';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment } from 'react';
import * as SectionCard from '../../SectionCard/SectionCard.styled';
import { TravellerDetailsValues } from '../hooks/useTravellerDetailsDefaultValues';
import { getComponentByField } from '../utils/getComponentByField';
import { getTravellerType } from '../utils/getTravellerType';
import * as Styled from './TravellerDetails.styled';

export const textRegex = /^[A-Za-zÄäÖöÜüẞß\- ]+$/;

let sortedFields = [
  'GENDER',
  'NAME',
  'SURNAME',
  'BIRTH_DATE',
  'NATIONALITY',
  'DOCUMENT_TYPE',
  'DOCUMENT_NUMBER',
  'EMISSION_DATE',
  'EMISSION_COUNTRY',
  'EXPIRATION_DATE',
];

interface TravellerDetailsProps {
  id?: number;
  travellerType: keyof RequiredDetails | string;
  handleChange: (e: any, travellerId: number) => void;
  handleValidate: (regex: RegExp) => (e: any, travellerId: number) => void;
  formData: { [K in keyof TravellerDetailsValues]: string | number };
  inputErrors: { [K in keyof TravellerDetailsValues]: boolean };
  disabled?: boolean;
}

const TravellerDetails: FC<TravellerDetailsProps> = ({
  id,
  travellerType,
  handleChange,
  formData,
  handleValidate,
  inputErrors,
  disabled,
}) => {
  const { t } = useTranslate();
  const [cartConfig, cartDetail] = useStore((state) => [
    state.cartConfig,
    state.cartDetail,
  ]);
  const requiredDetails = cartConfig?.traveller?.requiredDetails[
    travellerType.toLocaleLowerCase()
  ]?.map((item) => item.fieldType);

  if (!requiredDetails) {
    return null;
  }
  const onlyHotel = !!cartDetail.products.find((e) => e.type === 'HOTEL');

  const bookingDateString = onlyHotel
    ? cartDetail.products.find((e) => e.type === 'HOTEL').detail.accommodation
        .checkInDate
    : cartDetail.products.find((e) => e.type === 'FLIGHT').detail.itinerary
        .outboundTransport[0].segments[0].departureDateTime;

  const bookingDate = getTimeInTheTimezone(bookingDateString, undefined, true);

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title>{`${t('general.traveller')} ${id}`}</Styled.Title>
        <Styled.Subtitle>{getTravellerType(travellerType, t)}</Styled.Subtitle>
      </Styled.TitleContainer>

      <SectionCard.ContentContainer>
        {sortedFields
          .filter((e) => requiredDetails.includes(e))
          .map((fieldName, key) => (
            <Fragment key={key}>
              {getComponentByField({
                source: 'TRAVELLER',
                fieldName,
                formData,
                inputErrors,
                disabled,
                handleChange: (e) => handleChange(e, id),
                handleValidate: (regex) => (e: any) =>
                  handleValidate(regex)(e, id),
                passengerType: travellerType as 'INFANT' | 'CHILD' | 'ADULT',
                bookingDate: bookingDate,
              })}
            </Fragment>
          ))}
      </SectionCard.ContentContainer>
    </Styled.Container>
  );
};

export { TravellerDetails };
