import { useGetHotels } from '@/views/HotelsFlightsSearch/useGetHotels';
import { HotelCard } from '../HotelCard';
import * as Styled from './HotelList.styled';
import { useHighlightHotel } from '@/hooks/useHighlightHotel';
import { isEmpty } from '@/utils/isEmpty';
import { Pagination } from '../Pagination';
import { useStore } from '@/store/useStore';
import { Skeleton } from './Skeleton';
import { NofFound } from '../NotFound';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetParam } from '@/hooks/useGetParam';

export function HotelList() {
  const hotels = useGetHotels();

  const navigate = useNavigate();

  const getParam = useGetParam();

  const [onChangePageNumber, currentPageNumber, isFecthRetrieveProporsals] =
    useStore((state) => [
      state.onChangePageNumber,
      state.currentPageNumber,
      state.isFecthRetrieveProporsals,
    ]);

  const { setHotel, clearHotel } = useHighlightHotel();

  const onCLickHotelCard = (proposalId) => {
    navigate({
      pathname: `/hotel-detail`,
      search: `?${createSearchParams({
        outboundDate: getParam('outboundDate'),
        returnDate: getParam('returnDate'),
        adults: getParam('adults'),
        childrenAges: getParam('childrenAges'),
        searchId: getParam('searchId'),
        destinationId: getParam('destinationId'),
        proposalId,
      })}`,
    });
  };

  if (isFecthRetrieveProporsals) {
    return <Skeleton />;
  }

  if (isEmpty(hotels)) {
    return <NofFound />;
  }

  return (
    <Styled.Container>
      {hotels.map((hotel) => (
        <HotelCard
          {...hotel}
          key={hotel.proposalId}
          onMouseEnter={() => setHotel(hotel.proposalId)}
          onMouseLeave={() => clearHotel()}
          onCLickHotelCard={() => onCLickHotelCard(hotel.proposalId)}
        />
      ))}
      <Styled.PaginationContainer>
        <Pagination
          count={20}
          shape="rounded"
          onChange={(_, page) => onChangePageNumber(page)}
          page={currentPageNumber}
        />
      </Styled.PaginationContainer>
    </Styled.Container>
  );
}
