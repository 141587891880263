import { ClickAwayListener } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, useState } from 'react';
import { ArrowDownIcon } from '../../LmnIcons/LmnIcons';
import * as Styled from '../PassengerSelector.styled';
import { refineAge } from '../utils/refineAge';

interface AddChildSelectorProps {
  updateChild: (age: number) => void;
  disabled: boolean;
}

const AddChildSelector: FC<AddChildSelectorProps> = ({
  updateChild,
  disabled,
}) => {
  const { t } = useTranslate();
  const [open, setOpen] = useState<boolean>(false);

  const handleUpdateChild = (age: number) => {
    updateChild(age);
    setOpen(false);
  };

  if (disabled) {
    return null;
  }

  return (
    <Styled.AddChild>
      <Styled.AddChildButton onClick={() => setOpen(true)}>
        <span>{t('search.add.child')}</span>
        <ArrowDownIcon size={24} />
      </Styled.AddChildButton>
      {open && (
        <ClickAwayListener
          children={
            <Styled.ChildAgesList>
              {Array.from({ length: 12 }, (_, i) => i).map((age, k) => (
                <li key={k} value={age} onClick={() => handleUpdateChild(age)}>
                  {refineAge(age, 1, t)}
                </li>
              ))}
            </Styled.ChildAgesList>
          }
          onClickAway={() => setOpen(false)}
        ></ClickAwayListener>
      )}
    </Styled.AddChild>
  );
};

export { AddChildSelector };
