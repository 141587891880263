import styled from '@emotion/styled';

import { Button as ButtonMui } from '@mui/material';

export const Container = styled.div`
  display: flex;
  height: 56px;
  position: relative;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-bottom: 27px !important;
  background-image: url(https://www.it.lastminute.com/s/hdp/search/images/map-large-bg.svg);
  background-position-x: 100%;
  background-size: cover;
`;

export const ShowMapButton = styled(ButtonMui)`
  width: 50%;
  min-width: 130px;
`;
