import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './FreeCancellationFilter.styled';
import { isEmpty } from '@/utils/isEmpty';
import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';

export function FreeCancellationFilter() {
  const { retrieveProporsals, getHDPProposals, searchId, hdpFilters } =
    useGetFiltersData();

  const { t } = useTranslate();

  const [onlyFreeAccommodation, setOnlyFreeAccommodation] =
    useState<boolean>(false);

  useEffect(() => {
    hdpFilters?.freeCancellation &&
      hdpFilters?.freeCancellation[0] === 'true' &&
      setOnlyFreeAccommodation(true);
  }, []);

  const onSwitchOnlyFreeAccommodation = (e) => {
    setOnlyFreeAccommodation(!onlyFreeAccommodation);
    getHDPProposals(
      searchId,
      e.target.checked
        ? [
            {
              freeCancellation: ['true'],
            },
          ]
        : [{ freeCancellation: [] }]
    );
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Styled.RowContainer>
          <Styled.RowTitle>
            <Styled.Title>{t('filter.title.free_cancellation')}</Styled.Title>
          </Styled.RowTitle>
          <Styled.RowSwitch>
            <Switch
              checked={onlyFreeAccommodation}
              onChange={onSwitchOnlyFreeAccommodation}
            />
          </Styled.RowSwitch>
        </Styled.RowContainer>
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
