import styled from '@emotion/styled';

export const FLightsContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex: 1;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;
