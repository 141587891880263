import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }

  // TO DELETE
  letter-spacing: 0px;
  font-family: Open Sans;
`;

export const Item = styled.div<{ $selected?: boolean }>`
  font-size: 14px;
  font-weight: 700;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  width: calc(100% / 3);

  ${({ $selected }) =>
    $selected &&
    css`
      color: #f2007d;
      border-bottom: 2px solid #f2007d;

      & svg {
        fill: #f2007d;
      }
    `}

  &:hover {
    background-color: rgb(245, 248, 250);
  }
`;
