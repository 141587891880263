import { useTranslate } from '@tolgee/react';
import * as Styled from './MapToggle.styled';

export function MapToggle({ onToggle, action }) {
  const { t } = useTranslate();

  return (
    <Styled.Container>
      <Styled.ShowMapButton
        variant="contained"
        onClick={onToggle}
        disableElevation
        disableRipple
        fullWidth
        sx={{
          backgroundColor: 'white',
          borderColor: 'none',
          color: '#3c3c3c',
        }}
      >
        {action === 'open' && t('search_result.map.show')}
        {action === 'close' && t('search_result.map.hide')}
      </Styled.ShowMapButton>
    </Styled.Container>
  );
}
