import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import InfoIcon from '@mui/icons-material/Info';
import { Alert } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import * as Styled from './PriorityBoarding.styled';
import { PriorityOption } from './components/PriorityOption';

export interface PriorityOption {
  id: string;
  label: string;
  price: {
    fare: {
      currency: string;
      value: number;
      scale: number;
    };
    markup: {
      currency: string;
      value: number;
      scale: number;
    };
    total: {
      currency: string;
      value: number;
      scale: number;
    };
  };
  additionalInfo: {
    type: string;
  };
}

export const PriorityBoarding = () => {
  const [selected, setSelected] = useState<PriorityOption>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const [availableProducts] = useStore((state) => [state.availableProducts]);
  const currencyFormatter = useCurrencyFormatter();
  const addProduct = useAddProduct();
  const { t } = useTranslate();

  const priorityBoarding = availableProducts?.products.PRIORITY;

  const handleChange = async (value: PriorityOption) => {
    setIsLoading(true);

    const priorityObject = [
      {
        type: 'PRIORITY',
        detail: {
          lmnId: value.id,
          ancillary: {
            label: value.label,
          },
        },
        cost: {
          amount: value.price.total,
        },
      },
    ];
    try {
      await addProduct('PRIORITY', priorityObject)
        .then((e) => {
          setSelected(value);
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (!availableProducts) {
    return <Skeleton />;
  }

  if (!priorityBoarding || isFreeFlight) {
    return null;
  }

  const priorityOptions = priorityBoarding;

  return (
    <SectionCard>
      <Styled.Container>
        <div>
          <Styled.Title>{t('form.product.priority.hand_luggage')}</Styled.Title>
          <Styled.Subtitle>
            {t('form.product.priority.add_cabin_bag_claim')}
          </Styled.Subtitle>
        </div>
        <Styled.OptionsContainer>
          {priorityOptions
            .sort((a, b) => (a.id === 'PB_0' ? -1 : b.id === 'PB_0' ? 1 : 0))
            .map((e, index) => (
              <PriorityOption
                key={`priority_${index}`}
                freeOption={e.price.total.value === 0}
                pricePerPerson={currencyFormatter.formatNumber(
                  Number(e.price.total.value) / 100
                )}
                optionValue={e.id}
                isSelected={selected?.id === e.id}
                onSelect={() => handleChange(e)}
                disabled={isLoading}
              />
            ))}
        </Styled.OptionsContainer>
        {selected?.id === 'PB_0' && (
          <Alert
            severity="warning"
            icon={<InfoIcon sx={{ fontSize: '24px', fill: '#ff4800' }} />}
          >
            {t('form.product.priority.no_priority_option.disclaimer')}
          </Alert>
        )}
      </Styled.Container>
    </SectionCard>
  );
};
