import { useStore } from '@/store/useStore';
import { Radio } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from '../PriorityBoarding.styled';
import { DetailRow } from './DetailRow';

// TODO REVIEW URLS
const bag_url =
  'https://res.cloudinary.com/lastminute-contenthub/image/upload/v1695041627/DAM/SVG/Custom%20Icons/2021%20redesign/Ancillaries//blue_small%20bag.svg';
const cabin_url =
  'https://assets.staticroot.com/lmn-assets-ui/4/priority/blue_cabin_luggage.svg';

const priority_url =
  'https://assets.staticroot.com/lmn-assets-ui/4/priority/blue_priority_boarding.svg';

interface PriorityOptionProps {
  freeOption: boolean;
  pricePerPerson: string;
  optionValue: string;
  isSelected: boolean;
  disabled: boolean;
  onSelect: () => void;
}

export const PriorityOption: FC<PriorityOptionProps> = ({
  freeOption,
  pricePerPerson,
  optionValue,
  isSelected,
  disabled,
  onSelect,
}) => {
  const { t } = useTranslate();
  const primaryColorBg = useStore(
    (state) => state.brandConfig.theme.primaryColorBg
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onSelect();
  };

  return (
    <Styled.OptionContainer
      $selected={isSelected}
      primarycolorbg={primaryColorBg}
    >
      {freeOption ? (
        <Styled.ContentContainer>
          <Styled.OptionTitle>
            {t('form.product.priority.no_priority_option.title')}
          </Styled.OptionTitle>
          <DetailRow
            title={t('form.product.priority.small_bag')}
            subtitle={t('form.product.priority.small_bag_description')}
            url={bag_url}
          />
        </Styled.ContentContainer>
      ) : (
        <Styled.ContentContainer>
          <Styled.OptionTitle>
            {t('form.product.priority.priority_option.title')}
          </Styled.OptionTitle>
          <DetailRow
            title={t('form.product.priority.small_bag')}
            subtitle={t('form.product.priority.small_bag_description')}
            url={bag_url}
          />
          <DetailRow
            title={t('form.product.priority.hand_luggage')}
            subtitle={t('form.product.priority.hand_luggage_description')}
            url={cabin_url}
          />
          <DetailRow
            title={t('form.product.priority')}
            subtitle={t('form.product.priority.priority_boarding_description')}
            url={priority_url}
          />
        </Styled.ContentContainer>
      )}

      <Styled.PriceContainer>
        <Radio
          checked={isSelected}
          onChange={handleChange}
          value={optionValue}
          disableRipple
          disabled={disabled}
        />

        <Styled.PriceWrapper>
          <Styled.TotalAmount>{`${pricePerPerson} ${t('general.price.pp')}`}</Styled.TotalAmount>
          <Styled.FeesAmount>{t('general.for_all_flights')}</Styled.FeesAmount>
        </Styled.PriceWrapper>
      </Styled.PriceContainer>
    </Styled.OptionContainer>
  );
};
