import { CopyIcon } from '@/components/LmnIcons/LmnIcons';
import { InfoCircleFilled } from '@ant-design/icons';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  overflow: hidden;
  max-width: 100%;
  padding: 16px;
`;

export const CodeMobileLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CodeIcons = styled.div`
  display: flex;
  gap: 8px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const CodeCopy = styled(CopyIcon)`
  cursor: pointer;
`;

export const CodeMobileInfoIcon = styled(InfoCircleFilled)`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-left: 8px;
  vertical-align: top;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;
