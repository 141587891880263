import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import CurrencyFormatter from '@/utils/currencyFormatter';
import { isEmpty } from '@/utils/isEmpty';
import { Checkbox, FormGroup } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { Slider } from '../../Slider';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './BudgetFilters.styled';

export function BudgetFilters() {
  const {
    flightFilters,
    flightsStaticData,
    isFecthRetrieveProporsals,
    currency,
    searchId,
    getFlightsProposals,
    retrieveProporsals,
  } = useGetFiltersData();

  const { budgetRange } = flightsStaticData;

  const { t } = useTranslate();
  const track = useTrack();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  const currencyFormatter = new CurrencyFormatter('it-IT', currency, {
    minimumFractionDigits: 0,
  });

  const getPriceFormatted = (value: number) => {
    return currencyFormatter.formatNumber(value / 100);
  };

  const onUpdateSlider = (_, e: number[]) => {
    track({
      eventName: 'clicked.filter.budget',
      logLevel: 'info',
      eventProperties: {
        range: e,
      },
    });
    getFlightsProposals(searchId, [{ budgetRange: e }]);
  };

  const onLuggageHandle = (e) => {
    const luggageOptionsUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'luggageOptions',
      e.target
    );

    track({
      eventName: 'clicked.filter.luggage',
      logLevel: 'info',
      eventProperties: {
        options: luggageOptionsUpdated,
      },
    });

    getFlightsProposals(searchId, [{ luggageOptions: luggageOptionsUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  const minValue = (flightFilters.budgetRange ?? budgetRange)[0];

  const maxValue = (flightFilters.budgetRange ?? budgetRange)[1];

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Styled.Title>{t('general.price.per_person')}</Styled.Title>
        <Styled.SliderValuesContainer>
          <Styled.SliderValue>{getPriceFormatted(minValue)}</Styled.SliderValue>
          <Styled.SliderValue>{getPriceFormatted(maxValue)}</Styled.SliderValue>
        </Styled.SliderValuesContainer>
        <Styled.SliderContainer>
          <Slider
            defaultValue={[minValue, maxValue]}
            value={[minValue, maxValue]}
            disabled={isFecthRetrieveProporsals}
            onChange={onUpdateSlider}
            min={budgetRange[0]}
            max={budgetRange[1]}
            valueLabelFormat={(value) => `${getPriceFormatted(value)}`}
          />
        </Styled.SliderContainer>
        <Styled.Divider />
        <Styled.Title>{t('flight.luggage')}</Styled.Title>
        <FormGroup onChange={onLuggageHandle}>
          <Styled.FilterOptionsContainer>
            <Styled.FormLabel
              control={
                <Checkbox
                  value="ONLY_CABIN"
                  disabled={isFecthRetrieveProporsals}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={flightFilters.luggageOptions?.includes('ONLY_CABIN')}
                />
              }
              labelPlacement="start"
              label={
                <Styled.LabelOption>
                  {t('widget.trip_card.baggage.hand.label')}
                </Styled.LabelOption>
              }
            />
            <Styled.FormLabel
              control={
                <Checkbox
                  value="ONLY_CHECKED"
                  disabled={isFecthRetrieveProporsals}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={flightFilters.luggageOptions?.includes(
                    'ONLY_CHECKED'
                  )}
                />
              }
              labelPlacement="start"
              label={
                <Styled.LabelOption>
                  {t('flight.luggage.checked_luggage_included_short')}
                </Styled.LabelOption>
              }
            />
          </Styled.FilterOptionsContainer>
        </FormGroup>
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
