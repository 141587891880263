import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useTranslate } from '@tolgee/react';
import { Tooltip } from 'antd';
import * as Styled from './PriceInfo.styled';
import { PriceInfoProps } from './PriceInfo.types';

export function PriceInfo({
  originalPrice,
  priceUnity,
  offerPrice,
  extraFeeInfo,
}: PriceInfoProps) {
  const { t } = useTranslate();
  const bookingType = useBookingType();

  return (
    <Styled.Container>
      <Styled.ProductType>
        {bookingType === BookingType.FlightAndHotel
          ? t('general.product.dp')
          : t('general.product.hotel')}
      </Styled.ProductType>
      <Styled.OfferPrice>
        {originalPrice && (
          <Styled.OriginalPrice>{originalPrice}</Styled.OriginalPrice>
        )}
        {offerPrice ? offerPrice : originalPrice}
        <Styled.PriceUnity>{t(priceUnity)}</Styled.PriceUnity>
      </Styled.OfferPrice>
      {extraFeeInfo && (
        <Styled.ExtraFeeInfo>
          {t(extraFeeInfo.text)}
          {extraFeeInfo.tooltipInfo && (
            <Tooltip
              title={`+${extraFeeInfo.tooltipInfo.extraFeeAmountFormatted} ${t(extraFeeInfo.tooltipInfo.text)}`}
            >
              <Styled.IconCircleInfo />
            </Tooltip>
          )}
        </Styled.ExtraFeeInfo>
      )}
    </Styled.Container>
  );
}
