import { ClearXIcon } from '@/components/LmnIcons/LmnIcons';
import { Modal } from '@/components/Modal';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { ResultElement, ResultElementProps } from './ResultElement';
import * as Styled from './SearchInput.styled';

interface SearchInputProps {
  label: string;
  placeholder?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  selected: ResultElementProps;
  setSelected: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  results: ResultElementProps[];
  onSelect: (e: ResultElementProps) => void;
  onClear: () => void;
  onClickInput: () => void;
  error?: string;
}

const SearchInput: FC<SearchInputProps> = ({
  label,
  placeholder,
  value,
  setValue,
  results,
  selected,
  onSelect,
  onClear,
  onClickInput,
  error,
}) => {
  const [openResults, setOpenResults] = useState(false);
  const { isMobile } = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: ResultElementProps
  ) => {
    e.preventDefault();
    onSelect(value);
    setOpenResults(false);
  };

  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setOpenResults(false);
    onClear();
  };

  const handleOpenModal = () => {
    onClickInput();
    if (isMobile) {
      setIsModalOpen(true);
    }
  };

  return (
    <Styled.Container>
      <TextField
        onClickCapture={handleOpenModal}
        label={label}
        placeholder={placeholder}
        value={selected?.title || value}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => setOpenResults(true)}
        fullWidth
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        error={!!error}
        sx={{
          '& label': {
            fontSize: '18px',
          },
          '& input': {
            textOverflow: 'ellipsis',
          },
        }}
        InputProps={{
          endAdornment: (value || selected) && (
            <InputAdornment position="end" onClick={(e) => handleClear(e)}>
              <ClearXIcon size={20} style={{ color: 'rgb(186, 186, 192)' }} />
            </InputAdornment>
          ),
        }}
      />
      {error && <Styled.Error>{error}</Styled.Error>}
      {results?.length > 0 && openResults && !isMobile && (
        <ClickAwayListener
          children={
            <Styled.ResultsContainer>
              <Styled.ListContainer>
                {selected && (
                  <ResultElement {...selected} selected isSubElement={false} />
                )}
                {results
                  .filter((e) => e.title !== selected?.title)
                  .map((element, index) => (
                    <div
                      onClick={(event) => {
                        if (!element.disabled) {
                          handleSelect(event, element);
                        }
                      }}
                      key={index}
                    >
                      <ResultElement {...element} />
                    </div>
                  ))}
              </Styled.ListContainer>
            </Styled.ResultsContainer>
          }
          onClickAway={() => setOpenResults(false)}
        ></ClickAwayListener>
      )}
      <Modal
        title={label}
        isModalOpen={isModalOpen}
        fullHeight
        onCloseModal={() => setIsModalOpen(false)}
      >
        <Styled.TextField
          label={label}
          placeholder={placeholder}
          value={selected?.title || value}
          onChange={(e) => setValue(e.target.value)}
          onClick={() => setOpenResults(true)}
          fullWidth
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          error={!!error}
          sx={{
            '& label': {
              fontSize: '18px',
            },
            '& input': {
              textOverflow: 'ellipsis',
            },
          }}
          InputProps={{
            endAdornment: value && (
              <InputAdornment position="end" onClick={(e) => handleClear(e)}>
                <ClearXIcon size={20} style={{ color: 'rgb(186, 186, 192)' }} />
              </InputAdornment>
            ),
          }}
        />
        <Styled.MobileListContainer>
          {selected && (
            <ResultElement {...selected} selected isSubElement={false} />
          )}
          {results
            ?.filter((e) => e.title !== selected?.title)
            .map((element, index) => (
              <div
                onClick={(event) => {
                  if (!element.disabled) {
                    handleSelect(event, element);
                  }
                  setIsModalOpen(false);
                }}
                key={index}
              >
                <ResultElement {...element} />
              </div>
            ))}
        </Styled.MobileListContainer>
      </Modal>
    </Styled.Container>
  );
};

export { SearchInput };
