import { useGetParam } from '@/hooks/useGetParam';
import {
  CartApiServiceService,
  ProductInputDto,
} from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';

const addProductToCart = async ({
  cartId,
  requestUuid,
  requestBody,
}: {
  cartId?: string;
  requestUuid?: string;
  requestBody?: Array<ProductInputDto>;
}) => {
  return await CartApiServiceService.postApiServicesAppCartApiServiceAddProductToCart(
    { cartId, requestUuid, requestBody }
  ).catch((error) => error.response.data.error.message);
};

const updateProductToCart = async ({
  cartId,
  requestUuid,
  requestBody,
}: {
  cartId?: string;
  requestUuid?: string;
  requestBody?: Array<ProductInputDto>;
}) => {
  return await CartApiServiceService.postApiServicesAppCartApiServiceReplaceProductsInCart(
    { cartId, requestUuid, requestBody }
  ).catch((error) => error.response.data.error.message);
};

export const useAddProduct = () => {
  const getParam = useGetParam();
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');
  const [cartDetail, getCart, setCardExpired] = useStore((state) => [
    state.cartDetail,
    state.getCart,
    state.setCardExpired,
  ]);

  const addProduct = async (
    productType: string,
    products: ProductInputDto[]
  ) => {
    try {
      const productAlreadyPresent = cartDetail.products.find(
        (product) => product.type === productType
      );

      return productAlreadyPresent
        ? await updateProductToCart({
            cartId,
            requestUuid,
            requestBody: products,
          }).then((e) => {
            if (e === 'CART_EXPIRED') {
              setCardExpired();
            } else {
              getCart(cartId, requestUuid);
            }
          })
        : await addProductToCart({
            cartId,
            requestUuid,
            requestBody: products,
          }).then((e) => {
            if (e === 'CART_EXPIRED') {
              setCardExpired();
            } else {
              getCart(cartId, requestUuid);
            }
          });
    } catch (error) {
      console.log('addProductsToCart error', error);
      return false;
    }
  };

  return addProduct;
};
