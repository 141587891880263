import { DifferentDateAsMessage } from './DifferentDateAsMessage';
import * as Styled from './TransportDetail.styled';
import { TransportDetailsProps } from './TransportDetail.types';
import { TransportDetailSegment } from './TransportDetailSegment';
import { WayDetails } from './WayDetails';

export function TransportDetail({
  isInbound,
  locale,
  segmentsDetails,
  isDepartingOnDifferentDate = false,
  priceInformation,
  introInfo,
  showBaggageInfo = true,
  isFreeFlight = false,
}: TransportDetailsProps) {
  return (
    <Styled.Container>
      {isDepartingOnDifferentDate && (
        <Styled.DifferentDateContainer>
          <DifferentDateAsMessage isInbound={isInbound} />
        </Styled.DifferentDateContainer>
      )}
      {!!introInfo && <WayDetails isInbound={isInbound} {...introInfo} />}
      <Styled.SegmentsDetailsContainer>
        {segmentsDetails?.map((segmentInfo) => (
          <TransportDetailSegment
            key={`${segmentInfo.departure.date}-${segmentsDetails.indexOf(
              segmentInfo
            )}`}
            isInbound={isInbound}
            {...segmentInfo}
            locale={locale}
            showBaggageInfo={showBaggageInfo}
          />
        ))}
      </Styled.SegmentsDetailsContainer>
      {priceInformation && !isFreeFlight && (
        <Styled.PriceContainer>{priceInformation}</Styled.PriceContainer>
      )}
    </Styled.Container>
  );
}
