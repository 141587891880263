import { isBefore } from './datesComparison';

export const DIFFERENT_DAY_SEGMENT = {
  NEXT_DAY: 'nextDay',
  PREVIOUS_DAY: 'previousDay',
};

export const oneDayDifferenceKey = (
  initialDay: string,
  finalDay: string
): string | undefined => {
  const initialDayDate = new Date(initialDay);
  const finalDayDate = new Date(finalDay);

  if (
    initialDayDate.getDate() === finalDayDate.getDate() &&
    initialDayDate.getMonth() === finalDayDate.getMonth() &&
    initialDayDate.getFullYear() === finalDayDate.getFullYear()
  ) {
    return undefined;
  }
  return isBefore(initialDayDate, finalDayDate)
    ? DIFFERENT_DAY_SEGMENT.NEXT_DAY
    : DIFFERENT_DAY_SEGMENT.PREVIOUS_DAY;
};
