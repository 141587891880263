import { useStore } from '@/store/useStore';
import mixpanel from 'mixpanel-browser';
import { useGetParam } from './useGetParam';

interface eventSchema {
  eventName: string;
  eventSubName?: string;
  logLevel: 'warn' | 'log' | 'error' | 'info' | 'none';
  eventProperties?: object;
  eventData?: object;
  userProperties?: object;
  contextProperties?: object;
}

interface TrackOptions {
  omitEventR?: boolean;
  omitMixPanel?: boolean;
  omitSentry?: boolean;
}

const PROJECT_NAME = 'travel-ui';

const url = window._env_.VITE_EVENT_R_URL;

async function eventR(event: eventSchema) {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Special-Header': window._env_.VITE_EVENT_R_TOKEN,
    },
    body: JSON.stringify(event),
  });
}

export const useTrack = () => {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const getParam = useGetParam();
  const product = getParam('product');

  const track = ({
    eventName,
    eventSubName,
    logLevel,
    eventProperties,
    eventData,
    userProperties,
    contextProperties,
    omitEventR = false,
    omitMixPanel = false,
    omitSentry = false,
  }: eventSchema & TrackOptions) => {
    const eventObject = {
      eventSubName,
      logLevel,
      eventProperties,
      eventData: {
        brand: brandConfig?.brand?.brandName,
        product,
        ...eventData,
      },
      userProperties,
      contextProperties,
      project: PROJECT_NAME,
    };

    if (!omitMixPanel) {
      try {
        mixpanel?.track(eventName, eventObject);
        // fire and forget
      } catch (error) {}
    }

    if (!omitEventR) {
      try {
        eventR({ eventName, ...eventObject });
        // fire and forget
      } catch (error) {}
    }

    if (!omitSentry) {
      // SentryTrack
    }
  };

  return track;
};
