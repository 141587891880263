import { BedIcon, PlaneNEIcon } from '@/components/LmnIcons/LmnIcons';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment, useState } from 'react';
import { SectionCard } from '../../SectionCard/SectionCard';
import { Counter } from '../Counter';
import * as Styled from './Products.styled';

interface ProductsProps {
  fromToCities: string;
  hasFlight?: boolean;
  hasHotel?: boolean;
  dates: string;
  adultsNumber?: number;
  childrenNumber?: number;
  infantNumber?: number;
}
export const Products: FC<ProductsProps> = ({
  fromToCities,
  hasFlight,
  hasHotel,
  dates,
  adultsNumber,
  childrenNumber,
  infantNumber,
}) => {
  const { t } = useTranslate();
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const [order] = useStore((state) => [state.order]);
  const [open, setOpen] = useState(false);
  const orderId = order?.orderId;

  const handleCopy = () => {
    navigator.clipboard.writeText(`${orderId}`);
    setOpen(true);
  };

  return (
    <div>
      <SectionCard title={fromToCities}>
        <Fragment>
          <Styled.Container>
            <Styled.Pill>
              {hasFlight && <PlaneNEIcon />}
              {hasHotel && <BedIcon />}
            </Styled.Pill>
            <Counter type="calendar" value={dates} />
            {adultsNumber && <Counter type="adult" value={adultsNumber} />}
            {childrenNumber && (
              <Counter type="children" value={childrenNumber} />
            )}
            {infantNumber && <Counter type="infant" value={infantNumber} />}
          </Styled.Container>
          {!isFreeFlight && (
            <Styled.TextField
              label={t('general.booking_id')}
              value={orderId || ''}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t('general.actions.copy_booking_id')}>
                        <IconButton onClick={() => handleCopy()}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        </Fragment>
      </SectionCard>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={t('general.actions.copied_booking_id')}
      />
    </div>
  );
};
