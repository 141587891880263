import * as React from 'react';
import {
  createHotelDetailConfigStore,
  CreateHotelDetailConfigStore,
} from './createHotelDetailConfigStore';

type StoreHotelDetailProviderProps = {
  children: React.ReactNode;
};

export const StoreHotelDetailContext =
  React.createContext<CreateHotelDetailConfigStore | null>(null);

export function StoreHotelDetailProvider({
  children,
}: StoreHotelDetailProviderProps) {
  const storeRef = React.useRef<CreateHotelDetailConfigStore>();

  if (!storeRef.current) {
    storeRef.current = createHotelDetailConfigStore();
  }

  return (
    <StoreHotelDetailContext.Provider value={storeRef.current}>
      {children}
    </StoreHotelDetailContext.Provider>
  );
}
