import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InnerContainer = styled.div`
  display: flex;
  height: 44px;
  background-color: white;
  border-radius: 8px;
  padding: 8px 6px 8px 16px;
  color: #3c3c3c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const InnerContainerMobile = styled.div`
  display: flex;
  flex: 1;
  margin-right: 16px;
  flex-direction: column;
  height: 44px;
  background-color: white;
  border-radius: 6px;
  padding: 8px 8px;
  color: #3c3c3c;
  overflow: hidden;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0px 12px 0px 24px;
`;

export const Divider = styled.div`
  background-color: #eaeaeb;
  height: 24px;
  min-width: 1px;
  margin: 0px 8px;
`;

export const LogoContainer = styled.a<{ $hideLogo: boolean }>`
  cursor: pointer;
  @media (max-width: 1024px) {
    ${({ $hideLogo }) =>
      $hideLogo &&
      css`
        display: none;
      `}
  }
`;

export const Logo = styled.img`
  height: 44px;
`;

export const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const Button = styled.div<{ $backgroundcolor: string }>`
  background-color: ${({ $backgroundcolor }) => $backgroundcolor};
  width: 32px;
  height: 32px;
  margin-left: 8px;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileLocationText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #3c3c3c;
  border: 0px;
  line-height: 14px;
  height: 14px;
  height: auto;
  margin-top: 0px;
  text-overflow: ellipsis;
`;

export const MobileDatesText = styled.span`
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  font-style: normal;
  font-weight: 600;
  color: #3c3c3c;
  border: 0px;
  height: auto;
  margin-top: 0px;
  text-overflow: ellipsis;
`;

export const GrayScreen = styled.div`
  background-color: rgb(0, 0, 0);
  position: absolute;
  top: 64px;
  bottom: 0px;
  width: 100%;
  opacity: 0.3;
  z-index: 3;
`;

export const Test = styled.div`
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 1230px;
  }

  @media (min-width: 1200px) {
    max-width: 1230px;
  }
`;
export const SearchContainer = styled.div`
  background-color: white;
  position: absolute;
  top: 64px;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
`;
