import { useTranslate } from '@tolgee/react';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { Search } from '../Search';
import * as Styled from './Hero.styled';
import { HeroProps } from './Hero.types';

export function Hero({ backgroundUrl, title, subtitle }: HeroProps) {
  const { t } = useTranslate();

  return (
    <Styled.Container
      justify="center"
      align="middle"
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
    >
      <Styled.InnerContainer>
        <Flex vertical gap={8} style={{ marginBottom: 24 }}>
          <Title
            level={1}
            style={{ color: 'white', margin: 0, textAlign: 'center' }}
          >
            {t(title)}
          </Title>
          {subtitle && (
            <Title
              level={2}
              style={{
                color: 'white',
                fontWeight: 400,
                margin: 0,
                textAlign: 'center',
              }}
            >
              {t(subtitle)}
            </Title>
          )}
        </Flex>
        <Styled.Card data-test="search-form">
          <Search />
        </Styled.Card>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
