import { ClockCircleFilled } from '@ant-design/icons';
import {
  BaggageCheckedIcon,
  ChangeAirportIcon,
} from '@/components/LmnIcons/LmnIcons';
import * as Styled from './TransportStopBetweenSegments.styled';
import { TransportStopBetweenSegmentsProps } from './TransportStopBetweenSegments.types';

export function TransportStopBetweenSegments({
  title,
  description,
  differentLocationLabel,
  baggageRecheckLabel,
}: TransportStopBetweenSegmentsProps) {
  return (
    <Styled.Container>
      <Styled.Alert
        type="info"
        $withoutBorder={true}
        message={
          <>
            <Styled.TitleContainer>
              <ClockCircleFilled size={16} />
              <span>{title}</span>
            </Styled.TitleContainer>
            <Styled.DescriptionContainer>
              {description}
            </Styled.DescriptionContainer>
            {differentLocationLabel && (
              <Styled.DifferentTransportContainer>
                <ChangeAirportIcon size={16} />
                <span>{differentLocationLabel}</span>
              </Styled.DifferentTransportContainer>
            )}
            {baggageRecheckLabel && (
              <Styled.DifferentTransportContainer>
                <BaggageCheckedIcon size={16} />
                <span>{baggageRecheckLabel}</span>
              </Styled.DifferentTransportContainer>
            )}
          </>
        }
      />
    </Styled.Container>
  );
}
