import { useGetParam } from '@/hooks/useGetParam';
import { useHandleFilter } from '@/hooks/useHandleFilter';
import { useStore } from '@/store/useStore';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { useTranslate } from '@tolgee/react';
import * as Styled from './FlightTypeSelector.styled';

interface FlightTypeSelectorProps {
  flightType: FlightTypes;
  setFlightType: React.Dispatch<React.SetStateAction<FlightTypes>>;
}
export const FlightTypeSelector: React.FC<FlightTypeSelectorProps> = ({
  flightType,
  setFlightType,
}) => {
  const getParam = useGetParam();
  const brandConfig = useStore((s) => s.brandConfig);
  const { primaryColor } = brandConfig.theme;

  const { addFilter } = useHandleFilter();

  const { t } = useTranslate();

  const products = [
    { label: t('search.return'), value: FlightTypes.R },
    { label: t('search.one_way'), value: FlightTypes.OW, selected: true },
  ];

  return (
    <Styled.Container>
      {products.map((el) => (
        <Styled.Item
          key={el.value}
          onClick={() => setFlightType(el.value)}
          $selected={flightType === el.value}
          $color={primaryColor}
        >
          {el.label}
        </Styled.Item>
      ))}
    </Styled.Container>
  );
};
