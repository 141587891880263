import WifiIcon from '@mui/icons-material/Wifi';
import { Radio } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './Room.styled';
import { PriceInfo } from './PriceInfo';

export const Room: FC = () => {
  const { t } = useTranslate();
  return (
    <Styled.RoomContainer>
      <Styled.RoomSummaryContainer>
        <Styled.RoomSummaryMainColumn>
          <Styled.RoomMainImage />
          <Styled.TextContentWrapper>
            <Styled.RoomTitle>
              Superior XL with extra bed child
            </Styled.RoomTitle>
            <Styled.FacilitiesList>
              <Styled.Facility>
                <WifiIcon />
                Wifi
              </Styled.Facility>
              <Styled.Facility>
                <WifiIcon />
                Wifi
              </Styled.Facility>
              <Styled.Facility>
                <WifiIcon />
                Wifi
              </Styled.Facility>
              <Styled.ShowDetailsButton>Show details</Styled.ShowDetailsButton>
            </Styled.FacilitiesList>
          </Styled.TextContentWrapper>
        </Styled.RoomSummaryMainColumn>
        <Styled.Divider />
      </Styled.RoomSummaryContainer>
      <Styled.RoomDetailContainer>
        <Styled.SelectorsContainer>
          <Styled.SelectorContainer>
            <Styled.SelectorTitle>Meal plan</Styled.SelectorTitle>
            <Styled.OptionSelector>
              <Styled.Option
                value="female"
                control={
                  <Radio disableRipple disableFocusRipple disableTouchRipple />
                }
                label={
                  <Styled.LabelContainer>
                    <Styled.LabelTextContainer>
                      <span>Room Only</span>
                      <Styled.SecondLine>up to 28/10/24</Styled.SecondLine>
                    </Styled.LabelTextContainer>

                    <Styled.LabelPrice>+ £0</Styled.LabelPrice>
                  </Styled.LabelContainer>
                }
              />
              <Styled.Option
                value="male"
                control={
                  <Radio disableRipple disableFocusRipple disableTouchRipple />
                }
                label={
                  <Styled.LabelContainer>
                    <div>
                      <span>Breakfast included</span>
                    </div>
                    <Styled.LabelPrice>+ £14</Styled.LabelPrice>
                  </Styled.LabelContainer>
                }
              />
            </Styled.OptionSelector>
          </Styled.SelectorContainer>
          <Styled.SelectorContainer>
            <Styled.SelectorTitle>Meal plan</Styled.SelectorTitle>
            <Styled.OptionSelector>
              <Styled.Option
                value="female"
                control={
                  <Radio disableRipple disableFocusRipple disableTouchRipple />
                }
                label={
                  <Styled.LabelContainer>
                    <Styled.LabelTextContainer>
                      <span>Room Only</span>
                      <Styled.SecondLine>up to 28/10/24</Styled.SecondLine>
                    </Styled.LabelTextContainer>

                    <Styled.LabelPrice>+ £0</Styled.LabelPrice>
                  </Styled.LabelContainer>
                }
              />
              <Styled.Option
                value="male"
                control={
                  <Radio disableRipple disableFocusRipple disableTouchRipple />
                }
                label={
                  <Styled.LabelContainer>
                    <div>
                      <span>Breakfast included</span>
                    </div>
                    <Styled.LabelPrice>+ £14</Styled.LabelPrice>
                  </Styled.LabelContainer>
                }
              />
            </Styled.OptionSelector>
          </Styled.SelectorContainer>
        </Styled.SelectorsContainer>
        <Styled.Divider />
        <Styled.PriceContainer>
          <PriceInfo
            offerPrice={'200 €'}
            priceUnity={'pp'}
            extraFeeInfo={{
              text: '+£4 due later',
              tooltipInfo: { text: 'jiji', extraFeeAmountFormatted: '20 €' },
            }}
          />
          <Styled.HorizontalDivider />
          <Styled.LoadingButton
            variant="contained"
            loading={false}
            onClick={() => console.log('to cart!')}
          >
            {t('general.actions.next')}
          </Styled.LoadingButton>
        </Styled.PriceContainer>
      </Styled.RoomDetailContainer>
    </Styled.RoomContainer>
  );
};
