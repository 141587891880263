import { BookingType } from '@/hooks/useBookingType';
import {
  Proposal,
  ProposalsResponseAdvancedDto,
} from '@/services/codegen-romulo';
import CurrencyFormatter from '@/utils/currencyFormatter';
import { isEmpty } from '@/utils/isEmpty';
import { calcNightStays } from '@/utils/timeUtils';
import { Dayjs } from 'dayjs';
import { getConnection } from './getConnection';
import { getExtraFeeInfo } from './getExtraFeeInfo';
import { getFacilitiesOrdered } from './getFacilitiesOrdered';
import { getHotelLocation } from './getHotelLocation';
import { getLastminuteDeal } from './getLastminuteDeal';
import { getPrice } from './getPrice';
import { getRating } from './getRating';

const NUMBER_MAX = 999999;

const TOP_SECRET_TEXT = 'TOP_SECRET_HOTEL';

const FREE_CANCELLATION = 'FREE_CANCELLATION';

type GetHotelDataProps = {
  numberPassengers: number;
  bookingType: BookingType;
  accommodation: Proposal['accommodation'];
  retrieveProporsals: ProposalsResponseAdvancedDto;
  stayConfig: {
    checkOut: Dayjs;
    checkIn: Dayjs;
  };
  currencyFormatter: CurrencyFormatter;
};

export function getHotelData({
  stayConfig,
  accommodation,
  retrieveProporsals,
  numberPassengers,
  bookingType,
  currencyFormatter,
}: GetHotelDataProps) {
  const { cities, countries, promotions } = retrieveProporsals.staticData;

  const { transports, proposals } = retrieveProporsals.search;

  const accommodationProposal = proposals.find(
    (e) => e.accommodation.internalIdHotel === accommodation.internalIdHotel
  );

  const numberOfNights = calcNightStays(
    stayConfig.checkOut.format(),
    stayConfig.checkIn.format()
  );

  const proposalType = accommodationProposal.type;

  if (isEmpty(accommodationProposal?.rates)) {
    return null;
  }

  const ratesOrderedByPrice = accommodationProposal.rates
    .slice()
    .sort((a, b) => a.prices.std.fullPrice - b.prices.std.fullPrice);

  const rate = ratesOrderedByPrice[0];

  const lastminuteDeal = getLastminuteDeal({
    rate,
    promotions,
  });

  const priceConfig = getPrice({
    currencyFormatter,
    rate,
    numberPassengers,
    numberOfNights,
    bookingType,
  });

  const hasFreeCancellation = !!rate.accommodationRate.cancellationInfo.find(
    (e) => e.type === FREE_CANCELLATION
  );

  const mealPlan = rate.accommodationRate.mealPlan;

  const isAvailable = priceConfig.totalAmount <= NUMBER_MAX;

  const extraFeeInfo = getExtraFeeInfo({
    extraFeeAmountFormatted: priceConfig.extraFeeAmountFormatted,
  });

  return {
    isAvailable,
    stayConfig,
    extraFeeInfo,
    lastminuteDeal,
    proposalId: accommodationProposal.id,
    hotelLocation: getHotelLocation({
      cities,
      countries,
      accommodation,
    }),
    price: priceConfig,
    images: accommodation.images,
    stars: accommodation.stars,
    facilities: getFacilitiesOrdered({
      amenities: accommodation.amenities,
      hasFreeCancellation,
      mealPlan,
    }),
    rating: getRating(accommodation),
    hotelName: accommodation.label,
    connectionIncluded: getConnection(transports),
    isTopSecret: proposalType === TOP_SECRET_TEXT,
    location: [
      accommodation.coordinates.longitude,
      accommodation.coordinates.latitude,
    ],
  };
}
