import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  @media (min-width: 768px) {
    max-width: auto !important;
  }

  @media (min-width: 996px) {
    max-width: 996px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  @media (min-width: 744px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const RoomsContainer = styled.div``;

export const Card = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1280px) {
    padding: 0px;
    background-color: unset;
    border-radius: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
`;
