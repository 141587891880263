import { useCountDown } from '@/hooks/useCountDown';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Alert } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';

export const Timer: FC<{ targetDate: string }> = ({ targetDate }) => {
  const { t } = useTranslate();
  const countDown = useCountDown(targetDate, () => undefined);

  if (countDown[0].toString() == 'NaN') {
    return null;
  }

  if (countDown[2] < 0) {
    return null;
  }

  return (
    <Alert
      severity="info"
      icon={<AccessTimeFilledIcon sx={{ fontSize: '24px' }} />}
    >
      <p style={{ margin: 0, fontSize: 14 }}>
        {t('checkout.cart.timer.remaining')}{': '}
        <span style={{ fontWeight: 700 }}>{`${countDown[2]}:${
          countDown[3] < 10 ? '0' : ''
        }${countDown[3]}`}</span>
      </p>
    </Alert>
  );
};
