import {
  AdvancedSearchInput,
  CartApiServiceService,
  CartDetailResponseDto,
  ConfigResponseDto,
  CreateCartResponseDto,
  NewCartInputDto,
  SearchInputDto,
  SearchTransportInputDto,
  ShoppingHdpServiceService,
  ShoppingTransportServiceService,
} from '@/services/codegen-romulo';
import { AuthenticatedDto } from '@/services/codegen-welfare';

import { createStore } from 'zustand';
import type { BrandConfig } from './utils/configMapper';
import { getFlightFacets } from './utils/getFlightFacets';
import { getHDPFacets } from './utils/getHDPFacets';

const SORT_SELECTOR_DEFAULT = 'RECOMMENDED';

const SORT_SELECTOR_FLIGHT_CAMPAING = 'PRICE';

export type CreateConfigStore = ReturnType<typeof createConfigStore>;

type SortSelectorProps = {
  value: string;
  type?: string;
  ascending: boolean;
};

export interface StoreStateProps {
  brandConfig: BrandConfig | null;
  sortSelector: SortSelectorProps;
  flightFilters: any;
  hdpFilters: any;
  loading: boolean;
  retrieveProporsals: any;
  cartDetail?: CartDetailResponseDto;
  cartConfig?: ConfigResponseDto;
  availableProducts?: any;
  order?: any;
  currentSearchId: string;
  createSearchId: (data: SearchInputDto) => Promise<string> | null;
  createFlightSearchId: (
    data: SearchTransportInputDto
  ) => Promise<string> | null;
  getHDPProposals: (searchId: string, resultFilter?: any) => void;
  getFlightsProposals: (searchId: string, resultFilter?: any) => void;
  isFecthRetrieveProporsals: boolean;
  priceId: string;
  currentPageNumber: number;
  cartExpired: boolean;
  setCardExpired: () => void;
  onChangePageNumber: (page: number) => void;
  getCart: (cartId: string, requestUuid: string) => void;
  getCartConfig: (cartId: string, requestUuid: string) => void;
  getAvailableProducts: (cartId: string, requestUuid: string) => void;
  getOrder: (cartId: string, requestUuid: string) => void;
  onChangeSortSelector: (selectorData: SortSelectorProps) => void;
  createCart: (requestBody: NewCartInputDto) => Promise<CreateCartResponseDto>;
  externalAuthentication: AuthenticatedDto;
  cart: CreateCartResponseDto | null;
  isFetchCart: boolean;
}

export const createConfigStore = ({
  brandConfig,
  externalAuthentication,
}: {
  brandConfig?: BrandConfig;
  externalAuthentication?: AuthenticatedDto;
}) => {
  return createStore<StoreStateProps>()((set, get) => ({
    sortSelector: {
      value: SORT_SELECTOR_DEFAULT,
      ascending: true,
    },
    flightFilters: {},
    hdpFilters: {},
    brandConfig,
    priceId: null,
    isFecthRetrieveProporsals: true,
    currentSearchId: null,
    retrieveProporsals: null,
    cartDetail: null,
    cartConfig: null,
    order: null,
    availableProducts: null,
    loading: false,
    cartExpired: false,
    currentPageNumber: 1,
    cart: null,
    isFetchCart: false,
    externalAuthentication,
    isFetchFlightsAlternativeProporsals: false,
    createCart: async (
      requestBody: NewCartInputDto
    ): Promise<CreateCartResponseDto> => {
      try {
        set({
          isFetchCart: true,
        });
        const response =
          await CartApiServiceService.postApiServicesAppCartApiServiceCreateCart(
            {
              requestBody,
            }
          );
        set({
          isFetchCart: false,
        });
        return response;
      } catch (e) {
        set({
          isFetchCart: false,
        });
        console.log('🚀 ~ createConfigStore ~ e:', e);
        return null;
      }
    },

    onChangePageNumber: (page) => {
      set({
        currentPageNumber: page,
      });
    },
    getCart: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveCart(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          cartDetail: response,
        });
      } catch (e) {
        console.log('🚀 ~ retrieveCart: ~ e:', e);
      }
    },
    getCartConfig: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetCartConfig(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          cartConfig: response,
        });
      } catch (e) {
        console.log('🚀 ~ getCartConfig: ~ e:', e);
      }
    },
    getAvailableProducts: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetAvailableProducts(
            {
              cartId,
              requestUuid,
            }
          );

        set({
          availableProducts: response,
        });
      } catch (e) {
        console.log('🚀 ~ getAvailableProducts: ~ e:', e);
      }
    },
    getOrder: async (cartId: string, requestUuid: string) => {
      try {
        const response =
          await CartApiServiceService.getApiServicesAppCartApiServiceGetOrders({
            cartId,
            requestUuid,
          });

        set({
          order: response,
        });
      } catch (e) {
        console.log('🚀 ~ getOrder: ~ e:', e);
      }
    },
    createSearchId: async (data: SearchInputDto) => {
      try {
        const response =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceCreateSearch(
            { requestBody: data }
          );

        set({
          currentSearchId: response?.id?.value,
          loading: false,
        });

        return response?.id?.value;
      } catch (e) {
        console.log('🚀 ~ createSearchId: ~ e:', e);
        return null;
      }
    },
    createFlightSearchId: async (data: SearchTransportInputDto) => {
      try {
        const response =
          await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceCreateSearch(
            { requestBody: data }
          );

        set({
          currentSearchId: response.searchId,
          loading: false,
        });

        return response.searchId;
      } catch (e) {
        console.log('🚀 ~ createFlightSearchId: ~ e:', e);
        return null;
      }
    },
    onChangeSortSelector: (selectorData) =>
      set(() => ({ sortSelector: selectorData })),
    getHDPProposals: async (searchId, resultFilters = []) => {
      try {
        const filters = {
          ...get().hdpFilters,
          ...resultFilters.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
        };

        set(() => ({
          hdpFilters: filters,
          isFecthRetrieveProporsals: true,
        }));

        const facetsUpdated = getHDPFacets(filters);

        const currentPageNumber = get().currentPageNumber;

        const retrieveProporsals =
          await ShoppingHdpServiceService.postApiServicesAppShoppingHdpServiceRetrieveProposals(
            {
              searchId,
              requestBody: {
                page: {
                  number: currentPageNumber,
                  size: 20,
                },
                facets: facetsUpdated,
                sort: {
                  name: get().sortSelector.value,
                  ascending: get().sortSelector.ascending,
                },
              },
            }
          );

        set(() => ({
          retrieveProporsals,
          isFecthRetrieveProporsals: false,
        }));
      } catch (e) {
        set(() => ({
          isFecthRetrieveProporsals: false,
          loading: false,
        }));
        console.log('🚀 ~ getProposalsWithFilters: ~ e:', e);
      }
    },
    setCardExpired: () => set({ cartExpired: true }),
    getFlightsProposals: async (searchId, newFilters = []) => {
      try {
        const freeFlightConditions =
          get().brandConfig?.brand?.freeFlightConditions;
        const isFreeFlight = !!freeFlightConditions;

        const filters = {
          ...get().flightFilters,
          ...newFilters.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
        };

        set(() => ({
          flightFilters: filters,
          isFecthRetrieveProporsals: true,
        }));

        const facetsUpdated = getFlightFacets(filters, freeFlightConditions);

        const currentPageNumber = get().currentPageNumber;

        const alternativeTransportsRequest: AdvancedSearchInput = {
          facets: facetsUpdated,
          sort: {
            name: isFreeFlight
              ? SORT_SELECTOR_FLIGHT_CAMPAING
              : get().sortSelector.value,
            ascending: get().sortSelector.ascending,
          },
          page: {
            number: currentPageNumber,
            size: 20,
          },
          filtersMetadata: true,
        };

        const retrieveProporsals =
          await ShoppingTransportServiceService.postApiServicesAppShoppingTransportServiceRetrieveProposals(
            {
              searchId,
              requestBody: alternativeTransportsRequest,
            }
          );

        set(() => ({
          retrieveProporsals,
          isFecthRetrieveProporsals: false,
        }));
      } catch (e) {
        set(() => ({
          isFecthRetrieveProporsals: false,
          loading: false,
        }));
        console.log('Error - getFlightsProposals', e);
      }
    },
  }));
};
