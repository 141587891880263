import { useContext } from 'react';
import { useStore as useStoreZustand } from 'zustand';
import { StoreHotelDetailContext } from './storeHotelDetailProvider';
import { StoreHotelDetailStateProps } from './createHotelDetailConfigStore';

export function useStoreHotelDetail<T>(
  selector: (state: StoreHotelDetailStateProps) => T
): T {
  const store = useContext(StoreHotelDetailContext);
  if (!store) throw new Error('Missing useStore.Provider in the tree');
  return useStoreZustand(store, selector);
}
