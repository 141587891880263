import * as React from 'react';
import { useGetTransports } from '@/views/FlightsSearch/useGetTransports/useGetTransports';
import { FlightCard } from '@/components/FlightCard';
import { Skeleton } from '@/components/FlightsList/Skeleton';
import { Pagination } from '@/components/Pagination';
import * as Styled from './FlightList.styled';
import { useStoreHotelDetail } from '@/store/useStoreHotelDetail';
import { useStore } from '@/store/useStore';
import { calculatePriceDiffFormatted } from './utils/calculatePriceDiffFormatted';
import { TransportFlightOption } from '@/views/FlightsSearch/useGetTransports/types/TrasportFlightOption';
import { ModalFlight } from '@/components/ModalFlight';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import { useGetParamsInfo } from '@/views/HotelDetail/useGetParamsInfo';

export function FlightList({ selectedFlight }) {
  const [
    flightsAlternativeProposals,
    isFetchFlightsAlternativeProporsals,
    onChangePageNumber,
    currentPageNumber,
    retrievePricingProporsals,
  ] = useStoreHotelDetail((state) => [
    state.flightsAlternativeProposals,
    state.isFetchFlightsAlternativeProporsals,
    state.onChangePageNumber,
    state.currentPageNumber,
    state.retrievePricingProporsals,
  ]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { t } = useTranslate();

  const [modalData, setModalData] = React.useState<{
    price: string;
    tripDetails: TransportFlightOption['tripDetails'];
    alternativeId: string;
    fullPrice: number;
  }>();

  const language = useStore((state) => state.brandConfig.language);

  const { getDPFlightsAlternativeProposals } = useGetTransports();

  const { destinationId } = useGetParamsInfo();

  const accomodation = retrievePricingProporsals?.proposal.accommodation;

  const amountNights = dayjs(accomodation?.checkout)?.diff(
    accomodation?.checkin,
    'days'
  );

  const destination = retrievePricingProporsals?.staticData?.cities.find(
    (e) => e.id === Number(destinationId)
  );

  const flights = getDPFlightsAlternativeProposals(flightsAlternativeProposals);

  const onFlightCardClick = (item) => {
    setModalData(item);
    setIsModalOpen(true);
  };

  if (isFetchFlightsAlternativeProporsals) {
    return <Skeleton />;
  }

  return (
    <>
      <Styled.FLightsContainer>
        <FlightCard
          enableCardButton
          isSelectedFlight
          key={selectedFlight.alternativeId}
          inbound={selectedFlight.inbound}
          outbound={selectedFlight.outbound}
          tripDetails={selectedFlight.tripDetails}
          onFlightCardClick={() => onFlightCardClick(selectedFlight)}
        />
        {flights.map((item) => (
          <FlightCard
            enableCardButton
            key={item.alternativeId}
            inbound={item.inbound}
            outbound={item.outbound}
            onFlightCardClick={() => onFlightCardClick(item)}
            price={{
              offerPrice: calculatePriceDiffFormatted({
                selectedFlight: selectedFlight.fullPrice,
                flightPrice: item.fullPrice,
                language,
                currency: item.currency,
              }),
            }}
            tripDetails={item.tripDetails}
          />
        ))}
        {!!modalData && (
          <ModalFlight
            title={t('flight.trip_details')}
            isModalOpen={isModalOpen}
            onCloseModal={() => setIsModalOpen(false)}
            tripDetails={modalData.tripDetails}
            price={modalData.price}
            destination={destination.label}
            amountNights={amountNights}
            disabledFooter
          />
        )}
      </Styled.FLightsContainer>
      <Styled.PaginationContainer>
        <Pagination
          count={flightsAlternativeProposals.paging?.totalPages}
          shape="rounded"
          onChange={(_, page) => onChangePageNumber(page)}
          page={currentPageNumber}
        />
      </Styled.PaginationContainer>
    </>
  );
}
