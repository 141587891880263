import { useGetParam } from '@/hooks/useGetParam';
import {
  CartApiServiceService,
  ContactInputDto,
  Traveller,
} from '@/services/codegen-romulo';
import { Alert } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import { FC, Fragment, useEffect, useState } from 'react';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import { Contact } from './Contact';
import * as Styled from './Review.styled';

export const Review: FC = () => {
  const { t } = useTranslate();
  const [contactDetails, setContactDetails] = useState<ContactInputDto>();
  const [travellersDetails, setTravellersDetails] = useState<Traveller[]>();
  const getParam = useGetParam();
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');

  const getInfo = async () => {
    try {
      const contactDetails =
        await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveContact(
          { requestUuid, cartId }
        );
      const travellersDetails =
        await CartApiServiceService.postApiServicesAppCartApiServiceRetrieveTravellersByCartId(
          { requestUuid, cartId }
        );
      setContactDetails(contactDetails);
      setTravellersDetails(travellersDetails);
    } catch (error) {
      console.log('Error - RetrieveTravellers - RetrieveContact', error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  if (!travellersDetails || !contactDetails) {
    return <Skeleton />;
  }

  const { email, name, surname, phone } = contactDetails;

  return (
    <SectionCard title={t('form.review.title')}>
      <Fragment>
        <Styled.Title>{t('form.review.contact')}</Styled.Title>
        <Alert icon={false} severity="info">
          <div
            dangerouslySetInnerHTML={{
              __html: t('form.review.confirmation_email', {
                email: email || '____',
              }),
            }}
          />
        </Alert>
        <Styled.ContentContainer>
          <Contact
            name={name}
            surname={surname}
            email={email}
            phone={`+${phone.dialCode} ${phone.phoneNumber}`}
          />
        </Styled.ContentContainer>
        <Styled.Title>{t('form.review.travellers')}</Styled.Title>

        <Styled.ContentContainer>
          {travellersDetails?.map((e) => (
            <Contact
              gender={
                e.gender === 'M'
                  ? t('form.contact.gender.mr')
                  : t('form.contact.gender.ms')
              }
              name={e.name}
              surname={e.surname}
              birthDate={dayjs(e.dateOfBirth).format('DD MMM YYYY')}
            />
          ))}
        </Styled.ContentContainer>
      </Fragment>
    </SectionCard>
  );
};
