import { useTranslate } from '@tolgee/react';
import { useGetParam } from '../../hooks/useGetParam';
import { useGetBannerData } from './useGetBannerData';
import * as Styled from './Banner.styled';

export function Banner() {
  const { t } = useTranslate();

  const getParam = useGetParam();

  const pricingId = getParam('pricingId');

  const bannerData = useGetBannerData();

  if (pricingId) return null;

  if (!bannerData) return null;

  return (
    <Styled.Container
      href={bannerData.ctaURL}
      target="_blank"
      $imgUrl={bannerData.imgURL}
    >
      <Styled.InnerContainer>
        <Styled.Title
          dangerouslySetInnerHTML={{ __html: t(bannerData.titleKey) }}
        ></Styled.Title>
        <Styled.ButtonContainer>
          <Styled.Button type="primary">{t(bannerData.ctaKey)}</Styled.Button>
        </Styled.ButtonContainer>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
