import { explodeDuration } from '@/utils/timeUtils';

export const getDurationLabel = (
  t: (key: string, x: any, replaces: any) => string,
  durationInMinutes?: number
) => {
  if (typeof durationInMinutes === 'undefined') {
    return '';
  }

  const { minutes, hours } = explodeDuration(durationInMinutes);

  if (hours === 0) {
    return t('widget.trip_card.duration.in.minutes', '', {
      minutes: durationInMinutes.toString(),
    });
  }

  if (minutes === 0) {
    return t('widget.trip_card.duration.only.hours', '', {
      hours: hours.toString(),
    });
  }

  return `${t('widget.trip_card.duration.in.hours', '', {
    hours: hours.toString(),
    minutes: minutes.toString(),
  })}`;
};
