import { useGetParam } from '@/hooks/useGetParam';

export function useGetParamsInfo() {
  const getParam = useGetParam();

  const outboundDate = getParam('outboundDate');

  const returnDate = getParam('returnDate');

  const adults = getParam('adults');

  const childrenAges = getParam('childrenAges').filter(
    (e) => Number(e) > 1
  ).length;

  const searchId = getParam('searchId');

  const proposalId = getParam('proposalId');

  const destinationId = getParam('destinationId');

  return {
    searchId,
    proposalId,
    outboundDate,
    returnDate,
    adults,
    childrenAges,
    destinationId,
  };
}
