import { useBookingType } from '@/hooks/useBookingType';
import { useGetParam } from '@/hooks/useGetParam';
import { useStore } from '@/store/useStore';
import { useGetHotels } from '@/views/HotelsFlightsSearch/useGetHotels';
import '@mapbox-controls/zoom/src/index.css';
import { useTranslate } from '@tolgee/react';
import type { Feature } from 'geojson';
import mapboxgl, { Map as MapGL } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { createHotelMarker } from './hotelMarker';
import * as Styled from './Map.styled';
import './mapStyles.css';
import { useUpdateMap } from './useUpdateMap';
import { Button } from '@mui/material';
import { useIsMobile } from '@/hooks/useIsMobile';

mapboxgl.accessToken = window._env_.VITE_MAPBOX_KEY;

export function Map({ fullMapState }) {
  const [brandConfig, retrieveProporsals] = useStore((state) => [
    state.brandConfig,
    state.retrieveProporsals,
  ]);

  const { t } = useTranslate();

  const [fullMap, setFullMap] = fullMapState;

  const primaryColor = brandConfig.theme.primaryColor;

  const mapContainer = React.useRef(null);

  const map = React.useRef<MapGL>(null);

  const { isMobile, isTablet } = useIsMobile();

  const bookingType = useBookingType();

  const updateMap = useUpdateMap();

  const getParam = useGetParam();

  const hotels = useGetHotels();

  const [mapMarkers, setMapMarkers] = React.useState<mapboxgl.Marker[]>();

  const destination = getParam('destinationId');

  const translate = (key: string) => {
    return t(key);
  };

  React.useEffect(() => {
    if (map.current) return;
    if (
      retrieveProporsals &&
      retrieveProporsals?.staticData?.cities &&
      retrieveProporsals?.staticData?.accommodations
    ) {
      const cityCoordinates = retrieveProporsals.staticData.cities.find((e) => {
        return e.id === Number(destination);
      })?.coordinates;

      const secondCity = retrieveProporsals.staticData.cities[0].coordinates;

      const hotelArr = retrieveProporsals.facets.value
        .find((e) => e.id === 'coordinates')
        ?.items.map((e): Feature => {
          const coordinates = e.code.split('::');
          return {
            type: 'Feature',
            properties: {
              price: Math.floor(e.metadata.cheapestPrice.std / 100),
              code: e.code,
            },
            geometry: {
              type: 'Point',
              coordinates: [Number(coordinates[1]), Number(coordinates[0])],
            },
          };
        });

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/lastminute/cksrbm3920zks17mhurxew1fn',
        center: cityCoordinates
          ? [cityCoordinates.longitude, cityCoordinates.latitude]
          : [secondCity.longitude, secondCity.latitude],
        zoom: cityCoordinates ? 12 : 10,
      });

      map.current.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
        'top-right'
      );

      map.current.on('load', () => {
        map.current.addSource('hotels', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: hotelArr,
          },
        });

        map.current.addLayer({
          id: 'hotels',
          type: 'circle',
          source: 'hotels',
          paint: {
            'circle-color': primaryColor,
            'circle-radius': 2,
            'circle-stroke-width': 2,
            'circle-stroke-color': 'rgba(255,255,255,0)',
          },
        });

        map.current.on('zoomend', (e) => {
          const bounds = e.target.getBounds();
          updateMap(bounds);
        });

        map.current.on('dragend', (e) => {
          const bounds = e.target.getBounds();
          updateMap(bounds);
        });

        return () => map.current.remove();
      });
    }
  }, [retrieveProporsals]);

  React.useEffect(() => {
    if (
      retrieveProporsals &&
      retrieveProporsals?.staticData?.cities &&
      retrieveProporsals?.staticData?.accommodations &&
      map.current
    ) {
      if (mapMarkers) {
        mapMarkers.forEach((e) => e.remove());
        setMapMarkers(undefined);
      }

      const markers = hotels.map((hotel) =>
        createHotelMarker({
          hotelInfo: hotel,
          brandColor: primaryColor,
          map: map.current,
          translate: translate,
          bookingType,
        })
      );

      setMapMarkers(markers);
    }
  }, [retrieveProporsals]);

  return (
    <Styled.Container ref={mapContainer} $badgeColor={primaryColor}>
      {!isTablet && (
        <Styled.ToggleVisibilityButton onClick={() => setFullMap(!fullMap)}>
          {fullMap ? <Styled.ArrowFrontIcon /> : <Styled.ArrowBackIcon />}
        </Styled.ToggleVisibilityButton>
      )}
    </Styled.Container>
  );
}
