import {
  StaticDataTransportDetail,
  TransportProvider,
} from '@/services/codegen-romulo';
import CurrencyFormatter from '@/utils/currencyFormatter';
import { ProposalTransportWay } from '../types/ProposalTransportWay';
import {
  JourneyContractSegmentTransportType,
  JourneyContractWay,
  JourneyContractWayTransportCategory,
} from '../types/tripDetailsTypes/JourneyContract';
import { wayFactory } from '../types/tripDetailsTypes/Way';
import { WayDetailsType } from '../types/tripDetailsTypes/WayDetailsType';
import { journeyDetailsWayAdapter } from './journeyDetailsWayAdapter';
import { TolgeeTranslationFn } from '@/utils/types/TolgeeTranslationFn';

export const flightDetailsMapper = (
  t: TolgeeTranslationFn,
  proposalTransport: {
    outbound: ProposalTransportWay;
    inbound?: ProposalTransportWay;
  },
  airports: Record<string, StaticDataTransportDetail>,
  airlines: Record<string, TransportProvider>,
  wayFare: number,
  adults: number,
  childrenAges: number[],
  language: string,
  currency: string
): { outbound: WayDetailsType; inbound?: WayDetailsType } => {
  const currencyFormatter = new CurrencyFormatter(language, currency);
  const peopleNumber = adults + (childrenAges?.length ?? 0);
  const infantCount = childrenAges?.filter((e) => e < 2).length ?? 0;
  const childrenCount = childrenAges?.filter((e) => e >= 2).length ?? 0;

  const outbound = proposalTransport.outbound;

  const outboundDeparture = airports[outbound.departureId];
  const outboundArrival = airports[outbound.arrivalId];
  const outboundPrice = outbound?.price && {
    fare:
      (outbound.price.adult.fare * adults +
        outbound.price.child.fare * childrenCount +
        outbound.price.infant.fare * infantCount +
        wayFare) /
      peopleNumber /
      100,
    tax:
      (outbound.price.adult.tax * adults +
        outbound.price.child.tax * childrenCount +
        outbound.price.infant.tax * infantCount) /
      peopleNumber /
      100,
  };

  const outboundWay: JourneyContractWay = {
    segments: outbound.segments.map((s) => {
      const supplier = airlines[s.supplier.id];
      return {
        departure: {
          hub: {
            id: s.departureId,
            name: airports[s.departureId]?.label,
            terminal: s.terminal,
          },
          datetime: s.isoZonedDepartureDateTime,
        },
        arrival: {
          hub: {
            id: s.arrivalId,
            name: airports[s.arrivalId]?.label,
          },
          datetime: s.isoZonedArrivalDateTime,
        },
        transport: {
          class: t(s.classAvailability),
          operativeId: s.supplier.id + s.supplier.carrierId,
          type: JourneyContractSegmentTransportType.FLIGHT,
          companyCode: s.supplier.id,
          companyName: supplier?.label,
        },
        duration: outbound.durationInMinutes,
        technicalStops:
          s.technicalStops.length > 0
            ? s.technicalStops?.map((te) => {
                const hub = airports[te.locationId];
                return {
                  arrivalTime: te.isoZonedArrivalDateTime,
                  departureTime: te.isoZonedDepartureDateTime,
                  hub: {
                    id: te.locationId,
                    name: hub?.label,
                    terminal: undefined,
                  },
                };
              })
            : undefined,
      };
    }),
    priceInformation: outboundPrice && {
      price: {
        perPassenger: { amount: outboundPrice.fare },
      },
      taxes: { perPassenger: { amount: outboundPrice.tax } },
    },
    seats: undefined,
    transportCategory: JourneyContractWayTransportCategory.STANDARD,
    durationInMinutes: outbound.durationInMinutes,
    selected: false,
    onSelected: (checked) => undefined,
    baggage: {
      hand: outbound.handBaggageIncluded,
      hold: outbound.baggageIncluded,
    },
    locations: {
      origin: outboundDeparture?.cityName,
      destination: outboundArrival?.cityName,
    },
    /** Used only on details when search date different from trip date. Summaries components now rely on JourneySummaryContract.DateAsText */
    differentDepartureDate: false,
  };

  const inbound = proposalTransport.inbound;

  if (inbound) {
    const inboundDeparture = airports[inbound.departureId];
    const inboundArrival = airports[inbound.arrivalId];
    const inboundPrice = inbound?.price && {
      fare:
        (inbound.price.adult.fare * adults +
          inbound.price.child.fare * childrenCount +
          inbound.price.infant.fare * infantCount +
          wayFare) /
        peopleNumber /
        100,
      tax:
        (inbound.price.adult.tax * adults +
          inbound.price.child.tax * childrenCount +
          inbound.price.infant.tax * infantCount) /
        peopleNumber /
        100,
    };
    const inboundWay: JourneyContractWay = {
      segments: inbound.segments.map((s) => {
        const supplier = airlines[s.supplier.id];
        return {
          departure: {
            hub: {
              id: s.departureId,
              name: airports[s.departureId]?.label,
              terminal: s.terminal,
            },
            datetime: s.isoZonedDepartureDateTime,
          },
          arrival: {
            hub: {
              id: s.arrivalId,
              name: airports[s.arrivalId]?.label,
            },
            datetime: s.isoZonedArrivalDateTime,
          },
          transport: {
            class: t(s.classAvailability),
            operativeId: s.supplier.id + s.supplier.carrierId,
            type: JourneyContractSegmentTransportType.FLIGHT,
            companyCode: s.supplier.id,
            companyName: supplier?.label,
          },
          duration: 100,
          technicalStops: s.technicalStops
            ? s.technicalStops?.map((te) => {
                const hub = airports[te.locationId];
                return {
                  arrivalTime: te.isoZonedArrivalDateTime,
                  departureTime: te.isoZonedDepartureDateTime,
                  hub: {
                    id: te.locationId,
                    name: hub?.label,
                    terminal: undefined,
                  },
                };
              })
            : undefined,
        };
      }),
      /** Optional info about price and taxes. [ OBE: false; CNCR: true except promo; ] */
      priceInformation: inboundPrice && {
        price: {
          perPassenger: { amount: inboundPrice.fare },
        },
        taxes: { perPassenger: { amount: inboundPrice.tax } },
      },
      seats: undefined,
      transportCategory: JourneyContractWayTransportCategory.STANDARD,
      durationInMinutes: inbound.durationInMinutes,
      selected: false,
      onSelected: (checked) => undefined,
      baggage: {
        hand: inbound.handBaggageIncluded,
        hold: inbound.baggageIncluded,
      },
      locations: {
        origin: inboundDeparture?.cityName,
        destination: inboundArrival?.cityName,
      },
      /** Used only on details when search date different from trip date. Summaries components now rely on JourneySummaryContract.DateAsText */
      differentDepartureDate: false,
    };

    return {
      outbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(outboundWay),
        currencyFormatter
      ),
      inbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(inboundWay),
        currencyFormatter
      ),
    };
  } else {
    return {
      outbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(outboundWay),
        currencyFormatter
      ),
    };
  }
};
