import { Segment } from '../types/tripDetailsTypes/Segment';
import { SegmentStopInformation } from '../types/tripDetailsTypes/SegmentStopInformation';

export function getStopInfoBasedOnType(
  segmenDomain: Segment,
  allSegments: Array<Segment>,
  index: number
): SegmentStopInformation {
  const technicalStopsInfo = segmenDomain.getTechnicalStops();

  if (technicalStopsInfo?.length > 0) {
    return {
      stopLandingTime: technicalStopsInfo[0]?.arrivalTime,
      stopDepartureTime: technicalStopsInfo[0]?.departureTime,
      isTechnical: true,
    };
  }

  return {
    stopLandingTime: segmenDomain.getArrival().datetime,
    stopDepartureTime: allSegments[index + 1].getDeparture().datetime,
    isTechnical: false,
  };
}
