import { useStore } from '@/store/useStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, InputAdornment, Snackbar, Tooltip } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment, useState } from 'react';
import { SectionCard } from '../SectionCard/SectionCard';
import * as Styled from './SuccessCard.styled';
export const SuccessCard: FC = () => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [order] = useStore((state) => [state.order]);
  const orderId = order?.orderId;

  const handleCopy = () => {
    navigator.clipboard.writeText(`${orderId}`);
    setOpen(true);
  };

  return (
    <SectionCard title={t('checkout.order_complete.title')}>
      <Fragment>
        <Styled.Detail>
          {t('checkout.order_complete.description')}
        </Styled.Detail>
        <Styled.ContentContainer>
          <Styled.TextField
            label={t('general.booking_id')}
            value={orderId || ''}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={t('general.actions.copy_booking_id')}>
                      <IconButton onClick={() => handleCopy()}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Styled.ContentContainer>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={t('general.actions.copied_booking_id')}
        />
      </Fragment>
    </SectionCard>
  );
};
