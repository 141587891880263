import { Divider } from 'antd';
import React from 'react';
import styled from '@emotion/styled';
import { CodeCard } from './CodeCard';
import {
  BusLineIcon,
  PlaneNEIcon,
  TrainLineIcon,
} from '@/components/LmnIcons/LmnIcons';
import { getTimeInTheTimezone } from '@/utils/timeUtils';
import { Hub } from './Hub';
import { TransportStopBetweenSegments } from './TransportStopBetweenSegments';
import { TransportBaggage } from './TransportBaggage';
import { SeatSelectionMessage } from './SeatSelectionMessage';
import { LogoFallback } from './LogoFallback';
import { JourneyContractSegmentTransportType } from '@/views/FlightsSearch/useGetTransports/types/tripDetailsTypes/JourneyContract';
import { TransportDetailsSegmentProps } from './TransportDetailSegment.types';
import * as Styled from './TransportDetailSegment.styled';

const RotatedIconPlaneNe = styled(PlaneNEIcon)`
  transform: rotate(135deg);
  font-size: 18.75px;
`;

const IconMap = {
  [JourneyContractSegmentTransportType.FLIGHT]: RotatedIconPlaneNe,
  [JourneyContractSegmentTransportType.TRAIN]: TrainLineIcon,
  [JourneyContractSegmentTransportType.BUS]: BusLineIcon,
};

export function TransportDetailSegment({
  stopInformation,
  transportDuration,
  isInbound,
  departure,
  arrival,
  transport,
  baggage,
  seatSelection,
  showBaggageInfo,
}: TransportDetailsSegmentProps) {
  const StyledIcon = IconMap[transport.type];

  return (
    <>
      <Styled.SegmentContainer>
        <Styled.MediumText as={Styled.Time}>
          <Styled.Hour>
            {getTimeInTheTimezone(departure.datetime, undefined, true).format(
              'HH:mm'
            )}
          </Styled.Hour>
          <Styled.SmallText
            as={
              departure.differentDayDepartureKey
                ? Styled.RedText
                : Styled.GrayText
            }
          >
            <Styled.DifferentDay>
              {getTimeInTheTimezone(departure.datetime, undefined, true).format(
                'DD MMM'
              )}
            </Styled.DifferentDay>
          </Styled.SmallText>
        </Styled.MediumText>
        <Styled.SegmentInfo>
          <Styled.DotBeforeTransport
            isEmptyCircle={departure.departureAfterStop}
          />
          <Hub
            hub={departure.hub}
            shouldHighlight={departure.departingOnDifferentLocation}
            terminalText={departure.terminalText}
          />
          <Styled.ProviderInfo>
            <Styled.CompanyInfo>
              <LogoFallback
                companyCode={transport.companyCode}
                isInbound={isInbound}
                transportType={transport.type}
                logoSize={24}
              />
              <Styled.CompanyInfoCode>
                {transport.companyName}
              </Styled.CompanyInfoCode>
            </Styled.CompanyInfo>
            {transport.operatedByTransportLabel && (
              <Styled.BaseText as={Styled.GrayText}>
                {transport.operatedByTransportLabel}
              </Styled.BaseText>
            )}
            <Styled.BaseText as={Styled.GrayText}>
              {transport.operativeId} &#x2022; {transport.class}
            </Styled.BaseText>

            <Divider style={{ margin: '8px 0px' }} />
            {showBaggageInfo && (
              <>
                <Styled.BaggageContainer>
                  <TransportBaggage baggage={baggage} />
                </Styled.BaggageContainer>
              </>
            )}
            {seatSelection.showSeatSelectionMessage && (
              <Styled.SeatMessageContainer>
                <SeatSelectionMessage
                  companyName={seatSelection.companyNameSeatSelectionMessage}
                />
              </Styled.SeatMessageContainer>
            )}
            {transport.PNR && (
              <CodeCard
                code={transport.PNR.pnr}
                title={transport.PNR.title}
                tooltip={transport.PNR.tooltip}
                onCopyClick={transport.PNR.onCopyClick}
              />
            )}
          </Styled.ProviderInfo>
          <Styled.StyledIconContainer>
            <Styled.IconInnerContainer>
              {transportDuration && (
                <Styled.TransportDuration>
                  {transportDuration}
                </Styled.TransportDuration>
              )}
              <StyledIcon style={{ fontSize: 24 }} />
            </Styled.IconInnerContainer>
          </Styled.StyledIconContainer>
        </Styled.SegmentInfo>
      </Styled.SegmentContainer>
      <Styled.SegmentContainer>
        <Styled.MediumText as={Styled.Time}>
          <Styled.Hour>
            {getTimeInTheTimezone(arrival.datetime, undefined, true).format(
              'HH:mm'
            )}
          </Styled.Hour>
          <Styled.SmallText
            as={
              arrival.differentDayArrivalKey ? Styled.RedText : Styled.GrayText
            }
          >
            <Styled.DifferentDay>
              {getTimeInTheTimezone(arrival.datetime, undefined, true).format(
                'DD MMM'
              )}
            </Styled.DifferentDay>
          </Styled.SmallText>
        </Styled.MediumText>
        <Styled.SegmentInfo>
          <Styled.DotBeforeTransport
            isEmptyCircle={arrival.arrivalBeforeStop}
          />
          <Hub
            hub={arrival.hub}
            shouldHighlight={arrival.arrivalOnDifferentLocation}
            terminalText={arrival.terminalText}
          />
          {stopInformation && (
            <TransportStopBetweenSegments
              {...stopInformation}
              differentLocationLabel={arrival.differentLocationLabel}
              baggageRecheckLabel={arrival.baggageRecheckLabel}
            />
          )}
          {stopInformation && <Styled.StopLine />}
          {arrival.arrivalBeforeStop && !stopInformation && (
            <Styled.StyledIconContainer>
              <StyledIcon style={{ fontSize: 24 }} />
            </Styled.StyledIconContainer>
          )}
        </Styled.SegmentInfo>
      </Styled.SegmentContainer>
    </>
  );
}
