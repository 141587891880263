import { SearchIcon } from '@/components/LmnIcons/LmnIcons';
import { PassengersSelector } from '@/components/PassengerSelector';
import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useCreateSearch } from '@/hooks/useCreateSearch';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetParam } from '@/hooks/useGetParam';
import { useTrack } from '@/hooks/useTrack';
import { TravellerGroup } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { getDefaultDates } from '@/utils/getDefaultDates';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { CircularProgress, Switch } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { Dayjs } from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import { DateSelector } from './components/DateSelector/DateSelector';
import { FlightTypeSelector } from './components/FlightTypeSelector';
import { LocationSelector } from './components/LocationSelector/LocationSelector';
import { ProductSelector } from './components/ProductSelector';
import { useSearchStore } from './hooks/useSearchStore';
import * as Styled from './Search.styled';

export function Search() {
  const { t } = useTranslate();
  const bookingType = useBookingType();
  const [
    departure,
    setDeparture,
    missingDeparture,
    setMissingDeparture,
    missingDestination,
    setMissingDestination,
    destination,
    setDestination,
    flightType,
    setFlightType,
    loadingSearch,
    setLoadingSearch,
    withHotel,
    setWithHotel,
  ] = useSearchStore(
    ({
      departure,
      setDeparture,
      missingDeparture,
      setMissingDeparture,
      missingDestination,
      setMissingDestination,
      destination,
      setDestination,
      flightType,
      setFlightType,
      loadingSearch,
      setLoadingSearch,
      withHotel,
      setWithHotel,
    }) => [
      departure,
      setDeparture,
      missingDeparture,
      setMissingDeparture,
      missingDestination,
      setMissingDestination,
      destination,
      setDestination,
      flightType,
      setFlightType,
      loadingSearch,
      setLoadingSearch,
      withHotel,
      setWithHotel,
    ]
  );
  const track = useTrack();

  const freeFlightCampaign = useFreeFlightCampaign();
  const brandConfig = useStore((state) => state.brandConfig);
  const { accentColor } = brandConfig.theme;
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const [rooms, setRooms] = useState<TravellerGroup[]>([
    { adults: freeFlightCampaign?.adults || 2, childrenAges: [] },
  ]);
  const [dateRange, setDateRange] = useState<{ from?: Dayjs; to?: Dayjs }>(
    getDefaultDates()
  );
  const getParam = useGetParam();

  const createSearch = useCreateSearch();

  const product = getParam('product');

  // TODO FIND A BETTER SOLUTION
  React.useEffect(() => {
    setTimeout(() => {
      setMissingDeparture(false);
      setMissingDestination(false);
    }, 5000);
  }, [missingDeparture, missingDestination]);

  const onSearch = async () => {
    track({
      eventName: 'clicked.search-button',
      logLevel: 'info',
    });
    if (!departure) {
      setMissingDeparture(true);
    }
    if (!destination) {
      setMissingDestination(true);
    }

    const allFilled = checkStatus();

    if (allFilled) {
      setLoadingSearch(true);
      await createSearch({
        departure,
        destination,
        dateRange,
        withHotel: product === 'F' && flightType === FlightTypes.R && withHotel,
        travellerGroups: rooms,
      }).finally(() => setLoadingSearch(false));
    }
  };

  const checkStatus = (): boolean => {
    switch (bookingType) {
      case BookingType.FlightAndHotel:
        if (departure && destination && rooms) {
          if (dateRange) {
            if (dateRange.from && dateRange.to) {
              return true;
            }
            return false;
          }
          return false;
        }
        return false;
      case BookingType.Hotel:
        if (destination && rooms) {
          if (dateRange) {
            if (dateRange.from && dateRange.to) {
              return true;
            }
            return false;
          }
          return false;
        }
        return false;
      case BookingType.Flight:
        if (departure && destination && rooms) {
          if (dateRange) {
            if (flightType === FlightTypes.OW) {
              return !!dateRange.from;
            } else {
              return !!(dateRange.from && dateRange.to);
            }
          }
          return false;
        }
        return false;
      default:
        return false;
    }
  };

  return (
    <React.Fragment>
      {brandConfig.brand.productsInSelector.length > 0 && !isFreeFlight && (
        <ProductSelector />
      )}
      {bookingType === BookingType.Flight && !isFreeFlight && (
        <Styled.FlightOptions>
          <FlightTypeSelector
            flightType={flightType}
            setFlightType={setFlightType}
          />
          {flightType === FlightTypes.R && (
            <Styled.Switch>
              {t('search.add.hotel')}
              <Switch
                value={withHotel}
                disableRipple
                onChange={(_, active) => setWithHotel(active)}
              />
            </Styled.Switch>
          )}
        </Styled.FlightOptions>
      )}
      <Styled.SearchContainer $isFreeFlight={isFreeFlight}>
        <LocationSelector
          setDeparture={setDeparture}
          setDestination={setDestination}
          missingDeparture={missingDeparture}
          missingDestination={missingDestination}
        />
        <DateSelector
          type="RETURN"
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        {!isFreeFlight && (
          <PassengersSelector
            rooms={rooms}
            setRooms={setRooms}
            searchType={bookingType}
          />
        )}
        <Styled.Button
          onClick={onSearch}
          disabled={loadingSearch}
          data-test="search-form-search-button"
          $color={accentColor}
        >
          {loadingSearch ? (
            <CircularProgress
              sx={{
                color: 'white',
                width: '24px !important',
                height: '24px !important',
              }}
            />
          ) : (
            <SearchIcon size={24} />
          )}
          <Styled.SearchText>{t('search.search')}</Styled.SearchText>
        </Styled.Button>
      </Styled.SearchContainer>
      {bookingType === BookingType.Flight &&
        flightType === FlightTypes.R &&
        !isFreeFlight && (
          <Styled.SwitchMobile>
            {t('search.add_hotel')}
            <Switch
              value={withHotel}
              disableRipple
              onChange={(_, active) => setWithHotel(active)}
            />
          </Styled.SwitchMobile>
        )}
    </React.Fragment>
  );
}
