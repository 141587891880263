import { TransportType } from '@/components/TransportCard/FullTransportLogos/TransportLogo';
import { InboundTransport, OutboundTransport } from '@/services/codegen-romulo';
import { getTimeInTheTimezone, minutesToHours } from '@/utils/timeUtils';
import { FlightRowProps } from '../FlightRow';

export const flightRowAdapter = (
  transports: InboundTransport | OutboundTransport,
  t: (key: string) => string
): FlightRowProps => {
  const from = transports.segments[0];
  const to = transports.segments[transports.segments.length - 1];

  const departureCity = from?.departureStation;
  const arrivalCity = to?.destinationStation;
  const departureDate = getTimeInTheTimezone(
    from?.departureDateTime,
    undefined,
    true
  );
  const arrivalDate = getTimeInTheTimezone(
    to?.destinationDateTime,
    undefined,
    true
  );
  const diff = arrivalDate.diff(departureDate, 'minutes');
  const companies = transports.segments.map((s) => ({
    code: s.company.id,
    type: transports.transportType as TransportType,
    name: s.company.name,
  }));

  const isSameDay = departureDate.isSame(arrivalDate, 'day');

  return {
    companies: companies,
    departure: {
      time: departureDate.format('HH:mm'),
      airport: { name: departureCity.cityName, IATA: departureCity.iataCode },
    },
    arrival: {
      time: arrivalDate.format('HH:mm'),
      airport: { name: arrivalCity.cityName, IATA: arrivalCity.iataCode },
    },
    stops:
      transports.segments.length - 1 === 0
        ? t('flight.stops.direct')
        : `${transports.segments.length - 1} ${
            transports.segments.length - 1 > 1
              ? t('flight.stops').toLowerCase()
              : t('flight.stops.stop').toLowerCase()
          }`,
    duration: `${minutesToHours(diff)}`,
    differentDay: !isSameDay,
  };
};
