import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const TransportInfoContainer = styled.div<{ $withPin: boolean }>`
  display: grid;
  gap: 8px;

  ${({ $withPin }) =>
    $withPin
      ? css`
          grid-template-columns: 32px 48px 5fr 60px;
        `
      : css`
          grid-template-columns: 48px 5fr 60px;
        `}

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    padding-right: 0px;
  }
`;

export const LogoColumn = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  margin: 0px 0px 0px auto;

  @media (max-width: 767px) {
    display: none;
  }
`;
export const PinColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 8px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const TimelineColumn = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const JourneySummary = styled.div<{ $inLine?: boolean }>`
  width: 100%;
  display: grid;

  ${({ $inLine }) =>
    $inLine
      ? css`
          grid-template-columns: 62px auto 62px;
        `
      : css`
          grid-template-columns: 76px auto 76px;
        `}

  @media (max-width: 767px) {
    grid-template-columns: 62px auto 62px;
  }
`;

export const Luggage = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
