import * as React from 'react';
import * as Styled from './ImagesCarousel.styled';
import { carouselStylesProps } from './utils';

type AnimationProp = 'fade' | 'slide';

export function ImagesCarousel({
  images,
  className,
  animation = 'slide',
}: {
  images: string[];
  className?: any;
  animation?: AnimationProp;
}) {
  return (
    <Styled.Carousel
      className={className}
      animation={animation}
      navButtonsWrapperProps={carouselStylesProps.navButtonsWrapperProps}
      indicatorContainerProps={carouselStylesProps.indicatorContainerProps}
      indicatorIconButtonProps={carouselStylesProps.indicatorIconButtonProps}
      activeIndicatorIconButtonProps={
        carouselStylesProps.activeIndicatorIconButtonProps
      }
      NavButton={({ onClick, className, style, next, prev }) => {
        return (
          <Styled.IconContainer
            onClick={(e) => onClick(e)}
            className={className}
            style={style}
          >
            {next && <Styled.ChevronRightIcon />}
            {prev && <Styled.ChevronLeftIcon />}
          </Styled.IconContainer>
        );
      }}
      navButtonsAlwaysVisible={true}
      autoPlay={false}
    >
      {images
        ?.slice(0, 5)
        .map((image, key) => (
          <Styled.Image src={image} loading="lazy" key={key} />
        ))}
    </Styled.Carousel>
  );
}
