import { Skeleton as FlightCardSkeketon } from '../FlightCard/Skeleton';
import * as Styled from './FlightsList.styled';

export function Skeleton() {
  return (
    <Styled.Container>
      {Array.from({ length: 20 }).map((e, index) => (
        <FlightCardSkeketon key={`flight_card_skeleton_${e}_${index}`} />
      ))}
    </Styled.Container>
  );
}
