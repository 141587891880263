import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './DPFiltersVerticalContainer.styled';
import { BudgetFilters } from '../BudgetFilters';
import { FreeCancellationFilter } from '../FreeCancellationFilter';
import { PropertyTypesFilters } from '../PropertyTypeFilters';
import { StarsFilters } from '../StarsFilters';
import { RatingFilters } from '../RatingFilters';
import { AmenitiesFilters } from '../AmenitiesFilters';
import { CharacteristicsFilters } from '../CharacteristicsFilters';
import { MealPlansFilters } from '../MealPlansFilters';
import { ResetFilters } from '../../ResetFilters';

const allFilters = [
  {
    type: 'free-cancellation',
    title: 'filter.title.free_cancellation',
    content: <FreeCancellationFilter />,
  },
  {
    type: 'budget',
    title: 'filter.title.budget',
    content: <BudgetFilters />,
  },
  {
    type: 'property-type',
    title: 'filter.title.property_type',
    content: <PropertyTypesFilters />,
  },
  {
    type: 'stars',
    title: 'filter.title.stars',
    content: <StarsFilters />,
  },
  {
    type: 'meal-plan',
    title: 'filter.title.meal_plans',
    content: <MealPlansFilters />,
  },
  {
    type: 'rating',
    title: 'filter.title.rating',
    content: <RatingFilters />,
  },
  {
    type: 'aminities',
    title: 'filter.title.amenities',
    content: <AmenitiesFilters />,
  },
  {
    type: 'characteristics',
    title: 'filter.title.characteristics',
    content: <CharacteristicsFilters />,
  },
];

export function DPFiltersVerticalContainer() {
  const { t } = useTranslate();

  const [hdpFilters] = useStore((state) => [state.hdpFilters]);

  const { isFecthRetrieveProporsals } = useGetFiltersData();

  const disableResetButton =
    Object.keys(hdpFilters).filter((item) => item !== 'undefined').length ===
      0 || isFecthRetrieveProporsals;

  return (
    <>
      {allFilters.map((filter, key) => {
        return (
          <Styled.AccordionContainer defaultExpanded elevation={0} key={key}>
            <Styled.AccordionSummary
              aria-controls={`panel${key}-${filter.type}`}
              id={`panel${key}-header`}
              expandIcon={<Styled.ExpandIcon />}
            >
              <Styled.SectionTitle>{t(filter.title)}</Styled.SectionTitle>
            </Styled.AccordionSummary>
            <Styled.AccordionDetails>{filter.content}</Styled.AccordionDetails>
          </Styled.AccordionContainer>
        );
      })}
      <ResetFilters disableResetButton={disableResetButton} />
    </>
  );
}
