import {
  Segment,
  StaticDataTransportDetail,
  TransportSegment,
} from '@/services/codegen-romulo';
import { calculateTimeFrames } from '@/utils/timeUtils';
import dayjs from 'dayjs';

export const getStopsLabel = (
  segments: Segment[] | TransportSegment[],
  airports: Record<string, StaticDataTransportDetail>
) => {
  if (segments.length === 1) {
    return {
      label: 'flight.stops.direct',
    };
  } else {
    const segmentsInfo = segments.reduce(
      (acc, curr, currIndex, arr) => {
        if (currIndex === 0) {
          return acc;
        } else {
          const lastArrival = arr[currIndex - 1].isoZonedArrivalDateTime;
          const currDeparture = curr.isoZonedDepartureDateTime;
          const diffTime = dayjs(currDeparture).diff(lastArrival);
          const airportDeparture = curr.departureId;
          const airport = airports[airportDeparture]?.label;

          acc = [
            ...acc,
            {
              diffTime: calculateTimeFrames(diffTime),
              airportDeparture,
              airportName: airport,
            },
          ];
          return acc;
        }
      },
      [] as {
        diffTime: number[];
        airportDeparture: string;
        airportName: string;
      }[]
    );
    return {
      label: `${segmentsInfo.length}_${
        segmentsInfo.length > 1 ? 'flight.stops' : 'flight.stops.stop'
      }`,
      segmentsInfo,
    };
  }
};
