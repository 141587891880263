import { City, Country, Proposal } from '@/services/codegen-romulo';

type GetHotelLocationProps = {
  cities: City[];
  countries: Country[];
  accommodation: Proposal['accommodation'];
};

export function getHotelLocation({
  cities,
  countries,
  accommodation,
}: GetHotelLocationProps) {
  const city = cities.find((e) => e.id === accommodation.internalIdCity);

  const country = countries.find((e) => e.id === city?.countryId);

  const accommodationType = accommodation.accommodationType;

  return {
    city,
    country,
    accommodationType,
  };
}
