export const textRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s]+$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phoneRegex = /^(\+|00)?[1-9][0-9]{7,14}$/;
export const zipCodeRegex =
  /^(?:(?:[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2})|(?:\d{2}-\d{3})|(?:\d{3} ?\d{2})|(?:\d{4}-\d{3})|(?:\d{4} ?[A-Z]{2})|(?:\d{5}))$/;
export const streetNumberRegex =
  /^(?:\d+[A-Za-z]?(?:[-/]\d+[A-Za-z]?)?|\d+(?:º|ª)?)$/;
export const yearsRegex = /^(19|20)\d{2}$/;
export const daysRegex = /^([1-9]|[12]\d|3[01])$/;
export const numberRegex = /^\d+$/;
export const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
