import Checkbox from '@mui/material/Checkbox';
import * as Styled from './StopFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { StopProps } from './StopFilters.types';

export function Stop({ data, onStopHandle, disabled, stopFilter }: StopProps) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onStopHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((stop, index) => {
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${stop.code}_${index}_Stop`}
              control={
                <Checkbox
                  disabled={!stop.active || disabled}
                  value={stop.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={stopFilter?.includes(stop.code) ?? false}
                />
              }
              labelPlacement="start"
              label={<Styled.LabelOption>{stop?.label}</Styled.LabelOption>}
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
