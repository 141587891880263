import { useGetParam } from '@/hooks/useGetParam';
import {
  CartApiServiceService,
  SpecialRequestInputDto,
} from '@/services/codegen-romulo';
import { useSpecialAssistanceStore } from '../components/SpecialAssistance/hooks/useSpecialAssistanceStore';

export function usePersonalizeReservation() {
  const getParam = useGetParam();
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');

  const [
    needsSpecialAssistance,
    assistanceType,
    applicationType,
    specialAssistanceGeneralApplication,
  ] = useSpecialAssistanceStore((state) => [
    state.needsSpecialAssistance,
    state.assistanceType,
    state.applicationType,
    state.specialAssistanceGeneralApplication,
  ]);

  const handleAddSpecialRequest = async () => {
    if (!needsSpecialAssistance) {
      return Promise.resolve(true);
    }

    let application: SpecialRequestInputDto = {};

    switch (applicationType) {
      case 'GENERAL_APPLICATION':
        application = {
          openText: specialAssistanceGeneralApplication,
          unaccompaniedMinors: false,
          travellers: null,
        };

      case 'REDUCED_MOBILITY':
        application = {
          openText: '',
          unaccompaniedMinors: false,
          travellers: assistanceType.map((assis) => {
            return {
              id: Number(assis.travellerId),
              requirements: [
                {
                  assistanceType: assis.assistanceType,
                },
              ],
            };
          }),
        };

      default:
        break;
    }

    return await CartApiServiceService.postApiServicesAppCartApiServiceAddSpecialRequestToCart(
      {
        cartId,
        requestUuid,
        requestBody: application,
      }
    )
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject(false));
  };

  const personalizeReservation = async () => {
    return Promise.all([await handleAddSpecialRequest()])
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject(false));
  };

  return { personalizeReservation };
}
