import styled from '@emotion/styled';

export const Contanier = styled.div`
  display: none;
  align-items: center;
  justify-content: space-around;
  padding: 0 24px;
  width: 100%;
  height: 56px;
  background-color: #f5f5f5;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Text = styled.span`
  line-height: 20px;
  font-size: 14px;
`;

export const ModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
