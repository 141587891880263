import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';
export * from '../Layout.styled';

export const MapContainer = styled.div<{
  $fullWidth: any;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'calc(100vw - 876px)')};
  height: calc(100vh - 136px);
`;

export const TransportContainer = styled.div`
  margin-bottom: 25px;
`;

export const HotelResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 5px;
`;

export const TabletHorizontalContainer = styled(Paper)`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: white;
  opacity: 1;
  transition:
    opacity 0.3s ease-in,
    top 0.35s ease-out;
  position: fixed;
  top: 64px;
  left: 0px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  overflow: auto hidden;
  scrollbar-width: none;
`;

export const TabletHorizontalButton = styled.div`
  height: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 19px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  scroll-snap-align: center;
  user-select: none;

  box-shadow: unset;
  background-color: unset;
  width: 33.3%;
  justify-content: center;

  :not(:last-child) {
    border-right: 1px solid #e3e3e3;
    border-radius: 0;
  }
`;
