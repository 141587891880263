import { DocumentTitle } from '@/components/DocumentTitle';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { LmnFooter } from '@/components/LmnFooter';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import * as React from 'react';
import { TravelSearchInfo } from './TravelSearchInfo';
import { usePopulateTravelProduct } from './usePopulateTravelProduct';

export function TravelSearch() {
  const [brandConfig, currentSearchId] = useStore((state) => [
    state.brandConfig,
    state.currentSearchId,
  ]);

  const track = useTrack();

  usePopulateTravelProduct();

  React.useEffect(() => {
    if (currentSearchId) {
    }
  }, [currentSearchId]);

  React.useEffect(() => {
    if (brandConfig.landingContent) {
      track({
        eventName: 'opened.search-landing',
        logLevel: 'info',
      });
    }
  }, [brandConfig.landingContent]);

  if (!brandConfig.landingContent) {
    return null;
  }

  const { hero, productExplanation } = brandConfig.landingContent;

  return (
    <>
      <DocumentTitle title={brandConfig.brand.pageTitle} />
      <Header />
      <Hero
        title={hero.title}
        subtitle={hero.subtitle}
        backgroundUrl={hero.backgroundUrl}
      />
      {productExplanation && (
        <TravelSearchInfo
          title={productExplanation.title}
          subtitle={productExplanation.subtitle}
          description={productExplanation.description}
        />
      )}
      <LmnFooter />
    </>
  );
}
