import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { FreeFlightService } from '@/services/codegen-welfare';
import { useStore } from '@/store/useStore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Alert, CircularProgress } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useContactDetailsStore } from '../ContactDetails/hooks/useContactDetailsStore';
import { emailRegex } from '../ContactDetails/utils/regexDictionary';
import { SectionCard } from '../SectionCard/SectionCard';
import * as Styled from './FFVoucherPayment.styled';
import freeFlightErrorDictionary from './utils/freeFlightErrorDictionary.json';
import { getInputAdornment } from './utils/getInputAdornment';

export const FFVoucherPayment = () => {
  const brandConfig = useStore((state) => state.brandConfig);
  const { accentColor } = brandConfig.theme;
  const tenantDomain = brandConfig.brand.freeFlightConditions.tenantDomain;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslate();
  const [
    addFreeFlightVoucherInfo,
    freeFlightVoucherIsValid,
    SetFreeFlightVoucherIsValid,
    freeFlightVoucherError,
    setfreeFlightVoucherError,
    freeFlightVoucherInfo,
  ] = useContactDetailsStore(
    ({
      addFreeFlightVoucherInfo,
      freeFlightVoucherIsValid,
      SetFreeFlightVoucherIsValid,
      freeFlightVoucherError,
      setfreeFlightVoucherError,
      freeFlightVoucherInfo,
    }) => [
      addFreeFlightVoucherInfo,
      freeFlightVoucherIsValid,
      SetFreeFlightVoucherIsValid,
      freeFlightVoucherError,
      setfreeFlightVoucherError,
      freeFlightVoucherInfo,
    ]
  );
  const [voucherCode, setVoucherCode] = useState<string>(
    freeFlightVoucherInfo?.voucherCode
  );

  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const handleChange = (e: any) => {
    const { value } = e.target;
    setVoucherCode(value);
  };

  const handleValidate = async () => {
    setIsLoading(true);
    try {
      const voucherResult: any =
        await FreeFlightService.postApiServicesAppFreeFlightCheckVoucherCode({
          requestBody: {
            voucherCode,
            tenantDomain,
          },
        });

      if (emailRegex.test(voucherResult.result)) {
        SetFreeFlightVoucherIsValid(true);
        setfreeFlightVoucherError(undefined);
        addFreeFlightVoucherInfo({
          voucherCode,
          email: voucherResult.result,
        });
      } else {
        const error = freeFlightErrorDictionary.find((e) => {
          return e.messageFromAPI === voucherResult.error.message;
        });

        setfreeFlightVoucherError(error.title);
      }
    } catch (error) {
      SetFreeFlightVoucherIsValid(false);
      setfreeFlightVoucherError(error);
    }
    setIsLoading(false);
  };

  if (!isFreeFlight) {
    return null;
  }

  return (
    <SectionCard>
      <Styled.Container>
        <div>
          <Styled.Title>{t('form.payment.free_flight.title')}</Styled.Title>
          <Styled.Subtitle>
            {t('form.payment.free_flight.description')}
          </Styled.Subtitle>
        </div>
        <Styled.InputContainer>
          <Styled.TextField
            variant="filled"
            label={t('form.contact.voucher_code')}
            name={'voucherCode'}
            onChange={handleChange}
            value={voucherCode}
            disabled={!!freeFlightVoucherInfo}
            slotProps={{
              input: {
                endAdornment: getInputAdornment({
                  freeFlightVoucherIsValid,
                  freeFlightVoucherError: !!freeFlightVoucherError,
                }),
              },
            }}
          />
          {!!!freeFlightVoucherInfo && (
            <Styled.Button
              onClick={handleValidate}
              disabled={isLoading}
              $color={accentColor}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: 'white',
                    width: '24px !important',
                    height: '24px !important',
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </Styled.Button>
          )}
        </Styled.InputContainer>
        {freeFlightVoucherError && (
          <Alert
            onClose={() => setfreeFlightVoucherError(undefined)}
            severity="warning"
            icon={<InfoOutlinedIcon />}
          >
            {t(freeFlightVoucherError)}
          </Alert>
        )}
      </Styled.Container>
    </SectionCard>
  );
};
