import Checkbox from '@mui/material/Checkbox';
import * as Styled from './AirlinesFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { AirlineProps } from './AirlinesFilters.types';

export function Airline({
  data,
  onAirlineHandle,
  airlineStaticData,
  disabled,
  airlineFilter,
}: AirlineProps) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onAirlineHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((airline, index) => {
          const info = airlineStaticData[airline.code];
          if (!info) return null;
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${airline.code}_${index}_airline`}
              control={
                <Checkbox
                  disabled={!airline.active || disabled}
                  value={airline.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={airlineFilter?.includes(airline.code) ?? false}
                />
              }
              labelPlacement="start"
              label={<Styled.LabelOption>{info?.label}</Styled.LabelOption>}
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
