import { useGetParam } from '@/hooks/useGetParam';
import { BrandConfig } from '@/store/utils/configMapper';

const imgURL = {
  dp: 'https://storage.googleapis.com/strapi-rw-data/DESKTOP_LMN_IT_DP_21101973f6/DESKTOP_LMN_IT_DP_21101973f6.jpg',
  f: 'https://storage.googleapis.com/strapi-rw-data/DESKTOP_LMN_IT_FLIGHT_8e926837e4/DESKTOP_LMN_IT_FLIGHT_8e926837e4.jpg',
  h: 'https://storage.googleapis.com/strapi-rw-data/DESKTOP_LMN_IT_HOTEL_da06c42a4e/DESKTOP_LMN_IT_HOTEL_da06c42a4e.jpg',
};

export function getBannerData(promoCampaign: BrandConfig['promoCampaign']) {
  const getParam = useGetParam();
  const product = getParam('product').toLowerCase();

  if (!promoCampaign.banner[product].ctaKey) {
    return null;
  }

  return {
    titleKey: promoCampaign.banner[product].titleKey,
    ctaKey: promoCampaign.banner[product].ctaKey,
    imgURL: imgURL[product],
    ctaURL: promoCampaign.banner[product].ctaUrl,
  };
}
