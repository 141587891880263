import { StaticDataTransportDetail } from '@/services/codegen-romulo/models/StaticDataTransportDetail';
import { TransportProvider } from '@/services/codegen-romulo/models/TransportProvider';
import { ProposalTransport } from '@/services/codegen-romulo';
import { ProposalTransportWay } from '../FlightsSearch/useGetTransports/types/ProposalTransportWay';
import {
  journeyDetailsWayAdapter,
  WayDetailsType,
} from '../FlightsSearch/useGetTransports/utils/journeyDetailsWayAdapter';
import CurrencyFormatter from '@/utils/currencyFormatter';
import {
  JourneyContractSegmentTransportType,
  JourneyContractWay,
  JourneyContractWayTransportCategory,
} from '../FlightsSearch/useGetTransports/types/tripDetailsTypes/JourneyContract';
import { wayFactory } from '../FlightsSearch/useGetTransports/types/tripDetailsTypes/Way';

export const proposalTransportToProposalTransportWay = (
  el: ProposalTransport,
  airports: Record<string, TransportProvider>
): {
  outbound: ProposalTransportWay;
  inbound: ProposalTransportWay;
} => {
  const outboundMapped: ProposalTransportWay = {
    arrivalId: el.outbound.arrivalId,
    departureId: el.outbound.departureId,
    durationInMinutes: el.outbound.durationInMinutes,
    handBaggageIncluded: el.outbound.handBaggageIncluded,
    baggageIncluded: el.outbound.baggageIncluded,
    price: undefined,
    segments: el.outbound.segments.map((se) => ({
      terminal: se.departureTerminal,
      departureId: se.departureId,
      isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
      arrivalId: se.arrivalId,
      isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
      classAvailability: se.classAvailability,
      supplier: {
        id: se.supplier.id,
        carrierId: se.supplier.carrierId,
      },
      technicalStops: se.technicalStops.map((te) => {
        const hub = airports[te.locationId];
        return {
          locationId: te.locationId,
          isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
          isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
          hub: {
            id: te.locationId,
            label: hub?.label,
          },
        };
      }),
    })),
  };
  const inboundMapped: ProposalTransportWay = {
    arrivalId: el.inbound.arrivalId,
    departureId: el.inbound.departureId,
    durationInMinutes: el.inbound.durationInMinutes,
    handBaggageIncluded: el.inbound.handBaggageIncluded,
    baggageIncluded: el.inbound.baggageIncluded,
    price: undefined,
    segments: el.inbound.segments.map((se) => ({
      terminal: se.departureTerminal,
      departureId: se.departureId,
      isoZonedDepartureDateTime: se.isoZonedDepartureDateTime,
      arrivalId: se.arrivalId,
      isoZonedArrivalDateTime: se.isoZonedArrivalDateTime,
      classAvailability: se.classAvailability,
      supplier: {
        id: se.supplier.id,
        carrierId: se.supplier.carrierId,
      },
      technicalStops: se.technicalStops.map((te) => {
        const hub = airports[te.locationId];
        return {
          locationId: te.locationId,
          isoZonedDepartureDateTime: te.isoZonedDepartureDateTime,
          isoZonedArrivalDateTime: te.isoZonedArrivalDateTime,
          hub: {
            id: te.locationId,
            label: hub?.label,
          },
        };
      }),
    })),
  };
  return {
    outbound: outboundMapped,
    inbound: inboundMapped,
  };
};

export const flightDetailsMap = (
  t,
  proposalTransport: {
    outbound: ProposalTransportWay;
    inbound?: ProposalTransportWay;
  },
  airports: Record<string, StaticDataTransportDetail>,
  airlines: Record<string, TransportProvider>,
  wayFare: number = 0,
  adults: number = 0,
  childrenAges: number[] = [0]
): { outbound: WayDetailsType; inbound?: WayDetailsType } => {
  const currencyFormatter = new CurrencyFormatter('it-IT', 'EUR');

  //NOTE: check flight
  const peopleNumber = adults + (childrenAges?.length ?? 0);

  const infantCount = childrenAges.filter((e) => e < 2).length ?? 0;

  const childrenCount = childrenAges.filter((e) => e >= 2).length ?? 0;

  const outbound = proposalTransport.outbound;

  const outboundDeparture = airports[outbound.departureId];

  const outboundArrival = airports[outbound.arrivalId];

  const outboundPrice = outbound?.price && {
    fare:
      (outbound.price.adult.fare * adults +
        outbound.price.child.fare * childrenCount +
        outbound.price.infant.fare * infantCount +
        wayFare) /
      peopleNumber /
      100,
    tax:
      (outbound.price.adult.tax * adults +
        outbound.price.child.tax * childrenCount +
        outbound.price.infant.tax * infantCount) /
      peopleNumber /
      100,
  };
  const outboundWay: JourneyContractWay = {
    segments: outbound.segments.map((s) => {
      const supplier = airlines[s.supplier.id];
      return {
        departure: {
          hub: {
            id: s.departureId,
            name: airports[s.departureId]?.label,
            terminal: s.terminal,
          },
          datetime: s.isoZonedDepartureDateTime,
        },
        arrival: {
          hub: {
            id: s.arrivalId,
            name: airports[s.arrivalId]?.label,
          },
          datetime: s.isoZonedArrivalDateTime,
        },
        transport: {
          class: t(s.classAvailability),
          operativeId: s.supplier.id + s.supplier.carrierId,
          type: JourneyContractSegmentTransportType.FLIGHT,
          companyCode: s.supplier.id,
          companyName: supplier?.label,
        },
        duration: outbound.durationInMinutes,
        technicalStops:
          s.technicalStops.length > 0
            ? s.technicalStops?.map((te) => {
                const hub = airports[te.locationId];
                return {
                  arrivalTime: te.isoZonedArrivalDateTime,
                  departureTime: te.isoZonedDepartureDateTime,
                  hub: {
                    id: te.locationId,
                    name: hub?.label,
                    terminal: undefined,
                  },
                };
              })
            : undefined,
      };
    }),
    priceInformation: outboundPrice && {
      price: {
        perPassenger: { amount: outboundPrice.fare },
      },
      taxes: { perPassenger: { amount: outboundPrice.tax } },
    },
    seats: undefined,
    transportCategory: JourneyContractWayTransportCategory.STANDARD,
    durationInMinutes: outbound.durationInMinutes,
    selected: false,
    onSelected: (checked) => undefined,
    baggage: {
      hand: outbound.handBaggageIncluded,
      hold: outbound.baggageIncluded,
    },
    locations: {
      origin: outboundDeparture?.cityName,
      destination: outboundArrival?.cityName,
    },
    /** Used only on details when search date different from trip date. Summaries components now rely on JourneySummaryContract.DateAsText */
    differentDepartureDate: false,
  };

  const inbound = proposalTransport.inbound;

  if (inbound) {
    const inboundDeparture = airports[inbound.departureId];
    const inboundArrival = airports[inbound.arrivalId];
    const inboundPrice = inbound?.price && {
      fare:
        (inbound.price.adult.fare * adults +
          inbound.price.child.fare * childrenCount +
          inbound.price.infant.fare * infantCount +
          wayFare) /
        peopleNumber /
        100,
      tax:
        (inbound.price.adult.tax * adults +
          inbound.price.child.tax * childrenCount +
          inbound.price.infant.tax * infantCount) /
        peopleNumber /
        100,
    };
    const inboundWay: JourneyContractWay = {
      segments: inbound.segments.map((s) => {
        const supplier = airlines[s.supplier.id];
        return {
          departure: {
            hub: {
              id: s.departureId,
              name: airports[s.departureId]?.label,
              terminal: s.terminal,
            },
            datetime: s.isoZonedDepartureDateTime,
          },
          arrival: {
            hub: {
              id: s.arrivalId,
              name: airports[s.arrivalId]?.label,
            },
            datetime: s.isoZonedArrivalDateTime,
          },
          transport: {
            class: t(s.classAvailability),
            operativeId: s.supplier.id + s.supplier.carrierId,
            type: JourneyContractSegmentTransportType.FLIGHT,
            companyCode: s.supplier.id,
            companyName: supplier?.label,
          },
          duration: 100,
          technicalStops: s.technicalStops
            ? s.technicalStops?.map((te) => {
                const hub = airports[te.locationId];
                return {
                  arrivalTime: te.isoZonedArrivalDateTime,
                  departureTime: te.isoZonedDepartureDateTime,
                  hub: {
                    id: te.locationId,
                    name: hub?.label,
                    terminal: undefined,
                  },
                };
              })
            : undefined,
        };
      }),
      /** Optional info about price and taxes. [ OBE: false; CNCR: true except promo; ] */
      priceInformation: inboundPrice && {
        price: {
          perPassenger: { amount: inboundPrice.fare },
        },
        taxes: { perPassenger: { amount: inboundPrice.tax } },
      },
      seats: undefined,
      transportCategory: JourneyContractWayTransportCategory.STANDARD,
      durationInMinutes: inbound.durationInMinutes,
      selected: false,
      onSelected: (checked) => undefined,
      baggage: {
        hand: inbound.handBaggageIncluded,
        hold: inbound.baggageIncluded,
      },
      locations: {
        origin: inboundDeparture?.cityName,
        destination: inboundArrival?.cityName,
      },
      /** Used only on details when search date different from trip date. Summaries components now rely on JourneySummaryContract.DateAsText */
      differentDepartureDate: false,
    };

    return {
      outbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(outboundWay),
        currencyFormatter
      ),
      inbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(inboundWay),
        currencyFormatter
      ),
    };
  } else {
    return {
      outbound: journeyDetailsWayAdapter.adapt(
        t,
        wayFactory(outboundWay),
        currencyFormatter
      ),
    };
  }
};

export const sortOptions = {
  recomended: {
    label: 'Recommended',
    icon: '',
    value: 'RECOMMENDED',
    ascending: true,
  },
  price: {
    label: 'Price',
    icon: '',
    value: 'PRICE',
    options: [
      { label: 'highest first', optionValue: 'PRICE_HIGHEST', ascending: true },
      { label: 'lowest first', optionValue: 'PRICE_LOWEST', ascending: false },
    ],
  },
  //NOTE: check STARTS sort param
  // stars: {
  //   label: 'Stars',
  //   icon: '',
  //   value: 'STARS',
  //   options: [
  //     { label: 'highest first', value: 'STARS_HIGHEST', ascending: true },
  //     { label: 'lowest first', value: 'STARS_LOWEST', ascending: false },
  //   ],
  // },
  topRated: {
    label: 'TopRated',
    icon: '',
    value: 'TOP_RATED',
    ascending: true,
  },
};
