import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetParam } from '@/hooks/useGetParam';
import { useContactDetailsStore } from '../components/ContactDetails/hooks/useContactDetailsStore';
import { useTravellerDetailsStore } from '../components/ContactDetails/hooks/useTravellerDetailsStore';

export function useAddContactAndPassengers() {
  const getParam = useGetParam();
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');

  const [addContact, validateContactValues, validateVoucher] =
    useContactDetailsStore((state) => [
      state.addContact,
      state.validateContactValues,
      state.validateVoucher,
    ]);

  const [addTravellers, validateTravellersValues] = useTravellerDetailsStore(
    (state) => [state.addTravellers, state.validateTravellersValues]
  );

  const addContactAndPassengers = async () => {
    if (isFreeFlight) {
      const voucherValidation = validateVoucher();
      if (voucherValidation === false) {
        window.scrollTo(0, 0);
        return Promise.reject(false);
      }
    }
    const contactValidation = validateContactValues(isFreeFlight);
    const travellersValidation = validateTravellersValues();

    if (contactValidation === true && travellersValidation === true) {
      return Promise.all([
        await addContact(cartId, requestUuid),
        await addTravellers(cartId, requestUuid),
      ])
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject(false));
    } else {
      window.scrollTo(0, 0);
    }
    return Promise.reject(false);
  };

  return { addContactAndPassengers };
}
