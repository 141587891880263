import { useTranslate } from '@tolgee/react';
import { DirectFlight } from './Direct';
import * as Styled from './Facilities.styled';
import { FacilitiesProps } from './Facilities.types';

const FACILITIES_MAXIMUM_QUANTITY = 3;

function Facility({ icon, label, isLast, isFlight }) {
  const { t } = useTranslate();

  return (
    <Styled.FacilityContainer $isLast={isLast} $isFlight={isFlight}>
      <Styled.IconContainer>{icon}</Styled.IconContainer>
      <Styled.FacilityText>{t(label)}</Styled.FacilityText>
    </Styled.FacilityContainer>
  );
}

export function Facilities({ facilities, flightRate }: FacilitiesProps) {
  return (
    <>
      {facilities
        ?.slice(0, FACILITIES_MAXIMUM_QUANTITY)
        ?.map((e, i, arr) => (
          <Facility
            key={`facility_${e.label}`}
            {...e}
            isLast={arr.length === i + 1 ? true : false}
            isFlight={false}
          />
        ))}
      {flightRate && (
        <Styled.FacilityFlightContainer>
          <Styled.Divider />
          <Facility
            isLast={false}
            isFlight={true}
            icon={<DirectFlight />}
            label={flightRate}
          />
        </Styled.FacilityFlightContainer>
      )}
    </>
  );
}
