import { useStore } from '@/store/useStore';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { NewCartInputDto } from '../services/codegen-romulo';
import { BookingType, useBookingType } from './useBookingType';
import { useGetParam } from './useGetParam';

const getProductCategory = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.FlightAndHotel:
      return 'F+H';
    case BookingType.Hotel:
      return 'H';
    case BookingType.Flight:
      return 'F';
  }
};
const getShoppingData = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.FlightAndHotel:
    case BookingType.Hotel:
      return 'SHOPPING_HDP_API';
    case BookingType.Flight:
      return 'SHOPPING_TRANSPORT_API';
  }
};
const getTouchPoint = (bookingType: BookingType) => {
  switch (bookingType) {
    case BookingType.FlightAndHotel:
    case BookingType.Hotel:
      return 'SHOPPING_HDP';
    case BookingType.Flight:
      return 'SHOPPING_TRANSPORT';
  }
};

export function useCart() {
  const getParam = useGetParam();

  const cartId = getParam('cartId');
  const adults = getParam('adults');
  const childrenAges = getParam('childrenAges');
  const product = getParam('product');
  const bookingType = useBookingType();

  const navigate = useNavigate();

  const [brandConfig, createCart] = useStore((state) => [
    state.brandConfig,
    state.createCart,
  ]);

  const { businessProfileIdDP, businessProfileIdF, businessProfileIdH } =
    brandConfig.brand;

  const getBusinessProfile = (bookingType: BookingType) => {
    switch (bookingType) {
      case BookingType.FlightAndHotel:
        return businessProfileIdDP;
      case BookingType.Hotel:
        return businessProfileIdH;
      case BookingType.Flight:
        return businessProfileIdF;
    }
  };

  return async (
    shoppingId: string,
    priceShown: string,
    initialPrice?: number
  ) => {
    if (!cartId) {
      const adultsFormated = Array.from({ length: adults }, (_, i) => i).map(
        (it) => {
          return {
            type: 'ADULT',
            age: 30,
          };
        }
      );

      const childrenFormated = childrenAges
        ?.filter((item) => item > 1)
        .map((it) => {
          return {
            type: 'CHILD',
            age: it,
          };
        });

      const infantFormated = childrenAges
        ?.filter((item) => item < 2)
        .map((it) => {
          return {
            type: 'INFANT',
            age: it,
          };
        });

      const travellers = adultsFormated
        .concat(childrenFormated)
        .concat(infantFormated)
        .filter((e) => Boolean(e));

      const createCardRequest: NewCartInputDto = {
        cartRequest: {
          idBusinessProfile: getBusinessProfile(bookingType),
          travellers: travellers,
          touchPoint: getTouchPoint(bookingType),
          actionId: '',
          user: {
            locale: brandConfig.language,
            currency: 'EUR',
          },
        },
        tracingInfo: {
          productCategory: getProductCategory(bookingType),
        },
        shoppingData: {
          type: getShoppingData(bookingType),
          shoppingId: shoppingId,
        },
        additionalData: {
          idRequest: '1',
          priceShown: {
            value: priceShown,
            scale: '2',
            currency: 'EUR',
          },
        },
      };

      const cart = await createCart(createCardRequest);

      if (!!cart) {
        navigate({
          pathname: `/checkout`,
          search: `?${createSearchParams({
            product: product,
            transportId: shoppingId,
            cartId: cart.apiResponse.cartId,
            uuid: cart.uuid,
            initialPrice: String(initialPrice),
          })}`,
        });
      }
    }
  };
}
