import { useStore } from '@/store/useStore';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import { InsuranceInfo } from '../types';

const lmnBaseUrl = 'https://secure.lastminute.com';
const volagratisBaseUrl = 'https://secure.volagratis.com';

export const useInsuranceDetails = () => {
  const currencyFormatter = useCurrencyFormatter();
  const [brandConfig, availableProducts] = useStore((state) => [
    state.brandConfig,
    state.availableProducts,
  ]);
  const { language, brand } = brandConfig;
  const priorityBoarding = availableProducts?.products.INSURANCE;

  const baseUrl =
    brand.brandBase === 'Volagratis' ? volagratisBaseUrl : lmnBaseUrl;

  const getInsuranceDetails = (): InsuranceInfo[] => {
    return priorityBoarding.insurances.map((insurance) => {
      const {
        subtitle_with_price,
        summary,
        title,
        coverage,
        ipt_and_terms,
        reproposal,
      } = insurance.layout.dictionary[language].insurance;
      const { providerLogo } = insurance.layout;
      const { INTERNAL, PROVIDER, DEFAULT } =
        insurance.insuranceTermsAndConditionLinks.links;
      return {
        id: insurance.id,
        name: title.replace(/<[^>]*>/g, ''),
        providerLogo: providerLogo,
        price: subtitle_with_price.replaceAll(
          '__insurance_price__',
          `${currencyFormatter.formatNumber(insurance.priceToShow / 100)}`
        ),
        coverage: coverage.provider.content,
        summary: summary.text,
        reproposal: reproposal.body.coverage.features,
        termsAndConditions: ipt_and_terms
          .replaceAll('__pdf_internal__', `${baseUrl}${INTERNAL}`)
          .replaceAll('__pdf_default__', `${baseUrl}${DEFAULT}`)
          .replaceAll('__pdf_provider__', `${baseUrl}${PROVIDER}`),
        priceTotalMoney: insurance.priceTotalMoney,
        label: insurance.label,
      };
    });
  };
  return getInsuranceDetails;
};
