import { useCountDown } from '@/hooks/useCountDown';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './Counter.styled';

export function Counter({ date }: { date: string }) {
  const countdown = useCountDown(date);
  const brandConfig = useStore((state) => state.brandConfig);
  const { primaryColor } = brandConfig.theme;

  const { t } = useTranslate();

  if (countdown[2] < 0) {
    return null;
  }

  return (
    <Styled.Container>
      <Styled.LastMinuteLogo />
      <Styled.BadgeBackground style={{ backgroundColor: primaryColor }} />
      <span>{t('general.date.end.in')}</span>
      {countdown[0] === 0 ? (
        <span>{`${countdown[1]}${t('general.format.h')}:${countdown[2]}${t(
          'general.format.m'
        )}:${countdown[3]}${t('general.format.s')}`}</span>
      ) : (
        <span>{`${countdown[0]}${t('general.format.d')}:${countdown[1]}h:${
          countdown[2]
        }${t('general.format.m')}`}</span>
      )}
    </Styled.Container>
  );
}
