/* eslint-disable */
import { useStore } from '@/store/useStore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment, useEffect } from 'react';
import { useIsRyanair } from '../../hooks/useIsRyanair';
import { useTravellerDetailsStore } from '../ContactDetails/hooks/useTravellerDetailsStore';
import { SectionCard } from '../SectionCard/SectionCard';
import { useSpecialAssistanceStore } from './hooks/useSpecialAssistanceStore';
import * as Styled from './SpeacialAssistance.styled';
const assistanceLogo =
  'https://assets.staticroot.com/lmn-assets-ui/4.8/special-assistance/pink_special_assistance.svg';

interface SpecialAssistanceProps {
  disabled: boolean;
}

interface SpecialAssistance {
  travellerId: string;
  assistanceType: string;
}

const SpecialAssistance: FC<SpecialAssistanceProps> = ({ disabled }) => {
  const cartDetail = useStore((state) => state.cartDetail);
  const isRyanair = useIsRyanair();
  const { t } = useTranslate();
  const [
    needsSpecialAssistance,
    applicationType,
    assistanceType,
    specialAssistanceGeneralApplication,
    setNeedsSpecialAssistance,
    setApplicationType,
    setAssistanceType,
    setSpecialAssistanceGeneralApplication,
  ] = useSpecialAssistanceStore(
    ({
      needsSpecialAssistance,
      applicationType,
      assistanceType,
      specialAssistanceGeneralApplication,
      setNeedsSpecialAssistance,
      setApplicationType,
      setAssistanceType,
      setSpecialAssistanceGeneralApplication,
    }) => [
      needsSpecialAssistance,
      applicationType,
      assistanceType,
      specialAssistanceGeneralApplication,
      setNeedsSpecialAssistance,
      setApplicationType,
      setAssistanceType,
      setSpecialAssistanceGeneralApplication,
    ]
  );
  const [travellersDetails] = useTravellerDetailsStore((state) => [
    state.travellersDetails,
  ]);

  useEffect(() => {
    if (!needsSpecialAssistance) {
      setApplicationType(undefined);
    }
  }, [needsSpecialAssistance]);

  if (!cartDetail) {
    return null;
  }

  const isMoreThanOneTraveller = cartDetail.travellers.length > 1;

  const getTravellerFullName = (travellerId: number): string => {
    const { name, surname } = travellersDetails[travellerId];

    return name
      ? `${name} ${surname}`
      : `${t('general.traveller')} ${travellerId}`;
  };

  const updateTravellerToAssistanceTypeArray = (
    checked: boolean,
    travellerId: string
  ) => {
    if (checked) {
      const newSpecialAssistance: SpecialAssistance = {
        travellerId,
        assistanceType: 'WCHR',
      };
      if (
        !assistanceType?.find(
          (assistance) => assistance.travellerId === travellerId
        )
      ) {
        setAssistanceType(assistanceType.concat(newSpecialAssistance));
      }
    } else {
      setAssistanceType(
        assistanceType.filter(
          (assistance) => assistance.travellerId !== travellerId
        )
      );
    }
  };

  const updateAssistanceType = (newValue: string, travellerId: string) => {
    const assistanceTypeUpdated = assistanceType.map((assis) => {
      if (assis.travellerId === travellerId) {
        return {
          travellerId: assis.travellerId,
          assistanceType: newValue,
        };
      } else {
        return assis;
      }
    });
    setAssistanceType(assistanceTypeUpdated);
  };

  const cleanAssistance = () => {
    setNeedsSpecialAssistance(false);
    setApplicationType(undefined);
    setAssistanceType([]);
  };

  if (isRyanair) {
    return (
      <SectionCard title={t('form.special_assistance.title')}>
        <Styled.Container>
          <img
            style={{ width: 40, height: 'fit-content' }}
            src={assistanceLogo}
          />
          <div>
            <span>{t('form.special_assistance.description.ryanair')}</span>
          </div>
        </Styled.Container>
      </SectionCard>
    );
  }

  return (
    <SectionCard title={t('form.special_assistance.title')}>
      <Fragment>
        <Styled.FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              value={needsSpecialAssistance}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onChange={(e) => setNeedsSpecialAssistance(e.target.checked)}
            ></Checkbox>
          }
          label={
            <Styled.TextContainer>
              <img
                style={{ width: 40, height: 'fit-content' }}
                src={assistanceLogo}
              />
              <div>
                <span>{t('form.special_assistance.description')}</span>
              </div>
            </Styled.TextContainer>
          }
        />

        {needsSpecialAssistance && (
          <Fragment>
            <FormControl variant="filled">
              <InputLabel id="special_assistance.select_type">
                {t('form.special_assistance.select_type')}
              </InputLabel>
              <Select
                style={{ marginBottom: 0 }}
                onChange={(e: SelectChangeEvent<string>) => {
                  setApplicationType(e.target.value);
                  !isMoreThanOneTraveller &&
                    updateTravellerToAssistanceTypeArray(
                      true,
                      cartDetail.travellers[0].id.toFixed()
                    );
                }}
              >
                <MenuItem value={'REDUCED_MOBILITY'}>
                  {t('form.special_assistance.type.reduced_mobility')}
                </MenuItem>
                <MenuItem value={'GENERAL_APPLICATION'}>
                  {t('form.special_assistance.type.general_application')}
                </MenuItem>
              </Select>
            </FormControl>

            {applicationType === 'REDUCED_MOBILITY' && (
              <>
                {isMoreThanOneTraveller ? (
                  cartDetail.travellers.map((traveller, key) => (
                    <div key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={false}
                            onChange={(e) => {
                              updateTravellerToAssistanceTypeArray(
                                e.target.checked,
                                traveller.id.toFixed()
                              );
                            }}
                          ></Checkbox>
                        }
                        label={<div>{getTravellerFullName(traveller.id)}</div>}
                      />
                      {assistanceType?.find(
                        (assis) => assis.travellerId === traveller.id.toFixed()
                      ) && (
                        <Fragment>
                          <FormControl variant="filled">
                            <InputLabel id="special_assistance.select_type">
                              {t('form.special_assistance.type.title')}
                            </InputLabel>
                            <Select
                              defaultValue="WCHR"
                              onChange={(e: SelectChangeEvent<string>) => {
                                updateAssistanceType(
                                  e.target.value,
                                  traveller.id.toFixed()
                                );
                              }}
                            >
                              <MenuItem value={'WCHR'}>
                                {t('form.special_assistance.type.wchr')}
                              </MenuItem>
                              <MenuItem value={'WCHS'}>
                                {t('form.special_assistance.type.wchs')}
                              </MenuItem>
                              <MenuItem value={'WCHC_MANUAL'}>
                                {t('form.special_assistance.type.wchc_manual')}
                              </MenuItem>
                            </Select>
                          </FormControl>

                          {assistanceType?.find(
                            (assis) =>
                              assis.travellerId === traveller.id.toFixed() &&
                              assis.assistanceType.includes('WCHC')
                          ) && (
                            <FormControl variant="filled">
                              <InputLabel id="special_assistance.type.weelchair">
                                {t('form.special_assistance.type.weelchair')}
                              </InputLabel>
                              <Select
                                defaultValue="WCHC_MANUAL"
                                onChange={(e: SelectChangeEvent<string>) => {
                                  updateAssistanceType(
                                    e.target.value,
                                    traveller.id.toFixed()
                                  );
                                }}
                              >
                                <MenuItem value={'WCHC_MANUAL'}>
                                  {t('form.special_assistance.type.manual')}
                                </MenuItem>
                                <MenuItem value={'WCHC_DRY'}>
                                  {t(
                                    'form.special_assistance.type.dry_batteries'
                                  )}
                                </MenuItem>
                                <MenuItem value={'WCHC_LYQUID'}>
                                  {t(
                                    'form.special_assistance.type.wet_batteries'
                                  )}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Fragment>
                      )}
                    </div>
                  ))
                ) : (
                  <Fragment>
                    <FormControl variant="filled">
                      <InputLabel id="special_assistance.type.weelchair">
                        {t('form.special_assistance.type.title')}
                      </InputLabel>
                      <Select
                        defaultValue="WCHR"
                        onChange={(e: SelectChangeEvent<string>) => {
                          updateAssistanceType(
                            e.target.value,
                            cartDetail.travellers[0].id.toFixed()
                          );
                        }}
                      >
                        <MenuItem value={'WCHR'}>
                          {t('form.special_assistance.type.wchr')}
                        </MenuItem>
                        <MenuItem value={'WCHS'}>
                          {t('form.special_assistance.type.wchs')}
                        </MenuItem>
                        <MenuItem value={'WCHC_MANUAL'}>
                          {t('form.special_assistance.type.wchc_manual')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {assistanceType?.find(
                      (assis) =>
                        assis.travellerId ===
                          cartDetail.travellers[0].id.toFixed() &&
                        assis.assistanceType.includes('WCHC')
                    ) && (
                      <FormControl variant="filled">
                        <InputLabel id="special_assistance.type.weelchair">
                          {t('form.special_assistance.type.weelchair')}
                        </InputLabel>
                        <Select
                          defaultValue="WCHC_MANUAL"
                          onChange={(e: SelectChangeEvent<string>) => {
                            updateAssistanceType(
                              e.target.value,
                              cartDetail.travellers[0].id.toFixed()
                            );
                          }}
                        >
                          <MenuItem value={'WCHC_MANUAL'}>
                            {t('form.special_assistance.type.manual')}
                          </MenuItem>
                          <MenuItem value={'WCHC_DRY'}>
                            {t('form.special_assistance.type.dry_batteries')}
                          </MenuItem>
                          <MenuItem value={'WCHC_LYQUID'}>
                            {t('form.special_assistance.type.wet_batteries')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Fragment>
                )}
                <Alert
                  severity="info"
                  icon={<AccessTimeIcon sx={{ width: '52px' }} />}
                >
                  <span>
                    {t('form.special_assistance.review_message')}{' '}
                    <span
                      onClick={() => cleanAssistance()}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('form.special_assistance.click_here')}.
                    </span>
                  </span>
                </Alert>
              </>
            )}
            {applicationType === 'GENERAL_APPLICATION' && (
              <>
                <TextField
                  label={t('form.special_assistance.details')}
                  value={specialAssistanceGeneralApplication}
                  onChange={(e) =>
                    setSpecialAssistanceGeneralApplication(e.target.value)
                  }
                />
                <Alert
                  severity="info"
                  icon={<AccessTimeIcon sx={{ width: '52px' }} />}
                >
                  <span>
                    {t('form.special_assistance.review_message')}{' '}
                    <span
                      onClick={() => cleanAssistance()}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {t('form.special_assistance.click_here')}.
                    </span>
                  </span>
                </Alert>
              </>
            )}
          </Fragment>
        )}
      </Fragment>
    </SectionCard>
  );
};

export default SpecialAssistance;
