import Checkbox from '@mui/material/Checkbox';
import * as Styled from './CharacteristicsFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { CharacteristicProps } from './CharacteristicsFilters.types';
import { useTranslate } from '@tolgee/react';

export function Characteristic({
  data,
  onCharacteristicHandle,
  disabled,
  characteristicFilter,
}: CharacteristicProps) {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onCharacteristicHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((characteristic, index) => {
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${characteristic.code}_${index}_characteristic`}
              control={
                <Checkbox
                  disabled={!characteristic.active || disabled}
                  value={characteristic.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={
                    characteristicFilter?.includes(characteristic.code) ?? false
                  }
                />
              }
              labelPlacement="start"
              label={
                <Styled.LabelOption>
                  {t(
                    `hotel.characteristics.${characteristic.code.toLowerCase().replaceAll(' ', '_')}`
                  )}
                </Styled.LabelOption>
              }
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
