import styled from '@emotion/styled';

export const Text = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 12px;
`;

export const HubIdContainer = styled.span<{ shouldHighlight?: boolean }>`
  ${({ shouldHighlight }) => shouldHighlight && `color: #ff4800;`}
`;

export const TerminalText = styled(Text)`
  color: #74747c;
`;
