import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useTrack } from '@/hooks/useTrack';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { AirportSearch } from './components/AirportSearch/AirportSearch';
import { DestinationSearch } from './components/DestinationSearch/DestinationSearch';
import { ResultElementProps } from './components/SearchInput/ResultElement';
import * as Styled from './LocationSelector.styled';
interface LocationSelectorProps {
  setDeparture: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  setDestination: React.Dispatch<React.SetStateAction<ResultElementProps>>;
  missingDeparture?: boolean;
  missingDestination?: boolean;
}

const LocationSelector: FC<LocationSelectorProps> = ({
  setDeparture,
  setDestination,
  missingDeparture,
  missingDestination,
}) => {
  const track = useTrack();
  const bookingType = useBookingType();
  const { t } = useTranslate();
  const handleSetDeparture = (e: ResultElementProps | undefined) => {
    setDeparture(e);
    if (e) {
      track({
        eventName: 'selected.departure',
        logLevel: 'info',
        eventProperties: {
          title: e?.title,
        },
      });
    }
  };

  const handleSetDestination = (e: ResultElementProps | undefined) => {
    setDestination(e);
    if (e) {
      track({
        eventName: 'selected.destination',
        logLevel: 'info',
        eventProperties: {
          title: e?.title,
        },
      });
    }
  };

  const handleOnClickInput = (direction: string) => {
    track({
      eventName: `clicked.open-${direction}`,
      logLevel: 'info',
    });
  };

  switch (bookingType) {
    case BookingType.Flight:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={handleSetDeparture}
            label={t('search.departure')}
            handleOnClickInput={() => handleOnClickInput('departure')}
            placeholder={t('search.departure.placeholder')}
            error={missingDeparture && t('search.departure.error')}
            direction={'OUTBOUND'}
          />
          <Styled.Divider />
          <AirportSearch
            onChange={handleSetDestination}
            handleOnClickInput={() => handleOnClickInput('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={missingDestination && t('search.destination.error')}
            direction={'INBOUND'}
          />
        </Styled.Container>
      );

    case BookingType.FlightAndHotel:
      return (
        <Styled.Container>
          <AirportSearch
            onChange={handleSetDeparture}
            handleOnClickInput={() => handleOnClickInput('departure')}
            label={t('search.departure')}
            placeholder={t('search.departure.placeholder')}
            error={missingDeparture && t('search.departure.error')}
            direction={'OUTBOUND'}
          />
          <Styled.Divider />
          <DestinationSearch
            onChange={handleSetDestination}
            handleOnClickInput={() => handleOnClickInput('destination')}
            label={t('search.destination')}
            placeholder={t('search.destination.placeholder')}
            error={missingDestination && t('search.destination.error')}
          />
        </Styled.Container>
      );
    case BookingType.Hotel:
      return (
        <DestinationSearch
          onChange={handleSetDestination}
          handleOnClickInput={() => handleOnClickInput('destination')}
          label={t('search.destination')}
          placeholder={t('search.destination.placeholder')}
          error={missingDestination && t('search.destination.error')}
        />
      );

    default:
      return null;
  }
};

export { LocationSelector };
