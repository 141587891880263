import styled from '@emotion/styled';

export const Container = styled.div`
  overflow: hidden;
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  column-gap: 4px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
`;

export const ImagesMobile = styled.img`
  flex: 0 0 auto;
  scroll-snap-align: center;
  object-fit: cover;
  height: 250px;
  width: 250px;
  border-radius: 8px;
`;
