import { useTrack } from '@/hooks/useTrack';
import { getCheckboxFilterUpdated } from '@/store/utils/getCheckboxFilterUpdated';
import { isEmpty } from '@/utils/isEmpty';
import { useTranslate } from '@tolgee/react';
import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import { Airline } from './Airline';
import * as Styled from './AirlinesFilters.styled';

export function AirlinesFilters() {
  const {
    flightsStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getFlightsProposals,
    retrieveProporsals,
    flightFilters,
  } = useGetFiltersData();

  const { airlinesStaticData, inclusiveAirlines } = flightsStaticData;

  const { t } = useTranslate();
  const track = useTrack();
  const onAirlineHandle = (e) => {
    const airlinesUpdated = getCheckboxFilterUpdated(
      flightFilters,
      'airlines',
      e.target
    );

    track({
      eventName: 'clicked.filter.airline',
      logLevel: 'info',
      eventProperties: {
        airline: airlinesUpdated,
      },
    });

    getFlightsProposals(searchId, [{ airlines: airlinesUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Styled.Title>{t('filter.flights')}</Styled.Title>

        <Airline
          data={inclusiveAirlines}
          airlineStaticData={airlinesStaticData}
          onAirlineHandle={onAirlineHandle}
          disabled={isFecthRetrieveProporsals}
          airlineFilter={flightFilters.airlines}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
