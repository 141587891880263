import { useStore } from '@/store/useStore';
import InfoIcon from '@mui/icons-material/Info';
import { Alert } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment } from 'react';
import { SectionCard } from '../SectionCard/SectionCard';
import { Skeleton } from '../Skeleton';
import { TravellerDetails } from './components/TravellerDetails';
import { useTravellerDetailsDefaultValues } from './hooks/useTravellerDetailsDefaultValues';
import { useTravellerDetailsStore } from './hooks/useTravellerDetailsStore';

interface TravellersDetailsProps {
  disabled?: boolean;
}

const TravellersDetails: FC<TravellersDetailsProps> = ({ disabled }) => {
  const { t } = useTranslate();
  const [cartDetail] = useStore((state) => [state.cartDetail]);
  const travellers = cartDetail?.travellers;

  useTravellerDetailsDefaultValues();

  const [
    travellersDetails,
    setTravellerDetails,
    travellersInputErrors,
    setTravellerInputErrors,
  ] = useTravellerDetailsStore(
    ({
      travellersDetails,
      setTravellerDetails,
      travellersInputErrors,
      setTravellerInputErrors,
    }) => [
      travellersDetails,
      setTravellerDetails,
      travellersInputErrors,
      setTravellerInputErrors,
    ]
  );

  if (!travellers || !travellersDetails) {
    return <Skeleton />;
  }

  const handleChange = (e: any, travellerId: number) => {
    const { name, value } = e.target;
    setTravellerDetails(
      {
        ...travellersDetails[travellerId],
        [name]: value,
      },
      travellerId
    );
  };

  const handleValidate = (regex: RegExp) => (e: any, travellerId: number) => {
    const { name, value } = e.target;

    if (regex) {
      setTravellerInputErrors(
        {
          ...travellersInputErrors[travellerId],
          [name]: !regex.test(value),
        },
        travellerId
      );
    }
  };

  return (
    <SectionCard title={t('form.passenger.traveller_details')}>
      <Fragment>
        <Alert severity="info" icon={<InfoIcon sx={{ fontSize: '24px' }} />}>
          {t('form.warning.match_id')}
        </Alert>
        {travellers.map((traveller) => (
          <TravellerDetails
            travellerType={traveller.type}
            id={traveller?.id}
            handleChange={handleChange}
            formData={travellersDetails[traveller.id]}
            inputErrors={travellersInputErrors[traveller.id]}
            handleValidate={handleValidate}
            disabled={disabled}
          />
        ))}
      </Fragment>
    </SectionCard>
  );
};

export { TravellersDetails };
