import { PinFlightIcon } from '@/components/LmnIcons/LmnIcons';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useTranslate } from '@tolgee/react';
import { FullTransportLogos } from '../FullTransportLogos/FullTransportLogos';
import { FullTransportTimeAndLocation } from '../FullTransportTimeAndLocation/FullTransportTimeAndLocation';
import { LuggageDetails } from '../LuggageDetails';
import { TransportTimeline } from '../TransportTimeline/TransportTimeline';
import { MobileTransportInfo } from './MobileTransportInfo';
import * as Styled from './TransportInfo.styled';
import { TransportInfoProps } from './TransportInfo.types';

export function TransportInfo({
  showPin,
  companies,
  departureInfo,
  stops,
  stopsLabel,
  arrivalInfo,
  duration,
  baggageInfo,
  segmentsInfo,
  inLine,
}: TransportInfoProps) {
  const { isMobile } = useIsMobile();
  const { t } = useTranslate();

  const getStopLabel = () => {
    const labelInfo = stopsLabel.split('_');
    if (labelInfo.length === 1) {
      return t(labelInfo[0]);
    } else {
      return `${labelInfo[0]} ${t(labelInfo[1])}`;
    }
  };
  return (
    <>
      <Styled.TransportInfoContainer $withPin={showPin}>
        {showPin && (
          <Styled.PinColumn>
            <PinFlightIcon size={24} />
          </Styled.PinColumn>
        )}
        <Styled.LogoColumn>
          <FullTransportLogos companies={companies} />
        </Styled.LogoColumn>
        <Styled.JourneySummary $inLine={inLine}>
          <FullTransportTimeAndLocation
            time={departureInfo.time}
            location={departureInfo.location}
            isArrival={false}
            city={departureInfo.city}
            differentDate={departureInfo.differentDate}
            inLine={inLine}
          />
          <Styled.TimelineColumn>
            <TransportTimeline
              duration={duration}
              stops={[...Array(stops).keys()].map((_, index) => ({
                id: index,
              }))}
              stopLabel={getStopLabel()}
              segmentsInfo={segmentsInfo}
            />
          </Styled.TimelineColumn>
          <FullTransportTimeAndLocation
            time={arrivalInfo.time}
            location={arrivalInfo.location}
            isArrival={true}
            city={arrivalInfo.city}
            differentDate={arrivalInfo.differentDate}
            inLine={inLine}
          />
        </Styled.JourneySummary>
        {!isMobile && (
          <Styled.Luggage>
            <LuggageDetails
              carryOnIncluded={baggageInfo.carryOnIncluded}
              checkedBaggageIncluded={baggageInfo.checkedBaggageIncluded}
            />
          </Styled.Luggage>
        )}
      </Styled.TransportInfoContainer>
      <MobileTransportInfo companies={companies} baggageInfo={baggageInfo} />
    </>
  );
}
