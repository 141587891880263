import { useStore } from '@/store/useStore';
import { Checkbox } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment } from 'react';
import { DPContractPolicy } from './components/DPContractPolicy';
import { FlightContractPolicy } from './components/FlightContractPolicy';
import { HotelContractPolicy } from './components/HotelContractPolicy';
import { useSubscriptionsStore } from './hooks/useSubscriptionsStore';
import * as Styled from './Subscriptions.styled';
import { getBaseUrl } from './utils/getBrandBase';
interface SubscriptionsProps {
  disabled: boolean;
}

export const Subscriptions: FC<SubscriptionsProps> = ({ disabled }) => {
  const [cartConfig, brandConfig, cartDetail] = useStore((state) => [
    state.cartConfig,
    state.brandConfig,
    state.cartDetail,
  ]);
  const brandBase = brandConfig.brand.brandBase;
  const baseUrl = getBaseUrl(brandBase);
  const { t } = useTranslate();

  const [
    marketingConsent,
    contractConsent,
    setMarketingConsent,
    setContractConsent,
  ] = useSubscriptionsStore(
    ({
      marketingConsent,
      contractConsent,
      setMarketingConsent,
      setContractConsent,
    }) => [
      marketingConsent,
      contractConsent,
      setMarketingConsent,
      setContractConsent,
    ]
  );

  const marketingPolicy = cartConfig?.subscription.marketingPolicy;
  const contractPolicy = cartConfig?.subscription.contractPolicy;

  const flight = !!cartDetail?.products?.find((e) => e.type === 'FLIGHT');
  const hotel = !!cartDetail?.products?.find((e) => e.type === 'HOTEL');

  return (
    <Styled.Container>
      {marketingPolicy && (
        <Styled.FormControlLabel
          required={marketingPolicy.mandatory ?? undefined}
          control={
            <Checkbox
              disabled={disabled}
              value={marketingConsent}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onChange={(e) => setMarketingConsent(e.target.checked)}
              // manage required error {t('form.validation.marketing_policy')}
            ></Checkbox>
          }
          label={
            <div>
              {t('form.subscriptions.marketing_policy')}{' '}
              <a href={`${baseUrl}${marketingPolicy.url}`} target="_blank">
                {t('form.subscriptions.marketing_policy_link')}
              </a>
            </div>
          }
        />
      )}
      {contractPolicy && (
        <Styled.FormControlLabel
          required={contractPolicy.mandatory ?? undefined}
          control={
            <Checkbox
              disabled={disabled}
              value={contractConsent}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onChange={(e) => setContractConsent(e.target.checked)}
              // manage required error {t('form.validation.marketing_policy')}
            ></Checkbox>
          }
          label={
            <Fragment>
              {t('form.consent.contract_policy_confirmation')}{' '}
              <a href={`${baseUrl}${contractPolicy.url}`} target="_blank">
                {t('form.consent.contract_policy')}
              </a>
            </Fragment>
          }
        />
      )}
      {contractPolicy && flight && hotel && <DPContractPolicy />}
      {contractPolicy && !flight && hotel && <HotelContractPolicy />}
      {contractPolicy && flight && !hotel && <FlightContractPolicy />}
    </Styled.Container>
  );
};
