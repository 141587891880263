import { ProposalTransport } from '@/services/codegen-romulo';
import { isEmpty } from '@/utils/isEmpty';

export function getConnection(transports: ProposalTransport[]) {
  if (!transports) {
    return null;
  }

  const transportInbound = transports?.[0]?.inbound;

  const transportOutbound = transports?.[0]?.outbound;

  return isEmpty(transportInbound) || isEmpty(transportOutbound)
    ? 'general.direct_flights_included'
    : 'general.connection_flights_included';
}
