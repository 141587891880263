import { useTranslate } from '@tolgee/react';
import { WayDetailsProps } from './WayDetails.types';
import { getWayTypeKey } from './getWayTypeKey';
import { getDurationLabel } from '@/views/FlightsSearch/useGetTransports/utils/getDurationLabel';
import { getTimeInTheTimezone } from '@/utils/timeUtils';
import * as Styled from './WayDetails.styled';

export function WayDetails({
  isInbound,
  allSegmentTypes,
  locations,
  departureDate,
  durationMinutes,
  wayStopsLabel,
}: WayDetailsProps) {
  const { t } = useTranslate();

  const wayTypesKeys = getWayTypeKey(allSegmentTypes, isInbound);

  // NOTE: check translate
  return (
    <Styled.Container>
      <Styled.WayType>
        {t(wayTypesKeys.key, '', {
          tripType: wayTypesKeys.placeholders
            .map((placeholder) => t(placeholder))
            .join(' + '),
        })}
      </Styled.WayType>
      <Styled.WayDate>
        {getTimeInTheTimezone(departureDate, undefined, true).format(
          'dddd, DD MMMM'
        )}
      </Styled.WayDate>
      <Styled.WayTextContainer>
        {t('widget.trip_card.way.locations', '', {
          origin: locations.origin,
          destination: locations.destination,
        })}
      </Styled.WayTextContainer>
      <Styled.WayTextContainer>
        <span>{wayStopsLabel}</span> -{' '}
        <span>{getDurationLabel(t, durationMinutes)}</span>
      </Styled.WayTextContainer>
    </Styled.Container>
  );
}
