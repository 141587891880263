import { BookingType } from "@/hooks/useBookingType";

export const getProductType = (bookingType: BookingType) => {
    switch (bookingType) {
      case BookingType.FlightAndHotel:
        return 1;
      case BookingType.Hotel:
        return 0;
      case BookingType.Flight:
        return 2;
    }
  };