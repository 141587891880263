import { FlightTypes } from '@/utils/types/FlightTypes';
import { create } from 'zustand';
import { ResultElementProps } from '../components/LocationSelector/components/SearchInput/ResultElement';

interface SearchStore {
  departure: ResultElementProps;
  missingDeparture: boolean;
  missingDestination: boolean;
  destination: ResultElementProps;
  flightType: FlightTypes;
  loadingSearch: boolean;
  withHotel: boolean;
  setDeparture: (departure: ResultElementProps) => void;
  setMissingDeparture: (missingDeparture: boolean) => void;
  setMissingDestination: (missingDestination: boolean) => void;
  setDestination: (destination: ResultElementProps) => void;
  setFlightType: (flightType: FlightTypes) => void;
  setLoadingSearch: (loadingSearch: boolean) => void;
  setWithHotel: (withHotel: boolean) => void;
}

export const useSearchStore = create<SearchStore>((set, get) => ({
  departure: undefined,
  missingDeparture: false,
  missingDestination: false,
  destination: undefined,
  flightType: FlightTypes.R,
  loadingSearch: false,
  withHotel: false,
  setDeparture: (departure: ResultElementProps) => set({ departure }),
  setMissingDeparture: (missingDeparture: boolean) => set({ missingDeparture }),
  setMissingDestination: (missingDestination: boolean) =>
    set({ missingDestination }),
  setDestination: (destination: ResultElementProps) => set({ destination }),
  setFlightType: (flightType: FlightTypes) => set({ flightType }),
  setLoadingSearch: (loadingSearch: boolean) => set({ loadingSearch }),
  setWithHotel: (withHotel: boolean) => set({ withHotel }),
}));
