import styled from '@emotion/styled';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
  width: 100%;

  & .MuiRadio-root {
    padding: 0px;
    margin-right: 8px;
  }

  & .MuiTypography-root {
    flex: 1;
    width: 100%;
  }

  & .MuiRadio-root.MuiButtonBase-root {
    width: 24px;
    min-width: 24px;
  }
`;

export const NoInsuranceFormControlLabel = styled(FormControlLabel)`
  & .MuiRadio-root {
    margin-top: 0px;
  }
`;

export const RadioGroup = styled(MuiRadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  font-family: 'Open Sans';
`;
export const Price = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #74747c;
`;

export const NoInsuranceTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #74747c;
  margin-top: 2px;
`;
export const Content = styled.span`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;

  & ul {
    padding-left: 16px;
  }
`;

export const DetailsContent = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #3c3c3c;

  & ul {
    padding-left: 16px;
  }
`;
export const Terms = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #74747c;
`;
export const Image = styled.img`
  @media (max-width: 375px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const InsuranceContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & .MuiAccordionDetails-root {
    background-color: #f5f5f5;
    border-radius: 8px;
  }
`;

export const Accordion = styled(MuiAccordion)``;

export const AccordionSummary = styled(MuiAccordionSummary)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #3c3c3c;
  padding-left: 0px;
  flex-direction: row-reverse;
  gap: 4px;
  min-height: unset !important;
  & .MuiAccordionSummary-content {
    margin: 8px 0 !important;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    margin-left: -8px;
  }
`;
