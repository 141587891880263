import styled from '@emotion/styled';
import CheckIconMui from '@mui/icons-material/Check';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import {
  Accordion,
  accordionClasses,
  accordionSummaryClasses,
  FormControlLabel,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button as ButtonMui,
} from '@mui/material';
import DividerMui from '@mui/material/Divider';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0 !important;
`;

export const FiltersContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;

  > *:not(:last-child) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const Subtitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #74747c;
`;

export const FilterOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const FilterOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0px;
  margin-left: 0px;
  font-size: 14px;
  height: 36px;

  .MuiFormControlLabel-label {
    display: flex;
    align-items: center;
  }
`;

export const Divider = styled(DividerMui)`
  margin: 8px 0;
  border-color: rgba(0, 0, 0, 0.08);
`;

export const LabelOption = styled.div`
  font-size: 14px;
`;

export const Checkbox = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #babac0;
  border-radius: 2px;
`;

export const CheckIconContainer = styled.div<{ $color: string }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: ${(props) => `1px solid ${props.$color}`};
  background-color: ${(props) => props.$color};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckIcon = styled(CheckIconMui)`
  color: #fff;
`;

export const SliderContainer = styled.div`
  padding: 0 8px;
  width: 100%;
`;

export const SliderValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SliderValue = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

export const AccordionContainer = styled(Accordion)`
  background-color: transparent;
  margin-bottom: 0;
  &::before {
    display: none;
  }
  &.${accordionClasses.expanded} {
    margin: 0 0 24px 0;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
  min-height: 48px;
  align-items: start;
  &.${accordionSummaryClasses.expanded} {
    min-height: 48px;
  }

  & .${accordionSummaryClasses.content} {
    &.${accordionSummaryClasses.expanded} {
      margin: 0;
    }
    margin: 0;
  }
`;

export const ExpandIcon = styled(ExpandMoreRoundedIcon)`
  width: 1.5em;
  height: 1.5em;
`;

export const ResetFiltersButton = styled(ButtonMui)``;
