import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DividerMui from '@mui/material/Divider';

export const FacilityContainer = styled.div<{
  $isLast: boolean;
  $isFlight: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 100%;
  color: #74747c;

  ${({ $isLast }) =>
    $isLast &&
    css`
      margin-bottom: 8px;

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    `}

  ${({ $isFlight }) =>
    $isFlight &&
    css`
      padding: 8px 0px 0px;

      @media (max-width: 767px) {
        margin-top: 8px;
      }
    `}
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FacilityText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const Divider = styled(DividerMui)`
  border-color: rgba(0, 0, 0, 0.08);

  @media (max-width: 767px) {
    display: none;
  }
`;

export const FacilityFlightContainer = styled.div`
  position: absolute;
  bottom: 16px;
  width: 100%;
  right: 0;
  left: 0;
  padding: 0 16px;

  @media (max-width: 767px) {
    position: relative;
    padding: 0;
  }
`;
