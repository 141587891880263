import styled from '@emotion/styled';
import { ImagesCarousel } from '../ImagesCarousel';

export const Carousel = styled(ImagesCarousel)`
  height: 120px;
  width: 100%;
`;

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  width: 280px;
  min-height: 248px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out 0s;

  &:hover {
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }
`;

export const HotelInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const HotelName = styled.div`
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ProductType = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 8px;
  color: #3c3c3c;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 24px;
  padding: 16px;
`;

export const PriceText = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
`;
