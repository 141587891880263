import { Header } from '@/components/Header';
import { LmnFooter } from '@/components/LmnFooter';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetParam } from '@/hooks/useGetParam';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import React, { Fragment, useEffect, useState } from 'react';
import * as Styled from './Checkout.styled';
import { CheckedLuggage } from './components/CheckedLuggage/CheckedLuggage';
import { ContactDetails } from './components/ContactDetails/ContactDetails';
import { TravellersDetails } from './components/ContactDetails/TravellersDetails';
import { ErrorModal } from './components/ErrorModal/ErrorModal';
import { FFVoucherPayment } from './components/FFVoucherPayment/FFVoucherPayment';
import { Insurance } from './components/Insurance/Insurance';
import { PriorityBoarding } from './components/PriorityBoarding/PriorityBoarding';
import { Review } from './components/Review/Review';
import SpecialAssistance from './components/SpecialAssistance/SpecialAssistance';
import { StepNavigator } from './components/StepNavigator/StepNavigator';
import { Stepper } from './components/Stepper/Stepper';
import { Subscriptions } from './components/Subscriptions/Subscriptions';
import { SuccessCard } from './components/SuccessCard/SuccessCard';
import { Summary } from './components/Summary/Summary';
import WebCheckIn from './components/WebCheckIn/WebCheckIn';
import { useAddContactAndPassengers } from './hooks/useAddContactAndPassengers';
import { useConfirmReservation } from './hooks/useConfirmReservation';
import { usePersonalizeReservation } from './hooks/usePersonalizeReservation';
import { useSelectFFDefaultProducts } from './hooks/useSelectFFDefaultProducts';

export function Checkout() {
  const getParam = useGetParam();
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const track = useTrack();
  const { addContactAndPassengers } = useAddContactAndPassengers();
  const { personalizeReservation } = usePersonalizeReservation();
  const { confirmReservation } = useConfirmReservation();

  const [getCart, getCartConfig, getAvailableProducts, getOrder, brandConfig] =
    useStore((state) => [
      state.getCart,
      state.getCartConfig,
      state.getAvailableProducts,
      state.getOrder,
      state.brandConfig,
    ]);

  useSelectFFDefaultProducts({ cartId, requestUuid });

  React.useEffect(() => {
    getCart(cartId, requestUuid);
    getCartConfig(cartId, requestUuid);
    getAvailableProducts(cartId, requestUuid);
    getOrder(cartId, requestUuid);
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        track({
          eventName: 'opened.checkout.detail-step',
          logLevel: 'info',
        });
        break;
      case 2:
        track({
          eventName: 'opened.checkout.personalise-step',
          logLevel: 'info',
        });
        break;
      case 3:
        track({
          eventName: 'opened.checkout.review-step',
          logLevel: 'info',
        });
        break;
      case 4:
        track({
          eventName: 'opened.checkout.order-complete-step',
          logLevel: 'info',
        });
        break;
      default:
        break;
    }
  }, [currentStep]);

  const handleNextStep = async () => {
    setIsLoading(true);

    switch (currentStep) {
      case 1:
        await Promise.all([
          addContactAndPassengers(),
          getCart(cartId, requestUuid),
        ])
          .then(() => setCurrentStep(currentStep + 1))
          .finally(() => setIsLoading(false));
        break;
      case 2:
        await Promise.all([
          personalizeReservation(),
          getCart(cartId, requestUuid),
        ])
          .then(() => setCurrentStep(currentStep + 1))
          .finally(() => setIsLoading(false));
        break;
      case 3:
        await confirmReservation()
          .then(() => {
            track({
              eventName: 'clicked.checkout.create-reservation',
              logLevel: 'info',
            });
            setCurrentStep(currentStep + 1);
          })
          .finally(() => setIsLoading(false));
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Styled.CheckoutContainer>
      <Header />
      <Styled.ColumnsContainer>
        <Stepper currentStep={currentStep} />
      </Styled.ColumnsContainer>
      <Styled.ColumnsContainer>
        <Styled.ContentContainer>
          <Styled.MainContent>
            {currentStep === 1 && (
              <Fragment>
                {isFreeFlight && <FFVoucherPayment />}
                <ContactDetails disabled={isLoading} />
                {/* TODO <ImportantPoliciesMessage /> */}
                <TravellersDetails disabled={isLoading} />
              </Fragment>
            )}
            {currentStep === 2 && (
              <Fragment>
                {!isFreeFlight && <PriorityBoarding />}
                {!isFreeFlight && <Insurance />}
                <CheckedLuggage />
                {!isFreeFlight && <WebCheckIn />}
                <SpecialAssistance disabled={isLoading} />
              </Fragment>
            )}
            {currentStep === 3 && (
              <Fragment>
                {isFreeFlight && <FFVoucherPayment />}
                <Review />
                <Subscriptions disabled={isLoading} />
              </Fragment>
            )}
            {currentStep === 4 && (
              <Fragment>
                <SuccessCard />
                <Review />
              </Fragment>
            )}
            <StepNavigator
              onBack={handleBackStep}
              onNext={handleNextStep}
              isLoading={isLoading}
              currentStep={currentStep}
            />
          </Styled.MainContent>
          <Styled.SideContent>
            <Summary />
          </Styled.SideContent>
        </Styled.ContentContainer>
      </Styled.ColumnsContainer>
      <ErrorModal />
      <LmnFooter />
    </Styled.CheckoutContainer>
  );
}
