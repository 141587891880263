import styled from '@emotion/styled';

export const BaggageContainer = styled.div`
  margin-top: 1px;

  &:empty {
    margin-top: 0;
  }
`;

export const SeatMessageContainer = styled.div`
  margin-top: 8px;
`;

export const SegmentContainer = styled.div`
  display: flex;
`;

export const SegmentInfo = styled.div<{
  isStopLine?: boolean;
  isEmptyCirle?: boolean;
  hideLine?: boolean;
}>`
  padding-left: 16px;
  flex-grow: 2;
  position: relative;
`;

export const DotBeforeTransport = styled.span<{ isEmptyCircle: boolean }>`
  width: ${(p) => (p.isEmptyCircle ? '6px' : '10px')};
  height: ${(p) => (p.isEmptyCircle ? '6px' : '10px')};
  background-color: ${(p) => (p.isEmptyCircle ? 'transparent' : '#3c3c3c')};
  border-radius: 100%;
  border: ${(p) => (p.isEmptyCircle ? `2px solid #3c3c3c` : 'none')};
  position: absolute;
  left: -2.75px;
  top: 8px;
`;

export const Time = styled.div`
  padding-right: 18px;
  width: 68px;
  flex-shrink: 0;
`;

export const MediumText = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 12px;
`;

export const BaseText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const SmallText = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const DifferentDay = styled.div`
  text-align: right;
`;

export const Hour = styled.div`
  text-align: right;
`;

export const GrayText = styled.div`
  color: #74747c;
`;

export const RedText = styled.div`
  color: #ff4800;
`;

export const ProviderInfo = styled.div`
  padding: 12px;
  margin: 16px 0px;
  border: 1px solid #eaeaeb;
  border-radius: 8px;
`;

export const CompanyInfo = styled.div`
  display: flex;
`;

export const CompanyInfoCode = styled(BaseText)`
  padding-left: 8px;
`;

export const TransportDuration = styled.div`
  font-size: 12px;
  color: lightgray;
  margin-right: 8px;
`;

export const IconInnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledIconContainer = styled.div`
  &::before {
    content: ' ';
    border-left: 2px ${(p) => `solid #3c3c3c`};
    position: absolute;
    left: 1px;
    bottom: calc(50% + 4px);
    top: 24px;
  }

  &::after {
    content: ' ';
    border-left: 2px ${(p) => `solid #3c3c3c`};
    position: absolute;
    left: 1px;
    top: calc(50% + 28px);
    bottom: 0px;
  }
`;

export const StopLine = styled.div<{ isStopLine?: boolean }>`
  border-left: 2px dashed #babac0;
  position: absolute;
  left: 1.5px;
  top: 25px;
  bottom: 0px;
`;
