import { Row } from 'antd';
import styled from '@emotion/styled';

export const Container = styled(Row)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Card = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 10px 20px,
    rgba(0, 0, 0, 0.04) 0px 2px 10px,
    rgba(0, 0, 0, 0.02) 0px 0px 4px;
`;

export const InnerContainer = styled.div`
  width: 100%;
  padding: 8em 16px 8em 16px;

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;
