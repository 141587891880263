import styled from '@emotion/styled';
import { Drawer as MuiDrawer } from '@mui/material';

export const DrawerContent = styled.div`
  max-width: 1024px;
  width: calc(100% + 0px);
  background-color: #f5f5f5;
`;

export const Drawer = styled(MuiDrawer)`
  & .MuiModal-backdrop {
    background: rgba(0, 0, 0, 0.33);
  }

  & .MuiPaper-root {
    width: 100%;
    max-width: 1024px;
  }
`;

export const SortSelectorContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
