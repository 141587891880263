import styled from '@emotion/styled';
import { Button } from '@mui/material';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export const Container = styled.div<{ $badgeColor: string }>`
  height: calc(100vh - 136px);
  width: calc(100vw);
  position: relative;

  & .price-popup-focused {
    color: white;
    z-index: 2;
    background-color: ${({ $badgeColor }) => $badgeColor} !important;
  }

  & .price-popup-focused::after {
    border-color: ${({ $badgeColor }) => $badgeColor} transparent transparent !important;
  }
`;

export const ToggleVisibilityButton = styled(Button)`
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  user-select: none;
  display: flex;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 6;
  height: 38px;
  align-items: center;
  justify-content: center;
  padding: 0px 11px;
  min-width: auto;
`;

export const ArrowBackIcon = styled(ArrowBackIosRoundedIcon)`
  color: black;
  font-size: 1rem;
`;
export const ArrowFrontIcon = styled(ArrowBackIcon)`
  rotate: 180deg;
`;
