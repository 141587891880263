import Checkbox from '@mui/material/Checkbox';
import * as Styled from './AirportFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { AirportsProps } from './AirportFilters.types';

export function Airports({
  data,
  onAirportHandle,
  airportStaticData,
  disabled,
  airportFilter,
}: AirportsProps) {
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onAirportHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((airport, index) => {
          const info = airportStaticData[airport.code];
          if (!info) return null;
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${airport.code}_${index}_Outbound_Dep`}
              control={
                <Checkbox
                  disabled={!airport.active || disabled}
                  value={airport.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={airportFilter?.includes(airport.code) ?? null}
                />
              }
              labelPlacement="start"
              label={
                <Styled.LabelOption>
                  {info?.label} {airport.code}
                </Styled.LabelOption>
              }
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
