import React from 'react';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { DifferentDateAsMessageProps } from './DifferentDateAsMessage.types';

export function DifferentDateAsMessage({
  isInbound,
}: DifferentDateAsMessageProps) {
  const { t } = useTranslate();

  const differentDateMessage = t(
    'widget.trip_card.way.details.different_date.message'
  );
  const differentDateOutboundTitle = t(
    'widget.trip_card.way.details.different_date.title'
  );
  const differentDateInboundTitle = t(
    'widget.trip_card.way.details.different_date.inbound_title'
  );

  return (
    <Alert
      type="info"
      icon={<InfoCircleFilled />}
      description={differentDateMessage}
      message={
        isInbound ? differentDateInboundTitle : differentDateOutboundTitle
      }
    />
  );
}
