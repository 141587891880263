import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { CartDetailProduct } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { useCurrencyFormatter } from '@/views/Checkout/hooks/useCurrencyFormatter';
import { useTranslate } from '@tolgee/react';

export const useGetProductPrices = () => {
  const cartDetail = useStore((state) => state.cartDetail);
  const currencyFormatter = useCurrencyFormatter();
  const bookingType = useBookingType();
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const getFlightTaxes = (
    flightProduct: CartDetailProduct,
    paymentFees: string
  ) => {
    const itineraryInfo = flightProduct.detail.itinerary;
    const costDetails = itineraryInfo.costDetails;

    const isRyanair = !!itineraryInfo.inboundTransport
      ? itineraryInfo.outboundTransport[0]?.segments[0]?.company.id === 'FR' ||
        itineraryInfo.inboundTransport[0]?.segments[0]?.company.id === 'FR'
      : itineraryInfo.outboundTransport[0]?.segments[0]?.company.id === 'FR';

    if (costDetails) {
      const total = costDetails.total.value;
      const taxes = costDetails.taxes.value;

      const flightTaxes = taxes
        ? [
            {
              title: t('checkout.summary.taxes'),
              price: currencyFormatter.formatNumber(taxes / 100),
            },
          ]
        : [undefined];

      const discount = paymentFees
        ? [
            {
              title: t('checkout.summary.payment_fees', '', {
                brandName: brandConfig.brand.brandName,
              }),
              price: paymentFees,
            },
          ]
        : [undefined];

      if (isRyanair) {
        const agencyFee = flightProduct.cost.markup.value;

        return [
          {
            title: t('checkout.summary.rate'),
            price: currencyFormatter.formatNumber(
              (total - taxes - agencyFee) / 100
            ),
          },
          {
            title: t('checkout.summary.agency_fee'),
            price: currencyFormatter.formatNumber(agencyFee / 100),
          },

          ...flightTaxes,
          ...discount,
        ];
      } else {
        return [
          {
            title: t('checkout.summary.rate'),
            price: currencyFormatter.formatNumber((total - taxes) / 100),
          },
          ...flightTaxes,
          ...discount,
        ];
      }
    }

    return [];
  };

  const getProductPrices = () => {
    const packagePrice = cartDetail.specialPriceConditions?.shownPrice.value;

    const paymentFees = cartDetail.receipt.paymentFees.value
      ? currencyFormatter.formatNumber(
          cartDetail.receipt.paymentFees.value / 100
        )
      : null;

    const flightProduct = cartDetail.products.find((e) => e.type === 'FLIGHT');

    const extraProducts = cartDetail.products
      .filter((e) => e.cost && e.cost.amount.value !== 0)
      .filter((e) => e.type !== 'FLIGHT')
      .map((e) => ({
        title: t(`product.${e.type.toLocaleLowerCase()}`),
        price: currencyFormatter.formatNumber(e.cost?.amount?.value / 100),
      }));

    switch (bookingType) {
      case BookingType.FlightAndHotel:
        const itineraryInfo = flightProduct.detail.itinerary;
        const isRyanair = !!itineraryInfo.inboundTransport
          ? itineraryInfo.outboundTransport[0].segments[0].company.id ===
              'FR' ||
            itineraryInfo.inboundTransport[0].segments[0].company.id === 'FR'
          : itineraryInfo.outboundTransport[0].segments[0].company.id === 'FR';

        const flightCost = flightProduct.cost.baseCost.value;

        if (isRyanair) {
          return [
            packagePrice
              ? {
                  title: t('checkout.summary.package_cost'),
                  price: currencyFormatter.formatNumber(packagePrice / 100),
                  subItems: [
                    isRyanair
                      ? {
                          title: t('checkout.summary.flight_included'),
                          price: currencyFormatter.formatNumber(
                            flightCost / 100
                          ),
                        }
                      : undefined,
                  ],
                }
              : undefined,

            ...extraProducts,
          ].filter((e) => e);
        } else {
        }

      case BookingType.Hotel:
        return [
          packagePrice
            ? {
                title: t('checkout.summary.package_cost'),
                price: currencyFormatter.formatNumber(packagePrice / 100),
              }
            : undefined,
          ...extraProducts,
        ].filter((e) => e);

      case BookingType.Flight:
        const flightTaxes = getFlightTaxes(flightProduct, paymentFees);
        return [...flightTaxes, ...extraProducts].filter((e) => e);
      default:
        return [];
    }
  };

  return getProductPrices;
};
