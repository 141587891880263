import { useTranslate } from '@tolgee/react';
import * as Styled from './HotelSecretTag.styled';

export function HotelSecretTag() {
  const { t } = useTranslate();

  return (
    <Styled.TopSecretLabel>
      {t('search_result.hotel.secret')}
    </Styled.TopSecretLabel>
  );
}
