import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 24px;
`;
export const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #74747c;
`;