import { useBookingType } from '@/hooks/useBookingType';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetParam } from '@/hooks/useGetParam';
import {
  CartApiServiceService,
  SubscriptionInputDto,
} from '@/services/codegen-romulo';
import {
  ReservationInputBaseDto,
  ReservationService,
} from '@/services/codegen-welfare';
import { useStore } from '@/store/useStore';
import { AlertColor } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useSubscriptionsStore } from '../components/Subscriptions/hooks/useSubscriptionsStore';
import { getProductType } from '../utils/getProductType';
import { useContactDetailsStore } from '../components/ContactDetails/hooks/useContactDetailsStore';

const fiscalCodeError =
  'Insert failed\nKOInsert failed\nIl codice fiscale non è valido o non è stato inserito usando le lettere maiuscole!';
const creditError = 'Insert failed\nKONot enough credit';
const pIdError = 'Invalid PID';

export function useConfirmReservation() {
  const getParam = useGetParam();
  const bookingType = useBookingType();
  const [freeFlightVoucherInfo] = useContactDetailsStore(
    ({ freeFlightVoucherInfo }) => [freeFlightVoucherInfo]
  );
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;
  const [order, cartDetail, brandConfig] = useStore((state) => [
    state.order,
    state.cartDetail,
    state.brandConfig,
  ]);
  const domainName = brandConfig.domain;
  const { t } = useTranslate();
  const [reservationError, setReservationMessage] = useState<{
    type: AlertColor;
    message: string;
  }>(undefined);

  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');

  const hotel = cartDetail?.products?.find((e) => e.type === 'HOTEL');
  const accommodation = hotel?.detail.accommodation;

  const flight = cartDetail?.products?.find((e) => e.type === 'FLIGHT')?.detail
    ?.itinerary;
  const from = flight?.outboundTransport[0].segments[0];
  const to =
    flight?.outboundTransport[0].segments[
      flight?.outboundTransport[0].segments.length - 1
    ];
  const departureCity = from?.departureStation.cityName;
  const arrivalCity = to?.destinationStation.cityName;
  const city = accommodation?.staticData.address.city;
  const fromTo = flight
    ? `Flight: From ${departureCity} to ${arrivalCity}`
    : `City: ${city}`;

  const hotelName = ` | Hotel: ${accommodation?.staticData.name}`;
  const descriptionText = `BookingId: ${order?.orderId} | ${fromTo} ${hotelName}`;

  const [marketingConsent, contractConsent] = useSubscriptionsStore(
    ({ marketingConsent, contractConsent }) => [
      marketingConsent,
      contractConsent,
    ]
  );

  const handleAddSubscriptions = async () => {
    const privacySubscription: SubscriptionInputDto[] = contractConsent
      ? [{ type: 'mandatory_privacy_newsletter' }]
      : [];
    const marketingSubscription: SubscriptionInputDto[] = marketingConsent
      ? [{ type: 'marketing_newsletter' }]
      : [];
    const addSubscriptionsRequest: SubscriptionInputDto[] = [
      ...privacySubscription,
      ...marketingSubscription,
    ];

    await CartApiServiceService.postApiServicesAppCartApiServiceAddSubscriptions(
      { cartId, requestUuid, requestBody: addSubscriptionsRequest }
    )
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject(false));
  };

  const createReservation = async () => {
    const reservationCreationRequest: ReservationInputBaseDto = {
      productType: getProductType(bookingType),
      clientData: {
        // TODO fiscalCode: fiscalCodeValue,
        userId: '',
        pid: '',
        description: descriptionText || 'No description',
        providerData: {
          VoucherCode: freeFlightVoucherInfo.voucherCode,
          brandName: /*clientData?.brandName ||*/ null, // TODO
        },
      },
      lmDataReservation: {
        lmCartId: cartId || '',
        amount: cartDetail.receipt.totalCost.value,
        uuid: requestUuid,
      },
      domainName: domainName,
    };

    return await ReservationService.postApiServicesAppReservationCreateReservation(
      {
        requestBody: reservationCreationRequest,
      }
    ).then((response) => {
      const isSuccess = response.complete === true;
      const statesResultsErrors = response.statesResultsErrors;

      if (isSuccess) {
        return Promise.resolve(true);
      } else if (statesResultsErrors.stepName === 'Romulo.Payment') {
        setReservationMessage({
          type: 'warning',
          message: t('error.checkout.fiscal_code'),
        });
      } else {
        switch (statesResultsErrors.errorMsg) {
          case fiscalCodeError:
            setReservationMessage({
              type: 'error',
              message: t('error.checkout.fiscal_code'),
            });
            break;
          case creditError:
            setReservationMessage({
              type: 'error',
              message: t('error.checkout.enough_budget'),
            });
            break;
          case pIdError:
            setReservationMessage({
              type: 'error',
              message: t('error.session_expired'),
            });
            break;
          default:
            setReservationMessage({
              type: 'error',
              message: t('error.generic_error'),
            });
            break;
        }
      }
      return Promise.reject(true);
    });
  };

  const confirmReservation = async () => {
    return Promise.all([
      await handleAddSubscriptions(),
      await createReservation(),
    ])
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject(false));
  };

  return { confirmReservation, reservationError, setReservationMessage };
}
