import { create } from 'zustand';

interface SpecialAssistance {
  travellerId: string;
  assistanceType: string;
}

interface SpecialAssistanceStore {
  needsSpecialAssistance: boolean;
  applicationType?: string;
  assistanceType?: SpecialAssistance[];
  specialAssistanceGeneralApplication?: string;
  setNeedsSpecialAssistance: (value: boolean) => void;
  setApplicationType: (value: string) => void;
  setAssistanceType: (value: SpecialAssistance[]) => void;
  setSpecialAssistanceGeneralApplication: (value: string) => void;
}

export const useSpecialAssistanceStore = create<SpecialAssistanceStore>(
  (set) => ({
    needsSpecialAssistance: false,
    setNeedsSpecialAssistance: (needsSpecialAssistance: boolean) =>
      set({ needsSpecialAssistance }),
    applicationType: undefined,
    setApplicationType: (applicationType: string) => set({ applicationType }),
    assistanceType: [],
    setAssistanceType: (assistanceType: SpecialAssistance[]) =>
      set({ assistanceType }),
    specialAssistanceGeneralApplication: undefined,
    setSpecialAssistanceGeneralApplication: (
      specialAssistanceGeneralApplication: string
    ) => set({ specialAssistanceGeneralApplication }),
  })
);
