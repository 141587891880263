import Checkbox from '@mui/material/Checkbox';
import * as Styled from './AmenitiesFilters.styled';
import { FormGroup } from '@mui/material';
import { useStore } from '@/store/useStore';
import { AmenityProps } from './AmenitiesFilters.types';
import { useTranslate } from '@tolgee/react';

export function Amenity({
  data,
  onAmenityHandle,
  disabled,
  amenityFilter,
}: AmenityProps) {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor } = brandConfig.theme;

  return (
    <FormGroup onChange={onAmenityHandle}>
      <Styled.FilterOptionsContainer>
        {data?.map((amenity, index) => {
          return (
            <Styled.FormLabel
              disabled={disabled}
              key={`${amenity.code}_${index}_amenity`}
              control={
                <Checkbox
                  disabled={!amenity.active || disabled}
                  value={amenity.code}
                  icon={<Styled.Checkbox />}
                  disableRipple
                  checkedIcon={
                    <Styled.CheckIconContainer $color={accentColor}>
                      <Styled.CheckIcon />
                    </Styled.CheckIconContainer>
                  }
                  checked={amenityFilter?.includes(amenity.code) ?? false}
                />
              }
              labelPlacement="start"
              label={<Styled.LabelOption>{amenity.label}</Styled.LabelOption>}
            />
          );
        })}
      </Styled.FilterOptionsContainer>
    </FormGroup>
  );
}
