import { AuthenticatedDto, StrapiService } from '@/services/codegen-welfare';
import { getSearchParam } from '@/utils/getSearchParam';
import { authenticateExternal } from './authenticateExternal';
import { BrandConfig, configMapper } from './configMapper';

export interface BrandConfigResponse {
  id: string;
  domain: string;
  brandConfig: any;
}

const brandConfigurationParams = [
  '*',
  'products',
  'brandDetails.footer',
  'brandDetails.footer.logo',
  'brandDetails.freeFlightConditions',
  'paymentMethods',
  'landingContent',
  'landingContent.hero',
  'landingContent.hero.hero_background',
  'landingContent.productExplanation',
  'theme.favicon',
  'theme.logoPrimaryUrl',
  'theme.logoSecondaryUrl',
  'payment_methods',
  'hasTokenAuthentication',
  'checkoutOptions',
  'promo_campaign',
  'promo_campaign.bannerDP',
  'promo_campaign.bannerH',
  'promo_campaign.bannerF',
];

const brandConfigurationParamsString = brandConfigurationParams
  .map((value, index) => `populate[${index}]=${value}`)
  .join('&');

const hostname = window._env_.VITE_DOMAIN_STRAPI || window.location.hostname;

export const brandConfigurationPath = `brand-configurations/${hostname}?${brandConfigurationParamsString}`;

export const getInitialState: {
  config: BrandConfig;
  externalAuth: AuthenticatedDto;
} = await StrapiService.getApiServicesAppStrapiGetConfig({
  path: brandConfigurationPath,
})
  .then(async (configuration) => {
    const configId = configuration.data.id;
    const domain = configuration.data.attributes.domain;

    const config = configMapper({
      id: configId,
      domain: domain,
      brandConfig: configuration.data.attributes,
    });
    const token = getSearchParam('a');

    const externalAuthentication = authenticateExternal({
      token: token ? decodeURIComponent(token.replaceAll('%20', '+')) : '',
      domainName: domain,
    });

    return { config, externalAuthentication };
  })
  .catch((error) => {
    console.log('error', error);
    return null;
  });
