import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const useGetParam = () => {
  const [searchParams] = useSearchParams();

  return (searchKey: string): any => {
    const param = searchParams.get(searchKey);

    //NOTE: check with childrenAges
    const VALUES_SEARCH_PARAMS = {
      adults: param ? Number(param) : 0,
      childrenAges: param?.split(',')?.map((e) => Number(e)),
      outboundDate: dayjs(param)?.format('YYYYMMDD'),
      returnDate: dayjs(param)?.format('YYYYMMDD'),
      departure: param?.toLocaleLowerCase(),
      destination: param?.toLocaleLowerCase(),
      mealPlans: param
        ? param.split('-').filter((mealplan) => mealplan !== 'ALL_MEAL_PLANS')
        : [],
      freeCancellation: param && param === 'true' ? true : undefined,
    };

    return VALUES_SEARCH_PARAMS[searchKey] ?? param;
  };
};
