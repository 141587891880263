export const sortOptions = {
  recomended: {
    label: 'filter.sort.best',
    icon: '',
    value: 'RECOMMENDED',
    ascending: true,
  },
  recomended2: {
    label: 'filter.sort.fastest',
    icon: '',
    value: 'DURATION',
    ascending: true,
  },
  recomended3: {
    label: 'filter.sort.cheapest',
    icon: '',
    value: 'PRICE',
    ascending: true,
  },
};
