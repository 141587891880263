import AccessibleIcon from '@mui/icons-material/Accessible';
import BathtubIcon from '@mui/icons-material/Bathtub';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CheckIcon from '@mui/icons-material/Check';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import HikingIcon from '@mui/icons-material/Hiking';
import InfoIcon from '@mui/icons-material/Info';
import LaptopIcon from '@mui/icons-material/Laptop';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PetsIcon from '@mui/icons-material/Pets';
import PoolIcon from '@mui/icons-material/Pool';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import TranslateIcon from '@mui/icons-material/Translate';
import WifiIcon from '@mui/icons-material/Wifi';
import WineBarIcon from '@mui/icons-material/WineBar';

export const getFacilityIcon = (id: string) => {
  switch (id) {
    case 'PARKING':
    case 'Parking':
      return <LocalParkingIcon />;
    case 'FACILITIES_DISABLE_GUEST':
    case 'Accessibility':
      return <AccessibleIcon />;
    case 'FACILITIES_CHILDREN':
    case 'Children':
      return <ChildCareIcon />;
    case 'FREE_WIFI':
    case 'Internet':
      return <WifiIcon />;
    case 'Food & Drink':
      return <WineBarIcon />;
    case 'ENTERTAINMENT':
    case 'Activities':
      return <HikingIcon />;
    case 'Cleaning services':
      return <CleaningServicesIcon />;
    case 'Reception services':
      return <RoomServiceIcon />;
    case 'General':
      return <InfoIcon />;
    case 'SPA':
    case 'Bathroom':
      return <BathtubIcon />;
    case 'Media & Technology':
      return <LaptopIcon />;
    case 'POOL':
    case 'Swimming pool':
      return <PoolIcon />;
    case 'Language spoken':
      return <TranslateIcon />;
    case 'PETS_ALLOWED':
    case 'Pets':
      return <PetsIcon />;
    case 'Business equipment':
      return <BusinessCenterIcon />;
    case 'HEALTH_SAFETY':
      return <CleanHandsIcon />;

    default:
      return <CheckIcon />;
  }
};
