import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC, Fragment } from 'react';
import { SectionCard } from '../SectionCard/SectionCard';
import * as SectionCardStyled from '../SectionCard/SectionCard.styled';
import { Skeleton } from '../Skeleton';
import { useContactDetailsDefaultValues } from './hooks/useContactDetailsDefaultValues';
import { useContactDetailsStore } from './hooks/useContactDetailsStore';
import { getComponentByField } from './utils/getComponentByField';

const FISCAL_CODE = 'FISCAL_CODE';

let sortedFields = [
  'NAME',
  'SURNAME',
  // 'FISCAL_CODE',
  'EMAIL',
  'PHONE',
  'ADDRESS',
  'STREET_NUMBER',
  'POSTAL_CODE',
  'CITY',
  'NATIONALITY',
  'NOT_GUEST',
];

interface ContactDetailsProps {
  disabled?: boolean;
}

const ContactDetails: FC<ContactDetailsProps> = ({ disabled }) => {
  const { t } = useTranslate();
  const cartConfig = useStore((state) => state.cartConfig);
  const requiredDetails = cartConfig?.contact?.requiredDetails;
  // TODO
  const askForFiscalCode = false;
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  useContactDetailsDefaultValues();

  const [contactDetails, setContactDetails, inputErrors, setInputErrors] =
    useContactDetailsStore(
      ({ contactDetails, setContactDetails, inputErrors, setInputErrors }) => [
        contactDetails,
        setContactDetails,
        inputErrors,
        setInputErrors,
      ]
    );
  const [freeFlightVoucherInfo] = useContactDetailsStore(
    ({ freeFlightVoucherInfo }) => [freeFlightVoucherInfo]
  );

  if (!contactDetails) {
    return <Skeleton />;
  }

  // TODO FIX LOGICs
  if (askForFiscalCode) {
    sortedFields.filter((item) => item !== FISCAL_CODE);
  }

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    if (name === 'isTraveller') {
      setContactDetails({ ...contactDetails, [name]: checked });
    } else {
      setContactDetails({ ...contactDetails, [name]: value });
    }
  };

  const handleValidate = (regex?: RegExp) => (e: any) => {
    const { name, value } = e.target;

    if (name === 'email' && isFreeFlight) {
      setInputErrors({
        ...inputErrors,
        [name]: value !== freeFlightVoucherInfo.email,
      });
    } else {
      if (regex) {
        setInputErrors({ ...inputErrors, [name]: !regex.test(value) });
      }
    }
  };

  return (
    <SectionCard title={t('form.contact.contact_details')}>
      <SectionCardStyled.ContentContainer>
        {sortedFields
          .filter((e) => requiredDetails.includes(e) || e === FISCAL_CODE)
          .map((fieldName, key) => (
            <Fragment key={key}>
              {getComponentByField({
                source: 'CONTACT',
                fieldName,
                formData: contactDetails,
                inputErrors,
                disabled,
                handleChange,
                handleValidate,
              })}
            </Fragment>
          ))}
      </SectionCardStyled.ContentContainer>
    </SectionCard>
  );
};

export { ContactDetails };
