import { getCombination } from './getCombination';

export const getLuggageLabel = (
  luggageOption: any,
  t: (key: string) => string
) => {
  return luggageOption.additionalInfo.numberOfPieces < 1
    ? t('form.product.luggage.no_luggage')
    : luggageOption.additionalInfo.totalWeight
      ? getCombination(luggageOption.additionalInfo)
      : luggageOption.label;
};
