import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from '../Subscriptions.styled';
import {
  privacyRewardwave,
  rewardwaveTermsAndConditions,
} from '../utils/commonValues';
import { getBaseUrl } from '../utils/getBrandBase';

export const FlightContractPolicy: FC = () => {
  const { t } = useTranslate();
  const [cartConfig, brandConfig] = useStore((state) => [
    state.cartConfig,
    state.brandConfig,
  ]);
  const { brand, footer } = brandConfig;
  const baseUrl = getBaseUrl(brand.brandBase);

  const termsAndConditionUrl = cartConfig?.subscription?.termsAndCondition?.url;
  const contractPolicy = cartConfig?.subscription.contractPolicy;

  return (
    <Styled.LegalText>
      {brand.brandOrigin == 'Roiward' ? (
        <span
          dangerouslySetInnerHTML={{
            __html: ` ${t(
              'form.subscriptions.contract_policy.rewardwave.f.description_pt_1',
              '',
              {
                termsAndConditions: footer?.custom?.termsAndConditionsUrl,
                privacy: footer?.custom?.privacyUrl,
                rewardwaveTermsAndConditions: rewardwaveTermsAndConditions,
                privacyRewardwave: privacyRewardwave,
                buttonText: t(
                  brandConfig.checkout?.createReservationButtonText ||
                    'form.button.confirm_reservation'
                ),
              }
            )}`,
          }}
        />
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: ` ${t(
              'form.subscriptions.contract_policy.lastminute.f.description_pt_1',
              '',
              {
                termsAndConditions: `${baseUrl}${termsAndConditionUrl}`,
                privacy: `${baseUrl}${contractPolicy.url}`,
                buttonText: t(
                  brandConfig.checkout?.createReservationButtonText ||
                    'form.button.confirm_reservation'
                ),
              }
            )}`,
          }}
        />
      )}
    </Styled.LegalText>
  );
};
