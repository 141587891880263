import styled from '@emotion/styled';
import { Button as ButtomMui } from '@mui/material';

export const Container = styled.div`
  display: flex;
  margin: 16px 0;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 700;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

export const IconContainer = styled.div<{ $url: string }>`
  background-image: url(${({ $url }) => $url});
  height: 150px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Button = styled(ButtomMui)`
  background-color: #f2007d;

  &:hover {
    background-color: #f2007d;
  }
`;
