import SkeletonMui, { SkeletonProps } from '@mui/material/Skeleton';
import * as Styled from './Skeleton.styled';
import { useIsMobile } from '@/hooks/useIsMobile';

function SkeletonUI({ sx, ...props }: SkeletonProps) {
  return (
    <SkeletonMui
      animation="wave"
      variant="rectangular"
      sx={{ borderRadius: '2px', bgcolor: 'rgba(0, 0, 0, 0.06)', ...sx }}
      {...props}
    />
  );
}

export function Skeleton() {
  const { isMobile } = useIsMobile();

  return (
    <Styled.Container>
      <SkeletonUI
        sx={{
          borderRadius: '8px',
          width: isMobile ? '100%' : '250px',
          height: isMobile ? '180px' : '273px',
        }}
      />
      <Styled.InnerContainer>
        <Styled.InfoContainer>
          <SkeletonUI
            height={20}
            width={220}
            sx={{
              marginBottom: '4px',
            }}
          />
          <SkeletonUI
            height={45}
            width={300}
            sx={{
              marginBottom: '16px',
            }}
          />
          <SkeletonUI
            height={20}
            width={200}
            sx={{
              marginBottom: '32px',
            }}
          />
          <SkeletonUI
            height={20}
            width={250}
            sx={{
              marginBottom: '8px',
            }}
          />
          <SkeletonUI
            height={20}
            width={200}
            sx={{
              marginBottom: '8px',
            }}
          />
          <SkeletonUI height={20} width={200} />
        </Styled.InfoContainer>
        <Styled.PriceInfoContainer>
          <SkeletonUI
            height={45}
            width={100}
            sx={{
              marginBottom: '8px',
            }}
          />
          <SkeletonUI height={20} width={160} />
        </Styled.PriceInfoContainer>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
