import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const TotalItemsContainer = styled.div`
  border-radius: 8px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  padding: 16px;
  background-color: #fff;
  margin-bottom: 24px;

  > *:not(:last-child) {
    width: 100%;
    margin-bottom: 16px;
  }
`;
