import { useGetParam } from '@/hooks/useGetParam';
import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';

export function useGetInfoData(showSearchResume: boolean) {
  const { t } = useTranslate();

  const getParam = useGetParam();

  const appToken = getParam('a');

  const datefrom = getParam('outboundDate');

  const dateto = getParam('returnDate');

  const departureName = getParam('departureName');

  const destinationName = getParam('destinationName');

  const dateText = showSearchResume
    ? dateto === 'no-return'
      ? `${dayjs(datefrom, 'YYYYMMDD').format('DD MMM')}`
      : `${dayjs(datefrom, 'YYYYMMDD').format('DD MMM')} - 
  ${dayjs(dateto, 'YYYYMMDD').format('DD MMM')}`
    : '';

  const locationsText = showSearchResume
    ? departureName
      ? `${departureName} - 
  ${destinationName}`
      : `${destinationName}`
    : '';

  const adults = getParam('adults');

  const childrenAges = getParam('childrenAges');

  const peopleNumber = adults + childrenAges;

  const passengersText = `${peopleNumber} ${(peopleNumber > 1
    ? t('search.travellers')
    : t('search.traveller')
  ).toLowerCase()}`;

  const passengersFlightsText = `${peopleNumber} ${(peopleNumber > 1
    ? t('search.passengers')
    : t('search.passenger')
  ).toLowerCase()}`;

  return {
    dateText,
    locationsText,
    passengersText,
    passengersFlightsText,
    appToken,
  };
}
