import { useStore } from '@/store/useStore';
import { getBannerData } from './utils';

export function useGetBannerData() {
  const brandConfig = useStore((state) => state.brandConfig);

  const { promoCampaign } = brandConfig;

  return getBannerData(promoCampaign);
}
