import { Favicon } from '@/components/Favicon';
import { AntdProvider } from '@/providers/AntdProvider/AntdProvider';
import { StoreProvider } from '@/store/storeProvider';
import { getInitialState } from '@/store/utils/getInitialState';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { DevTools } from './components/DevTools/DevTools';
import { ErrorScreen } from './components/ErrorScreen/ErrorScreen';
import { Router } from './components/Router/Router';
import { FlagProvider } from './providers/FlagProvider';
import { MuiProvider } from './providers/MuiProvider';
import { TolgeeProvider } from './providers/TolgeeProvider';
import { localeObject } from './utils/localeObjects';

dayjs.extend(utc);
dayjs.extend(timezone);

export function App() {
  const initialStateMemoized = React.useMemo(
    () => ({
      brandConfig: getInitialState?.config,
      externalAuthentication: getInitialState?.externalAuth,
    }),
    []
  );

  React.useEffect(() => {
    if (getInitialState?.config) {
      dayjs.locale(
        getInitialState.config?.language,
        localeObject[getInitialState.config?.language]
      );
    }
  }, []);

  if (!getInitialState?.config) {
    return <ErrorScreen />;
  }

  return (
    <BrowserRouter>
      <StoreProvider initialState={initialStateMemoized}>
        <FlagProvider>
          <TolgeeProvider>
            <AntdProvider>
              <MuiProvider>
                <Router />
                <Favicon />
                <DevTools />
              </MuiProvider>
            </AntdProvider>
          </TolgeeProvider>
        </FlagProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}
