import styled from '@emotion/styled';

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: #3c3c3c;
  line-height: 20px;
`;
export const Detail = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: #3c3c3c;
  line-height: 20px;
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 8px;
  padding: 16px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 996px) {
    max-width: 400px;
  }
`;
