import { css } from '@emotion/react';
import styled from '@emotion/styled';
import DialogMaterial from '@mui/material/Dialog';

export const Dialog = styled(DialogMaterial)<{ $fullheight?: string }>`
  .MuiPaper-root {
    border-radius: 16px;
    width: 100%;
    max-width: 600px;
    margin: 0;
    overflow: hidden;

    ${({ $fullheight }) =>
      $fullheight === 'true' &&
      css`
        height: calc(100% - 32px);
        max-height: calc(100% - 32px);
      `}

    @media (max-width: 600px) {
      position: absolute;
      bottom: 0px;
      border-radius: 16px 16px 0px 0px;
    }
  }

  .MuiDialogContent-root {
    padding: 16px;
    scrollbar-width: none;
  }

  .MuiDialogTitle-root {
    box-shadow:
      0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 10px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.02);
    font-weight: 700;
    font-size: 16px;
  }

  .MuiDialogActions-root {
    padding: 16px;
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }
`;
