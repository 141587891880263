import { useGetParam } from '@/hooks/useGetParam';
import { CartApiServiceService } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';

export const removeProductFromCart = async ({
  cartId,
  requestUuid,
  productIds,
}: {
  cartId?: string;
  requestUuid?: string;
  productIds?: Array<string>;
}) => {
  return await CartApiServiceService.deleteApiServicesAppCartApiServiceRemoveProductsFromCart(
    { cartId, requestUuid, productIds }
  ).catch((error) => error.response.data.error.message);
};

export const useRemoveProducts = () => {
  const getParam = useGetParam();
  const cartId = getParam('cartId');
  const requestUuid = getParam('uuid');
  const [cartDetail, getCart, setCardExpired] = useStore((state) => [
    state.cartDetail,
    state.getCart,
    state.setCardExpired,
  ]);

  const removeProducts = async (productType: string) => {
    try {
      const productAlreadyPresent = cartDetail.products.filter(
        (product) => product.type === productType
      );

      return (
        productAlreadyPresent &&
        (await removeProductFromCart({
          cartId,
          requestUuid,
          productIds: productAlreadyPresent.map((e) => e.id),
        }).then((e) => {
          if (e === 'CART_EXPIRED') {
            setCardExpired();
          } else {
            getCart(cartId, requestUuid);
          }
        }))
      );
    } catch (error) {
      console.log('addProductsToCart error', error);
      return false;
    }
  };

  return removeProducts;
};
