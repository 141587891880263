import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  @media (max-width: 767px) {
    padding-bottom: 0px;
  }
`;

export const Summary = styled.span`
  color: #74747c;
  font-size: 14px;
  line-height: 20px;
`;

export const HotelName = styled.div`
  color: #3c3c3c;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
