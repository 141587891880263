import { SearchIcon } from '@/components/LmnIcons/LmnIcons';
import { BookingType, useBookingType } from '@/hooks/useBookingType';
import { useGetParam } from '@/hooks/useGetParam';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { Fragment, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Search } from '../Search';
import { CallUsButton } from './CallButton';
import * as Styled from './Header.styled';
import { useGetInfoData } from './useGetInfoData';

export function Header({
  showSearchSummary = false,
}: {
  showSearchSummary?: boolean;
}) {
  const brandConfig = useStore((state) => state.brandConfig);
  const [open, setOpen] = useState(false);
  const { theme, brand } = brandConfig;
  const bookingType = useBookingType();
  const { isMobile } = useIsMobile();
  const track = useTrack();
  const getParam = useGetParam();
  const product = getParam('product');
  const { headerColor, accentColor, logoPrimaryUrl } = theme;
  const { hasAuthentication } = brand;

  const {
    dateText,
    locationsText,
    passengersText,
    passengersFlightsText,
    appToken,
  } = useGetInfoData(showSearchSummary);

  const handleLogoClick = () => {
    track({
      eventName: 'clicked.header.logo',
      logLevel: 'info',
    });
    if (hasAuthentication) {
      window.location.href = `/?a=${appToken}`;
    } else {
      window.location.href = `/?product=${product}`;
    }
  };

  const handleSearchClick = () => {
    track({
      eventName: 'clicked.header.search',
      logLevel: 'info',
    });
    setOpen(true);
  };

  return (
    <Fragment>
      <Styled.Container
        style={{
          backgroundColor: headerColor,
        }}
      >
        <Styled.LogoContainer
          $hideLogo={showSearchSummary}
          onClick={handleLogoClick}
        >
          <Styled.Logo src={logoPrimaryUrl} alt="logo" />
        </Styled.LogoContainer>

        {showSearchSummary && !open && (
          <Styled.InnerContainer onClick={handleSearchClick}>
            <Styled.Text>{locationsText}</Styled.Text>
            <Styled.Divider />
            <Styled.Text>{dateText}</Styled.Text>
            <Styled.Divider />
            <Styled.Text>
              {bookingType === BookingType.Flight
                ? passengersFlightsText
                : passengersText}
            </Styled.Text>
            <Styled.Button $backgroundcolor={accentColor}>
              <SearchIcon size={16} />
            </Styled.Button>
          </Styled.InnerContainer>
        )}
        {showSearchSummary && !open && (
          <Styled.InnerContainerMobile onClick={handleSearchClick}>
            <Styled.MobileLocationText>
              {locationsText}
            </Styled.MobileLocationText>
            <Styled.MobileDatesText style={{ fontWeight: 400 }}>
              {dateText},
              {bookingType === BookingType.Flight
                ? passengersFlightsText
                : passengersText}
            </Styled.MobileDatesText>
          </Styled.InnerContainerMobile>
        )}
        <CallUsButton />

        {/* // TODO Authentication and show client info*/}
      </Styled.Container>
      {open && (
        <Styled.SearchContainer>
          <Styled.Test>
            <Search />
          </Styled.Test>
        </Styled.SearchContainer>
      )}
      {open && <Styled.GrayScreen onClick={() => setOpen(false)} />}
    </Fragment>
  );
}
