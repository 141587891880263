import { Way } from '@/services/codegen-romulo';
import { MapperTransportObject } from '../types/MapperTransportObject';
import { TransportType } from '../types/TransportType';

export const transportWayAdapter = (el: Way): MapperTransportObject => {
  return {
    segments: el.segments.map((segment) => ({
      isoZonedDepartureDateTime: segment.isoZonedDepartureDateTime,
      isoZonedArrivalDateTime: segment.isoZonedArrivalDateTime,
      departureId: segment.departureId,
      supplierId: segment.supplier.carrierId,
      transportType: segment.transportType as TransportType,
    })),
    isoZonedDepartureDateTime: el.isoZonedDepartureDateTime,
    departureId: el.departureId,
    isoZonedArrivalDateTime: el.isoZonedArrivalDateTime,
    arrivalId: el.arrivalId,
    transportType: TransportType.FLIGHT,
    durationInMinutes: el.duration,
    handBaggageIncluded: el.segments[0].handBaggageIncluded,
    baggageIncluded: el.segments[0].baggageIncluded,
  };
};
