import { FC } from 'react';
import { Room } from './Room/Room';
import * as Styled from './RoomDetail.styled';

export const RoomDetail: FC = () => {
  return (
    <Styled.ContentContainer>
      <Styled.SectionTitle>Select room and board type</Styled.SectionTitle>
      <Styled.Card>
        <Room />
        <Room />
      </Styled.Card>
    </Styled.ContentContainer>
  );
};
