import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 14px;
  margin: 8px 0px;
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  height: 16px;
`;

export const Dates = styled.span`
  line-height: 24px;
`;
