import { SortArrowIcon } from '@/components/LmnIcons/LmnIcons';
import { useTrack } from '@/hooks/useTrack';
import { useTranslate } from '@tolgee/react';
import * as Styled from './SortSelector.styled';

export function SortSelector({
  sortOptions,
  onChangeSortSelector,
  sortSelector,
}) {
  const track = useTrack();

  const { t } = useTranslate();

  return (
    <Styled.Container>
      {Object.keys(sortOptions).map((e, index) => {
        if (sortOptions[e].options) {
          const selectedSubOption =
            sortOptions[e].options.find(
              (so) => so.value === sortSelector.type
            ) || sortOptions[e].options[0];
          return (
            <Styled.Item
              key={`${e}_${index}_sort_option`}
              $selected={
                sortSelector.type === sortOptions[e].options[0].optionValue ||
                sortSelector.type === sortOptions[e].options[1].optionValue
              }
              onClick={() => {
                const { optionValue, ascending } = sortOptions[e].options.find(
                  (o) => o.optionValue !== sortSelector.type
                );

                track({
                  eventName: 'clicked.sort',
                  logLevel: 'info',
                  eventProperties: {
                    value: sortOptions[e].value,
                    ascending,
                    type: optionValue,
                  },
                });

                onChangeSortSelector({
                  value: sortOptions[e].value,
                  ascending,
                  type: optionValue,
                });
              }}
            >
              {`${sortOptions[e].label} (${selectedSubOption.label})`}
              <SortArrowIcon style={{ marginLeft: 8 }} />
            </Styled.Item>
          );
        } else {
          return (
            <Styled.Item
              key={`${e}_${index}_sort_option`}
              $selected={sortSelector.value === sortOptions[e].value}
              onClick={() => {
                const { value, ascending } = sortOptions[e];

                track({
                  eventName: 'clicked.sort',
                  logLevel: 'info',
                  eventProperties: {
                    value: sortOptions[e].value,
                    ascending,
                  },
                });
                onChangeSortSelector({ value, ascending });
              }}
            >
              {t(sortOptions[e].label)}
            </Styled.Item>
          );
        }
      })}
    </Styled.Container>
  );
}
