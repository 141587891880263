import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  @media (min-width: 767px) {
    display: none;
  }
`;

export const TransportCompanyContainer = styled.div`
  display: flex;
`;

export const TransportCompany = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
  color: #8c8c92;
`;

export const LogosContainer = styled.div`
  display: flex;

  & img:nth-of-type(1) {
    z-index: 1;
    background-color: white;
  }

  & img:nth-of-type(n + 2) {
    margin-left: -10px;
  }

  &:hover {
    transition: margin 0.2s ease-in-out 0s;
    & img:nth-of-type(n + 2) {
      margin-left: 4px;
    }
  }
`;
