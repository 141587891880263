import { FlightCard } from '@/components/FlightCard';
import { NofFound } from '@/components/NotFound';
import { Pagination } from '@/components/Pagination';
import { useCart } from '@/hooks/useCart';
import { useGetParam } from '@/hooks/useGetParam';
import { useTrack } from '@/hooks/useTrack';
import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import { useGetTransports } from '@/views/FlightsSearch/useGetTransports/useGetTransports';
import * as React from 'react';
import { Banner } from '../Banner';
import * as Styled from './FlightsList.styled';
import { Skeleton } from './Skeleton';
import { ModalFlight } from '../ModalFlight';
import { useTranslate } from '@tolgee/react';
import { TransportFlightOption } from '@/views/FlightsSearch/useGetTransports/types/TrasportFlightOption';
import { PriceProps } from '../TransportCard/Price/Price.types';

export function FlightsList({ isFreeFlight }: { isFreeFlight?: boolean }) {
  const [
    currentSearchId,
    getFlightsProposals,
    retrieveProporsals,
    currentPageNumber,
    onChangePageNumber,
    sortSelector,
    isFecthRetrieveProporsals,
    isFetchCart,
  ] = useStore((state) => [
    state.currentSearchId,
    state.getFlightsProposals,
    state.retrieveProporsals,
    state.currentPageNumber,
    state.onChangePageNumber,
    state.sortSelector,
    state.isFecthRetrieveProporsals,
    state.isFetchCart,
  ]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [modalData, setModalData] = React.useState<{
    price: string;
    tripDetails: TransportFlightOption['tripDetails'];
    alternativeId: string;
    fullPrice: number;
  }>();

  const getParam = useGetParam();

  const track = useTrack();

  const creartCart = useCart();

  const { t } = useTranslate();

  const searchId = currentSearchId ?? getParam('searchId');

  React.useEffect(() => {
    getFlightsProposals(searchId);
  }, [currentPageNumber, sortSelector, searchId]);

  React.useEffect(() => {
    if (!isFecthRetrieveProporsals) {
      track({
        eventName: 'opened.flights-results',
        logLevel: 'info',
        eventProperties: {
          number: retrieveProporsals?.proposals?.length || 0,
        },
      });
    }
  }, [isFecthRetrieveProporsals, retrieveProporsals]);

  const { getTransportProposals } = useGetTransports();

  const onModalButtonClick = async ({
    transportId,
    priceShown,
    initialPrice,
  }) => {
    track({
      eventName: 'clicked.flight-option',
      logLevel: 'info',
    });

    await creartCart(transportId, priceShown, initialPrice);
  };

  if (isFecthRetrieveProporsals) {
    return <Skeleton />;
  }

  if (
    isEmpty(retrieveProporsals) ||
    retrieveProporsals?.proposals?.length === 0
  ) {
    return <NofFound />;
  }

  const flights = getTransportProposals(retrieveProporsals);

  const onFlightCardClick = (item) => {
    setModalData(item);

    setIsModalOpen(true);

    track({
      eventName: 'viewed.flight-detail',
      logLevel: 'info',
    });
  };

  return (
    <Styled.Container>
      <Banner />
      {flights.map((item) => (
        <FlightCard
          key={item.alternativeId}
          inbound={item.inbound}
          outbound={item.outbound}
          price={{
            offerPrice: item.price,
          }}
          tripDetails={item.tripDetails}
          onFlightCardClick={() => onFlightCardClick(item)}
          isFreeFlight={isFreeFlight}
        />
      ))}
      {!!modalData && (
        <ModalFlight
          title={t('flight.trip_details')}
          isModalOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          tripDetails={modalData.tripDetails}
          isFreeFlight={isFreeFlight}
          price={modalData.price}
          isButtonLoading={isFetchCart}
          onModalButtonClick={() =>
            onModalButtonClick({
              transportId: modalData.alternativeId,
              priceShown: Math.floor(modalData.fullPrice),
              initialPrice: Math.round(modalData.fullPrice * 100),
            })
          }
        />
      )}
      <Styled.PaginationContainer>
        <Pagination
          count={retrieveProporsals.paging?.totalPages}
          shape="rounded"
          onChange={(_, page) => onChangePageNumber(page)}
          page={currentPageNumber}
        />
      </Styled.PaginationContainer>
    </Styled.Container>
  );
}
