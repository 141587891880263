import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';
export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const Pill = styled.div`
  padding: 8px 12px;
  background-color: rgb(245, 245, 245);
  border-radius: 21px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  width: fit-content;
  gap: 4px;
`;

export const TextField = styled(MuiTextField)`
  & div {
    &:focus-within {
      box-shadow: unset;
    }
  }
`;
