import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useStore } from '@/store/useStore';
import { FC } from 'react';
import { getProductsSummary } from '../../utils/getProductsSummary';
import { Extras } from './Extras/Extras';
import { Flights } from './Flights/Flights';
import { PriceDetails } from './PriceDetails/PriceDetails';
import { Products } from './Products/Products';
import * as Styled from './Summary.styled';
import { Timer } from './Timer/Timer';

interface SummaryProps {}
export const Summary: FC<SummaryProps> = () => {
  const [cartDetail, order] = useStore((state) => [
    state.cartDetail,
    state.order,
  ]);
  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  if (!cartDetail || !order) {
    return null;
  }

  const targetDate = cartDetail?.baseInfo?.expiresOnUTC;
  const productsSummary = getProductsSummary(cartDetail, order);

  return (
    <Styled.Container>
      <Products {...productsSummary} />
      <Flights />
      <Extras />
      {!isFreeFlight && <PriceDetails />}
      <Timer targetDate={targetDate} />
    </Styled.Container>
  );
};
