import { PersonIcon } from '@/components/LmnIcons/LmnIcons';
import { FC } from 'react';
import * as Styled from './Review.styled';

interface ContactProps {
  gender?: string;
  name: string;
  surname: string;
  email?: string;
  phone?: string;
  birthDate?: string;
}

export const Contact: FC<ContactProps> = ({
  gender,
  name,
  surname,
  email,
  phone,
  birthDate,
}) => {
  return (
    <Styled.Container>
      <PersonIcon size={20} />
      <div>
        <Styled.Title>
          {gender} {name} {surname}
        </Styled.Title>
        {email && <Styled.Detail>{email}</Styled.Detail>}
        {phone && <Styled.Detail>{phone}</Styled.Detail>}
        {birthDate && <Styled.Detail>{birthDate}</Styled.Detail>}
      </div>
    </Styled.Container>
  );
};
