import React, { ReactEventHandler } from 'react';
import { CdnImageTransformations } from './CdnImageUrlAdapter';
import { buildCloudinaryCdnImageUrlAdapter } from './CloudinaryCdnImageUrlAdapter';

type Props = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  loading?: 'eager' | 'lazy';
  transformations?: CdnImageTransformations;
  className?: string;
  onError?: ReactEventHandler<HTMLImageElement>;
};

export const CdnImage: React.FC<Props> = (props) => {
  const adapter = buildCloudinaryCdnImageUrlAdapter();

  return (
    <img
      className={props.className || ''}
      loading={props.loading}
      width={props.width}
      height={props.height}
      style={{ borderRadius: 4 }}
      src={
        adapter.adaptUrl({
          url: props.src,
          transformations: props.transformations || {},
        }).url
      }
      alt={props.alt}
      onError={props.onError}
    />
  );
};
