import { useTranslate } from '@tolgee/react';
import { Moon } from './Moon';
import * as Styled from './NightCounter.styled';
import { NightCounterProps } from './NightCounter.types';

export function NightCounter({ stayConfig }: NightCounterProps) {
  const { t } = useTranslate();

  const numberOfNightsText = `${stayConfig.checkOut?.diff(stayConfig.checkIn, 'days')} ${
    stayConfig.checkOut?.diff(stayConfig.checkIn, 'days') > 1
      ? t('hotel.common.nights')
      : t('hotel.common.nights')
  } · ${stayConfig.checkIn?.format('DD MMM')} - ${stayConfig.checkOut?.format('DD MMM')}`;

  return (
    <Styled.Container>
      <Styled.IconContainer>
        <Moon />
      </Styled.IconContainer>
      <Styled.Dates>{numberOfNightsText}</Styled.Dates>
    </Styled.Container>
  );
}
