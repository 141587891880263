import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import * as Styled from './BirthdayField.styled';

interface DateOfBirthFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  disabled?: boolean;
  passengerType: 'INFANT' | 'CHILD' | 'ADULT';
  bookingDate: Dayjs;
}

const DateOfBirthField: FC<DateOfBirthFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
  passengerType,
  bookingDate,
}) => {
  const { t } = useTranslate();

  const [maxNumberOfDays, setMaxNumberOfDays] = useState<number>(31);

  const [selectedDay, setSelectedDay] = useState<string>(undefined);
  const [selectedMonth, setSelectedMonth] = useState<string>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>(undefined);
  const [dateIsWrong, setDateIsWrong] = useState<
    'adultAge' | 'childAge' | 'infantAge' | 'monthDay'
  >(undefined);

  useEffect(() => {
    const newMaxDays = dayjs()
      .year(Number(selectedYear) || dayjs().year())
      .month((Number(selectedMonth) || 1) - 1)
      .endOf('month')
      .date();

    setMaxNumberOfDays(newMaxDays);
    if (Number(selectedDay) > newMaxDays) {
      setDateIsWrong('monthDay');
    } else {
      const selectedDate =
        selectedDay && selectedMonth && selectedYear
          ? dayjs(`${selectedYear}-${selectedMonth}-${selectedDay}`)
          : undefined;
      if (selectedDate && bookingDate) {
        const passengerAgeOnBookingDate = bookingDate.diff(
          selectedDate,
          'year',
          true
        );

        const infantNok =
          passengerType === 'INFANT' &&
          (passengerAgeOnBookingDate >= 2 || passengerAgeOnBookingDate < 0);

        const childNok =
          passengerType === 'CHILD' &&
          (passengerAgeOnBookingDate < 2 || passengerAgeOnBookingDate >= 12);

        const adultNok =
          passengerType === 'ADULT' &&
          passengerAgeOnBookingDate > 0 &&
          passengerAgeOnBookingDate < 12;

        infantNok && setDateIsWrong('infantAge');
        childNok && setDateIsWrong('childAge');
        adultNok && setDateIsWrong('adultAge');
        !infantNok && !childNok && !adultNok && setDateIsWrong(undefined);

        handleChange({
          target: {
            name: 'dateOfBirth',
            value: selectedDate,
          },
        });
        handleBlur({
          target: {
            name: 'dateOfBirth',
            value: selectedDate.format('YYYY-MM-DD'),
          },
        });
      }
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  const getErrorMsg = () => {
    switch (dateIsWrong) {
      case 'monthDay':
        return 'form.validation.birth_date_error';
      case 'infantAge':
        return 'form.validation.infant_age';
      case 'childAge':
        return 'form.validation.children_age';
      case 'adultAge':
        return 'form.validation.adults_age';
    }
  };

  return (
    <Styled.Container>
      <Styled.Title> {t('form.contact.birth_date')}</Styled.Title>
      <Styled.DateSelectorContainer>
        <TextField
          variant="filled"
          label={t('general.time.day')}
          name={'dateOfBirth_day'}
          error={hasError || !!dateIsWrong}
          onChange={(e) => setSelectedDay(e.target.value)}
          value={selectedDay}
          helperText={
            hasError &&
            t('form.validation.day', '', {
              last_month_day: `${maxNumberOfDays}`,
            })
          }
          sx={{
            minWidth: 'unset',
            flex: '1 1 0%',
          }}
          disabled={disabled}
        />
        <FormControl
          variant="filled"
          sx={{
            maxWidth: 'calc(48% - 16px)',
            flex: '2 1 0%',
            width: '100%',
          }}
        >
          <InputLabel id="birth-month-select-label">
            {t('general.time.month')}
          </InputLabel>
          <Styled.Select
            labelId="birth-month-select-label"
            variant="filled"
            name={'dateOfBirth_month'}
            label="dfgfd"
            error={hasError || !!dateIsWrong}
            onChange={(e) => setSelectedMonth(`${e.target.value}`)}
            value={selectedMonth}
            disabled={disabled}
          >
            {Array.from(Array(12).keys())
              .map((e) => ({
                label: dayjs(`-${e + 1}`, '-M').format('MMMM'),
                value: e + 1,
              }))
              .map((e) => (
                <MenuItem key={e.value} value={e.value}>
                  {e.label}
                </MenuItem>
              ))}
          </Styled.Select>
        </FormControl>
        <TextField
          variant="filled"
          label={t('general.time.year')}
          name={'dateOfBirth_year'}
          error={hasError || !!dateIsWrong}
          onChange={(e) => setSelectedYear(e.target.value)}
          value={selectedYear}
          helperText={
            hasError &&
            t('form.validation.day', '', {
              last_month_day: `${maxNumberOfDays}`,
            })
          }
          sx={{
            minWidth: 'unset',
            flex: '1 1 0%',
          }}
          disabled={disabled}
        />
      </Styled.DateSelectorContainer>
      {dateIsWrong && <Styled.ErrorMsg>{t(getErrorMsg())}</Styled.ErrorMsg>}
    </Styled.Container>
  );
};

export default DateOfBirthField;
