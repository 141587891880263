import { useGetParam } from '@/hooks/useGetParam';
import {
  PricingResponseDto,
  TransportResponseAdvanceUpdated,
} from '@/services/codegen-romulo';
import { useTranslate } from '@tolgee/react';
import { staticDataToAirports } from './utils/staticDataToAirports';
import { transportProposalDpToTransportOption } from './utils/transportProposalDpToTransportOption';
import { transportProposalMapperToFlightOption } from './utils/transportProposalToFlightOptionAdapter';
import { transportProposalToTransportOptionAdapter } from './utils/transportProposalToTransportOptionAdapter';
import {
  transportProposalToWayDetailsAdapter,
  transportProposalToWayDetails,
} from './utils/transportProposalToWayDetailsAdapter';
import { staticDataToAirlines } from './utils/staticDataToAirlines';

export function useGetTransports() {
  const { t } = useTranslate();

  const getParam = useGetParam();

  const adults = getParam('adults');
  const childrenAges = getParam('childrenAges');
  const language = 'it-IT';
  const userBudget = 999999;

  const getTransportProposals = (
    transportProposals: TransportResponseAdvanceUpdated
  ) => {
    const { airports, providers } = transportProposals.staticData;
    const currency = transportProposals.priceInfo.currency;
    const proposals = transportProposals?.proposals;

    return proposals?.map((flightProposal) =>
      transportProposalMapperToFlightOption({
        t,
        proposalFlight:
          transportProposalToTransportOptionAdapter(flightProposal),
        tripDetails: transportProposalToWayDetailsAdapter(
          flightProposal,
          airports
        ),
        adults: adults,
        childrenAges: childrenAges,
        userBudget,
        currency,
        language,
        airports,
        airlines: providers,
      })
    );
  };

  const getDPTransportProposal = (transportProposal: PricingResponseDto) => {
    const airports = staticDataToAirports(transportProposal?.staticData);

    const airlines = staticDataToAirlines(transportProposal?.staticData);

    const currency = transportProposal?.amountInfo?.currency;

    const proposal = transportProposal?.proposal;

    const fullPrice =
      transportProposal?.proposal?.rates?.[0]?.prices?.std?.fullPrice;

    return transportProposalMapperToFlightOption({
      t,
      proposalFlight: {
        ...transportProposalDpToTransportOption(proposal),
        fullPrice,
      },
      tripDetails: transportProposalToWayDetails(proposal, airports),
      adults: adults,
      childrenAges: childrenAges,
      userBudget,
      currency,
      language,
      airports,
      airlines: airlines,
    });
  };

  const getDPFlightsAlternativeProposals = (transportProposals) => {
    const airports = staticDataToAirports(transportProposals?.staticData);
    const airlines = staticDataToAirlines(transportProposals?.staticData);
    const currency = transportProposals?.amountInfo?.currency;
    const proposals = transportProposals?.transportProposals;

    return proposals?.map((flightProposal) =>
      transportProposalMapperToFlightOption({
        t,
        proposalFlight: transportProposalDpToTransportOption(flightProposal),
        tripDetails: transportProposalToWayDetails(flightProposal, airports),
        adults: adults,
        childrenAges: childrenAges,
        userBudget,
        currency,
        language,
        airports,
        airlines: airlines,
      })
    );
  };

  return {
    getTransportProposals,
    getDPTransportProposal,
    getDPFlightsAlternativeProposals,
  };
}
