import { Skeleton } from '../../Skeleton';
import { useGetFiltersData } from '../../hooks/useGetFiltersData';
import * as Styled from './MealPlansFilters.styled';
import { isEmpty } from '@/utils/isEmpty';
import { MealPlans } from './MealPlans';
import { useEffect, useState } from 'react';
import {
  AllInclusiveIcon,
  AnyBoardIcon,
  BreakFastIcon,
  FullBoardIcon,
  HalfBoardIcon,
  NoMealIcon,
} from '@/components/LmnIcons/LmnIcons';

export interface MealPlanFullInfo {
  active: boolean;
  code: string;
  label: string;
  metadata: any;
  selected: boolean;
  name: string;
  icon: React.JSX.Element;
}

const allMealPlansOrdered = [
  {
    code: 'ALL_MEAL_PLANS',
    icon: <AnyBoardIcon />,
    name: 'filter.meal_plan.all',
  },
  {
    code: 'ACCOMODATION_ONLY',
    icon: <NoMealIcon />,
    name: 'filter.meal_plan.room_only',
  },
  {
    code: 'ACCOMODATION_AND_BREAKFAST',
    icon: <BreakFastIcon />,
    name: 'filter.meal_plan.breakfast',
  },
  {
    code: 'HALF_BOARD',
    icon: <HalfBoardIcon />,
    name: 'filter.meal_plan.half_board',
  },
  {
    code: 'UNKNOWN',
    icon: <FullBoardIcon />,
    name: 'filter.meal_plan.full_board',
  },
  {
    code: 'ALL_INCLUSIVE',
    icon: <AllInclusiveIcon />,
    name: 'filter.meal_plan.all_inclusive',
  },
];

export function MealPlansFilters() {
  const {
    hdpStaticData,
    isFecthRetrieveProporsals,
    searchId,
    getHDPProposals,
    retrieveProporsals,
    hdpFilters,
  } = useGetFiltersData();

  const { mealPlans } = hdpStaticData;

  const mealPlansFullInfoState = useState<MealPlanFullInfo[]>([]);

  const [_, setMealPlansFullInfo] = mealPlansFullInfoState;

  useEffect(() => {
    initializeMealPlans();
  }, []);

  const initializeMealPlans = () => {
    const mealPlanFullInfo = allMealPlansOrdered.map((plan) => {
      const currentDataInfo = mealPlans.find((d) => d.code === plan.code);
      if (currentDataInfo) {
        return {
          ...currentDataInfo,
          selected: false,
          name: plan.name,
          icon: plan.icon,
        };
      }

      const isAllMealPlans = plan.code === 'ALL_MEAL_PLANS';

      return {
        active: isAllMealPlans,
        code: plan.code,
        label: '',
        metadata: {
          count: isAllMealPlans
            ? retrieveProporsals.facets.filteredProposals
            : 0,
        },
        selected: isAllMealPlans,
        name: plan.name,
        icon: plan.icon,
      };
    });

    if (hdpFilters?.mealPlan?.length > 0) {
      const filtersAppliedFromStaticData = mealPlans
        .map((plan) => {
          return hdpFilters.mealPlan.find((filter) => filter === plan.code);
        })
        .filter((item) => item);

      const allFiltersSelected =
        filtersAppliedFromStaticData.length === mealPlans.length;

      const mealPlanFullInfoFiltered = mealPlanFullInfo.map((plan) => {
        if (plan.code === 'ALL_MEAL_PLANS') {
          if (allFiltersSelected) {
            return {
              ...plan,
              selected: true,
            };
          } else {
            return {
              ...plan,
              selected: false,
            };
          }
        } else {
          if (allFiltersSelected) {
            return {
              ...plan,
              selected: false,
            };
          } else {
            return {
              ...plan,
              selected: hdpFilters.mealPlan.find((meal) => meal === plan.code)
                ? true
                : false,
            };
          }
        }
      });

      setMealPlansFullInfo(mealPlanFullInfoFiltered);
    } else {
      setMealPlansFullInfo(mealPlanFullInfo);
    }
  };

  const onMealPlanHandle = (mealPlansUpdated) => {
    getHDPProposals(searchId, [{ mealPlan: mealPlansUpdated }]);
  };

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <MealPlans
          mealPlansFullInfoState={mealPlansFullInfoState}
          onMealPlanHandle={onMealPlanHandle}
        />
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
