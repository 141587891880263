export const getWaysNumber = (
  outboundReservationId: string,
  inboundReservationId: string
) => {
  if (outboundReservationId && inboundReservationId) {
    if (outboundReservationId === inboundReservationId) {
      return 2;
    } else {
      return 1;
    }
  } else {
    return 1;
  }
};

