import { useTranslate } from '@tolgee/react';
import { Divider } from 'antd';
import { LuggageDetailsProps } from '../LuggageDetails.types';
import * as Styled from './LuggageTooltip.styled';

export function LuggageTooltip({
  carryOnIncluded,
  checkedBaggageIncluded,
}: LuggageDetailsProps) {
  const { t } = useTranslate();

  const labels = {
    checkedBaggageIncluded: t('flight.luggage.checked_luggage_included'),
    carryOnIncluded: t('flight.luggage.carry_on_included'),
    backpack:
      checkedBaggageIncluded || carryOnIncluded
        ? t('flight.luggage.small_bag_included')
        : t('flight.luggage.only_small_bag_included'),
  };

  return (
    <Styled.Container>
      {checkedBaggageIncluded && (
        <>
          <Styled.Text>{t(labels.checkedBaggageIncluded)}</Styled.Text>
          <Divider style={{ borderColor: 'white', margin: 0 }} dashed />
        </>
      )}
      {carryOnIncluded && (
        <>
          <Styled.Text>{t(labels.carryOnIncluded)}</Styled.Text>
          <Divider style={{ borderColor: 'white', margin: 0 }} dashed />
        </>
      )}
      <Styled.Text>{t(labels.backpack)}</Styled.Text>
    </Styled.Container>
  );
}
