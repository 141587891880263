import dayjs from 'dayjs';
import { useTranslate } from '@tolgee/react';
import { getHotelDetail } from './getHotelDetail';
import { useGetParamsInfo } from '../useGetParamsInfo';
import { useStoreHotelDetail } from '@/store/useStoreHotelDetail';

export function useGetHotelInfo() {
  const [retrievePricingProporsals] = useStoreHotelDetail((state) => [
    state.retrievePricingProporsals,
  ]);

  const { t } = useTranslate();

  const { outboundDate, returnDate } = useGetParamsInfo();

  return getHotelDetail(
    retrievePricingProporsals?.staticData,
    dayjs(returnDate),
    dayjs(outboundDate),
    t
  );
}
