import { Header } from '@/components/Header';
import { HotelList } from '@/components/HotelList';
import { Map } from '@/components/Map';
import { TransportCard } from '@/components/TransportCard';
import { useGetParam } from '@/hooks/useGetParam';
import { useStore } from '@/store/useStore';
import { isEmpty } from '@/utils/isEmpty';
import * as React from 'react';
import { staticDataToAirlines } from '../FlightsSearch/useGetTransports/utils/staticDataToAirlines';
import dayjs from 'dayjs';
import { staticDataToAirports } from '../FlightsSearch/useGetTransports/utils/staticDataToAirports';
import * as Styled from './HotelsFlightsSearch.styled';
import { SortSelector } from '@/components/SortSelector';
import { sortOptions } from './utils';
import { DPFiltersVerticalContainer } from '@/components/Filters/DP/DPFiltersVerticalContainer';
import { TotalItems } from '@/components/Filters/TotalItems';
import { MapToggle } from '@/components/Filters/MapToggle';
import { LmnFooter } from '@/components/LmnFooter';
import { DPFiltersHorizontalContainer } from '@/components/Filters/DP/DPFiltersHorizontalContainer';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Modal } from '@/components/Modal';
import { useTranslate } from '@tolgee/react';

export function HotelsFlightsSearch() {
  const [
    getHDPProposals,
    currentSearchId,
    retrieveProporsals,
    currentPageNumber,
    sortSelector,
    onChangeSortSelector,
  ] = useStore((state) => [
    state.getHDPProposals,
    state.currentSearchId,
    state.retrieveProporsals,
    state.currentPageNumber,
    state.sortSelector,
    state.onChangeSortSelector,
  ]);

  const [showMap, setShowMap] = React.useState<boolean>(true);
  const fullMapState = React.useState<boolean>(false);
  const [fullMap, setFullMap] = fullMapState;

  const [isFiltersModalOpen, setIsFiltersModalOpen] =
    React.useState<boolean>(false);
  const [isSortingModalOpen, setIsSortingModalOpen] =
    React.useState<boolean>(false);

  const { isMobile, isTablet } = useIsMobile();

  React.useEffect(() => {
    isTablet && setShowMap(true);
    isTablet && setFullMap(false);
  }, [isTablet]);

  const getParam = useGetParam();
  const { t } = useTranslate();

  const searchId = currentSearchId ?? getParam('searchId');

  const destinationId = getParam('destinationId');

  React.useEffect(() => {
    getHDPProposals(searchId);
  }, [currentPageNumber, sortSelector]);

  if (isEmpty(retrieveProporsals)) {
    return null;
  }

  const staticData = retrieveProporsals.staticData;

  const destination = staticData?.cities?.find(
    (e) => e.id === Number(destinationId)
  );

  const accomodation = retrieveProporsals?.search?.proposals[0]?.accommodation;

  const numberOfNights = dayjs(accomodation?.checkout)?.diff(
    accomodation?.checkin,
    'days'
  );

  const airports = staticDataToAirports(staticData);

  const airlines = staticDataToAirlines(staticData);

  const transport = retrieveProporsals.search?.transports?.[0];

  return (
    <Styled.Container>
      <Header showSearchSummary />

      {showMap ? (
        <Styled.DualContainer>
          <Styled.HorizontalFiltersContainer>
            {isTablet ? (
              <Styled.TabletHorizontalContainer>
                <Styled.TabletHorizontalButton
                  onClick={() => setIsSortingModalOpen(true)}
                >
                  {t('filter.sort')}
                </Styled.TabletHorizontalButton>
                <Styled.TabletHorizontalButton
                  onClick={() => setIsFiltersModalOpen(true)}
                >
                  {t('filter.all')}
                </Styled.TabletHorizontalButton>
                <Styled.TabletHorizontalButton
                  onClick={() => setFullMap(!fullMap)}
                >
                  {fullMap ? t('search_result.list') : t('search_result.map')}
                </Styled.TabletHorizontalButton>
              </Styled.TabletHorizontalContainer>
            ) : (
              <DPFiltersHorizontalContainer
                closeMap={() => setShowMap(false)}
              />
            )}
          </Styled.HorizontalFiltersContainer>
          <Styled.HotelResultsContainer>
            {!fullMap && (
              <Styled.ResultsDualContainer $isTablet={isTablet}>
                <Styled.TransportContainer>
                  <TransportCard
                    transport={transport}
                    airports={airports}
                    airlines={airlines}
                    numberOfNights={numberOfNights}
                    destination={destination?.label}
                  />
                </Styled.TransportContainer>
                <Styled.SortSelectorContainer>
                  <SortSelector
                    sortOptions={sortOptions}
                    onChangeSortSelector={onChangeSortSelector}
                    sortSelector={sortSelector}
                  />
                </Styled.SortSelectorContainer>
                <HotelList />
                <LmnFooter />
              </Styled.ResultsDualContainer>
            )}
            {(!isTablet || (isTablet && fullMap)) && (
              <Styled.MapContainer $fullWidth={fullMap}>
                <Map fullMapState={fullMapState} />
              </Styled.MapContainer>
            )}
          </Styled.HotelResultsContainer>
          <Modal
            title={`${t('filter.sort.by')}:`}
            isModalOpen={isSortingModalOpen}
            onCloseModal={() => setIsSortingModalOpen(false)}
          >
            <SortSelector
              sortOptions={sortOptions}
              onChangeSortSelector={onChangeSortSelector}
              sortSelector={sortSelector}
            />
          </Modal>
          <Modal
            title={t('filter.name.plural')}
            isModalOpen={isFiltersModalOpen}
            onCloseModal={() => setIsFiltersModalOpen(false)}
          >
            <DPFiltersVerticalContainer />
          </Modal>
        </Styled.DualContainer>
      ) : (
        <>
          <Styled.SimpleContainer>
            <Styled.VerticalFiltersContainer>
              <MapToggle action="open" onToggle={() => setShowMap(true)} />
              <TotalItems />
              <DPFiltersVerticalContainer />
            </Styled.VerticalFiltersContainer>
            <Styled.ResultsContainer>
              <Styled.TransportContainer>
                <TransportCard
                  transport={transport}
                  airports={airports}
                  airlines={airlines}
                  numberOfNights={numberOfNights}
                  destination={destination?.label}
                />
              </Styled.TransportContainer>
              <Styled.SortSelectorContainer>
                <SortSelector
                  sortOptions={sortOptions}
                  onChangeSortSelector={onChangeSortSelector}
                  sortSelector={sortSelector}
                />
              </Styled.SortSelectorContainer>
              <HotelList />
            </Styled.ResultsContainer>
          </Styled.SimpleContainer>
          <LmnFooter />
        </>
      )}
    </Styled.Container>
  );
}
