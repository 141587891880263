import { calcNightStays, getHours } from '@/utils/timeUtils';
import { enrichWithTechnicalStops } from '../../utils/enrichWithTechnicalStops';
import { oneDayDifferenceKey } from '../../utils/oneDayDifferenceKey';
import { tripBaggageAdapter } from '../../utils/tripBaggageAdapter';
import {
  JourneyContractLocations,
  JourneyContractSegmentTransportType,
  JourneyContractWay,
  JourneyContractWayPrice,
} from './JourneyContract';
import { Segment, TripBaggageAdapted } from './Segment';
import { WAY_TRANSPORT_TYPES } from './WayTransporTypes';

export const wayFactory = (wayFromContract: JourneyContractWay): Way => {
  const baggage = tripBaggageAdapter.adapt(wayFromContract.baggage);
  const segmentsWithTechStops = enrichWithTechnicalStops(
    wayFromContract.segments
  );

  const segments = segmentsWithTechStops.map((segment, index) => {
    const isFirstSegment = index === 0;
    const isLastSegment = index === segmentsWithTechStops.length - 1;
    const differentDayArrival = oneDayDifferenceKey(
      segment.departure.datetime,
      segment.arrival.datetime
    );
    const differentDayDeparture = isFirstSegment
      ? undefined
      : oneDayDifferenceKey(
          segmentsWithTechStops[index - 1].arrival.datetime,
          segmentsWithTechStops[index].departure.datetime
        );

    return new Segment({
      ...segment,
      baggage,
      isFirstSegment,
      isLastSegment,
      differentDeparture: false,
      differentArrival: false,
      differentDayArrival,
      differentDayDeparture,
    });
  });

  return new Way({
    segments,
    baggage,
    differentDepartureDate: wayFromContract.differentDepartureDate || false,
    durationInMinutes: wayFromContract.durationInMinutes,
    locations: wayFromContract.locations,
    seats: wayFromContract.seats,
    selected: wayFromContract.selected,
    onSelectCallback: wayFromContract.onSelected,
    priceInformation: wayFromContract.priceInformation,
  });
};

type WayProps = {
  segments: Array<Segment>;
  baggage: TripBaggageAdapted;
  differentDepartureDate: boolean;
  durationInMinutes?: number;
  locations: JourneyContractLocations;
  seats?: number;
  selected: boolean;
  onSelectCallback?: ((checked: boolean) => void) | undefined;
  priceInformation?: JourneyContractWayPrice;
};

// to be used in all the trip card components, but not everywhere (not in the trip-card independent and reusable components)
/** to instantiate with its own factory `wayFactory` */
export class Way {
  public readonly segments: Array<Segment>;
  private readonly baggage: TripBaggageAdapted;
  private readonly differentDepartureDate: boolean;
  private readonly durationInMinutes?: number;
  private readonly locations: JourneyContractLocations;
  private readonly seats?: number;
  private readonly selected: boolean;
  public readonly onSelectCallback: ((checked: boolean) => void) | undefined;
  private readonly priceInformation?: JourneyContractWayPrice;

  constructor(props: WayProps) {
    this.segments = props.segments;
    this.selected = props.selected;
    this.durationInMinutes = props.durationInMinutes;
    this.seats = props.seats;
    this.baggage = props.baggage;
    this.onSelectCallback = props.onSelectCallback;
    this.locations = props.locations;
    this.priceInformation = props.priceInformation;
    this.differentDepartureDate = props.differentDepartureDate;
  }

  isFlightOnly(): boolean {
    return this.segments.every(isFlightPredicate);
  }

  isTrainOnly(): boolean {
    return this.segments.every(isTrainPredicate);
  }

  isBusOnly(): boolean {
    return this.segments.every(isBusPredicate);
  }

  isMixed(): boolean {
    return !this.isFlightOnly() && !this.isTrainOnly() && !this.isBusOnly();
  }

  isDepartingOnDifferentDate(): boolean {
    return this.differentDepartureDate;
  }

  isDirect(): boolean {
    return this.segments.length === 1;
  }

  isOvernight(): boolean {
    const arrivalHours = getHours(this.getArrivalDate());
    const isNightFlight = arrivalHours >= 5 && arrivalHours <= 10;
    const arriveOnAnotherDay =
      calcNightStays(this.getDepartureDate(), this.getArrivalDate()) > 0;

    return this.isDirect() && arriveOnAnotherDay && isNightFlight;
  }

  getType(): WAY_TRANSPORT_TYPES {
    if (this.isFlightOnly()) return WAY_TRANSPORT_TYPES.FLIGHT;
    if (this.isTrainOnly()) return WAY_TRANSPORT_TYPES.TRAIN;
    if (this.isBusOnly()) return WAY_TRANSPORT_TYPES.BUS;

    return WAY_TRANSPORT_TYPES.MIXED;
  }

  getAllSegmentTypes(): WAY_TRANSPORT_TYPES[] {
    const contractToDomainMap = {
      [JourneyContractSegmentTransportType.TRAIN]: WAY_TRANSPORT_TYPES.TRAIN,
      [JourneyContractSegmentTransportType.FLIGHT]: WAY_TRANSPORT_TYPES.FLIGHT,
      [JourneyContractSegmentTransportType.BUS]: WAY_TRANSPORT_TYPES.BUS,
    };

    const uniqueTransportTypes = Array.from(
      new Set(this.segments.map((s) => s.getTransportType()))
    );
    return uniqueTransportTypes.map(
      (contractType) => contractToDomainMap[contractType]
    );
  }

  getLocations(): JourneyContractLocations {
    return this.locations;
  }

  getId(): string {
    return `${this.segments[0].getDeparture().datetime}-${this.segments[0].getArrival().datetime}-${
      this.segments[0].getDeparture().hub.id
    }-${this.segments[0].getArrival().hub.id}`;
  }

  // warn: returning Date object will change offset to browser offset instead of (implicit) airport offset
  getDepartureDate(): string {
    return this.segments[0].getDeparture().datetime;
  }

  getDepartureIATACode(): string {
    return this.segments[0].getDeparture().hub.id;
  }

  getDepartureLocation(): string {
    return this.segments[0].getDeparture().hub.name;
  }

  getDepartureTransportType(): JourneyContractSegmentTransportType {
    return this.segments[0].getTransportType();
  }

  // warn: returning Date object will change offset to browser offset instead of (implicit) airport offset
  getArrivalDate(): string {
    return this.segments[this.segments.length - 1].getArrival().datetime;
  }

  getArrivalIATACode(): string {
    return this.segments[this.segments.length - 1].getArrival().hub.id;
  }

  getArrivalLocation(): string {
    return this.segments[this.segments.length - 1].getArrival().hub.name;
  }

  getArrivalTransportType(): JourneyContractSegmentTransportType {
    return this.segments[this.segments.length - 1].getTransportType();
  }

  isSelected(): boolean {
    return this.selected;
  }

  getDurationMinutes(): number | undefined {
    return this.durationInMinutes;
  }

  getSeats(): number | undefined {
    return this.seats && this.seats > 9 ? 9 : this.seats;
  }

  getBaggage(): TripBaggageAdapted {
    return this.baggage;
  }

  getSegments(): Array<Segment> {
    return this.segments;
  }

  getPriceInformation(): JourneyContractWayPrice | undefined {
    return this.priceInformation;
  }
}

const isFlightPredicate = (currentSegment: Segment) =>
  currentSegment.getTransport().type ===
  JourneyContractSegmentTransportType.FLIGHT;

const isTrainPredicate = (currentSegment: Segment) =>
  currentSegment.getTransport().type ===
  JourneyContractSegmentTransportType.TRAIN;

const isBusPredicate = (currentSegment: Segment) =>
  currentSegment.getTransport().type ===
  JourneyContractSegmentTransportType.BUS;
