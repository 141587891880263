import { useTranslate } from '@tolgee/react';
import { theme } from 'antd';
import * as Styled from './LastMinuteDealTag.styled';

export function LastminuteDealTag() {
  const { token } = theme.useToken();

  const { t } = useTranslate();

  return (
    <Styled.LastMinuteLabel style={{ backgroundColor: token.colorPrimary }}>
      {t('partner.lastminute.deal.badge')}
    </Styled.LastMinuteLabel>
  );
}
