import { useStore } from '@/store/useStore';
import { useGetFiltersData } from '../hooks/useGetFiltersData';
import * as Styled from './ResetFilters.styled';
import { useTranslate } from '@tolgee/react';

export function ResetFilters({ disableResetButton }) {
  const { t } = useTranslate();

  const { resetAllHDPFilters } = useGetFiltersData();

  const [brandConfig] = useStore((state) => [state.brandConfig]);

  const { accentColor, primaryColorBgTextColor } = brandConfig.theme;

  const onResetFilters = () => {
    resetAllHDPFilters();
  };

  return (
    <Styled.Container>
      <Styled.FiltersContainer>
        <Styled.ResetFiltersButton
          variant="contained"
          onClick={onResetFilters}
          disableElevation
          disableRipple
          fullWidth
          sx={{
            backgroundColor: accentColor,
            borderColor: accentColor,
            color: primaryColorBgTextColor,
          }}
          disabled={disableResetButton}
        >
          {t('filter.reset')}
        </Styled.ResetFiltersButton>
      </Styled.FiltersContainer>
    </Styled.Container>
  );
}
