import { Rating } from '@/components/HotelCard/Rating';
import PlaceIcon from '@mui/icons-material/Place';
import { getFacilityIcon } from '../../../utils/dictionaries/getFacilityIcon';
import * as Styled from './HotelSummary.styled';
import { useGetHotelInfo } from './useGetHotelInfo';
import { useTranslate } from '@tolgee/react';

export function HotelSummary() {
  const { t } = useTranslate();

  const hotelInfo = useGetHotelInfo();

  return (
    <Styled.ColumnsContainer style={{ backgroundColor: 'white' }}>
      <Styled.ContentContainer>
        <>
          <Styled.HotelName>{hotelInfo.name}</Styled.HotelName>
          <Styled.HotelDetailContainer>
            <Rating stars={hotelInfo.stars} />
            <Styled.Divider />
            <PlaceIcon sx={{ fontSize: 16, marginRight: '8px' }} />
            <Styled.HotelDirection>
              {hotelInfo.location.direction}
            </Styled.HotelDirection>
          </Styled.HotelDetailContainer>
        </>
        <Styled.ImageCarouselContainer>
          <Styled.MainImage src={hotelInfo.images[0]} />
          {hotelInfo.images.slice(1, 4).map((image) => (
            <Styled.CarouselImage src={image} />
          ))}
          <Styled.LastImage src={hotelInfo.images[5]} />
        </Styled.ImageCarouselContainer>
        <Styled.HotelDetailsContainer>
          <Styled.AmenitiesContainer>
            <Styled.AmenetiesTitle>
              {t('hotel.information_and_facilities')}
            </Styled.AmenetiesTitle>
            <Styled.AmenitiesList>
              {hotelInfo?.facilities?.slice(0, 5)?.map((facility) => (
                <Styled.Amenity>
                  {getFacilityIcon(facility.id)}
                  {facility.label}
                </Styled.Amenity>
              ))}
              {hotelInfo?.facilities?.length > 3 && (
                <Styled.ViewAllAmenities>
                  {t('checkout.summary.hotel.view_facilities')}
                </Styled.ViewAllAmenities>
              )}
            </Styled.AmenitiesList>
          </Styled.AmenitiesContainer>
          <Styled.StayContainer>
            <Styled.CheckInInfoContainer>
              <Styled.CheckInContainer>
                <Styled.CheckInTitle>
                  {t('general.check_in')}
                </Styled.CheckInTitle>
                <Styled.CheckInContent>
                  {hotelInfo.checkIn.date}
                  {hotelInfo.checkIn.hour && `${hotelInfo.checkIn.hour}`}
                </Styled.CheckInContent>
              </Styled.CheckInContainer>
              <Styled.CheckOutContainer>
                <Styled.CheckInTitle>
                  {t('general.check_out')}
                </Styled.CheckInTitle>
                <Styled.CheckInContent>
                  {hotelInfo.checkOut.date}
                  {hotelInfo.checkOut.hour && `${hotelInfo.checkOut.hour}`}
                </Styled.CheckInContent>
              </Styled.CheckOutContainer>
            </Styled.CheckInInfoContainer>
            {/* TODO REVIEW IF WE HAVE ENOUGH INFO TO SHOW THIS BLOCK */}
            {/* <Styled.CheckInInfoContainer>
              <Styled.CheckOutContainer>
                <Styled.CheckInContent>
                  <PlaceIcon sx={{ fontSize: 20, marginRight: '8px' }} />
                  1.24 mi from the centre of Milan
                </Styled.CheckInContent>
                <Styled.CheckInContent>
                  {' '}
                  <PlaneNEIcon size={20} style={{ marginRight: '8px' }} />
                  4.82 mi from the airport
                </Styled.CheckInContent>
              </Styled.CheckOutContainer>
            </Styled.CheckInInfoContainer> */}
          </Styled.StayContainer>
        </Styled.HotelDetailsContainer>
      </Styled.ContentContainer>
    </Styled.ColumnsContainer>
  );
}
