import * as Styled from '../PriorityBoarding.styled';

export const DetailRow: React.FC<{
  title: string;
  subtitle: string;
  detail?: string;
  url: string;
}> = ({ title, subtitle, detail, url }) => {
  return (
    <Styled.DetailRowContainer>
      <Styled.DetailRowTextContainer>
        <Styled.MainTextRow>{title}</Styled.MainTextRow>
        <Styled.DetailTextRow>{subtitle}</Styled.DetailTextRow>
        <Styled.DetailTextRow>{detail}</Styled.DetailTextRow>
      </Styled.DetailRowTextContainer>
      <img src={url} style={{ height: '56px' }} />
    </Styled.DetailRowContainer>
  );
};
