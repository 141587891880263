import * as Styled from './SectionCard.styled';

const SectionCard = ({
  title,
  children,
}: {
  title?: string;
  children: React.ReactElement;
}) => {
  return (
    <Styled.Container>
      {title && <Styled.Title>{title}</Styled.Title>}
      {children}
    </Styled.Container>
  );
};

export { SectionCard };
