import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import * as Styled from './FlightDetail.styled';
import { FLightsDrawer } from './FlightsDrawer';
import { useGetFlightInfo } from './useGeFlightInfo';
import { useGetFiltersData } from './FlightsDrawer/useGetFiltersData';
import { FlightCard } from '@/components/FlightCard';
import { ModalFlight } from '@/components/ModalFlight';

export function FlightDetail() {
  const [changeFlightOpen, seChangeFlightOpen] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { t } = useTranslate();

  const flightInfo = useGetFlightInfo();

  const filter = useGetFiltersData();

  return (
    <Styled.ContentContainer>
      <Styled.SectionTitle>{t('flight.included.title')}</Styled.SectionTitle>
      <Styled.Card>
        <FlightCard
          key={flightInfo.alternativeId}
          inbound={flightInfo.inbound}
          outbound={flightInfo.outbound}
          tripDetails={flightInfo.tripDetails}
          disabledElevation
        />
        <Styled.ButtonsContainer>
          <Styled.CardButton onClick={() => setIsModalOpen(true)}>
            {t('flight.trip_details')}
          </Styled.CardButton>
          <Styled.CardButton onClick={() => seChangeFlightOpen(true)}>
            {t('general.actions.change')}
          </Styled.CardButton>
        </Styled.ButtonsContainer>
      </Styled.Card>
      <ModalFlight
        title={t('flight.trip_details')}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        tripDetails={flightInfo.tripDetails}
        price={flightInfo.price}
        destination={flightInfo.destination.label}
        amountNights={flightInfo.amountNights}
        disabledFooter
      />
      <FLightsDrawer
        open={changeFlightOpen}
        onClose={() => seChangeFlightOpen(false)}
        selectedFlight={flightInfo}
      />
    </Styled.ContentContainer>
  );
}
