import { FullTransportLogos } from '@/components/TransportCard/FullTransportLogos/FullTransportLogos';
import { TransportType } from '@/components/TransportCard/FullTransportLogos/TransportLogo';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from '../CheckedLuggage.styled';
import { AllLuggagesStatus } from './LuggageStatus';

export const ModalContent: FC<{
  luggagesByTraveller: {
    luggages: {
      luggageOptions: any;
      luggageCommunications: any;
    }[];
    travellerType: string;
    travellerId: number;
    travellerFullName: string;
    selectedLuggages: any;
  }[];
}> = ({ luggagesByTraveller }) => {
  const [cartDetail] = useStore((state) => [state.cartDetail]);
  const { t } = useTranslate();

  const flightInfo = cartDetail?.products?.find((e) => e.type === 'FLIGHT');

  const outboundSegments =
    flightInfo.detail.itinerary.outboundTransport[0]?.segments;
  const returnSegments =
    flightInfo.detail.itinerary?.inboundTransport[0]?.segments;

  const isSameReservationId =
    luggagesByTraveller?.find((e) => e.travellerType === 'ADULT')?.luggages
      ?.length === 1
      ? 0
      : undefined;

  return (
    <Styled.Container>
      {outboundSegments.map((segment, key) => {
        const company = {
          code: segment.company.id,
          type: TransportType.FLIGHT,
          name: segment.company.name,
        };
        return (
          <Styled.SegmentContainer>
            <Styled.FlightRow>
              <Styled.LogoColumn>
                <FullTransportLogos companies={[company]} />
              </Styled.LogoColumn>
              <Styled.DirectionColumn>
                <Styled.Section>
                  {t('general.trip.outbound.flight')}
                </Styled.Section>
                <Styled.FlightDetail>
                  {`${segment.departureStation.cityName} - ${segment.destinationStation.cityName} • ${segment.company.name}`}
                </Styled.FlightDetail>
              </Styled.DirectionColumn>
            </Styled.FlightRow>
            <AllLuggagesStatus
              luggagesByTraveller={luggagesByTraveller}
              direction={isSameReservationId ?? 0}
            />
          </Styled.SegmentContainer>
        );
      })}
      {returnSegments && <Styled.Divider />}
      {returnSegments?.map((segment, key) => {
        const company = {
          code: segment.company.id,
          type: TransportType.FLIGHT,
          name: segment.company.name,
        };
        return (
          <Styled.SegmentContainer>
            <Styled.FlightRow>
              <Styled.LogoColumn>
                <FullTransportLogos companies={[company]} />
              </Styled.LogoColumn>
              <Styled.DirectionColumn>
                <Styled.Section>
                  {t('form.product.luggage.modal.return_flight')}
                </Styled.Section>
                <Styled.FlightDetail>
                  {`${segment.departureStation.cityName} - ${segment.destinationStation.cityName} • ${segment.company.name}`}
                </Styled.FlightDetail>
              </Styled.DirectionColumn>
            </Styled.FlightRow>
            <AllLuggagesStatus
              luggagesByTraveller={luggagesByTraveller}
              direction={isSameReservationId ?? 1}
            />
          </Styled.SegmentContainer>
        );
      })}
    </Styled.Container>
  );
};
