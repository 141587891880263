import { TransportProposal } from '@/services/codegen-romulo';
import { TransportOption } from '../types/TransportOption';
import { dpWayAdapter } from './dpWayAdapter';

export const transportProposalDpToTransportOption = (
  el: TransportProposal
): TransportOption => ({
  id: el.id,
  outbound: dpWayAdapter(el.transport.outbound),
  inbound: dpWayAdapter(el.transport.inbound),
  fullPrice: el?.rate?.prices?.std?.fullPrice,
});
