import * as React from 'react';
import * as Styled from './FlightsDrawer.styled';
import { SortSelector } from '@/components/SortSelector';
import { FlightList } from './FlightList/FlightList';
import { sortOptions } from '../utils/sortOptions';
import { useStoreHotelDetail } from '@/store/useStoreHotelDetail';

export function FLightsDrawer({ open, onClose, selectedFlight }) {
  const [
    onChangeSortSelector,
    sortSelector,
    currentPageNumber,
    getFlightsAlternativeProposals,
    pricingId,
  ] = useStoreHotelDetail((state) => [
    state.onChangeSortSelector,
    state.sortSelector,
    state.currentPageNumber,
    state.getFlightsAlternativeProposals,
    state.pricingId,
  ]);

  React.useEffect(() => {
    getFlightsAlternativeProposals(pricingId);
  }, [currentPageNumber, sortSelector]);

  return (
    <Styled.Drawer anchor={'right'} open={open} onClose={onClose} elevation={0}>
      <Styled.DrawerContent>
        <Styled.SortSelectorContainer>
          <SortSelector
            sortOptions={sortOptions}
            onChangeSortSelector={onChangeSortSelector}
            sortSelector={sortSelector}
          />
        </Styled.SortSelectorContainer>
        <FlightList selectedFlight={selectedFlight} />
      </Styled.DrawerContent>
    </Styled.Drawer>
  );
}
