import * as React from 'react';
import { useStore } from '@/store/useStore';
import { useBookingType } from '@/hooks/useBookingType';
import { useGetParam } from '@/hooks/useGetParam';
import { getHotelData } from './utils/getHotelData';
import { Proposal } from '@/services/codegen-romulo';
import dayjs from 'dayjs';
import CurrencyFormatter from '@/utils/currencyFormatter';

export function useGetHotels() {
  const retrieveProporsals = useStore((state) => state.retrieveProporsals);

  const accommodations = retrieveProporsals?.staticData?.accommodations;

  const bookingType = useBookingType();

  const getParam = useGetParam();

  const adults = getParam('adults');

  const childrenAges = getParam('childrenAges');

  const outboundDate = getParam('outboundDate');

  const returnDate = getParam('returnDate');

  const stayConfig = {
    checkIn: dayjs(outboundDate),
    checkOut: dayjs(returnDate),
  };

  const currency = retrieveProporsals.search.amountInfo.currency;

  const currencyFormatter = new CurrencyFormatter('it-IT', currency, {
    minimumFractionDigits: 0,
  });

  const numberPassengers = adults + childrenAges;

  return React.useMemo(
    () =>
      accommodations
        ?.map((accommodation: Proposal['accommodation']) =>
          getHotelData({
            currencyFormatter,
            stayConfig,
            accommodation,
            retrieveProporsals,
            numberPassengers,
            bookingType,
          })
        )
        .filter((el) => Boolean(el)),
    [accommodations]
  );
}
