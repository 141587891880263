import { Radio } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import * as Styled from './GenderField.styled';
interface GenderFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleBlur: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const GenderField: FC<GenderFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();

  const handleTrigger = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    handleBlur(event);
  };

  return (
    <Styled.Container>
      <Styled.RadioGroup name="gender" value={value} onChange={handleTrigger}>
        <Styled.Option
          value="M"
          control={<Radio />}
          label={t('form.contact.gender.mr')}
          disabled={disabled}
        />
        <Styled.Option
          value="F"
          control={<Radio />}
          label={t('form.contact.gender.ms')}
          disabled={disabled}
        />
      </Styled.RadioGroup>
      {hasError && (
        <Styled.ErrorMsg>{t('form.contact.gender')}</Styled.ErrorMsg>
      )}
    </Styled.Container>
  );
};

export default GenderField;
