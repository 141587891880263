import styled from '@emotion/styled';

export const InnerContainer = styled.a`
  display: flex;
  @media (min-width: 768px) {
    @media (min-width: 768px) {
      width: calc(100% - 220px);
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  @media (max-width: 767px) {
    padding-bottom: 0px;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  min-height: 273px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out 0s;
  display: flex;

  @media (max-width: 767px) {
    min-height: unset;
    flex-direction: column;
  }

  &:hover {
    box-shadow:
      0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 10px rgba(0, 0, 0, 0.04);
  }
`;

export const PriceInfoContainer = styled.div`
  @media (min-width: 768px) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: -16px;
      bottom: 0px;
      width: 16px;
      box-shadow:
        rgba(0, 0, 0, 0.04) -16px 0px 20px -16px inset,
        rgba(0, 0, 0, 0.04) -16px 0px 10px -16px inset,
        rgba(0, 0, 0, 0.02) -16px 0px 4px -16px inset;
    }
  }

  @media (min-width: 768px) {
    width: 180px;
  }

  @media (min-width: 1440px) {
    width: 200px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  padding: 16px;
`;
