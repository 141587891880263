import { useTranslate } from '@tolgee/react';
import { Divider, Flex, Tooltip } from 'antd';
import React, { Fragment } from 'react';
import styled from '@emotion/styled';

export type TransportStop = {
  id: number;
};

export type TransportTimelineProps = {
  duration: string;
  stops: TransportStop[];
  stopLabel: string;
  segmentsInfo: {
    diffTime: number[];
    airportDeparture: string;
    airportName: string;
  }[];
};

export const TransportTimeline: React.FC<TransportTimelineProps> = ({
  duration,
  stops,
  stopLabel,
  segmentsInfo,
}) => {
  const { t } = useTranslate();

  return (
    <TravelSummary>
      <Tooltip title={t('flight.trip_time')}>
        <StyledP color="gray400">{duration}</StyledP>
      </Tooltip>
      <LineWrapper>
        <Arrow />
        <Dots>{stops.map((value) => value && <Dot key={value.id} />)}</Dots>
      </LineWrapper>
      {segmentsInfo ? (
        <Tooltip
          title={
            <Flex gap={2} vertical style={{ textAlign: 'center' }}>
              {segmentsInfo?.map((e, index, arr) => (
                <Fragment key={index}>
                  <StyledP style={{ color: 'white' }}>
                    {e.diffTime[0] ? (
                      <span>{`${e.diffTime[0]}d`}</span>
                    ) : undefined}{' '}
                    {e.diffTime[1] ? (
                      <span>{`${e.diffTime[1]}h`}</span>
                    ) : undefined}{' '}
                    {e.diffTime[2] ? (
                      <span>{`${e.diffTime[2]}m`}</span>
                    ) : undefined}{' '}
                    {t('Layover')} - {`(${e.airportDeparture})`} {e.airportName}
                  </StyledP>
                  {index !== arr.length - 1 && (
                    <Divider
                      style={{ borderColor: 'white', margin: 0 }}
                      dashed
                    />
                  )}
                </Fragment>
              ))}
            </Flex>
          }
        >
          <StyledP color="gray400">{stopLabel}</StyledP>
        </Tooltip>
      ) : (
        <StyledP color="gray400">{stopLabel}</StyledP>
      )}
    </TravelSummary>
  );
};

const Arrow = () => (
  <>
    <Line />
    <Triangle />
  </>
);

const StyledP = styled.p`
  color: gray;
  margin: 0px;
`;

const TravelSummary = styled.div`
  text-align: center;
  height: 50px !important;
  margin: 0px 16px 0px 4px;
`;

const LineWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 1px 0;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid gray;
`;

const Dots = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  padding: 0 16%;
`;

const Dot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: gray;
  border: 2px solid white;
`;
