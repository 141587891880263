import { AuthenticatedDto } from '@/services/codegen-welfare';
import { TextField } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { FC, FocusEventHandler } from 'react';

interface FiscalCodeFieldProps {
  hasError: boolean;
  value: string | number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
}

const FiscalCodeField: FC<FiscalCodeFieldProps> = ({
  hasError,
  handleChange,
  handleBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslate();

  const authInfo: AuthenticatedDto = {};

  return (
    <TextField
      variant="filled"
      label={t('form.contact.fiscal_code')}
      name={'fiscalCode'}
      error={hasError}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value || authInfo?.clientData?.fiscalCode}
      helperText={hasError && t('form.validation.fiscal_code')}
      disabled={!!authInfo?.clientData?.fiscalCode || disabled}
    />
  );
};

export default FiscalCodeField;
