import {
  AuthenticatorInputDto,
  TokenAuthService,
} from '@/services/codegen-welfare';
import { setCookieValue } from './authenticateExternal/setCookieValue';
import {
  encriptedAuthTokenName,
  tokenCookieName,
} from './authenticateExternal/tokenNames';

export const authenticateExternal = async (request: AuthenticatorInputDto) => {
  const result = await TokenAuthService.postApiTokenAuthAuthenticateExternal({
    requestBody: request,
  });

  var tokenExpireDate = new Date(
    new Date().getTime() + 1000 * result.authApiModel.expireInSeconds
  );

  setCookieValue(
    tokenCookieName,
    result.authApiModel.accessToken,
    tokenExpireDate
  );
  setCookieValue(
    encriptedAuthTokenName,
    result.authApiModel.encryptedAccessToken,
    tokenExpireDate
  );

  return result;
};
