import { Tooltip } from 'antd';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslate } from '@tolgee/react';
import { TransportType } from '@/views/FlightsSearch/useGetTransports/types/TransportType';

export type FullTransportTimeAndLocationProps = {
  time: string;
  location: {
    iataCode: string;
    label: string;
    highlight: boolean;
    transportType: TransportType;
  };
  differentDate?: {
    date: string;
    plusDays: number;
  };
  isArrival: boolean;
  city: string;
  enableTooltip?: boolean;
  inLine?: boolean;
};

export const FullTransportTimeAndLocation: React.FC<
  FullTransportTimeAndLocationProps
> = ({
  time,
  differentDate,
  location,
  isArrival,
  enableTooltip = true,
  inLine = false,
}) => {
  const { t } = useTranslate();

  const differentDateMessage = differentDate
    ? composeDifferentDateMessage(differentDate.plusDays, t)
    : '';

  return (
    <TimeAndLocationWrapper>
      <TimeAndDateWrapper>
        <Time $inLine={inLine}>{time}</Time>
        {differentDate && (
          <DateComponent
            tooltipMessage={differentDateMessage}
            days={differentDate.plusDays}
            enableTooltip={enableTooltip}
          />
        )}
      </TimeAndDateWrapper>
      <LocationWrapper $isArrival={isArrival}>
        <Location $highlight={location.highlight}>{location.iataCode}</Location>
      </LocationWrapper>
    </TimeAndLocationWrapper>
  );
};

type DateComponentProps = {
  tooltipMessage: string | JSX.Element;
  enableTooltip?: boolean;
  days?: number;
};

const DateComponent = ({
  tooltipMessage,
  days,
  enableTooltip,
}: DateComponentProps) => (
  <>
    {enableTooltip ? (
      <Tooltip title={tooltipMessage} placement="top">
        <DateWrapper>
          <Date>+{days}</Date>
        </DateWrapper>
      </Tooltip>
    ) : (
      <DateWrapper>
        <Date>+{days}</Date>
      </DateWrapper>
    )}
  </>
);

const composeDifferentDateMessage = (days, t) => {
  if (days > 1) {
    return t('NextDays');
  }
  return t('NextDay');
};

const TimeAndLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const TimeAndDateWrapper = styled.div`
  position: relative;
`;

const Time = styled.span<{ $inLine?: boolean }>`
  font-weight: 700;
  margin-bottom: 0px;

  ${({ $inLine }) =>
    $inLine
      ? css`
          font-size: 18px;
          line-height: 28px;
        `
      : css`
          font-size: 24px;
          line-height: 26px;
        `}

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const LocationWrapper = styled.div<{ $isArrival: boolean }>`
  display: flex;
  align-items: baseline;
  white-space: nowrap;

  ${({ $isArrival }) =>
    $isArrival
      ? css`
          flex-direction: row-reverse;
          margin-right: 16px;
        `
      : ''};
`;

const Location = styled.span<{ $highlight: boolean }>`
  color: ${({ $highlight }) => ($highlight ? 'red' : 'gray')};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
`;

const DateWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: -14px;
  text-align: left;
  width: 26px;
  height: 20px;
  display: inline-block;
`;

const Date = styled.span<{ colour?: string }>`
  color: red;
  font-weight: 700;
`;
