import { useTranslate } from '@tolgee/react';
import * as Styled from './TotalItems.styled';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useGetFiltersData } from '../hooks/useGetFiltersData';
import { isEmpty } from '@/utils/isEmpty';
import { Skeleton } from '../Skeleton';

export function TotalItems() {
  const { t } = useTranslate();

  const freeFlightCampaign = useFreeFlightCampaign();
  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const { retrieveProporsals } = useGetFiltersData();

  const actualSearchAmountItems = retrieveProporsals?.facets.filteredProposals;

  if (isFreeFlight) return null;

  if (isEmpty(retrieveProporsals)) {
    return <Skeleton />;
  }

  return (
    <Styled.Container>
      <Styled.TotalItemsContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: t('filter.total_items', {
              actualSearchAmountItems,
            }),
          }}
        />
      </Styled.TotalItemsContainer>
    </Styled.Container>
  );
}
